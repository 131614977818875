import { createFeature, createReducer, on } from '@ngrx/store';
import { CurrencyCRUDStatus, initialPricingCurrencyState } from './currency-state.model';
import { PricingCurrencyActions } from './currency.actions';

export const pricingCurrencyReducer = createReducer(
  initialPricingCurrencyState,
  on(PricingCurrencyActions.loadPricingCurrencyListSuccess, (state, { data }) => {
    // console.log('Data received in loadPricingCurrencyListSuccess:', data);
    return {
      ...state,
      pricingCurrencyData: {
        id: '',
        name: '',
        currency_code: '',
        rate: '',
        rounding_type: '',
        is_primary_exchange_rate_currency: false,
        is_primary_store_currency: false,
        pricing_tier_status: false,
        force: false,
        published: false,
        country: [],
        symbol: '',
        primary_currency: '',
      },
      pricingCurrencyList: data,
      status: CurrencyCRUDStatus.Success,
    };
  }),

  on(PricingCurrencyActions.addPricingCurrencySuccess, (state, { data }) => {
    console.log('Data received in addPricingCurrencySuccess:', data);
    return {
      ...state,

      status: CurrencyCRUDStatus.Success,
    };
  }),

  on(PricingCurrencyActions.addPricingCurrencyFail, (state, { data }) => {
    console.log('Error in adding new currency addPricingCurrencyFail:', data);
    return {
      ...state,
      pricingCurrencyList: { ...state?.pricingCurrencyList },
      status: CurrencyCRUDStatus.Failed,
    };
  }),

  on(PricingCurrencyActions.addPricingCurrencyFailed, (state, {}) => {
    return {
      ...state,
      status: CurrencyCRUDStatus.Failed,
    };
  }),

  on(PricingCurrencyActions.clearCurrencyCRUDStatus, (state, {}) => ({
    ...state,
    status: CurrencyCRUDStatus.Empty,
  })),

  on(PricingCurrencyActions.getPricingCurrencySuccess, (state, { data }) => {
    // console.log('Data received in getPricingCurrencySuccess:', data);
    return {
      ...state,
      pricingCurrencyData: data,
      status: CurrencyCRUDStatus.Success,
    };
  }),

  on(PricingCurrencyActions.updatePricingCurrencySuccess, (state, { data }) => ({
    ...state,
    // pricingCurrencyList: data,
    // pricingCurrencyList: { ...state?.pricingCurrencyList },
    status: CurrencyCRUDStatus.Success,
  })),

  on(PricingCurrencyActions.updatePricingCurrencyFailed, (state, {}) => {
    return {
      ...state,
      status: CurrencyCRUDStatus.Failed,
    };
  }),

  on(PricingCurrencyActions.removePricingCurrencySuccess, (state, { id }) => {
    const updatedItems = Object.values(state.pricingCurrencyList);
    const filteredData = updatedItems.filter((item: any) => item.id !== id);
    return {
      ...state,
      pricingCurrencyList: filteredData,
      status: CurrencyCRUDStatus.Success,
    };
  })
);

export const pricingCurrencyFeature = createFeature({
  name: 'pricing-currency',
  reducer: pricingCurrencyReducer,
});
