<div class="course-overview p-4">
   <div class="page-heading mb-4 flex items-center justify-between">
      <div>
         <h3>{{ title }}</h3>
      </div>
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'hidden':''"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
               <form [formGroup]="filterForm" (ngSubmit)="submitFilter()">
                  <div class="form">
                     <formly-form [form]="filterForm" [fields]="filterFormFields" [model]="filterFormModel">
                     </formly-form>
                  </div>
                  <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                     <button (click)="resetFilter()" type="button" class="btn btn-outline-dark border-neutral-600">
                        <span>Reset</span>
                     </button>
                     <button type="submit" class="btn btn-dark bg-neutral-600 text-white">
                        <span>Filter</span>
                     </button>
                  </div>
               </form>
            </div>
         </div>
         <div class="input-combine flex">
            <div class="input-group">
               <div class="group">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input (keyup.enter)="search($event)" placeholder="Enter your full name" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex items-center gap-1">
               <h6>Top course tables</h6>
               <div class="tag tag-sm">50 Courses</div>
            </div>
            <div class="actions relative">
               <button (click)="dropdown('table')" class="btn-neutral px-2">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button>
               <div [ngClass]="isDropdown['table']?'':'hidden'"
                  class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
                  <div *ngFor="let col of columns" class="input-check w-48 my-2">
                     <input [checked]="col.visible" (change)="toggleColumn(col)" type="checkbox" id="default-checkbox">
                     <label for="default-checkbox">{{col.name}}</label>
                  </div>
               </div>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list">
               <div class="datagrid-table relative overflow-x-auto">
                  <table class="w-full text-left">
                     <thead class="heading">
                        <tr>
                           <ng-container *ngFor="let column of columns">
                              <th *ngIf="column.visible" scope="col" class="">
                                 <div class="col-name">{{column.name}}</div>
                              </th>
                           </ng-container>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="" *ngFor="let course of courses$ | async; let i = index">
                           <td *ngIf="columns[0].visible" class="">
                              <span class="course-id">
                                 <a [routerLink]="'/course/' + course.id + '/details'"
                                    class="hover:text-blue-600 hover:underline">
                                    #{{ course.id.substring(0, 8) }}
                                 </a>
                              </span>
                           </td>
                           <td *ngIf="columns[1].visible">
                              <div class="flex min-h-[69px] max-w-[170px] items-center">
                                 <a [routerLink]="'/course/' + course.id + '/details'"
                                    class="course-name line-clamp-3 text-blue-600 underline">
                                    #{{ course.title }}
                                 </a>
                              </div>
                           </td>
                           <td *ngIf="columns[2].visible" class="max-w-[170px]">
                              <span class="whitespace-nowrap text-blue-600 underline" *ngIf="course.user">
                                 {{ course?.user }}
                              </span>
                           </td>
                           <td *ngIf="columns[3].visible">
                              <span class="course-category line-clamp-3 capitalize" *ngIf="course.category">
                                 {{ (getCategoryName(course.category) | async)?.name }}
                              </span>
                           </td>
                           <td *ngIf="columns[4].visible" class="max-w-[170px]">
                              <ul class="course-curriculum list-inside list-disc">
                                 <!-- <li class="whitespace-nowrap">{{ course.course_topics.total_sections }} Sections</li>
                       <li class="whitespace-nowrap">{{ course.course_topics.total_lectures }} Lectures</li> -->
                              </ul>
                           </td>
                           <td *ngIf="columns[5].visible">
                              <!-- <span class="course-price">{{ course.listed_price | price }}</span> -->
                              <span class="course-price">{{ +(course.price ?? '') | price }}</span>
                           </td>
                           <td *ngIf="columns[6].visible">
                              <button (click)="seeChart(course)" class="btn bg-blue-50 text-blue-500 border-0">
                                 <svg-icon src="@assets/images/icons/arrow-trending-up.svg" class="btn-icon"></svg-icon>
                                 <span>See Chart</span>
                              </button>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <app-common-pagination (paginatorData)="paginat($event)" [type]="'dynamic'"></app-common-pagination>
            </div>
         </div>
      </div>
   </div>

   <!-- Chart -->
   <div class="statistics-blocks mt-4">
      <div class="grid grid-cols-12 gap-4">
         <div class="col-span-6">
            <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
               <div class="card-heading flex items-center justify-between border-b-2 border-neutral-50 px-9 py-4">
                  <div class="flex items-center gap-1">
                     <h6>Enrollments</h6>
                     <div class="tag tag-sm">50 Courses</div>
                  </div>
                  <div class="actions">
                     <div class="form">
                        <form [formGroup]="enrollmentsFilterForm">
                           <formly-form [form]="enrollmentsFilterForm" [fields]="enrollmentsFormFields"
                              [model]="enrollmentsFormModel">
                           </formly-form>
                        </form>
                     </div>
                  </div>
               </div>
               <div class="card-body">
                  <div class="py-6 pl-8 pr-3">
                     <app-common-chart [tickYValues]="lineCharttickYValues" [tickYMin]="0" [tickYMax]="750"
                        [tickYStepSize]="250" [inputLineChartData]="lineChartData" [chartStyle]="'lineChart'"
                        [inputLineChartLabels]="lineChartLabels" (chartFilter)="chartFilter($event)">
                     </app-common-chart>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-span-6">
            <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
               <div class="card-heading flex items-center justify-between border-b-2 border-neutral-50 px-[10px] py-5">
                  <div class="flex items-center gap-1">
                     <h6>Top Courses</h6>
                  </div>
                  <div class="actions">
                     <div class="form">
                        <form [formGroup]="topCourseFilterForm">
                           <formly-form [form]="topCourseFilterForm" [fields]="topCourseFilterFormFields"
                              [model]="topCourseFilterFormModel">
                           </formly-form>
                        </form>
                     </div>
                  </div>
               </div>
               <div class="card-body">
                  <div class="p-4">
                     <app-common-chart [barChartTickYValues]="barCharttickYValues" [inputBarChartData]="barChartData"
                        [chartStyle]="'barChart'" [inputBarChartLabels]="barChartLabels"
                        (chartFilter)="chartFilter($event)">
                     </app-common-chart>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-span-3">
            <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
               <div class="card-heading flex flex-col border-b-2 border-neutral-50 px-[10px] pt-5">
                  <h6 class="text-[18px] text-slate-600">Most Popular Courses</h6>
                  <p class="text-sm text-slate-400">
                     Based on the number of users who started the course in the specified time period.
                  </p>
               </div>
               <div class="card-body">
                  <div class="flex justify-between px-4 pb-2 border-b border-neutral-100">
                     <h6 class="text-slate-900 text-sm">Number</h6>
                     <h6 class="text-slate-900 text-sm">Courses</h6>
                  </div>
                  <div class="p-4 flex flex-col gap-3">
                     <!-- Progressbar -->
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#0496FF] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,100</h6>
                           <h6 class="text-[#0496FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#FF715B] h-1.5 max-w-full rounded-full" [style.width.%]="60"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,000</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#FF715B] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1.200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#DE0D92] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1.200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#34D1BF] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="col-span-3">
            <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
               <div class="card-heading flex flex-col border-b-2 border-neutral-50 px-[10px] pt-5">
                  <h6 class="text-[18px] text-slate-600">Most Engaging Courses</h6>
                  <p class="text-sm text-slate-400">
                     Based on the average time spent in the course in the specified time period.
                  </p>
               </div>
               <div class="card-body">
                  <div class="flex justify-between px-4 pb-2 border-b border-neutral-100">
                     <h6 class="text-slate-900 text-sm">Number</h6>
                     <h6 class="text-slate-900 text-sm">Courses</h6>
                  </div>
                  <div class="p-4 flex flex-col gap-3">
                     <!-- Progressbar -->
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#0496FF] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,100</h6>
                           <h6 class="text-[#0496FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#FF715B] h-1.5 max-w-full rounded-full" [style.width.%]="60"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,000</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#FF715B] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1.200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#DE0D92] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1.200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#34D1BF] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="col-span-3">
            <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
               <div class="card-heading flex flex-col border-b-2 border-neutral-50 px-[10px] pt-5">
                  <h6 class="text-[18px] text-slate-600">Unfinished Courses</h6>
                  <p class="text-sm text-slate-400">
                     Based on the percentage of users who haven’t completed the course on time.
                  </p>
               </div>
               <div class="card-body">
                  <div class="flex justify-between px-4 pb-2 border-b border-neutral-100">
                     <h6 class="text-slate-900 text-sm">Number</h6>
                     <h6 class="text-slate-900 text-sm">Courses</h6>
                  </div>
                  <div class="p-4 flex flex-col gap-3">
                     <!-- Progressbar -->
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#0496FF] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,100</h6>
                           <h6 class="text-[#0496FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#FF715B] h-1.5 max-w-full rounded-full" [style.width.%]="60"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,000</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#FF715B] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1.200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#DE0D92] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1.200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#34D1BF] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="col-span-3">
            <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
               <div class="card-heading flex flex-col border-b-2 border-neutral-50 px-[10px] pt-5">
                  <h6 class="text-[18px] text-slate-600">Withdrawn Students</h6>
                  <p class="text-sm text-slate-400">
                     Based on the percentage of opt-out users for the past 30 days.
                  </p>
               </div>
               <div class="card-body">
                  <div class="flex justify-between px-4 pb-2 border-b border-neutral-100">
                     <h6 class="text-slate-900 text-sm">Number</h6>
                     <h6 class="text-slate-900 text-sm">Courses</h6>
                  </div>
                  <div class="p-4 flex flex-col gap-3">
                     <!-- Progressbar -->
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#0496FF] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,100</h6>
                           <h6 class="text-[#0496FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#FF715B] h-1.5 max-w-full rounded-full" [style.width.%]="60"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1,000</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#FF715B] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1.200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#DE0D92] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                     <div class="flex w-full flex-col gap-2">
                        <div class="flex justify-between">
                           <h6 class="text-slate-900 text-sm">1.200</h6>
                           <h6 class="text-[#9094FF] text-sm">#SAP MM Material ...</h6>
                        </div>
                        <div class="progress-track h-1.5 overflow-hidden rounded-full bg-[#E5E5E5]">
                           <div class="progres bg-[#34D1BF] h-1.5 max-w-full rounded-full" [style.width.%]="70"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   </div>
</div>


<!-- Hold confirm modal -->
<common-modal #chartModal customClass="sm:w-full sm:max-w-[540px]">
   <ng-container header>
      <div *ngIf="chartModal.isOpened" class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl">#{{selectedCourse.title}}</h1>
         </div>
         <button (click)="chartModal.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <h1 class=" text-center p-40">Chart</h1>
   </ng-container>
</common-modal>