import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { authFeature } from './auth.reducer';

export const {
  selectAuthState,
  selectUserId,
  selectEmail,
  selectFirstName,
  selectIsInstructor,
  selectLastName,
  selectToken,
  selectUsername,
} = authFeature;

export const selectIsAuthenticated = createSelector(selectAuthState, (auth) => !_.isEmpty(auth.token));
