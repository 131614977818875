import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SharesAdsComponent } from './ads/ads.component';
import { SharesAffiliateComponent } from './affiliate/affiliate.component';
import { SharesInstructorComponent } from './instructor/instructor.component';
import { SharesOrganicsComponent } from './organics/organics.component';
import { PriceSharesComponent } from './price-shares.component';
import { SharesStudentComponent } from './student/student.component';

export const Components = [
  PriceSharesComponent,
  SharesOrganicsComponent,
  SharesAdsComponent,
  SharesInstructorComponent,
  SharesStudentComponent,
  SharesAffiliateComponent,
];

export const Routing = [
  {
    path: 'shares/organics',
    component: SharesOrganicsComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Organics' },
  },
  {
    path: 'shares/ads',
    component: SharesAdsComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Ads' },
  },
  {
    path: 'shares/instructor-referral',
    component: SharesInstructorComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Instructor' },
  },
  {
    path: 'shares/student-referral',
    component: SharesStudentComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Student' },
  },
  {
    path: 'shares/affiliate',
    component: SharesAffiliateComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Affiliate' },
  },
];
