import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PricingCurrencyState } from './currency-state.model';

export const selectPricingCurrencyState = createFeatureSelector<PricingCurrencyState>('pricing-currency');

export const selectPricingCurrency = createSelector(selectPricingCurrencyState, (entities) => entities);

// export const selectPricingCurrencyList = createSelector(
//   selectPricingCurrencyState,
//   (entities) => entities.pricingCurrencyList
// );

export const selectPricingCurrencyList = createSelector(
  selectPricingCurrencyState,
  (entities) => {
    // console.log('Inside selectPricingCurrencyList selector. Entities:', entities);
    return entities.pricingCurrencyList;
  }
);

export const selectLoadedPricingCurrencyData = createSelector(
  selectPricingCurrencyState,
  (entities) => entities.pricingCurrencyData
);

export const selectCurrencyCRUDStatus = createSelector(selectPricingCurrencyState, (entities) => entities.status);
