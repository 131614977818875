<app-course-header></app-course-header>
<div class="course-statistics p-4">
  <app-course-heading></app-course-heading>

  <div class="statistics-blocks">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-8">
        <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
          <div class="card-heading flex items-center justify-between border-b-2 border-neutral-50 px-9 py-4">
            <div class="title">
              <!-- <h6>User Progress</h6> -->
            </div>
            <div class="actions">
              <div class="select2">
                <div class="relative">
                  <button type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
                    (click)="select.select = !select.select" [class.active]="select.select">
                    <div class="selected-item truncate">Last 7 Days</div>
                  </button>
                  <ul [ngClass]="select.select ? 'show' : 'hidden'" tabindex="-1" role="listbox"
                    aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3" class="select-items">
                    <li id="listbox-option-0" role="option" class="item">Today</li>
                    <li id="listbox-option-0" role="option" class="item selected">Last 7 Days</li>
                    <li id="listbox-option-0" role="option" class="item">This Month</li>
                    <li id="listbox-option-0" role="option" class="item">Custom Date</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="py-6 pl-8 pr-3">
              <app-common-chart [tickYValues]="lineCharttickYValues" [tickYMin]="0" [tickYMax]="750"
                [tickYStepSize]="250" [inputLineChartData]="lineChartData" [chartStyle]="'lineChart'"
                [inputLineChartLabels]="lineChartLabels" (chartFilter)="chartFilter($event)">
              </app-common-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-4">
        <div class="card h-full rounded-[5px] border border-neutral-100 bg-white text-black">
          <div class="card-heading flex items-center justify-between border-b-2 border-neutral-50 px-4 py-4">
            <div class="title">
              <h6>General Stats</h6>
            </div>
            <div class="actions"></div>
          </div>
          <div class="card-body">
            <div class="stats-grid p-5">
              <div class="grid grid-cols-3 gap-5">
                <div class="text-center">
                  <div class="mb-2 text-lg font-bold">0</div>
                  <div class="text-sm">All User</div>
                </div>
                <div class="text-center">
                  <div class="mb-2 text-lg font-bold">0</div>
                  <div class="text-sm">New User</div>
                </div>
                <div class="text-center">
                  <div class="mb-2 text-lg font-bold">0</div>
                  <div class="text-sm">Active User</div>
                </div>
                <div class="text-center">
                  <div class="mb-2 text-lg font-bold">0</div>
                  <div class="text-sm">Total Time</div>
                </div>
                <div class="text-center">
                  <div class="mb-2 text-lg font-bold">0</div>
                  <div class="text-sm">Course Revenue</div>
                </div>
                <div class="text-center">
                  <div class="mb-2 text-lg font-bold">0</div>
                  <div class="text-sm">Course Sales Count</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-4">
        <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
          <div class="card-heading flex items-center justify-between border-b-2 border-neutral-50 px-[10px] py-5">
            <div class="title">
              <h6>Course Done</h6>
            </div>
            <div class="actions">
              <button class="btn-outline">
                <span>See All</span>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="p-4">
              <app-common-chart [barChartTickYValues]="courseDoneTickYValues" [inputBarChartData]="courseDoneData"
                [chartStyle]="'barChart'" [inputBarChartLabels]="courseDoneLabels" (chartFilter)="chartFilter($event)">
              </app-common-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-4">
        <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
          <div class="card-heading flex items-center justify-between border-b-2 border-neutral-50 px-[10px] py-5">
            <div class="title">
              <h6>Active User</h6>
            </div>
            <div class="actions">
              <button class="btn-outline">
                <span>See All</span>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="p-4">
              <app-common-chart [barChartTickYValues]="activeUserTickYValues" [inputBarChartData]="activeUserData"
                [chartStyle]="'barChart'" [inputBarChartLabels]="activeUserLabels" (chartFilter)="chartFilter($event)">
              </app-common-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-4">
        <div class="card rounded-[5px] border border-neutral-100 bg-white text-black">
          <div class="card-heading flex items-center justify-between border-b-2 border-neutral-50 px-[10px] py-5">
            <div class="title">
              <h6>Inactive User</h6>
            </div>
            <div class="actions">
              <button class="btn-outline">
                <span>See All</span>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="p-4">
              <app-common-chart [barChartTickYValues]="inactiveUserTickYValues" [inputBarChartData]="inactiveUserData"
                [chartStyle]="'barChart'" [inputBarChartLabels]="inactiveUserLabels"
                (chartFilter)="chartFilter($event)">
              </app-common-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>