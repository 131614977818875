<div class="w-full flex justify-between p-4">
   <button class="btn-secondary btn btn-sm" (click)="back()">Back</button>
</div>
<div class="course-overview p-4 pt-0">
   <div class="flex items-center justify-between mb-4">
      <div class="page-heading mb-4">
         <div *ngIf="!request"[thkLibProcessing]="true" class="flex"></div>
         <h3 *ngIf="request" class="text-2xl font-semibold text-neutral-800">
            Payout ({{requestId$ | async}}) {{request.instructor?.fullname}} (<span class="text-blue-600 cursor-pointer">{{request.instructor?.id}}</span>)
            {{request.transaction_type}}
         </h3>
      </div>
      <div class="actions flex flex-row gap-4">
         <button class="btn px-6 py-3 bg-teal-50 rounded-[4px] gap-2 border-0">
            <svg-icon src="@assets/images/icons/payout-receipt-icon.svg" class="text-teal-600"></svg-icon>
            <span class="text-teal-600 font-semibold text-sm">Payout Receipt</span>
         </button>
         <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
            <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
            <span class="text-blue-600 font-semibold text-sm">Export CSV</span>
         </button>
      </div>
   </div>
   <div class="flex flex-col gap-4 mb-4">
      <div *ngIf="!summary" class="flex py-10 w-full" [thkLibProcessing]="true"></div>
      <div *ngIf="summary" class="flex flex-row gap-4">
         <app-metric-card [data]="{ title: 'Total Amount', value: summary.total_requested_amount | currency:summary.currency_symbol }" class="w-full"></app-metric-card>
         <app-metric-card [data]="{ title: 'Period', value: summary.period_start + ' - ' + (summary.period_end || 'N/A') }" class="w-full"></app-metric-card>
         <app-metric-card [data]="{ title: 'Total Transactions', value: summary.total_transactions }" class="w-full"></app-metric-card>
         <app-metric-card [data]="{ title: 'Total Refund', value: summary.total_requested_amount | currency:summary.currency_symbol }" class="w-full"></app-metric-card>
      </div>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="toggleDropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="showingDropdown === 'filter' ? '' : 'hidden'"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <formly-form [form]="filterForm" [fields]="filterFormFields" [model]="filterFormModel">
                  </formly-form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
               </div>
            </div>
         </div>
         <!-- Search With Dropdown -->
         <app-combination-single-input [keys]="searchOptions" [model]="searchValue" (valueChanges)="search($event)"></app-combination-single-input>
      </div>
   </div>

   <div class="flex items-start gap-4">
      <div class="flex-3 w-full overflow-hidden card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex items-center gap-1">
               <h6>Transactions</h6>
               <div *ngIf="transactionsPagination" class="tag tag-sm">{{transactionsPagination.count}} Transactions</div>
            </div>
            <div class="actions flex flex-row gap-4">
               <button class="btn-neutral px-3">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button>
            </div>
         </div>
         <div class="card-body">
            <div *ngIf="!transactionsPagination" class="flex w-full justify-center py-10" [thkLibProcessing]="true"></div>
            <div *ngIf="transactionsPagination" class="courses-list">
               <!-- Table -->
               <div class=" datagrid-table relative overflow-x-auto">
                  <table class="w-full min-w-[850px] text-left">
                     <thead class="heading">
                        <tr>
                           <th scope="col" class="">
                              <div class="col-name">Transaction ID</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Order ID</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Date</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Sale Price</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Thkee Earning</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Net Ammount</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Instructor Earning</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Status</div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="hover:cursor-pointer" *ngFor="let data of transactionsPagination?.results">
                           <td>
                              <span class="course-id">
                                 <span (click)="openTransactionDrawer(data)" class="text-blue-600 cursor-pointer">
                                    {{ data.id }}
                                 </span>
                              </span>
                           </td>
                           <td>
                              <span class="course-id">
                                 <span (click)="openOrderDrawer(data)" class="text-blue-600 cursor-pointer">
                                    {{ data.order_id }}
                                 </span>
                              </span>
                           </td>
                           <td>
                              <span class="course-id">
                                 <span class="">
                                    {{ data.date_created | date:'d MMMM y' }}
                                 </span>
                              </span>
                           </td>
                           <td class="max-w-[170px]">
                              <span class="">
                                 {{ data.sale_price | currency:data.currency_symbol }}
                              </span>
                           </td>
                           <td>
                              {{data.platform_share | currency:data.currency_symbol}}
                           </td>
                           <td>
                              {{data.net_amount | currency:data.currency_symbol}}
                           </td>
                           <td>
                              <span class="course-price flex items-center">
                                 <button class="btn btn-transparent p-1">
                                    <svg-icon src="@assets/images/icons/down-circle.svg" tooltip="Co-Instructor Earning"
                                       class="w-5 h-5">
                                    </svg-icon>
                                 </button>
                                 {{ data.instructor_share | currency:data.currency_symbol }}
                              </span>
                           </td>
                           <td>
                              <div [ngClass]="getStatus(data.payout_status).bg"
                                 class="flex flex-col px-2.5 py-[5px] rounded-2xl">
                                 <span [ngClass]="getStatus(data.payout_status).text" class="text-sm">{{data.payout_status}}</span>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <!-- Pagination -->
               <div class="pagination flex items-center justify-between p-5">
                  <thk-pagination class="w-full" (paginationChange)="paginate($event)" [sizeChangable]="true" [total]="transactionsPagination?.count || 0" [page]="query?.page" [size]="query?.page_size || 10"></thk-pagination>
               </div>
            </div>
         </div>
      </div>

      <div class="min-w-[275px] card rounded-lg border border-neutral-100 px-4 flex-1">
         <div class="py-4">
            <h5 class="text-2xl font-semibold text-black mb-4">History</h5>
            <app-payout-request-history *ngIf="requestId$ | async as id" [requestId]="+id"></app-payout-request-history>
         </div>
      </div>
   </div>
</div>


<!-- Transaction details drawer -->
<app-drawer #transactionDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h1 class="text-black font-semibold text-xl">Transaction ID {{transactionData?.id}}</h1>
         <div class="flex justify-between items-center gap-3">
            <button [routerLink]="'/ecommerce/sales/transaction/'+transactionData?.id"
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to Transaction detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="transactionDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>

   <ng-container content>
      <app-transaction-details-overview [id]="transactionData?.id" [isSidebarComponent]="true">
      </app-transaction-details-overview>
   </ng-container>
</app-drawer>


<!-- Order details drawer -->
<app-drawer #orderDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h1 class="text-black font-semibold text-xl">Order ID {{orderData?.order_id}}</h1>
         <div class="flex justify-between items-center gap-3">
            <button [routerLink]="'/ecommerce/order/'+orderData?.order_id"
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to Order detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="orderDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>

   <ng-container content>
      <app-order-details-overview [id]="orderData?.order_id" [isSidebarComponent]="true"></app-order-details-overview>
   </ng-container>
</app-drawer>
