import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TabItem } from '@shared';
import * as _ from 'lodash';

interface Item {
  current: string;
  parent?: string;
  data?: any;
}

@Component({
  selector: 'app-common-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
})
export class TabMenuComponent implements OnInit {
  @Input() items: TabItem[] = [];
  @Input() title: string = '';
  // @Output() item = new EventEmitter<Item>();
  parentRoute: string = '';
  activeItem: string = '/';

  constructor(private router: Router) {
    this.getActive();
    router.events.forEach((event) => {
      // TODO: replace by state route
      if (event instanceof NavigationEnd) this.getActive();
    });
  }

  ngOnInit(): void {}

  getActive() {
    this.activeItem = '/' + _.last(this.router.url.split('/'));
    this.parentRoute = this.router.url.replace(this.activeItem, '');
    // this.item.emit({
    //   current: this.activeItem,
    //   parent: this.parentRoute,
    // });
  }
}
