<table class="w-full text-left">
  <thead class="heading">
    <tr>
      <th scope="col" class="w-80">
        <div class="col-name">Groups</div>
      </th>
      <th scope="col" class="w-64">
        <div class="col-name">Instructor Share</div>
      </th>
      <th scope="col" class="w-64">
        <div class="sortable">
          <div class="col-name">Platform Shares</div>
        </div>
      </th>
      <th scope="col">
        <div class="col-name">Channel</div>
      </th>
      <th scope="col" class="w-20" *ngIf="enableEdit">
        <div class="col-name">Option</div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class="" class="text-left" *ngFor="let data of data">
      <td class="!py-6">
        <span class="course-id py-3">{{ data.group_name }}</span>
      </td>
      <td>
        <span *ngIf="editId !== data.id" class="course-id">{{ data.instructor_share }}%</span>
        <div *ngIf="editId === data.id">
          <input placeholder="0%" class="input-field" [(ngModel)]="editModel.instructor_share"
            (ngModelChange)="onShareChange($event, 'instructor_share')" />
        </div>
      </td>
      <td>
        <span *ngIf="editId !== data.id" class="course-price">{{ data.platform_share }}%</span>
        <div *ngIf="editId === data.id">
          <input placeholder="0%" class="input-field" [(ngModel)]="editModel.platform_share"
            (ngModelChange)="onShareChange($event, 'platform_share')" />
        </div>
      </td>
      <td>
        <span class="course-sold normal-case">{{ ShareTypes[data.share_types] }}</span>
      </td>
      <td *ngIf="enableEdit">
        <div class="flex justify-center gap-2">
          <button *ngIf="editId !== data.id" class="btn-amber-soft btn-sm px-2" (click)="editSharePricing(data)">
            <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
            Edit
          </button>
          <button *ngIf="editId === data.id" (click)="saveSharePricing()" class="btn-teal btn-sm px-2">Save</button>
        </div>
      </td>
    </tr>
  </tbody>
</table>