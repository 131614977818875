<div class="menu-dropdown {{ alignment }}">
  <div class="button-container" (ClickOutside)="handleClickOutside()" (click)="show = !show">
    <ng-content select="[button]"></ng-content>
  </div>
  <div [ngClass]="show ? 'active' : 'hidden'" class="dropdown-container">
    <div class="content max-h-[400px]">
      <ng-container *ngTemplateOutlet="listTemplate; context: { items: items }"></ng-container>
    </div>
  </div>
</div>

<ng-template #listTemplate let-items="items">
  <ul class="dropdown-items">
    <li class="relative" *ngFor="let item of items; let i = index">
      <a [routerLink]="item.link">
        <svg-icon src="{{ item.icon }}" *ngIf="item.icon"></svg-icon>
        {{ item.title }}
        <svg-icon *ngIf="item.subItem" class="sub-arrow" src="@assets/images/icons/chevron-right.svg"></svg-icon>
      </a>
      <ng-container *ngIf="item.subItem">
        <div class="sub-item dropdown-container">
          <ng-container *ngTemplateOutlet="listTemplate; context: { items: item.subItem }"></ng-container>
        </div>
      </ng-container>
    </li>
  </ul>
</ng-template>
