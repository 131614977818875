import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-wrapper-iframe',
  template: ` <div class="h-full w-full" [innerHtml]="sanitizedHtml"></div> `,
})
export class FormlyWrapperIframeComponent extends FieldWrapper implements OnInit {
  sanitizedHtml!: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    const htmlContent = this.field.props?.['htmlContent'] || '';
    this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }
}
