import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';

export type ButtonProps = {
  disabled?: boolean;
  click?: () => void;
  template: '';
  inputAppendClass?: string[];
};

@Component({
  selector: 'app-formly-button',
  template: `
    <button [disabled]="to.disabled" (click)="handleClick()" type="button" class="w-full">
      <span class="w-full" [innerHtml]="sanitizeHTML(field.props?.['template'])"></span>
    </button>
  `,
  styles: [
    `
      :host {
        @apply w-full;
      }
    `,
  ],
})
export class FormlyButtonTypeComponent extends FieldArrayType<FormlyFieldConfig<ButtonProps>> {
  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {}

  handleClick() {
    if (this.props?.click) {
      this.props.click();
    }
  }

  sanitizeHTML(url?: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(url ?? '');
  }
}
