import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  PricingTierActions,
  PricingTierPayload,
  PricingTierStatus,
  selectPricingTier,
  selectPricingTierCRUDStatus,
} from '@shared';
import { Observable } from 'rxjs';
import { ModalService, PricingTier, ToastService } from 'thkee-common';
@Component({
  selector: 'app-price-tiers',
  templateUrl: './price-tiers.component.html',
  styleUrls: ['./price-tiers.component.scss'],
})
export class PriceTiersComponent implements OnInit {
  title: string = 'Pricing Tiers';
  pricingTierList$: Observable<any> = this.store.select(selectPricingTier);
  priceTierData: Partial<PricingTier>[] = [];

  pricingTierList: any = [];
  pricingTierPayload!: PricingTierPayload;

  status$: Observable<PricingTierStatus> = this.store.select(selectPricingTierCRUDStatus);
  constructor(
    private store: Store,
    private readonly modalService: ModalService,
    private readonly toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.pricingTierPayload = {
      page: 1,
      page_size: 10,
    };
    this.store.dispatch(PricingTierActions.loadPricingTierList(this.pricingTierPayload));
    this.store.dispatch(PricingTierActions.clearPricingTierStatus());

    this.pricingTierList$.subscribe((item) => {
      if (typeof item.pricingTierList.results !== 'undefined') {
        this.pricingTierList = [...item?.pricingTierList?.results] || [];
      }
    });
  }

  remove(data: PricingTier) {
    this.store.dispatch(PricingTierActions.removePricingTier({ id: data?.id }));

    this.status$.subscribe((status) => {
      if (status === PricingTierStatus.Success) {
        this.toastService.message({
          message: `Pricing Tier removed successfully`,
        });
        this.router.navigate(['/price-management/tiers']);
      }
    });
  }

  deleteConfirmation(data: PricingTier) {
    this.modalService.confirm({
      title: 'Delete Confirmation',
      message: `Are you sure?`,
      onConfirm: () => {
        // Con confirm action here
        // console.log('Delete this: ', { id: data?.id });
        this.remove(data);
      },
    });
  }
}
