import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PricingGeoActions, PricingGeoPayload, PricingGeoService, selectPricingGeoList } from '@shared';
import { Observable } from 'rxjs';
import { GeoLocation, ModalService, ToastService } from 'thkee-common';

@Component({
  selector: 'app-price-geolocation',
  templateUrl: './price-geolocation.component.html',
  styleUrls: ['./price-geolocation.component.scss'],
})
export class PriceGeolocationComponent implements OnInit {
  pricingGeoList$: Observable<GeoLocation[]> = this.store.select(selectPricingGeoList);
  priceGeoData: Partial<GeoLocation>[] = [];

  pricingGeoList: any = [];
  pricingGeoPayload!: PricingGeoPayload;
  constructor(
    private store: Store,
    private pricingGeoService: PricingGeoService,
    private readonly modalService: ModalService,
    private router: Router,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.pricingGeoPayload = {
      page: 1,
      page_size: 10,
    };
    this.store.dispatch(PricingGeoActions.loadGeoLocation(this.pricingGeoPayload));
  }

  deleteConfirmation(data: GeoLocation) {
    this.modalService.confirm({
      title: 'Delete Confirmation',
      message: `Are you sure?`,
      onConfirm: () => {
        if (data.id) {
          this.pricingGeoService.deleteGeoLoc(data.id).subscribe((response) => {
            this.toastService.message({
              message: `Geolocation successfully deleted..`,
            });
            this.store.dispatch(PricingGeoActions.loadGeoLocation(this.pricingGeoPayload));
          });
        }
      },
    });
  }
}
