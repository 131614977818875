import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DrawerComponent, UntilDestroy, untilDestroyed } from '@shared';
import { PAYOUT_METHOD_IDS, PAYOUT_METHOD_NAMES } from '@shared/constants/payout';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { combineLatest, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { AdminInstructorBalance, AdminInstructorWithdrawActivitiesQuery, AdminInstructorWithdrawActivity, AdminInstructorWithdrawEvent, AdminPayoutTransaction, Pagination, QueryFormConverter, QueryStringFilterService, RouterStoreService, TabItem } from 'thkee-common';

const WITHDRAW_EVENTS: Record<AdminInstructorWithdrawEvent, string> = {
  'requested': 'Requested',
  'paid': 'Paid',
  'failed': 'Failed',
  'deactivated': 'Deactivated',
  'activated': 'Activated',
  'ready': 'Ready',
  'voided': 'Unset',
  'refund': 'Refund'
};
@UntilDestroy()
@Component({
  selector: 'app-instructor-balance-details',
  templateUrl: './instructor-balance-details.component.html',
  styleUrls: ['./instructor-balance-details.component.scss'],
  providers: [QueryStringFilterService.forComponent({ key: 'withdraw' })]
})
export class InstructorBalanceDetailsComponent implements OnInit, AfterViewInit {
  instructorBalance?: AdminInstructorBalance;
  params: any = {};
  summaryItems: { title: string, field: keyof AdminInstructorBalance }[] = [
    {
       title: 'Available Balances',
       field: 'available_balance',
    },
    {
       title: 'Pending Balances',
       field: 'pending_balance',
    },
    {
       title: 'Total Earning',
       field: 'total_earnings',
    },
    {
       title: 'Total Withdrawn',
       field: 'total_withdraw',
    },
    {
       title: 'Total Refund',
       field: 'total_refund',
    },
    // {
    //    title: 'Forced Refund',
    //    field: 'forced_refund',
    // },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout method</div>`,
    },
    {
      key: 'payout_method',
      fieldGroup: Object.values(PAYOUT_METHOD_IDS).map(id => {
        return {
          key: id,
          type: 'checkbox',
          className: '',
          props: {
            label: PAYOUT_METHOD_NAMES[id],
            required: true,
          },
        }
      })
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Event</div>`,
    },
    {
      key: 'event',
      fieldGroup: Object.entries(WITHDRAW_EVENTS).map(([event, label]) => {
        return {
          key: event,
          type: 'checkbox',
          className: '',
          props: {
            label,
            required: true,
          },
        }
      })
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Date Created</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'date_created_before',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'yyyy-mm-dd',
          },
        },
        {
          template: '-',
        },
        {
          key: '  date_created_after',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'yyyy-mm-dd',
          },
        },
      ],
    },
  ];

  tabItems: TabItem[] = [];
  query?: AdminInstructorWithdrawActivitiesQuery;

  private queryFormConverter = new QueryFormConverter<AdminInstructorWithdrawActivitiesQuery>(
    ['payout_method', 'event']
  );
  private instructorId$ = this.routerStore.getParam('instructorId').pipe(
    filter(Boolean),
    distinctUntilChanged()
  );

  withdrawsPagination?: Pagination<AdminInstructorWithdrawActivity>;

  constructor(
    private routerStore: RouterStoreService,
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
    private queryFilterService: QueryStringFilterService<AdminInstructorWithdrawActivitiesQuery>,
  ) {}

  ngOnInit(): void {
    this.getInstructorBalance()
    this.initialUiData();
  }

  ngAfterViewInit(): void {
    this.setupFilterWithdraws();
  }

  paginate($event: PaginationChange) {
    this.queryFilterService.patch($event);
  }

  search(value: string) {
    if (value) {
      this.queryFilterService.patch({ search: value });
      return;
    }
    this.queryFilterService.remove(['search']);
  }

  resetFilter() {
    this.filterForm.reset();
  }

  // Dropdown methode
  showingDropdown: string = '';
  dropdown(type: any) {
    if (type === this.showingDropdown) {
      this.showingDropdown = '';
      return;
    }

    this.showingDropdown = type;
  }
  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Available') {
      style.bg = 'bg-blue-50';
      style.text = 'text-blue-600';
    } else if (status === 'Forced Refund') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-amber-50';
      style.text = 'text-amber-600';
    }
    return style;
  }

  transactionData: any;
  openTransactionDrawer(data: AdminPayoutTransaction, drawer: DrawerComponent) {
    this.transactionData = data;
    drawer.open();
  }

  private getInstructorBalance() {
    this.instructorId$
      .pipe(untilDestroyed(this))
      .subscribe(intructorId => {
        this.payoutService.getInstructorBalance(Number(intructorId))
          .subscribe(data => this.instructorBalance = data);
      })
  }

  private initialUiData() {
    this.routerStore.getParam('instructorId').subscribe(instructorId => {
      this.tabItems = [
        { id: 'Requests', title: 'Requests', route: `/ecommerce/payout/instructor-balance/${instructorId}/requests` },
        { id: 'Transactions', title: 'Transactions', route: `/ecommerce/payout/instructor-balance/${instructorId}/transactions` },
      ];

      const breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Instructor Balances',
          url: '/ecommerce/payout/instructor-balances',
        },
        {
          label: `Faris's #${instructorId}`,
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  private setupFilterWithdraws() {
    this.queryFilterService.initialValue.subscribe(query => {
      this.query = query;
      this.filterForm.patchValue(this.queryFormConverter.toForm(query))
    });

    this.filterForm.valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe(formValue => {
      this.queryFilterService.patch(this.queryFormConverter.toQuery(formValue as any));
    });

    combineLatest([
      this.instructorId$,
      this.queryFilterService.valueChanges
    ]).pipe(
      debounceTime(400),
      untilDestroyed(this)
    ).subscribe(([id, query]) => {
      this.payoutService.getInstructorWithdrawActivities(Number(id), { page_size: 5, ...query }).subscribe(res => {
        this.withdrawsPagination = res;
      })
    })
  }
}
