import { Component, Input, OnInit } from '@angular/core';
import { PayoutService } from '@shared/services/payout/payout.service';
import { AdminPayoutRequestHistoryEvent, PayoutRequestStatus } from 'thkee-common';

@Component({
  selector: 'app-payout-request-history',
  templateUrl: './payout-request-history.component.html',
  styleUrls: ['./payout-request-history.component.scss']
})
export class PayoutRequestHistoryComponent implements OnInit {
  @Input() set requestId(requestId: number | undefined) {
    if (!requestId) {
      this.history = [];
      return;
    }

    this.getHistory(requestId!);
  };

  history?: AdminPayoutRequestHistoryEvent[];
  actionTitles: Record<PayoutRequestStatus, string> = {
    paid: 'Paid',
    processing: 'Processing',
    requested: 'Payout Request Created',
    deactivated: 'Payout Request Deactivated',
    ready: 'Payout Request Ready',
    failed: 'Payout Request Failed',
  }

  constructor(
    private payoutService: PayoutService
  ) { }

  ngOnInit(): void {
  }

  private getHistory(id: number) {
    this.payoutService.getPayoutRequestHistory(id).subscribe(data => this.history = data)
  }
}
