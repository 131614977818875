import { Component, OnInit } from '@angular/core';
import { PayoutActivationLists, PayoutActivationSummary, UserService } from '@shared/services';
import { Pagination, PaginationService, UtilsService } from 'thkee-common';

const pageSize: number = 10;

interface QueryParams {
  page: number;
  page_size: number;
  search?: string;
  submited_date?: string;
  verified_date?: string;
}

@Component({
  selector: 'app-payout-activation',
  templateUrl: './payout-activation.component.html',
  styleUrls: ['./payout-activation.component.scss'],
})
export class PayoutActivationComponent implements OnInit {
  payoutActivationSummary!: PayoutActivationSummary;
  payoutActivation!: Pagination<PayoutActivationLists>;
  isLoading: boolean = false;
  isLoadingSummary: boolean = false;
  queryParams: QueryParams = { page: 1, page_size: pageSize };

  constructor(
    private userService: UserService,
    private paginationService: PaginationService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.getPayoutActivationSummary();
    this.getPayoutActivation(this.queryParams);
  }

  private getPayoutActivationSummary() {
    this.isLoadingSummary = true;
    this.userService.getPayoutActivationSummary().subscribe({
      next: (res) => {
        this.isLoadingSummary = false;
        this.payoutActivationSummary = res;
      },
      error: (err) => {},
    });
  }

  private getPayoutActivation(queryParams = {} as object) {
    this.isLoading = true;
    this.userService.getPayoutActivation(queryParams).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.payoutActivation = res;
        this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
      },
      error: (err) => {},
    });
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      return this.utilsService.getStatusColor(status);
    }
    return 'green';
  }

  search(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.queryParams.search = inputElement.value;
    this.getPayoutActivation(this.queryParams);
  }

  selectSubmitDate(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.queryParams.submited_date = inputElement.value;
  }

  selectVerifiedDate(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.queryParams.verified_date = inputElement.value;
    this.getPayoutActivation(this.queryParams);
  }

  // Pageination
  paginat(page_number: number) {
    this.queryParams.page = page_number;
    this.getPayoutActivation(this.queryParams);
  }
}
