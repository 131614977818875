<div class="container">
  <div class="py-12 text-center">
    <svg-icon src="@assets/images/not-found.svg" class="mx-auto my-8 block w-72"></svg-icon>
    <h3 class="mb-3" translate>Oh man, You don't supposed to see this!</h3>
    <p translate>
      Please find your way to the <a href="../">homepage</a> or get help <a href="#">here</a>. And Remember you didn't
      see anything!
    </p>
    <div>
      <a routerLink="../">
        <button class="btn-blue">
          <span translate>Back</span>
        </button>
      </a>
    </div>
  </div>
</div>
