<app-header>
  <ng-container action>
    <div class="flex gap-[10px] border-l border-neutral-200 pl-4" *ngIf="courseState">
      <ng-container *ngIf="courseState === '1'">
        <ng-container *ngTemplateOutlet="buttonState"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</app-header>
<app-common-tab-menu [items]="tabItems" title="Overview"></app-common-tab-menu>
<app-common-breadcrumbs></app-common-breadcrumbs>

<!-- <ng-template #buttonState>
  <button class="btn-outline" (click)="emitButton('reject')">
    <span>Reject</span>
  </button>
  <button class="btn-teal" (click)="emitButton('accept')">
    <span>Accept</span>
  </button>
</ng-template> -->
<ng-template #buttonState>
  <button class="btn-outline" (click)="emitButton('cancel')">
    <span>Cancel</span>
  </button>
  <button class="btn-amber" (click)="emitButton('update')">
    <span>Update</span>
  </button>
</ng-template>
<!-- <ng-template #buttonState>Button State 3</ng-template> -->