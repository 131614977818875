<div class="flex max-h-[60px] w-full justify-between border-b border-neutral-100 px-4 py-3">
  <div class="left flex items-center gap-4">
    <div class="collapse-sidebar">
      <a href="#" class="text-neutral">
        <svg-icon src="@assets/images/icons/menu-alt.svg" class="block h-5 w-5"></svg-icon>
      </a>
    </div>
    <div class="page-title text-black">
      <h5>{{ titleService.getTitle() }}</h5>
    </div>
  </div>
  <div class="right flex items-center gap-4">
    <div class="notif flex h-full items-center gap-4 border-r border-neutral-100 px-4">
      <a href="#" class="hover:text-blue relative">
        <svg-icon class="block h-5 w-5" src="@assets/images/icons/mail.svg"></svg-icon>
        <div
          class="bg-blue absolute -right-2 -top-2 inline-flex h-5 w-5 items-center justify-center rounded-full border-2 border-white text-xs text-white">
          1
        </div>
      </a>
      <a href="#" class="hover:text-blue relative">
        <svg-icon class="block h-5 w-5" src="@assets/images/icons/bell.svg"></svg-icon>
        <div
          class="bg-blue absolute -right-2 -top-2 inline-flex h-5 w-5 items-center justify-center rounded-full border-2 border-white text-xs text-white">
          1
        </div>
      </a>
    </div>
    <div class="user-menu">
      <app-common-dropdown [items]="userMenu" alignment="right">
        <ng-container button>
          <div class="inline-flex cursor-pointer items-center justify-end gap-2">
            <div class="flex items-center justify-start gap-2">
              <div class="inline-flex flex-col items-end justify-start text-right">
                <div><strong>{{userName}}</strong></div>
                <div class="text-xs">{{userRole}}</div>
              </div>
              <thk-avatar class="me-0" [url]="userProfileIcon" [title]="userName"></thk-avatar>
            </div>
            <div class="relative">
              <svg-icon class="block h-3.5 w-3.5" src="@assets/images/icons/chevron-down.svg"></svg-icon>
            </div>
          </div>
        </ng-container>
      </app-common-dropdown>
    </div>
    <div class="course-action">
      <ng-content select="[action]"></ng-content>
    </div>
  </div>
</div>