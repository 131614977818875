import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@shared';
import { AuthenticationService, User } from '@shared/services';
import { Subscription } from 'rxjs';
import { CredentialsService } from 'thkee-common';

interface UserProfile {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  headline?: string;
  bio: string;
  language: string;
  photo: string;
  is_staff: boolean;
  is_active: boolean;
  date_updated: string;
  paypal_email?: string;
  inactive_reason: string;
  handle: string;
  overall_ratings: number;
  is_instructor: boolean;
  fullname: string;
  profile_completion: string;
  social_profile?: string;
  privacy_setting?: string;
  notification_settings?: string;
  cart: {
    items: [];
    id: number;
  };
  mobile?: string;
  photo_url?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  pageTitle: string = 'Overview';

  userMenu = [
    {
      title: 'Settings',
      link: '/user/settings',
      icon: '@assets/images/icons/cog.svg',
    },
    {
      title: 'Logout',
      link: '/logout',
      icon: '@assets/images/icons/logout.svg',
    },
  ];

  userInfo!: User;
  private userInfoSubscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    protected titleService: Title
  ) {}

  ngOnInit() {
    this.userInfoSubscription.add(
      this.authenticationService.getUser().subscribe((res) => {
        this.userInfo = res;
      })
    );
  }

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  get userName() {
    return this.userInfo?.fullname ?? '';
  }

  get userRole() {
    return this.userInfo?.handle ?? '';
  }

  get userProfileIcon() {
    return this.userInfo?.photo_url ?? '';
  }

  ngOnDestroy(): void {
    this.userInfoSubscription.unsubscribe();
  }
}
