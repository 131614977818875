import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DrawerComponent, ModalComponent } from '@shared';
import { PayoutHistory, UserService } from '@shared/services';
import { InstructorPaymentMethod, InstructorUserDetails, ModalService } from 'thkee-common';

@Component({
  selector: 'app-activation-details',
  templateUrl: './activation-details.component.html',
  styleUrls: ['./activation-details.component.scss'],
})
export class ActivationDetailsComponent implements OnInit {
  isLoading: boolean = false;
  payoutInstructorProfileDetails!: InstructorUserDetails;
  instructorId: string = '';
  params: string = '';
  tabs: { label: string; value: string }[] = [
    { label: 'Profile', value: 'profile' },
    { label: 'Identity Verification', value: 'identity' },
    { label: 'Terms Of Use', value: 'terms' },
    { label: 'Marketing', value: 'marketing' },
    { label: 'Payout Method', value: 'payout' },
  ];

  title1: string = $localize`Thkee’s Promotional Programs`;
  content11: string = $localize`Thkee offers several optional marketing programs in which you can choose to participate. These programs can help increase your revenue potential on Thkee by finding the optimal price point for your content and promoting it through additional marketing channels.`;
  content12: string = $localize`There is no up-front cost to participate in these programs, and you can modify your participation status at any time. However, changes you make will not apply to currently active campaigns.`;
  content13: string = $localize`These programs are subject to the terms of our [Promotions Policy], so you should read it carefully.`;

  title2: string = $localize`Deals Program`;
  content21: string = $localize`We will take care of promoting your content to a huge range of audiences using our advanced marketing tools. Participation can increase your revenue potential by enabling Thkee to offer your content at a compelling discount via targeted promotions.  `;
  content22: string = $localize`The Deals Program allows Thkee to offer your content for a price no lower than 75 SR (or local equivalent) except in select countries or to certain resellers. It also allows Thkee to optimize your list price in different markets.`;
  content23: string = $localize`The Deals Program allows Thkee to offer your content for a price no lower than 75 SR (or local equivalent) except in select countries or to certain resellers. It also allows Thkee to optimize your list price in different markets.`;

  // GET Payment method details
  paypalMethod?: InstructorPaymentMethod;
  payoneerMethod?: InstructorPaymentMethod;
  bankMethod: InstructorPaymentMethod[] = [];

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private readonly modalService: ModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.params = this.activatedRoute.snapshot.queryParams['params'];
    this.instructorId = this.activatedRoute.snapshot.params['instructorId'];
    this.getPayoutInstructorProfileDetails(this.instructorId);
    this.getAdminInstructorPayoutHistory(this.instructorId);
    this.selectedTab = this.params;
  }

  private getPayoutInstructorProfileDetails(instructorId: string) {
    this.isLoading = true;
    this.userService.getPayoutInstructorProfileDetails(instructorId).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.payoutInstructorProfileDetails = res;
        this.bankMethod = res.payment_methods.filter((m: InstructorPaymentMethod) => m.name === 'bank_account');
        this.paypalMethod = res.payment_methods.find((m: InstructorPaymentMethod) => m.name === 'paypal');
        this.payoneerMethod = res.payment_methods.find((m: InstructorPaymentMethod) => m.name === 'payoneer');
      },
      error: (err) => {},
    });
  }

  payoutHistory: PayoutHistory[] = [];
  private getAdminInstructorPayoutHistory(instructorId: string) {
    this.isLoading = true;
    this.userService.getAdminInstructorPayoutHistory(instructorId).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.payoutHistory = res;
      },
      error: (err) => {},
    });
  }

  // Disable
  disablePayout() {
    this.modalService.confirm({
      title: 'Disable Confirmation',
      message: `Are you sure, you want to disable ?`,
      onConfirm: () => {
        this.disablePayoutConfirm();
      },
    });
  }

  private disablePayoutConfirm() {
    this.userService.adminDisablePayout(this.instructorId).subscribe({
      next: (res) => {
        this.getPayoutInstructorProfileDetails(this.instructorId);
      },
      error: (err) => {},
    });
  }

  // Verify
  verifyInstructor() {
    this.modalService.confirm({
      title: 'Verify Confirmation',
      message: `Are you sure, you want to verify this instructor ?`,
      onConfirm: () => {
        this.verifyPayoutConfirm();
      },
    });
  }

  private verifyPayoutConfirm() {
    this.userService.adminVerifyInstructorProfile(this.instructorId).subscribe({
      next: (res) => {
        this.getPayoutInstructorProfileDetails(this.instructorId);
      },
      error: (err) => {},
    });
  }

  // Revoke
  revokeVerification() {
    this.modalService.confirm({
      title: 'Verify Confirmation',
      message: `Are you sure, you want to verify this instructor ?`,
      onConfirm: () => {
        this.revokePayoutConfirm();
      },
    });
  }

  private revokePayoutConfirm() {
    this.userService.adminRejectedInstructorProfile(this.instructorId).subscribe({
      next: (res) => {
        this.getPayoutInstructorProfileDetails(this.instructorId);
      },
      error: (err) => {},
    });
  }

  // Approved
  approved(paymentMethodId: number) {
    this.accountDetails.close();
    this.userService.adminApprovedAccount(paymentMethodId?.toString()).subscribe({
      next: (res) => {
        this.getPayoutInstructorProfileDetails(this.instructorId);
      },
      error: (err) => {},
    });
  }

  // Rejected
  rejectedReason: string = '';
  paymentMethodId!: number;
  @ViewChild('rejectConfirmedModal') private rejectConfirmedModal!: ModalComponent;
  rejected(paymentMethodId: number) {
    this.paymentMethodId = paymentMethodId;
    this.rejectConfirmedModal.open();
  }

  rejectedConfirmed() {
    this.rejectConfirmedModal.close();
    this.userService.adminRejectAccount(this.paymentMethodId?.toString(), { reason: this.rejectedReason }).subscribe({
      next: (res) => {
        this.getPayoutInstructorProfileDetails(this.instructorId);
      },
      error: (err) => {},
    });
  }

  // Drawer
  bankAccount?: InstructorPaymentMethod;
  @ViewChild('accountDetails') private accountDetails!: DrawerComponent;
  showDetails(account: InstructorPaymentMethod) {
    this.bankAccount = account;
    this.accountDetails.open();
  }
  // Tab
  selectedTab: string = 'profile';
  selectTab(item: string) {
    this.selectedTab = item;
    this.router.navigate([`/users/payout-activation/details/${this.instructorId}`], {
      queryParams: { params: item },
      replaceUrl: true,
    });
  }
}
