import { PricingTierData } from 'thkee-common';

// Define the enum for status
export enum PricingTierStatus {
  Empty = '',
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export type PricingTierState = {
  error: object;
  pricingTierList: any;
  pricingTierData: PricingTierData;
  status: PricingTierStatus;
};

export const initialPricingTierState: PricingTierState = {
  error: {},
  pricingTierList: [],
  pricingTierData: {
    id: '',
    price: 0,
    name: '',
    price_tier_status: false,
    currency: '',
  },
  status: PricingTierStatus.Empty,
};

export interface PricingTierPayload {
  page: number;
  page_size: number;
  currency_code?: string;
}

export interface PricingTierCreateUpdatePayload {
  id?: string;
  name: string;
  currency: string;
  price: number;
  price_tier_status: boolean;
}

export interface PricingTierLoadRemovePayload {
  id: string;
}

export interface PricingTier {
  id: string;
  currency_code: string;
  date_created: string;
  date_updated: string;
  price: string;
  name: string;
  price_tier_status: boolean;
  is_active: boolean;
  currency: number;
}
