<div class="course-overview p-4 pt-0">

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">

         <div class="border-b border-neutral-100 pl-4 pt-9 pr-6 pb-4 flex flex-col gap-6 justify-between">
            <div class="flex justify-between w-full">
               <svg-icon src="@assets/logo.svg" class="block w-28"></svg-icon>
               <button
                  class="btn rounded-2xl bg-teal-50 px-4 py-[2px] text-teal-600 text-sm font-normal border-0">Paid</button>
            </div>
            <div class="w-full flex justify-between">
               <div class="flex flex-col gap-1">
                  <p class="text text-base font-normal text-neutral-400 mb-0">
                     <span class="text-neutral-900">Email: </span> support&#64;thkee.com
                  </p>
                  <p class="text text-base font-normal text-neutral-400 mb-0">
                     <span class="text-neutral-900">Telephone: </span> (+1) 765-652-3143
                  </p>
                  <p class="text text-base font-normal text-neutral-400 mb-0">
                     <span class="text-neutral-900">Fax: </span> 803-524-8398
                  </p>
               </div>
               <div class="flex flex-col gap-1 text-end">
                  <p class="text text-base font-normal text-neutral-400 mb-0">
                     123 Sisipi St
                  </p>
                  <p class="text text-base font-normal text-neutral-400 mb-0">
                     Riyadh
                  </p>
                  <p class="text text-base font-normal text-neutral-400 mb-0">
                     Saudi Arabia, 1234
                  </p>
               </div>
            </div>
         </div>
         <!-- Invoice details -->

         <div class="p-9 flex flex-col gap-6 justify-between">
            <div class="w-full flex justify-between">
               <div class="flex flex-col gap-1">
                  <h5 class="text-base font-normal text-black opacity-50 mb-0">INVOICE DETAILS</h5>
                  <h5 class="text-xl font-semibold text-black mb-0">INV-10839</h5>
                  <p class="text-base font-normal text-neutral-400 mb-0">
                     <span class="text-neutral-900">Payout created:</span> March 5, 2020
                  </p>
                  <p class="text-base font-normal text-neutral-400 mb-0">
                     <span class="text-neutral-900">Payout Period: </span> March, 2020
                  </p>
                  <p class="text-base font-normal text-neutral-400 mb-0">
                     <span class="text-neutral-900">Status: </span>
                     <button
                        class="btn rounded-2xl bg-teal-50 px-4 py-[2px] text-teal-600 text-sm font-normal border-0">Paid</button>
                  </p>
               </div>
            </div>
         </div>

         <div class="card-subheader flex items-center justify-between px-3 py-5">
            <div class="flex items-center gap-1">
               <h6>Transactions</h6>
               <div class="tag tag-sm">16 Transaction</div>
            </div>
         </div>

         <div class="card-body">
            <div class="courses-list">
               <!-- Table -->
               <div class="datagrid-table relative overflow-x-auto">
                  <table class="w-full text-left">
                     <thead class="heading">
                        <tr>
                           <th scope="col" class="">
                              <div class="col-name">Transaction ID</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Product ID</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Buyer</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Date</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name text-end">Sales</div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="hover:cursor-pointer" *ngFor="let order of dummyData; let i = index">
                           <td>
                              <div class="flex min-h-[69px] max-w-[170px] items-center">
                                 <span class="course-name line-clamp-3">
                                    #{{ order.transaction_id}}
                                 </span>
                              </div>
                           </td>
                           <td>
                              <span class="whitespace-nowrap">
                                 {{ order.product.title }}
                              </span>
                           </td>
                           <td>
                              <span class="whitespace-nowrap">
                                 {{ order.buyer.title }}
                              </span>
                           </td>
                           <td>
                              {{order.date | date}}
                           </td>
                           <td class="text-end">
                              <span class="course-price">{{ order.sale_price | currency }}</span>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <div class="flex justify-end px-4 py-5 border-t border-neutral-100">
                  <div class="flex gap-36">
                     <h3 class="text-n text-neutral-900 text-lg font-bold">Total</h3>
                     <h3 class="text-n text-neutral-900 text-lg font-bold">$174,4</h3>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="flex justify-end gap-4 mt-4">
         <button class="btn btn-blue">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="btn-icon">
               <path stroke-linecap="round" stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75">
               </path>
            </svg>
            <span>Send Email</span>
         </button>
         <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
            <svg-icon src="@assets/images/icons/arrow-down-tray.svg" class="text-blue-600"></svg-icon>
            <span class="text-blue-600 font-semibold text-sm">Exprot PDF</span>
         </button>
      </div>
   </div>
</div>