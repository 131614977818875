import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_PRICING_LIST, HttpService, PricingTierData, PricingTierList, encodeURL } from 'thkee-common';
import { PricingTierCreateUpdatePayload, PricingTierLoadRemovePayload } from './pricing-state.model';

@Injectable({
  providedIn: 'root',
})
export class PricingTierService {
  constructor(private readonly http: HttpService) {}

  getPricingTierList(params = {} as object): Observable<PricingTierList[]> {
    return this.http.get<PricingTierList[]>(encodeURL(API_PRICING_LIST, params));
  }

  getPricingTier(params = {} as PricingTierLoadRemovePayload): Observable<PricingTierData> {
    return this.http.get<PricingTierData>(API_PRICING_LIST + params?.id + '/');
  }

  addPricingTier(params = {} as object): Observable<any> {
    return this.http.post<any>(API_PRICING_LIST, params);
  }

  updatePricingTier(params = {} as PricingTierCreateUpdatePayload): Observable<any> {
    return this.http.patch<any>(API_PRICING_LIST + params?.id + '/', params);
  }

  removePricingTier(params = {} as PricingTierLoadRemovePayload): Observable<any> {
    return this.http.delete<any>(API_PRICING_LIST + params?.id + '/');
  }
}
