import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthActions, UntilDestroy } from '@shared';
import { Subscription } from 'rxjs';
import { Logger, LoginContext, selectError } from 'thkee-common';

const log = new Logger('Login');

export interface Message {
  type: 'error' | 'success' | 'info' | 'warning';
  message: string;
}

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  messages = <Message[]>[];
  loggingIn = false;
  readonly form = new FormGroup({});
  readonly model: LoginContext = {
    email: '',
    password: '',
    remember: true,
  };
  readonly fields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      props: {
        label: 'Email Address',
        placeholder: 'Email Address...',
        type: 'email',
        required: true,
        inputAppendClass: ['input-lg'],
      },
      validators: {
        validation: ['email'],
      },
    },
    {
      key: 'password',
      type: 'input-password',
      props: {
        label: 'Password',
        placeholder: '**********',
        required: true,
        type: 'password',
        inputAppendClass: ['input-lg'],
      },
    },
    {
      key: 'remember',
      type: 'checkbox',
      props: {
        label: 'Remember me',
        inputAppendClass: ['input-lg'],
      },
    },
  ];
  private subscription!: Subscription;

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.processErrorMessages();
  }

  onLogin(): void {
    log.debug(this.model);
    if (this.form.valid) {
      this.messages = [];
      this.loggingIn = true;
      this.store.dispatch(AuthActions.login(this.model));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private processErrorMessages() {
    this.subscription = this.store.select(selectError).subscribe((errors) => {
      if (errors?.login) {
        this.loggingIn = false;
        this.messages = errors.login.map((msg: string) => {
          return {
            type: 'error',
            message: msg,
          };
        });
      }
    });
  }
}
