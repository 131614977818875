import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Column,
  COURSE_OVERVIEW_COLUMN,
  COURSES_TABLE_SEARCH,
  FilterKey,
  FilterOptionComponent,
  FilterOptionsOverview,
  TableSearch,
} from '@shared';
import * as _ from 'lodash';
import { map, Observable, of } from 'rxjs';
import {
  CategoryV2,
  CourseActions,
  CourseV2,
  LoaderService,
  Params,
  selectCategories,
  selectCourses,
  UtilsService,
} from 'thkee-common';
const pageSize: number = 5;
@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  @Input() status: string = '';
  @Input() title: string = 'Overview';

  // Common variables
  queryParams: Params = { page: 1, page_size: pageSize };
  salePriceKey: FilterKey = { key1: 'sale_price_min', key2: 'sale_price_max' };
  courseSoldKey: FilterKey = { key1: 'min_sold_count', key2: 'max_sold_count' };
  courseSectionKey: FilterKey = { key1: 'min_section_count', key2: 'max_section_count' };
  courseLectureKey: FilterKey = { key1: 'min_lecture_count', key2: 'max_lecture_count' };
  filterValues: FilterOptionsOverview = {
    rating: [],
    category: [],
  };
  filterOptions: FilterOptionsOverview = {
    rating: [],
    category: [],
  };
  searchType: TableSearch[] = COURSES_TABLE_SEARCH;
  selectedItems: TableSearch = COURSES_TABLE_SEARCH[0];
  columns: Column[] = COURSE_OVERVIEW_COLUMN;
  seeMore: boolean = false;
  isDropdown: boolean[] = [];

  courses$!: Observable<CourseV2[]>;
  categories$!: Observable<CategoryV2[]>;

  isLoading$ = this.loaderService.response('loading-instructor-courses');

  constructor(
    private readonly store: Store,
    private router: Router,
    private utilsService: UtilsService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.courses$ = this.store.select(selectCourses);
    // this.courses$ = this.store
    //   .select(selectCourses)
    //   .pipe(map((courses) => courses.filter((course) => !this.status || course.status === this.status)));
    this.categories$ = this.store.select(selectCategories);

    this.store.select(selectCategories).subscribe((cat) => {
      this.setFilterOptions(cat);
    });
  }

  // Get data
  private getData(params: Params = {}) {
    this.queryParams.project_state = this.status;
    this.store.dispatch(CourseActions.loadCourses({ params: this.queryParams }));
  }

  // Common UI actions
  // Filter
  setFilterOptions(category: CategoryV2[] = []) {
    const newOption = category.map((item: CategoryV2) => ({
      value: item.slug,
      label: item.name,
    }));
    this.filterOptions.category = newOption;
    this.filterOptions.rating = [
      { value: '5', label: '5 Star' },
      { value: '4', label: '4 Star' },
      { value: '3', label: '3 Star' },
      { value: '2', label: '2 Star' },
      { value: '1', label: '1 Star' },
    ];
  }

  // Filter values
  filterHander(event: { action: string; data: {} }) {
    if (event.action === 'change') {
      const queryParams = this.utilsService.convertQueryParams(event.data);
      this.queryParams = { ...this.queryParams, ...queryParams };
    }
    if (event.action === 'change-input') {
      this.queryParams = { ...this.queryParams, ...event.data };
    }
    if (event.action === 'reset') {
      this.queryParams = {};
      this.clearFilter([]);
    }
  }

  // Reset filter
  clearFilter(filters: FilterOptionComponent[]) {
    if (filters) {
      filters.forEach((filter: any) => {
        filter.reset();
      });
    }
    this.queryParams = {};
  }

  // Filter
  submitFilter(filters: FilterOptionComponent[]) {
    this.isDropdown[1] = false;
    this.getData();
    this.clearFilter(filters);
    this.queryParams = {};
  }

  // Search
  selectItems(item: TableSearch) {
    this.queryParams = { page: 1, page_size: pageSize };
    this.selectedItems = item;
  }
  search(event: any) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.search = `${this.selectedItems.value + ':' + event.target.value}`;
    // this.queryParams[this.selectedItems.value] = event.target.value;
    this.getData(this.queryParams);
  }

  // Common dropdown
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Table
  toggleColumn(col: Column): void {
    col.visible = !col.visible;
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      return this.utilsService.getStatusColor(status);
    }
    return 'green';
  }

  // Pageinations
  paginat(event: any) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = event;
    this.getData(this.queryParams);
  }

  // Get category by category id
  getCategoryName(categoryId: string | undefined): Observable<CategoryV2 | undefined> {
    if (categoryId === undefined) {
      return of(undefined);
    }
    return this.categories$.pipe(map((categories) => _.find(categories, { id: categoryId })));
  }

  // View details
  viewDetails(course: CourseV2) {
    if (course.status === 'in_review') {
      this.router.navigate(['/courses/' + course.id + '/revision']);
    } else {
      this.router.navigate(['/course/' + course.id + '/details']);
    }
  }
}
