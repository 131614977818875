import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Params } from '@angular/router';
import { TransactionItems } from '@shared';
import { TransactionsService } from '@shared/services';
import { Subscription } from 'rxjs';
import { RouterStoreService, UtilsService } from 'thkee-common';

@Component({
  selector: 'app-transaction-details-overview',
  templateUrl: './transaction-details-overview.component.html',
  styleUrls: ['./transaction-details-overview.component.scss'],
})
export class TransactionDetailsOverviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: any;
  @Input() data?: TransactionItems;
  @Input() isSidebarComponent: boolean = false;
  private routerSubscriptions = new Subscription();
  params: Params = {};

  show_refunded_mark: boolean = false;
  show_refund_pending_mark: boolean = false;
  show_refund_button: boolean = false;
  show_refund_mark_bottom: boolean = false;

  // OLD logic
  // if transaction_type == 'Refund':
  //     Then show the Refunded mark, no button to refund

  // else if transaction_type == 'Sale':
  //     if transaction item has "is_refunded" == true
  //         Then show the Refunded mark, no button to refund
  //     else if transaction item has "is_refunded" == false and ransaction item has "refund" == true
  //         Then show Pending Refund Request mark
  //     else:
  //         show refund button (edited)

  // 227
  // "transaction_type": "Sale",
  // "is_refunded": false,
  // "refund": false,

  // NEW logic
  //   if transaction_type == 'Refund':
  //    if transaction item has "is_refunded" == false and ransaction item has "refund" == true
  //         Then show Pending Refund Request mark
  //     else:
  //         show the Refunded mark
  // else if transaction_type == 'Sale':
  //     if transaction item has "is_refunded" == true
  //         Then show the Refunded mark, no button to refund
  //     else if transaction item has "is_refunded" == false and ransaction item has "refund" == true
  //         Then show Pending Refund Request mark
  //     else:
  //         show refund button

  constructor(
    private routerStore: RouterStoreService,
    private utilsService: UtilsService,
    private transactionService: TransactionsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id']) {
      this.getTransaction(changes['id'].currentValue);
    }
  }

  ngOnInit(): void {
    this.routerSubscriptions.add(
      this.routerStore.getParams().subscribe((params) => {
        this.params = params;
      })
    );
    if (this.data?.transaction_type === 'refund') {
      if (!this.data?.is_refunded && this.data?.refund) {
        this.show_refund_pending_mark = true;
      } else if (this.data?.transaction_type === 'refund' && this.data?.is_refunded && this.data?.refund) {
        this.show_refund_mark_bottom = true;
      } else {
        this.show_refunded_mark = true;
      }
    } else if (this.data?.transaction_type === 'sale') {
      if (this.data?.is_refunded) {
        this.show_refunded_mark = true;
      } else if (!this.data?.is_refunded && this.data?.refund) {
        this.show_refund_pending_mark = true;
      } else {
        this.show_refund_button = true;
      }
    }
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      return this.utilsService.getStatusColor(status);
    }
    return 'green';
  }

  get transaction_type() {
    if (this.data?.transaction_type) {
      return this.data.transaction_type;
    }
    return '';
  }
  get is_refunded() {
    if (this.data?.is_refunded) {
      return this.data.is_refunded;
    }
    return false;
  }
  get refund() {
    if (this.data?.refund) {
      return this.data.refund;
    }
    return false;
  }

  get transaction_id() {
    if (this.data) {
      return this.data.transaction_id;
    }
    return 0;
  }

  get product_title() {
    if (this.data?.product) {
      return this.data.product.title;
    }
    return 'Product title';
  }

  get product_id() {
    if (this.data?.product) {
      return this.data.product.id;
    }
    return 'product-id';
  }

  get buyer_name() {
    if (this.data?.buyer) {
      return this.data.buyer.fullname;
    }
    return 'Buyer name';
  }

  get buyer_id() {
    if (this.data?.buyer) {
      return this.data.buyer.id;
    }
    return 'Buyer name';
  }

  get transaction_date() {
    if (this.data) {
      return this.data.date_created;
    }
    return 'October 28, 2022';
  }

  get payment_gateway() {
    if (this.data?.payment_gateway) {
      return this.data.payment_gateway;
    }
    return 'Payment Gateway';
  }

  get payment_method() {
    if (this.data?.payment_method) {
      return this.data.payment_method;
    }
    return '';
  }

  get channel() {
    if (this.data) {
      return this.data.channel_type;
    }
    return 'Channel';
  }

  get coupon() {
    if (this.data?.coupon) {
      return this.data.coupon;
    }
    return 'SDWQ13';
  }

  get instructor_share() {
    if (this.data?.instructor_share) {
      return this.data.instructor_share;
    }
    return 0;
  }
  get instructor_share_percentage() {
    if (this.data?.instructor_share_percentage) {
      return this.data.instructor_share_percentage;
    }
    return 0;
  }

  get affiliates_earning() {
    return 0;
  }

  get currency_symbol() {
    if (this.data?.currency_symbol) {
      return this.data.currency_symbol;
    }
    return 'SAR';
  }

  // Transaction Detail
  get listed_price() {
    if (this.data) {
      return this.data.list_price;
    }
    return 0;
  }

  get sale_price() {
    if (this.data) {
      return this.data.sale_price;
    }
    return 0;
  }

  get gateway_charge_vat() {
    if (this.data) {
      return this.data.gateway_charge_vat;
    }
    return '0';
  }

  get gateway_charge() {
    if (this.data) {
      return this.data.gateway_charge;
    }
    return '0';
  }

  get pricing_tier() {
    if (this.data) {
      return this.data.pricing_tier;
    }
    return 'NA';
  }

  get discount_coupon() {
    if (this.data) {
      return this.data.discount_coupon;
    }
    return '0.00';
  }

  get discount_coupon_code() {
    if (this.data) {
      return this.data.discount_coupon_code;
    }
    return '0';
  }

  get discount() {
    if (this.data) {
      return this.data.discount;
    }
    return '0';
  }

  get net_amount() {
    if (this.data) {
      return this.data.net_amount;
    }
    return '0';
  }

  get credit_card_fee_paypal() {
    if (this.data) {
      return parseFloat(this.gateway_charge_vat) + parseFloat(this.gateway_charge);
    }
    return 0;
  }

  get credit_card_fee_paypal_percentage() {
    if (this.data) {
      return ((this.credit_card_fee_paypal / parseFloat(this.net_amount)) * 100).toFixed(2);
    }
    return 0;
  }

  // Share Detail
  get platform_share() {
    if (this.data) {
      return this.data.platform_share;
    }
    return 0;
  }

  get platform_share_percentage() {
    if (this.data) {
      return this.data.platform_share_percentage;
    }
    return 0;
  }

  get instructore_share() {
    if (this.data?.share_detail) {
      return this.data.share_detail.instructore_share;
    }
    return 0;
  }

  get refunded_amount() {
    if (this.data?.refunded_amount) {
      return this.data.refunded_amount;
    }
    return '0';
  }

  get related_transaction() {
    if (this.data?.related_transaction) {
      return this.data.related_transaction;
    }
    return '';
  }

  gotToRelatedRefund(id: number) {
    window.location.replace('/ecommerce/sales/transaction/' + id);
  }

  ngOnDestroy(): void {
    this.routerSubscriptions.unsubscribe();
  }

  private getTransaction(id?: number) {
    if (!id) {
      this.data = undefined;
      return;
    }

    this.transactionService.getTransactionDetails(String(id)).subscribe((data) => (this.data = data));
  }
}
