
<div class="card-subheader flex items-center justify-between px-6 py-5">
  <div class="flex items-center gap-1">
     <h6>Transactions</h6>
     <div *ngIf="transactionsPagination" class="tag tag-sm">{{transactionsPagination.count}} Transactions</div>
  </div>
  <div class="actions flex flex-row gap-4">
     <!-- Filter -->
     <div class="relative">
        <button (click)="dropdown('transaction-filter')" class="btn-neutral">
           <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
           <span>Filter</span>
        </button>
        <div [ngClass]="showingDropdown === 'transaction-filter' ? '' : 'hidden'"
           class="bg-white shadow-lg rounded px-2.5 pt-5 pb-[250px] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
           <div class="form">
              <formly-form [form]="filterForm" [fields]="filterFormFields">
              </formly-form>
           </div>
           <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
              <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                 <span>Reset</span>
              </button>
           </div>
        </div>
     </div>
     <button class="btn-neutral px-3">
        <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
     </button>
  </div>
</div>
<div class="card-body">
  <div class="courses-list">
     <!-- Table -->
     <div class=" datagrid-table relative overflow-x-auto">
        <table class="w-full min-w-[850px] text-left">
           <thead class="heading">
              <tr>
                 <th scope="col" class="">
                    <div class="col-name">Payout ID</div>
                 </th>
                 <th scope="col" class="">
                    <div class="col-name">Transaction ID</div>
                 </th>
                 <th scope="col" class="">
                    <div class="col-name">Date</div>
                 </th>
                 <th scope="col" class="">
                    <div class="col-name">Release Date</div>
                 </th>
                 <th scope="col" class="">
                    <div class="col-name">Sale Orice</div>
                 </th>
                 <th scope="col" class="w-28">
                    <div class="col-name">Net Amount</div>
                 </th>
                 <th scope="col" class="">
                    <div class="col-name">Instructor Share</div>
                 </th>
                 <th scope="col" class="">
                    <div class="col-name">Status</div>
                 </th>
              </tr>
           </thead>
           <tbody>
              <tr class="hover:cursor-pointer" *ngFor="let data of transactionsPagination?.results">
                 <td>
                    <span class="course-id">
                       <span [routerLink]="'/ecommerce/payout/paid-payout/'+data.payout_request_id+'/all'"
                          class="text-blue-600 cursor-pointer">
                          {{ data.payout_request_id }}
                       </span>
                    </span>
                 </td>
                 <td>
                    <span class="course-id">
                       <span (click)="openTransactionDrawer(data, transactionDrawer)" class="text-blue-600 cursor-pointer">
                          {{ data.id }}
                       </span>
                    </span>
                 </td>
                 <td>
                    <span class="course-id">
                       <span class="">
                          {{ data.date_created | date:'d MMMM y' }}
                       </span>
                    </span>
                 </td>
                 <td>
                    <span class="course-id">
                       <span class="">
                          {{ data.release_date | date:'d MMMM y' }}
                       </span>
                    </span>
                 </td>
                 <td>
                    {{data.sale_price | currency:data.currency_symbol }}
                 </td>
                 <td>
                    {{data.net_amount | currency:data.currency_symbol }}
                 </td>
                 <td>
                    <span class="course-price flex items-center">
                       <button class="btn btn-transparent p-1">
                          <svg-icon src="@assets/images/icons/down-circle.svg" tooltip="Co-Instructor Earning"
                             class="w-5 h-5">
                          </svg-icon>
                       </button>
                       {{ data.instructor_share | currency:data.currency_symbol }}
                    </span>
                 </td>
                 <td>
                    <div [ngClass]="getStatus(data.payout_status).bg" class="flex flex-col px-2.5 py-[5px] rounded-2xl">
                       <span [ngClass]="getStatus(data.payout_status).text" class="text-sm">{{data.payout_status}}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>
     </div>

     <!-- Pagination -->
     <div class="pagination flex items-center justify-between p-5">
        <thk-pagination class="w-full" (paginationChange)="paginate($event)" [total]="transactionsPagination?.count || 0" [page]="query.page || 1" [size]="query.page_size || 10"></thk-pagination>
     </div>
  </div>
</div>

<!-- Transaction details drawer -->
<app-drawer #transactionDrawer>
  <ng-container header>
     <!-- Header -->
     <div *ngIf="transactionData" class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
        <h1 class="text-black font-semibold text-xl">Transaction ID #{{transactionData.id}}</h1>
        <div class="flex justify-between items-center gap-3">
           <button [routerLink]="'/ecommerce/sales/transaction/' + transactionData.id"
              class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
              Go to Transaction detail
              <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
              </svg-icon>
           </button>
           <button (click)="transactionDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
              <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
              </svg-icon>
           </button>
        </div>
     </div>
  </ng-container>

  <ng-container content>
     <app-transaction-details-overview [id]="transactionData?.id" [isSidebarComponent]="true">
     </app-transaction-details-overview>
  </ng-container>
</app-drawer>
