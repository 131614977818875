import { createAction, props } from '@ngrx/store';
import { User } from '@shared/services/auth/authentication.service';
import { LoginContext, LoginResponse } from 'thkee-common';

export const AuthActions = {
  login: createAction('[Auth] Login', props<LoginContext>()),
  loginSuccess: createAction('[Auth] Login Success', props<LoginResponse>()),
  loginError: createAction('[Auth] Login Error', props<{ error: unknown }>()),
  logout: createAction('[Auth] Logout'),
  signup: createAction('[Auth] Signup', props<{ email: string; password: string; name: string }>()),
  signupSuccess: createAction('[Auth] Signup Success'),
  signupError: createAction('[Auth] Signup Error', props<{ error: unknown }>()),
  userLoad: createAction('[Auth] User Load'),
  userLoaded: createAction('[Auth] User Loaded', props<{ user: User }>()),
  signupInstructorSuccess: createAction('[Instructor] Signup Instructor Success'),
  signupInstructorError: createAction('[Instructor] Signup Instructor Error', props<{ error: unknown }>()),
};
