<ng-container *ngIf="{
    currencyList: (currencyList$ | async) ?? []
  } as vm">
  <div class="price-tiers-new p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>Add Pricing Tiers</h3>
      </div>
    </div>

    <div class="tier-form">
      <div class="card rounded-lg border border-neutral-100 px-6 py-9">
        <app-form-price-tier (submitEvent)="onSubmit($event)" [currencies]="vm.currencyList"
          *ngIf="vm.currencyList.length > 0"></app-form-price-tier>
      </div>
    </div>
  </div>
</ng-container>