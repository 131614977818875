import { createFeature, createReducer, on } from '@ngrx/store';
import { PricingTierStatus, initialPricingTierState } from './pricing-state.model';
import { PricingTierActions } from './pricing.actions';

export const pricingTierReducer = createReducer(
  initialPricingTierState,
  on(PricingTierActions.loadPricingTierListSuccess, (state, { data }) => ({
    ...state,
    pricingTierData: {
      id: '',
      price: 0,
      name: '',
      price_tier_status: false,
      currency: '',
    },
    pricingTierList: { ...state?.pricingTierList, ...data },
  })),
  on(PricingTierActions.getPricingTierSuccess, (state, { data }) => ({
    ...state,
    pricingTierData: data,
  })),
  on(PricingTierActions.addPricingTierSuccess, (state, { data }) => ({
    ...state,
    pricingTierList: data,
    status: PricingTierStatus.Success,
  })),
  on(PricingTierActions.addPricingTierFail, (state, {}) => ({
    ...state,
    status: PricingTierStatus.Failed,
  })),
  on(PricingTierActions.addPricingTier, (state, {}) => ({
    ...state,
    status: PricingTierStatus.Pending,
  })),
  on(PricingTierActions.updatePricingTierSuccess, (state, { data }) => ({
    ...state,
    pricingTierList: data,
    status: PricingTierStatus.Success,
  })),
  on(PricingTierActions.updatePricingTierFail, (state, {}) => ({
    ...state,
    status: PricingTierStatus.Failed,
  })),
  on(PricingTierActions.updatePricingTier, (state, {}) => ({
    ...state,
    status: PricingTierStatus.Pending,
  })),
  on(PricingTierActions.clearPricingTierStatus, (state, {}) => ({
    ...state,
    status: PricingTierStatus.Empty,
  })),
  on(PricingTierActions.removePricingTierSuccess, (state, { id }) => {
    const updatedItems = state.pricingTierList['results'].filter((item: { id: string }) => item.id !== id);
    return {
      ...state,
      pricingTierList: { results: [...updatedItems] },
      status: PricingTierStatus.Success,
    };
  })
);

export const pricingTierFeature = createFeature({
  name: 'pricing-tier',
  reducer: pricingTierReducer,
});
