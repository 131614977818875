import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  INSTRUCTOR_DRAWER_TAB,
  INSTRUCTOR_SETTINGS_DRAWER_TAB,
  ModalComponent,
  TabItem,
  UntilDestroy,
  untilDestroyed
} from '@shared';
import { CombinationSingleInputItem, CombinationSingleInputValue } from '@shared/components/combination-single-input/combination-single-input.component';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { catchError, debounceTime, map, of, tap } from 'rxjs';
import {
  AdminFailedPayoutsQuery,
  AdminPayoutRequest,
  FileProps,
  Pagination,
  QueryFormConverter,
  QueryStringFilterService,
  RouterStoreService,
  ToastService
} from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-failed-payout',
  templateUrl: './failed-payout.component.html',
  styleUrls: ['./failed-payout.component.scss'],
  providers: [QueryStringFilterService.forComponent()]
})
export class FailedPayoutComponent implements OnInit, AfterViewInit {
  payoutsPagination?: Pagination<AdminPayoutRequest>;
  params: any = {};

  //Student TAB
  instructorTabLists: TabItem[] = INSTRUCTOR_DRAWER_TAB;

  //Student Settings TAB
  instructorSettingsTabLists: TabItem[] = INSTRUCTOR_SETTINGS_DRAWER_TAB;

  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Status</div>`,
    },
    {
      key: 'payout_type',
      fieldGroup: [
        {
          key: 'monthly_payout',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Monthly',
            required: true,
          },
        },
        {
          key: 'ondemand_payout',
          type: 'checkbox',
          className: '',
          props: {
            label: 'On-Demand',
            required: true,
          },
        },
      ]
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'no_of_transactions_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'eg: 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'no_of_transactions_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'eg: 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Created Date</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'date_created_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'yyyy-mm-dd',
          },
        },
        {
          template: '-',
        },
        {
          key: 'date_created_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'yyyy-mm-dd',
          },
        },
      ],
    },
  ];

  // Dropdown methode
  isDropdown: boolean[] = [];

  // Marke As Paid/unpaid Confirm
  markedAsPaidForm = new FormGroup({});
  markedAsPaidFormFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'textarea',
      props: {
        label: 'Description',
        labelClass: 'font-semibold text-black text-lg',
        placeholder: 'Enter a description',
        required: true,
        rows: 3,
        minHeight: '110px',
        maxHeight: '100%',
      },
      validation: {
        messages: {
          required: $localize`Reason is required!`,
        },
      },
    },
    {
      key: 'reference_id',
      type: 'input',
      props: {
        label: 'Reference ID',
        labelClass: 'font-semibold text-black text-lg',
        required: true,
        minHeight: '110px',
        maxHeight: '100%',
      },
      validation: {
        messages: {
          required: $localize`Reason is required!`,
        },
      },
    },
    {
      key: 'receipt',
      type: 'file',
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Upload Receipt',
        placeholder: 'Supported files are .jpg, .jpeg, or .png.',
        preview: true,
        previewType: 'image',
        allowedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
        uploadType: 'dashboard',
        disabled: false,
      } as FileProps,
      expressions: {
        'props.metadata': of({ public: true }).pipe(
          map(meta => Object.assign(meta, { payout: this.selectedPayout?.id || Date.now() + '' + Math.random() }))
        ),
      },
      validation: {
        messages: {
          required: $localize`Reason is required!`,
        },
      },
    },
  ];

  private queryFormConverter = new QueryFormConverter<AdminFailedPayoutsQuery>(['payout_type']);
  query?: AdminFailedPayoutsQuery;
  searchOptions: CombinationSingleInputItem[] = [
    { title: 'ID', placeholder: 'Enter ID', id: 'id' },
    { title: 'Request ID', placeholder: 'Enter Request ID', id: 'request_id' },
    { title: 'Instructor Name', placeholder: 'Enter Instructor Name', id: 'name' },
  ];
  searchValue?: CombinationSingleInputValue;
  selectedPayout?: AdminPayoutRequest;
  processingPayout?: boolean;
  filtering = false;

  constructor(
    private routerStore: RouterStoreService,
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
    private toastService: ToastService,
    private queryStringService: QueryStringFilterService<AdminFailedPayoutsQuery>
  ) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Failed Payout',
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  retryPayout(modal: ModalComponent) {
    if (!this.selectedPayout?.id) {
      return;
    }

    this.processingPayout = true;
    this.payoutService.retryFailedPayout(this.selectedPayout.id)
      .pipe(catchError(() => of(false)))
      .subscribe(ok => {
        this.processingPayout = false;
        if (!ok) {
          this.showErrorMessage();
          return;
        }

        this.toastService.message({
          type: 'message',
          message: 'Retry successfully'
        });
        modal.close();
      });
  }

  markAsPaid(modal: ModalComponent) {
    if (!this.selectedPayout?.id || this.markedAsPaidForm.invalid) {
      return;
    }

    this.processingPayout = true;
    this.payoutService
      .markPayoutRequestAsPaid(this.selectedPayout.id, this.markedAsPaidForm.value as any)
      .pipe(catchError(() => of(false)))
      .subscribe(ok => {
        this.processingPayout = false;
        if (!ok) {
          this.showErrorMessage();
          return;
        }

        this.toastService.message({
          type: 'message',
          message: 'Payout is marked as paid successfully'
        });

        modal.close();
      });
  }

  openConfirmationModal(selectedPayout: AdminPayoutRequest, modal: ModalComponent) {
    this.selectedPayout = selectedPayout;
    modal.open();
  }

  search($event: CombinationSingleInputValue) {
    const { key, value } = $event;
    if (value) {
      this.queryStringService.patch({ search: [key, value].join(':') });
      return;
    }

    this.queryStringService.remove(['search']);
  }

  paginate($event: PaginationChange) {
    this.queryStringService.patch($event);
  }

  resetFilter() {
    this.filterForm.reset();
  }

  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Unpaid') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 2,
      title: 'Total Earnings',
      value: 2191,
      type: 'currency',
      tooltip: 'Total earnings',
    },
    {
      id: 1,
      title: 'Failed Payouts',
      value: 8,
      type: 'number',
      tooltip: 'Failed Payouts',
    },
    {
      id: 3,
      title: 'Total Transactions',
      value: 28,
      type: 'number',
      tooltip: 'Total Transactions',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      period: '2024-02-20T10:30:31.398Z',
      payout_amount: 344,
      transaction: 20,
      payout_methode: 'Paypal',
      status: 'Unpaid',
    },
    {
      id: '133',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      period: '2024-02-20T10:30:31.398Z',
      payout_amount: 344,
      transaction: 20,
      payout_methode: 'Paypal',
      status: 'Unpaid',
    },
    {
      id: '125',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      period: '2024-02-20T10:30:31.398Z',
      payout_amount: 344,
      transaction: 20,
      payout_methode: 'Paypal',
      status: 'Paid',
    },
  ];

  private setupFilter() {
    this.queryStringService.initialValue.subscribe(query => {
      this.query = query;
      if (this.query.search) {
        const [key, value] = this.query.search.split(':');
        if (key && value) {
          this.searchValue = { key, value };
        }
      }
      this.filterForm.patchValue(this.queryFormConverter.toForm(query));
    });

    this.queryStringService.valueChanges.pipe(
      debounceTime(400),
      tap(() => this.filtering = true),
      untilDestroyed(this),
    ).subscribe(query => {
      this.payoutService.getFailedPayouts(query).subscribe(res => {
        this.payoutsPagination = res;
        this.filtering = false;
      });
    });

    this.filterForm.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(formValue => {
      this.queryStringService.patch(this.queryFormConverter.toQuery(formValue as any));
    });
  }

  private showErrorMessage() {
    this.toastService.message({
      type: 'error',
      message: 'An error occurred, please try again.'
    });
  }
}
