<ng-container *ngIf="{ course: course$ | async, courseHistory: courseHistory$ | async } as vm">
  <div class="changes-history custom-scrollbar overflow-y-auto p-4" [style.maxHeight]="maxHeight">
    <div class="history-heading mt-5 flex items-center">
      <div class="flex grow gap-3">
        <strong>Changes History</strong>
        <svg-icon
          src="@assets/images/icons/question-mark-solid.svg"
          class="block h-5 w-5"
          tooltip="This is a tooltip. This is a tooltip This is a tooltip. This is a tooltip'"
        ></svg-icon>
      </div>
      <div>
        <button type="button" class="btn btn-outline-red">Purge all</button>
      </div>
    </div>
    <div class="text-sm text-amber-600">Old versions will be deleted after 30 days automatically</div>
    <div class="history-items mt-5">
      <ng-container *ngFor="let course of vm.courseHistory; let idx = index">
        <ng-container *ngTemplateOutlet="historyItem; context: { course: course, idx: idx }"></ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #historyItem let-course="course" let-idx="idx">
    <div class="item flex h-[90px] items-center gap-3 border-b border-neutral-200 text-sm">
      <div class="line relative mx-4 h-full w-px border-l border-dashed border-neutral-900">
        <svg-icon
          src="@assets/images/icons/dot.svg"
          class="absolute -bottom-[3px] -left-[3px] block h-[6px] w-[6px]"
        ></svg-icon>
      </div>
      <div class="grow py-[10px]">
        <div class="value mb-3 py-[10px]">
          <a class="hover:underline" [routerLink]="'/course/' + course.id + '/details'">
            <span>v{{ course.version }} - {{ course.status }} - {{ course.title }}</span>
            <span *ngIf="course.id === vm.course?.id" class="tag-green">Current</span>
          </a>
        </div>
        <div class="meta flex items-center gap-3 text-neutral-500">
          <svg-icon src="@assets/images/icons/calendar2.svg" class="block h-4 w-4"></svg-icon>
          <div>{{ course.date_created | date : 'dd MMM yyyy hh:mm a' }}</div>
          <!-- <div
          class="text-xs italic text-neutral-700 underline"
          tooltip="This is a tooltip. This is a tooltip This is a tooltip. This is a tooltip"
          tooltip_position="top"
        >
          View feedback
        </div> -->
        </div>
      </div>
      <div class="">
        <button type="button" class="btn btn-outline-yellow">
          <svg-icon src="@assets/images/icons/refresh-solid.svg" class="btn-icon"></svg-icon>
          <span>Restore</span>
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
