import { Injectable } from '@angular/core';
import { AdminActivities, AdminQNA, AdminQnAReply, AdminReviews, CoursesResult } from '@shared/models';
import { Observable } from 'rxjs';
import {
  API_DELETE_AND_UPDATE_ADMIN_COURSES_REVIEW,
  API_GET_ADMIN_COURSES_ACTIVITIES,
  API_GET_ADMIN_COURSES_QNA,
  API_GET_ADMIN_COURSES_QNA_REPLIES,
  API_GET_ADMIN_COURSES_REVIEWS,
  API_GET_RECENTLY_UPDATED_COURSE,
  encodeURL,
  HttpService,
  UtilsService,
} from 'thkee-common';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private readonly http: HttpService, private utilsService: UtilsService) {}

  // Recently update course
  getRecentlyUpdatedCourse(params = {} as object, reload: boolean): Observable<CoursesResult> {
    if (reload) {
      return this.http.get<CoursesResult>(encodeURL(API_GET_RECENTLY_UPDATED_COURSE, params));
    } else {
      return this.utilsService.get<CoursesResult>(encodeURL(API_GET_RECENTLY_UPDATED_COURSE, params));
    }
  }

  getAdminCoursesQNA(params = {} as object, productId: string, reload: boolean): Observable<AdminQNA> {
    if (reload) {
      return this.http.get<AdminQNA>(encodeURL(API_GET_ADMIN_COURSES_QNA.replace('<PRODUCT_ID>', productId), params));
    } else {
      return this.utilsService.get<AdminQNA>(
        encodeURL(API_GET_ADMIN_COURSES_QNA.replace('<PRODUCT_ID>', productId), params)
      );
    }
  }

  getAdminCoursesQNAReplies(qnaId: string): Observable<AdminQnAReply> {
    return this.http.get<AdminQnAReply>(API_GET_ADMIN_COURSES_QNA_REPLIES.replace('<QNA_ID>', qnaId));
  }

  getAdminCoursesReviews(params = {} as object, productId: string, reload: boolean): Observable<AdminReviews> {
    if (reload) {
      return this.http.get<AdminReviews>(
        encodeURL(API_GET_ADMIN_COURSES_REVIEWS.replace('<PRODUCT_ID>', productId), params)
      );
    } else {
      return this.utilsService.get<AdminReviews>(
        encodeURL(API_GET_ADMIN_COURSES_REVIEWS.replace('<PRODUCT_ID>', productId), params)
      );
    }
  }

  deleteAdminCoursesReviews(reviewId: string): Observable<void> {
    return this.http.delete(API_DELETE_AND_UPDATE_ADMIN_COURSES_REVIEW.replace('<REVIEW_ID>', reviewId));
  }

  updateAdminCoursesReviews(reviewId: string, payload = {} as object): Observable<void> {
    return this.http.patch(API_DELETE_AND_UPDATE_ADMIN_COURSES_REVIEW.replace('<REVIEW_ID>', reviewId), payload);
  }

  getAdminCoursesActivities(params = {} as object, productId: string, reload: boolean): Observable<AdminActivities> {
    if (reload) {
      return this.http.get<AdminActivities>(
        encodeURL(API_GET_ADMIN_COURSES_ACTIVITIES.replace('<PRODUCT_ID>', productId), params)
      );
    } else {
      return this.utilsService.get<AdminActivities>(
        encodeURL(API_GET_ADMIN_COURSES_ACTIVITIES.replace('<PRODUCT_ID>', productId), params)
      );
    }
  }
}
