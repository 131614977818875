import { Component, OnInit } from '@angular/core';
import { InstructorGroup, SharePricing } from 'thkee-common';

let dummyData: InstructorGroup = {
  id: 3,
  date_created: '2023-05-24T19:34:25.087673Z',
  date_updated: '2023-05-24T19:34:25.087725Z',
  group_name: 'Premium',
  is_active: true,
  total_users: 34,
};

let shareData: SharePricing[] = [
  {
    id: 1,
    date_created: '2024-01-04T03:53:31.630Z',
    date_updated: '2024-01-04T03:53:31.630Z',
    code: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    share_types: 'ORGANICS',
    instructor_share: 60,
    platform_share: 40,
    affiliate_share: 100,
    thkee_credit: 100,
    group_name: 'Instructor (Default)',
    group: 0,
  },
  {
    id: 2,
    date_created: '2024-01-04T03:53:31.630Z',
    date_updated: '2024-01-04T03:53:31.630Z',
    code: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    share_types: 'ORGANICS',
    instructor_share: 90,
    platform_share: 10,
    affiliate_share: 100,
    thkee_credit: 100,
    group_name: 'Premium Instructor',
    group: 0,
  },
  {
    id: 3,
    date_created: '2024-01-04T03:53:31.630Z',
    date_updated: '2024-01-04T03:53:31.630Z',
    code: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    share_types: 'ORGANICS',
    instructor_share: 70,
    platform_share: 30,
    affiliate_share: 100,
    thkee_credit: 100,
    group_name: 'Partner Instructor',
    group: 0,
  },
];

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class InstructorGroupViewComponent implements OnInit {
  groupData: InstructorGroup = dummyData; // TODO: Update or replace this with real data
  sharePricingData: SharePricing[] = shareData; // TODO: Update or replace this with real data
  constructor() {}

  ngOnInit(): void {}
}
