import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Category, ModalComponent, SubCategory } from '@shared';
import { SettingsService } from '@shared/services';
import { Subscription } from 'rxjs';
import { ModalService, ToastService } from 'thkee-common';

@Component({
  selector: 'app-course-categories',
  templateUrl: './course-categories.component.html',
  styleUrls: ['./course-categories.component.scss'],
})
export class CourseCategoriesComponent implements OnInit, OnDestroy {
  private categoryListSubscriptions = new Subscription();
  private categoryActionSubscriptions = new Subscription();
  categoryLists: Category[] = [];
  categoryListLoading: boolean = false;

  // Category form
  categoryForm = new FormGroup({});
  categoryFormModel = { name: '', description: '' };
  categoryFormFields: FormlyFieldConfig[] = [];
  initCategoryForm(disabled: boolean) {
    this.categoryFormFields = [
      {
        key: 'name',
        type: 'input',
        props: {
          label: $localize`Category Name`,
          wrapClass: 'mb-0 input-group',
          labelClass: 'font-bold text-black text-lg pb-2.5',
          minLength: 6,
          maxLength: 60,
          placeholder: $localize`English`,
          tips: $localize`Category Title`,
          disabled: disabled,
        },
        validation: {
          messages: {
            minLength: $localize`Title can't be lower than 6 characters.`,
          },
        },
      },
      {
        key: 'name_ar',
        type: 'input',
        props: {
          label: $localize`Category Name Arabic`,
          hideLabel: true,
          labelClass: 'font-bold text-black text-lg pb-2.5',
          minLength: 6,
          maxLength: 60,
          placeholder: $localize`Arabic`,
          tips: $localize`Category Name Arabic`,
          disabled: disabled,
        },
        validation: {
          messages: {
            minLength: $localize`Title can't be lower than 6 characters.`,
          },
        },
      },
      {
        key: 'description',
        type: 'textarea',
        props: {
          label: $localize`Description`,
          wrapClass: 'mb-0 input-group',
          labelClass: 'font-bold text-black text-lg pb-2.5',
          tips: $localize`Description English`,
          placeholder: $localize`English`,
          required: false,
          minHeight: '100px',
          maxHeight: '150px',
          disabled: disabled,
        },
      },
      {
        key: 'description_ar',
        type: 'textarea',
        props: {
          label: $localize`Description Arabic`,
          hideLabel: true,
          labelClass: 'font-bold text-black text-lg pb-2.5',
          tips: $localize`Description Arabic`,
          placeholder: $localize`Arabic`,
          required: false,
          minHeight: '100px',
          maxHeight: '150px',
          disabled: disabled,
        },
      },
    ];
  }

  constructor(
    private settingsService: SettingsService,
    private toastService: ToastService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getCategoryLists();
    this.initCategoryForm(false);
  }

  private getCategoryLists() {
    this.categoryListLoading = true;
    this.categoryListSubscriptions.add(
      this.settingsService.getCategoryLists().subscribe({
        next: (res: Category[]) => {
          this.categoryLists = res;
          this.categoryListLoading = false;
        },
      })
    );
  }

  // Managed category
  @ViewChild('categoryConfirmedModal') categoryConfirmedModal!: ModalComponent;
  selectedCategory!: Category;
  categoryActionType: string = '';
  isSubmittedCategory: boolean = false;
  categoryAction(type: string, data: Category = {}, index: number = 0) {
    this.categoryForm.reset();
    this.categoryForm.markAsUntouched();
    this.initCategoryForm(false);
    this.selectedCategory = data;
    this.categoryActionType = type;
    if (type === 'Add') {
      this.categoryConfirmedModal.open();
    } else if (type === 'View') {
      this.categoryForm.patchValue(data);
      this.initCategoryForm(true);
      this.categoryConfirmedModal.open();
    } else if (type === 'Update') {
      this.categoryForm.patchValue(data);
      this.categoryConfirmedModal.open();
    }
    if (type === 'Delete') {
      this.modalService.confirm({
        title: $localize`Delete Confirmation`,
        message: $localize`Are you sure, You want to delete category: ${data.name}`,
        onConfirm: () => {
          this.deleteCategory(data);
        },
      });
    }
  }

  // Create & Update Category
  categoryCreate() {
    this.isSubmittedCategory = true;
    if (this.categoryActionType === 'Add') {
      this.categoryActionSubscriptions.add(
        this.settingsService.createCategory(this.categoryForm.value).subscribe({
          next: (res: Category) => {
            this.categoryForm.reset();
            this.categoryConfirmedModal.close();
            this.isSubmittedCategory = false;
            this.listUpdater('Add', this.categoryLists, res);
          },
          error: (error) => {
            this.isSubmittedCategory = false;
            this.toastService.error({
              message: error.error.errors[0].detail,
            });
          },
        })
      );
    } else {
      if (this.selectedCategory.id) {
        this.categoryActionSubscriptions.add(
          this.settingsService.updateCategory(this.categoryForm.value, this.selectedCategory.id).subscribe({
            next: (res: Category) => {
              this.categoryForm.reset();
              this.categoryConfirmedModal.close();
              this.isSubmittedCategory = false;
              this.listUpdater('Update', this.categoryLists, res);
            },
            error: (error) => {
              this.isSubmittedCategory = false;
              this.toastService.error({
                message: error.error.errors[0].detail,
              });
            },
          })
        );
      }
    }
  }

  // Delete category
  private deleteCategory(data: Category) {
    if (data.id) {
      this.categoryActionSubscriptions.add(
        this.settingsService.deleteCategory(data.id).subscribe({
          next: (res: Category) => {
            this.listUpdater('Delete', this.categoryLists, data);
            this.selectedCategory = {};
          },
          error: (error) => {
            this.toastService.error({
              message: error.error.errors[0].detail,
            });
          },
        })
      );
    }
  }

  // Dropdown
  isDropdown: boolean[] = [];
  dropdown(type: number) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  handleClickOutside(type: number) {
    this.isDropdown[type] = false;
  }

  // Sub category
  onclickCategory(data: Category) {
    this.selectedCategory = data;
  }

  // Managed Sub category
  @ViewChild('subCategoryFormModal') subCategoryFormModal!: ModalComponent;
  selectedSubCategory!: SubCategory;
  subCategoryActionType: string = '';
  isSubmittedSubcategory: boolean = false;
  subCategoryAction(type: string, data: SubCategory = {}, index: number = 0) {
    this.categoryForm.reset();
    this.categoryForm.markAsUntouched();
    this.initCategoryForm(false);
    this.selectedSubCategory = data;
    this.subCategoryActionType = type;
    if (type === 'Add') {
      this.subCategoryFormModal.open();
    } else if (type === 'View') {
      this.categoryForm.patchValue(data);
      this.initCategoryForm(true);
      this.subCategoryFormModal.open();
    } else if (type === 'Update') {
      this.categoryForm.patchValue(data);
      this.subCategoryFormModal.open();
    }
    if (type === 'Delete') {
      this.modalService.confirm({
        title: $localize`Delete Confirmation`,
        message: $localize`Are you sure, You want to delete category: ${data.name}`,
        onConfirm: () => {
          this.deleteSubCategory(data);
        },
      });
    }
  }

  subCategoryCreate() {
    this.isSubmittedSubcategory = true;
    if (this.subCategoryActionType === 'Add') {
      const payload = {
        name: this.categoryFormModel.name,
        category: this.selectedCategory.id,
        description: this.categoryFormModel.description,
      };
      this.categoryActionSubscriptions.add(
        this.settingsService.createSubCategory(payload).subscribe({
          next: (res: SubCategory) => {
            this.categoryForm.reset();
            this.subCategoryFormModal.close();
            this.isSubmittedSubcategory = false;
            this.listUpdater('Add', this.selectedCategory.subcategories, res);
          },
          error: (error) => {
            this.isSubmittedSubcategory = false;
            this.toastService.error({
              message: error.error.errors[0].detail,
            });
          },
        })
      );
    } else {
      if (this.selectedSubCategory.id) {
        const payload = {
          name: this.categoryFormModel.name,
          category: this.selectedCategory.id,
          description: this.categoryFormModel.description,
        };
        this.categoryActionSubscriptions.add(
          this.settingsService.updateSubCategory(payload, this.selectedSubCategory.id).subscribe({
            next: (res: SubCategory) => {
              this.categoryForm.reset();
              this.subCategoryFormModal.close();
              this.isSubmittedSubcategory = false;
              this.listUpdater('Update', this.selectedCategory.subcategories, res);
            },
            error: (error) => {
              this.isSubmittedSubcategory = false;
              this.toastService.error({
                message: error.error.errors[0].detail,
              });
            },
          })
        );
      }
    }
  }

  // Delete sub category
  private deleteSubCategory(data: SubCategory) {
    if (data.id) {
      this.categoryActionSubscriptions.add(
        this.settingsService.deleteSUbCategory(data.id).subscribe({
          next: (res: SubCategory) => {
            this.listUpdater('Delete', this.selectedCategory.subcategories, data);
          },
          error: (error) => {
            this.toastService.error({
              message: error.error.errors[0].detail,
            });
          },
        })
      );
    }
  }

  // Dropdown
  isDropdownSub: boolean[] = [];
  subCategoryDropdown(type: number) {
    this.isDropdownSub[type] = !this.isDropdownSub[type];
  }

  handleSubCategoryClickOutside(type: number) {
    this.isDropdownSub[type] = false;
  }

  // List action
  listUpdater(actionType: string, list: Category[] | SubCategory[] = [], res: Category | SubCategory) {
    if (actionType === 'Add') {
      list.unshift(res);
    } else if (actionType === 'Update') {
      const index = list.findIndex((d) => d.id === res.id);
      list.splice(index, 1, res);
    } else {
      const index = list.findIndex((d) => d.id === res.id);
      list.splice(index, 1);
    }
  }

  ngOnDestroy(): void {
    this.categoryListSubscriptions.unsubscribe();
    this.categoryActionSubscriptions.unsubscribe();
  }
}
