import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-circle-progressbar',
  templateUrl: './circle-progressbar.component.html',
  styleUrls: ['./circle-progressbar.component.scss'],
})
export class CircleProgressbarComponent implements OnInit {
  @Input() outerStrokeColor: any = '#3941FE';
  @Input() innerStrokeColor: any = '#CCCCCC';
  @Input() percent: number = 0;
  @Input() radius: number = 36;
  @Input() outerStrokeWidth: number = 8;
  @Input() innerStrokeWidth: number = 4;
  @Input() space: number = -5;
  @Input() animationDuration: number = 300;
  @Input() backgroundPadding: number = 0;

  constructor() {}

  ngOnInit(): void {}
}
