<div class="price-currencies p-4">
  <div class="mb-4 flex flex-col justify-between gap-4">
    <div>
      <h3>Instructor Group</h3>
    </div>
    <div>
      <div class="grid grid-cols-3 gap-4">
        <div>
          <div class="stats-box rounded-[5px] border border-neutral-100 p-4 text-center">
            <div class="stats-label mb-2 flex items-center justify-center gap-2 font-bold">
              <span>Total Instructor Group</span>
              <span>
                <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
                  tooltip="This is a tooltip. This is a tooltip This is a tooltip. This is a tooltip'"></svg-icon>
              </span>
            </div>
            <div class="stats-data text-5xl">3</div>
          </div>
        </div>
        <div>
          <div class="stats-box rounded-[5px] border border-neutral-100 p-4 text-center">
            <div class="stats-label mb-2 flex items-center justify-center gap-2 font-bold">
              <span>Active Instructor Group</span>
              <span>
                <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
                  tooltip="This is a tooltip. This is a tooltip This is a tooltip. This is a tooltip'"></svg-icon>
              </span>
            </div>
            <div class="stats-data text-5xl">3</div>
          </div>
        </div>
        <div>
          <div class="stats-box rounded-[5px] border border-neutral-100 p-4 text-center">
            <div class="stats-label mb-2 flex items-center justify-center gap-2 font-bold">
              <span>Inactive Instructor Group</span>
              <span>
                <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
                  tooltip="This is a tooltip. This is a tooltip This is a tooltip. This is a tooltip'"></svg-icon>
              </span>
            </div>
            <div class="stats-data text-5xl">0</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <a routerLink="./new">
        <button class="btn-blue">
          <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
          <span>Add Group</span>
        </button>
      </a>
    </div>
    <div class="actions flex gap-4">
      <!-- TODO: Convert to formly -->
      <div>
        <button class="btn-neutral">
          <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
          <span>Filter</span>
        </button>
      </div>
      <div class="input-combine flex">
        <div class="select2">
          <div class="relative">
            <a type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
              class="btn-neutral rounded-r-none">
              <div class="selected-item truncate">Instructor Group ID</div>
              <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
            </a>
            <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"
              class="select-items hidden" ng-reflect-ng-class="hidden">
              <li id="listbox-option-0" role="option" class="item">Item 1</li>
              <li id="listbox-option-0" role="option" class="item">Item 2</li>
              <li id="listbox-option-0" role="option" class="item">Item 3</li>
            </ul>
          </div>
        </div>
        <div class="input-group">
          <div class="group !rounded-l-none">
            <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
            <input placeholder="Enter your full name" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tier-list">
    <app-data-table title="Instructor Group" badge="3 Instructor Group">
      <ng-container table>
        <table class="w-full text-left">
          <thead class="heading">
            <tr>
              <th scope="col">
                <div class="col-name">ID</div>
              </th>
              <th scope="col">
                <div class="col-name">Instructor Group Name</div>
              </th>
              <th scope="col">
                <div class="col-name">Instructors</div>
              </th>
              <th scope="col">
                <div class="col-name">Created Date</div>
              </th>
              <th scope="col">
                <div class="col-name">Status</div>
              </th>
              <th scope="col" class="w-20">
                <div class="col-name">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="" class="text-left" *ngFor="let group of instructorGroup">
              <td class="!py-5">
                <span class="data-id py-2">#{{ group.id }}</span>
              </td>
              <td>
                <a class="data-name text-blue-600 underline" routerLink="./{{ group.id }}">{{ group.group_name }}</a>
              </td>
              <td>
                <a class="data-instructor text-blue-600" routerLink="./{{ group.id }}/member">{{
                  group.total_users
                  }}</a>
              </td>
              <td>
                <div class="data-time">{{ group.date_created | formatDate : 'll' }}</div>
                <div class="data-time text-neutral-500">{{ group.date_created | formatDate : 'h:ss A' }}</div>
              </td>
              <td>
                <div class="tag-green tag-sm" *ngIf="group.is_active">Active</div>
                <div *ngIf="!group.is_active" class="tag-red tag-sm">Disabled</div>
              </td>
              <td>
                <div class="flex justify-center gap-2">
                  <a routerLink="./{{ group.id }}"><button class="btn-black btn-sm px-2">
                      <svg-icon src="@assets/images/icons/cog.svg" class="btn-icon h-4 w-4"></svg-icon>
                    </button>
                  </a>
                  <a routerLink="./{{ group.id }}/edit">
                    <button class="btn-amber-soft btn-sm px-2">
                      <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
                    </button>
                  </a>
                  <button class="btn-outline-red btn-sm px-2">
                    <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon h-4 w-4"></svg-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </app-data-table>
  </div>
</div>