<app-course-header [courseState]="courseState"></app-course-header>
<div class="course-intended p-4">
   <app-course-heading></app-course-heading>
   <div class="card mb-4 block">
      <div class="mb-3 h5">
         Activities Breakdown
      </div>
      <div class="sections flex flex-col gap-3">
         <div class="section-item bg-neutral-50 pb-px" *ngFor="let section of activity_lists.results; let i = index"
            [attr.sectionId]="i">
            <ng-container
               *ngTemplateOutlet="sectionHeader; context: { i: i, section: section, active: sectionCollapse[i] }">
            </ng-container>
            <ng-container
               *ngTemplateOutlet="sectionContent; context: { i: i, subsections: section.subsections, active: sectionCollapse[i] }">
            </ng-container>
         </div>
      </div>
   </div>
</div>

<ng-template #sectionHeader let-i="i" let-section="section" let-isActive="active">
   <div class="header flex items-center justify-between rounded-[5px] px-4 py-2" [class.bg-neutral-100]="isActive">
      <div class="left flex items-center gap-3">
         <div class="drag-option">
            <svg-icon src="@assets/images/icons/menu-alt-solid.svg" class="block h-4 w-4"></svg-icon>
         </div>
         <div class="section-number text-sm font-bold">Section {{ i + 1 }}:</div>
         <div class="section-name flex cursor-pointer items-center gap-3" [class.hidden]="sectionEdit[i]">
            <div class="text-sm text-neutral-700">{{section.title}}</div>
         </div>
      </div>
      <div class="right flex items-center gap-3">
         <div class="section-action">
            <button class="btn-transparent btn-sm px-[7px] text-black"
               (click)="sectionCollapse[i] = !sectionCollapse[i]">
               <svg-icon src="@assets/images/icons/chevron-down.svg" class="btn-icon"
                  [class.rotate-180]="sectionCollapse[i]">
               </svg-icon>
            </button>
         </div>
      </div>
   </div>
</ng-template>
<ng-template #sectionContent let-i="i" let-subsections="subsections" let-isActive="active">
   <ng-container *ngIf="isActive">
      <div class="content p-5">
         <div class="subsection-items">
            <div class="datagrid-table no_pagination relative">
               <table class="w-full text-left">
                  <thead class="heading">
                     <tr>
                        <th scope="col" class="w-[15%]">
                           <div class="col-name">Lecture Type</div>
                        </th>
                        <th scope="col" class="w-[40%]">
                           <div class="col-name">Lecture Name</div>
                        </th>
                        <th scope="col" class="w-[15%]">
                           <div class="col-name">Users who completed</div>
                        </th>
                        <th scope="col" class="w-[15%]">
                           <div class="col-name">Avg Study Time</div>
                        </th>
                        <th scope="col" class="w-[15%]">
                           <div class="col-name">Total Study Time</div>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr class="" class="text-left" *ngFor="let subsection of subsections; let i = index">
                        <td class="">
                           <span *ngIf="subsection.type==='Article'"
                              class="course-id py-0 text-neutral-400 flex items-center gap-2.5">
                              <svg-icon src="@assets/images/icons/book-open.svg" class="w-4 h-4"></svg-icon>
                              Article
                           </span>
                           <span *ngIf="subsection.type==='Video'"
                              class="course-id py-0 text-neutral-400 flex items-center gap-2.5">
                              <svg-icon src="@assets/images/icons/play-circle-solid.svg" class="w-4 h-4"></svg-icon>
                              Video
                           </span>
                           <span *ngIf="subsection.type==='quiz'"
                              class="course-id py-0 text-neutral-400 flex items-center gap-2.5">
                              <svg-icon src="@assets/images/icons/puzzle-piece-solid.svg" class="w-4 h-4"></svg-icon>
                              Quiz
                           </span>
                           <span *ngIf="subsection.type==='assignment'"
                              class="course-id py-0 text-neutral-400 flex items-center gap-2.5">
                              <svg-icon src="@assets/images/icons/clipboard-solid.svg" class="w-4 h-4"></svg-icon>
                              Assignment
                           </span>
                        </td>
                        <td class="">
                           <span class="course-id py-0"> <span class="font-normal text-slate-600">Lecture</span>
                              {{i+1}}: {{subsection.subsection_type.title}}</span>
                        </td>
                        <td class="">
                           <span class="course-id py-0">{{subsection.users_have_completed}}</span>
                        </td>
                        <td class="">
                           <span class="course-id py-0">{{
                              subsection.average_study_time ? subsection.average_study_time : '--'}}</span>
                        </td>
                        <td class="">
                           <span class="course-id py-0">{{
                              subsection.total_study_time ? subsection.total_study_time : '--'}}</span>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </ng-container>
</ng-template>