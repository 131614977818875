import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { Shell } from '@app/shell/shell.service';
//import { CoursesComponent } from './courses/courses.component';
// import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { HomeComponent } from './home/home.component';
import { MarketingComponent } from './marketing/marketing.component';
import { OverviewComponent } from './overview/overview.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReportComponent } from './report/report.component';

import {
  ActivitiesComponent,
  CourseCurriculumComponent,
  CourseDetailsComponent,
  CourseIntendedComponent,
  CoursePricingComponent,
  CourseQAComponent,
  CourseReviewsComponent,
  CourseRevisionComponent,
  CourseStatisticsComponent,
} from './course'; // Single Course Components
import {
  CourseSettingsComponent,
  CoursesComponent,
  PublishedComponent,
  RejectedComponent,
  ReviewComponent,
  StatisticsComponent,
  UpdatedComponent,
} from './courses'; // All Courses Components

import {
  DashboardComponent,
  InvoiceComponent,
  OrderDetailsComponent,
  OrdersComponent,
  RefundDetailsComponent,
  RefundRequestDetailsComponent,
  RefundRequestsComponent,
  TransactionDetailsComponent,
  TransactionsComponent,
} from './e-commerce';

// import * as ECommerce from './e-commerce';
import * as Payouts from '../pages/e-commerce/payouts';
import { readyRequestsRouting } from './e-commerce/ready-requests';
import * as Price from './price-management'; // All Courses Components
import * as Settings from './settings'; // All Users Components
import { SettingsComponent } from './settings';
import * as Users from './users';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/overview', pathMatch: 'full', data: { title: marker('Home') } },
    { path: 'dashboard', component: HomeComponent, data: { title: marker('Home') } },
    { path: 'overview', component: OverviewComponent, data: { title: marker('Overview') } },
    // { path: 'e-commerce', component: ECommerceComponent, data: { title: marker('E-Commerce') } },
    { path: 'marketing', component: MarketingComponent, data: { title: marker('Marketing') } },
    { path: 'courses', component: CoursesComponent, data: { title: marker('Courses') } },
    { path: 'report', component: ReportComponent, data: { title: marker('Report') } },
    // { path: 'users', component: UsersComponent, data: { title: marker('Users') } },
    // { path: 'settings', component: SettingsComponent, data: { title: marker('Settings') } },
  ]),
  Shell.childSettingsRoutes([
    { path: 'settings', component: SettingsComponent, data: { title: marker('Settings') } },
    ...Settings.Routing,
  ]),
  Shell.childCommerceRoutes([
    {
      path: 'dashboard',
      component: DashboardComponent,
      data: { title: marker('E-Commerce'), breadcrumb: 'Dashboard' },
    },
    {
      path: 'sales/transactions',
      component: TransactionsComponent,
      data: { title: marker('E-Commerce'), breadcrumb: 'Transactions' },
    },
    {
      path: 'sales/transaction/:transactionId',
      component: TransactionDetailsComponent,
      data: { title: marker('E-Commerce'), breadcrumb: 'Transactions Details' },
    },
    {
      path: 'sales/refund/:transactionId',
      component: RefundDetailsComponent,
      data: { title: marker('E-Commerce'), breadcrumb: 'Transactions Details' },
    },
    {
      path: 'orders',
      component: OrdersComponent,
      data: { title: marker('E-Commerce'), breadcrumb: 'Orders' },
    },
    {
      path: 'order/:orderId',
      component: OrderDetailsComponent,
      data: { title: marker('E-Commerce'), breadcrumb: 'Order Details' },
    },
    {
      path: 'order/:orderId/invoice',
      component: InvoiceComponent,
      data: { title: marker('E-Commerce'), breadcrumb: 'Invoice' },
    },
    {
      path: 'refund',
      component: RefundRequestsComponent,
      data: { title: marker('E-Commerce'), breadcrumb: 'Refund Request' },
    },
    readyRequestsRouting,
    {
      path: 'refund/:orderId',
      component: RefundRequestDetailsComponent,
      data: { title: marker('E-Commerce'), breadcrumb: 'Order' },
    },
    ...Payouts.Routing,
  ]),
  Shell.childCoursesRoutes([
    {
      path: 'overview',
      component: CoursesComponent,
      data: { title: marker('Courses'), breadcrumb: 'Overview' },
    },
    {
      path: 'statistics',
      component: StatisticsComponent,
      data: { title: marker('Courses'), breadcrumb: 'Statistic' },
    },
    {
      path: 'review',
      component: ReviewComponent,
      data: { title: marker('Courses'), breadcrumb: 'To Review' },
    },
    {
      path: 'published',
      component: PublishedComponent,
      data: { title: marker('Courses'), breadcrumb: 'Published' },
    },
    {
      path: 'updated',
      component: UpdatedComponent,
      data: { title: marker('Courses'), breadcrumb: 'Recently Updated' },
    },
    {
      path: 'rejected',
      component: RejectedComponent,
      data: { title: marker('Courses'), breadcrumb: 'Rejected' },
    },
    {
      path: 'settings',
      component: CourseSettingsComponent,
      data: { title: marker('Courses'), breadcrumb: 'Course Settings' },
    },
    {
      path: ':courseId/revision',
      component: CourseRevisionComponent,
      data: { title: 'Courses', breadcrumb: 'Revision' },
    },
  ]),
  Shell.childCourseRoutes([
    {
      path: '',
      redirectTo: '/courses/overview', // Redirect to the plural form 'courses'
      pathMatch: 'full',
    },
    {
      path: ':courseId/',
      component: CourseStatisticsComponent,
      data: { title: marker('Courses'), breadcrumb: 'Course12' },
    },
    {
      path: ':courseId/statistics',
      component: CourseStatisticsComponent,
      data: { title: marker('Courses'), breadcrumb: 'Course12' },
    },
    {
      path: ':courseId/details',
      component: CourseDetailsComponent,
      data: { title: marker('Courses'), breadcrumb: 'Course12' },
    },
    {
      path: ':courseId/intended-learners',
      component: CourseIntendedComponent,
      data: { breadcrumb: 'Overview' },
    },
    {
      path: ':courseId/curriculum',
      component: CourseCurriculumComponent,
      data: { breadcrumb: 'Overview' },
    },
    {
      path: ':courseId/pricing',
      component: CoursePricingComponent,
      data: { breadcrumb: 'Overview' },
    },
    {
      path: ':courseId/qa',
      component: CourseQAComponent,
      data: { breadcrumb: 'Overview' },
    },
    {
      path: ':courseId/reviews',
      component: CourseReviewsComponent,
      data: { breadcrumb: 'Overview' },
    },
    {
      path: ':courseId/activities',
      component: ActivitiesComponent,
      data: { breadcrumb: 'Overview' },
    },
  ]),
  Price.Routing,
  Users.Routing,
  // ECommerce.Routing,
  { path: '404', component: PageNotFoundComponent, data: { title: marker('Home') } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PageRoutingModule { }
