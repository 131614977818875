import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  PricingGeoActions,
  PricingTierActions,
  PricingTierState,
  UntilDestroy,
  selectGeoPricingTiers,
  selectLoadedPricingGeoData,
  selectPricingTier,
  untilDestroyed,
} from '@shared';
import * as _ from 'lodash';
import { Observable, combineLatest, map, of, switchMap, tap } from 'rxjs';
import { GeoLocation, GeoPricingTier, ModalService, PricingTier } from 'thkee-common';

interface geoPricingTiersTable {
  id: string | number;
  name: string;
  percentage: string | number;
  tierAmount: string | number;
  geoAmount: string | number;
  status: boolean | undefined;
  currency_code: string;
}

@UntilDestroy()
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class PriceGeolocationViewComponent implements OnInit {
  geoGeoData!: GeoLocation; // TODO: Update or replace this with real data
  geoTiersData!: GeoPricingTier[];

  id = '';
  priceGeo$: Observable<GeoLocation> = this.store.select(selectLoadedPricingGeoData);
  priceGeoDataDefault: GeoLocation = {
    id: 0,
    date_created: '',
    date_updated: '',
    status: false,
    is_deleted: false,
    country: 0,
    currency: 0,
    country_str: '',
    currency_str: '',
    currency_convert: false,
  };
  formData: any = {};
  hasData = false;

  // Pricing Tiers
  priceTiers$: Observable<PricingTierState> = this.store.select(selectPricingTier);
  priceTiers!: PricingTier[];

  // Geo Pricing Tiers
  geoPricingTiers$: Observable<GeoPricingTier[]> = this.store.select(selectGeoPricingTiers);
  geoPricingTiers!: GeoPricingTier[];

  // Geo Tiers Data
  geoPricingTiersTable!: geoPricingTiersTable[];

  constructor(
    private store: Store,
    private readonly modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(PricingGeoActions.getGeoLoc({ id: this.id }));

    // Get Geo Location Data with ID then fetch tiers and geo tiers
    this.priceGeo$
      .pipe(
        untilDestroyed(this),
        switchMap((item): any => {
          // Handle the initial data
          this.geoGeoData = item;
          this.formData = item;
          this.hasData = true;

          if (item.id) {
            // Dispatch the action only if geo_pricing.id is available
            this.store.dispatch(PricingTierActions.loadPricingTierList({ page: 1, page_size: 100 }));
            this.store.dispatch(PricingGeoActions.loadGeoPricingTiers({ geo_location: item.id }));
          }
          return of(item);
        })
      )
      .subscribe();

    // forkJoin([
    //   this.priceTiers$.pipe(
    //     untilDestroyed(this),
    //     tap((priceTiers: PricingTierState) => {
    //       this.priceTiers = priceTiers?.pricingTierList?.results || [];
    //       console.log('Q1--', this.priceTiers);
    //     })
    //   ),
    //   this.geoPricingTiers$.pipe(
    //     untilDestroyed(this),
    //     tap((geoPricingTier: GeoPricingTier[]) => {
    //       this.geoPricingTiers = geoPricingTier || [];
    //       console.log('Q2--', this.geoPricingTiers);
    //     })
    //   ),
    // ]).subscribe();

    combineLatest([
      this.priceTiers$.pipe(
        untilDestroyed(this),
        tap((priceTiers) => {
          this.priceTiers = priceTiers?.pricingTierList?.results || [];
          return this.priceTiers;
        }),
        map(() => this.priceTiers)
      ),
      this.geoPricingTiers$.pipe(untilDestroyed(this)),
    ]).subscribe(([priceTiers, geoPricingTier]) => {
      this.geoPricingTiersTable = [];
      if (geoPricingTier) {
        geoPricingTier.forEach((gTier) => {
          const sTier = _.find(priceTiers, { id: gTier.pricing_tier });
          if (sTier) {
            // Compute
            let originalPrice = Number(sTier?.price);
            let tierPrice: number = 0;
            tierPrice = originalPrice + (originalPrice * Number(gTier.percentage)) / 100;
            const data: geoPricingTiersTable = {
              id: gTier.id,
              name: sTier?.name || 'Not Found',
              percentage: Math.round(Number(gTier.percentage)),
              tierAmount: sTier ? Number(sTier?.price).toFixed(2) : '',
              geoAmount: tierPrice.toFixed(2),
              status: sTier?.price_tier_status || false,
              currency_code: sTier['currency_code'] ?? 'USD',
            };
            this.geoPricingTiersTable.push(data);
          }
        });
      }

      console.log('this.geoPricingTiersTable--', this.geoPricingTiersTable);
    });

    // TODO: Soon to be deprecated please convert to latest endpoint
    // this.store.dispatch(PricingTierActions.loadPricingTierList({ page: 1, page_size: 10 }));
    // combineLatest([this.priceTiers$, this.priceGeo$])
    //   .pipe(untilDestroyed(this))
    //   .subscribe(([pricingTiers, geoLocation]) => {
    //     try {
    //       this.priceTiers = [...pricingTiers?.pricingTierList?.results] || [];
    //     } catch (e) {}

    //     console.log('C1--', this.priceTiers, geoLocation);

    //     this.geoGeoData = geoLocation;
    //     this.formData = geoLocation;
    //     this.hasData = true;
    //     if (geoLocation.geo_pricing) {
    //       const geoPricingId = geoLocation.geo_pricing.id;
    //       if (geoPricingId) {
    //         // this.store.dispatch(PricingGeoActions.loadGeoPricingTiers({ geo_pricing: geoPricingId }));
    //       }
    //     }
    //   });
  }

  editMode() {
    this.modalService.confirm({
      title: 'Edit Confirmation',
      message: `Are you sure want to edit Geolocation for ${this.geoGeoData.country_str}?`,
      onConfirm: () => {
        this.router.navigate([this.router.url + '/edit']);
      },
    });
  }
}
