<div *ngIf="!data" class="w-full py-10 flex justify-center" [thkLibProcessing]="true"></div>
<div *ngIf="data" class="courses-list">
   <div [ngClass]="isSidebarComponent?'':'rounded-lg border border-neutral-100'" class="card">
      <!-- Card Header -->
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-normat text-neutral-800" i18n>Transaction ID</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">#{{transaction_id}}</h6>
            </div>
            <div class="flex flex-col gap-3 max-w-lg">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-normat text-neutral-800" i18n>Product</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
                  </svg-icon>
               </div>
               <div class="flex justify-start gap-2">
                  <h6 class="text-xl font-semibold text-neutral-900">
                     {{product_title}}
                     (<span class="text-blue-600">#{{product_id.substring(0, 8)}} </span>)
                  </h6>
                  <thk-ui-snippets [type]="'clipboardButton'" [clipboardInput]="product_id"
                     customClass="w-11 h-11 !rounded-full">
                  </thk-ui-snippets>
               </div>
            </div>

            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-normat text-neutral-800" i18n>Type</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Type" class="w-5 h-5">
                  </svg-icon>
               </div>
               <div
                  class="tag tag-{{ getStatusTagColor(transaction_type) }} tag-sm max-w-[60px] capitalize">
                  {{transaction_type }}
               </div>
               <div *ngIf="transaction_type === 'sale' && refund" class="flex items-center gap-2">
                  <span class="w-2.5 h-2.5 rounded-full bg-rose-600"></span>
                  <h6 class="text-xl font-semibold text-neutral-900" i18n>Refunded</h6>
                  <a (click)="gotToRelatedRefund(related_transaction)" class="text-blue-500 text-sm font-normal cursor-pointer">({{related_transaction}})</a>
               </div>
               <div *ngIf="transaction_type === 'refund' && refund" class="flex items-center gap-2">
                  <span class="w-2.5 h-2.5 rounded-full bg-rose-600"></span>
                  <h6 class="text-xl font-semibold text-neutral-900" i18n>Related Transaction</h6>
                  <a (click)="gotToRelatedRefund(related_transaction)" class="text-blue-500 text-sm font-normal cursor-pointer">({{related_transaction}})</a>
               </div>
            </div>
         </div>

         <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
            <svg-icon src="@assets/images/icons/arrow-down-tray.svg" class="text-blue-600 w-4 h-4"></svg-icon>
            <span class="text-blue-600 font-semibold text-sm" i18n>Download PDF</span>
         </button>
      </div>

      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2 relative">
               <h5 class="text-sm font-semibold text-neutral-600" i18n>Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-neutral-900">
               {{data.instructor?.fullname}} ( <span class="text-blue-600">#{{data.instructor?.id}}</span> )
            </h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2 relative">
               <h5 class="text-sm font-semibold text-neutral-600" i18n>Buyer</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Buyer" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-neutral-900">
               {{buyer_name}} ( <span class="text-blue-600">#{{buyer_id}}</span> )
            </h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2 relative">
               <h5 class="text-sm font-semibold text-neutral-600" i18n>Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Date" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base">{{transaction_date | date}}</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2 relative">
               <h5 class="text-sm font-semibold text-neutral-600" i18n>Payment Gateway</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Gateway"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-neutral-900">{{payment_gateway}}</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2 relative">
               <h5 class="text-sm font-semibold text-neutral-600" i18n>Payment Method</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Method"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-neutral-600">{{payment_method}}</h3>
         </div>
      </div>

      <!-- Summary graph -->
      <div class="px-6 pt-6 pb-12">
         <h5 class="text-base text-neutral-900 font-bold" i18n>Premium Instructor</h5>
         <div class="flex flex-row gap-2.5 my-4">
            <div class="flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full">
               <span class="w-[42px] h-11 bg-teal-600 rounded-[5px] flex justify-center items-center">
                  <svg-icon src="@assets/images/icons/dollar-sign.svg" class="text-black">
                  </svg-icon>
               </span>
               <div class="flex flex-col gap-1 flex-1">
                  <div class="flex flex-row items-center gap-2 relative">
                     <h5 class="text-sm font-semibold text-neutral-600" i18n>Sale Price</h5>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Method"
                        class="text-black w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="font-bold text-base text-neutral-900">{{currency_symbol}}{{sale_price}}</h3>
               </div>
            </div>
            <div class="flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full">
               <span class="w-[42px] h-11 bg-blue-600 rounded-[5px] flex justify-center items-center">
                  <svg-icon src="@assets/images/icons/channel-icon.svg" class="text-black">
                  </svg-icon>
               </span>
               <div class="flex flex-col gap-1 flex-1">
                  <div class="flex flex-row items-center gap-2 relative">
                     <h5 class="text-sm font-semibold text-neutral-600" i18n>Channel</h5>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Channel"
                        class="text-black w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="font-bold text-base text-neutral-900">{{channel}}</h3>
               </div>
            </div>
            <div class="flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full">
               <span class="w-[42px] h-11 bg-amber-600 rounded-[5px] flex justify-center items-center">
                  <svg-icon src="@assets/images/icons/coupon-icon.svg" class="text-black">
                  </svg-icon>
               </span>
               <div class="flex flex-col gap-1 flex-1">
                  <div class="flex flex-row items-center gap-2 relative">
                     <h5 class="text-sm font-semibold text-neutral-600" i18n>Coupon</h5>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Coupon"
                        class="text-black w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="font-bold text-base text-rose-600">{{coupon}}</h3>
               </div>
            </div>
         </div>

         <div class="flex flex-row gap-2.5">
            <div class="flex flex-row items-center gap-2.5 rounded-[5px] px-4 py-6 w-full border border-neutral-100">
               <app-circle-progressbar [outerStrokeColor]="'#3941FE'" [percent]="platform_share_percentage">
                  <ng-container content>
                     <div
                        class="flex flex-col items-center justify-center p-1 w-[70%] h-[70%] absolute bg-white rounded-full">
                        <h3 class="text-base text-slate-600 font-bold">{{platform_share_percentage}}%</h3>
                        <!-- <h3 class="text-base text-blue-600 font-bold">38%</h3> -->
                        <h5 class="text-xs text-slate-600 font-normal" i18n>Shares</h5>
                     </div>
                  </ng-container>
               </app-circle-progressbar>
               <div class="flex flex-col gap-1 flex-1">
                  <div class="flex flex-row items-center gap-2 relative">
                     <h5 class="text-sm font-semibold text-neutral-600" i18n>Platform Earning</h5>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Platform Earning"
                        class="text-black w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="font-bold text-base text-neutral-900">{{currency_symbol}}{{platform_share}}</h3>
               </div>
            </div>
            <div class="flex flex-row items-center gap-2.5 rounded-[5px] px-4 py-6 w-full border border-neutral-100">
               <app-circle-progressbar [outerStrokeColor]="'#0d9488'" [percent]="instructor_share_percentage">
                  <ng-container content>
                     <div
                        class="flex flex-col items-center justify-center p-1 w-[70%] h-[70%] absolute bg-white rounded-full">
                        <h3 class="text-base text-slate-600 font-bold">{{instructor_share_percentage}}%</h3>
                        <!-- <h3 class="text-base text-teal-600 font-bold">60%</h3> -->
                        <h5 class="text-xs text-slate-600 font-normal" i18n>Shares</h5>
                     </div>
                  </ng-container>
               </app-circle-progressbar>
               <div class="flex flex-col gap-1 flex-1">
                  <div class="flex flex-row items-center gap-2 relative">
                     <h5 class="text-sm font-semibold text-neutral-600" i18n>Instructor Earning</h5>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor Earning"
                        class="text-black w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="font-bold text-base text-neutral-900">{{currency_symbol}}{{instructor_share}}</h3>
               </div>
            </div>
            <div class="flex flex-row items-center gap-2.5 rounded-[5px] px-4 py-6 w-full border border-neutral-100">
               <app-circle-progressbar [outerStrokeColor]="'#D97706'" [percent]="affiliates_earning">
                  <ng-container content>
                     <div
                        class="flex flex-col items-center justify-center p-1 w-[70%] h-[70%] absolute bg-white rounded-full">
                        <h3 class="text-base text-rose-600 font-bold">{{affiliates_earning}}%</h3>
                        <!-- <h3 class="text-base text-amber-600 font-bold">2%</h3> -->
                        <h5 class="text-xs text-slate-600 font-normal" i18n>Shares</h5>
                     </div>
                  </ng-container>
               </app-circle-progressbar>
               <div class="flex flex-col gap-1 flex-1">
                  <div class="flex flex-row items-center gap-2 relative">
                     <h5 class="text-sm font-semibold text-neutral-600" i18n>Affiliates Earning</h5>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Affiliates Earning"
                        class="text-black w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="font-bold text-base text-rose-600">{{currency_symbol}}{{affiliates_earning}}</h3>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Payment breakdown -->
   <div [ngClass]="isSidebarComponent?'course-overview p-4 pt-0':''" class="relative">
      <h3 class="text-2xl text-neutral-800 font-semibold my-4" i18n>Payment Breakdown</h3>
      <div *ngIf="transaction_type === 'refund'" class="absolute w-full h-full bg-white bg-opacity-50 top-0 left-0 z-50 flex items-end justify-end p-12">
         <svg-icon src="@assets/images/icons/stamp.svg" class="w-64"></svg-icon>
      </div>
      <div class="grid grid-cols-2 gap-4">
         <div class="card border border-neutral-100 w-full">
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-row items-center gap-2.5 relative">
                  <h3 class="text-base font-bold text-neutral-800" i18n>Transaction Detail</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction Detail"
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h3 class="text-base font-bold text-neutral-800" i18n>Total</h3>
            </div>

            <!-- Listed Price -->
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1 w-full max-w-[350px]">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>
                        Listed Price
                        <ng-container>(<span class="text-blue-600 font-normal"> #{{params['transactionId']}} </span> )
                        </ng-container>
                        <!-- text-blue-600 -->
                     </h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Listed Price"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-neutral-800" i18n>Original Price <span class="">
                        ({{pricing_tier}})</span></h3>
               </div>
               <h3 class="text-base font-semibold text-neutral-800">{{currency_symbol}}{{listed_price}}</h3>
            </div>

            <!-- Discount: Coupon -->
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1 w-full max-w-[350px]">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>
                        Discount: Coupon
                        <ng-container>(<span class="text-blue-600 font-normal"> #{{params['transactionId']}} </span> )
                        </ng-container>
                        <!-- text-blue-600 -->
                     </h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Discount: Coupon"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-rose-600">-10%</h3>
               </div>
               <h3 class="text-base font-semibold text-neutral-600">-{{currency_symbol}}{{discount_coupon}}</h3>
               <h3 class="text-base font-semibold text-neutral-800">{{currency_symbol}}{{discount}}</h3>
            </div>

            <!-- Sale Price -->
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1 w-full max-w-[350px]">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>
                        Sale Price
                     </h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Sale Price" class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-neutral-800" i18n>Actual Amount Charged by Payment Gateway</h3>
               </div>
               <h3 class="text-base font-semibold text-neutral-800">{{currency_symbol}}{{sale_price}}</h3>
            </div>

            <!-- Vat -->
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1 w-full max-w-[350px]">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>Vat</h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Vat" class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-rose-600">0% of price after coupon</h3>
               </div>
               <h3 class="text-base font-semibold text-rose-600">-{{currency_symbol}}{{0}}</h3>
               <h3 class="text-base font-semibold text-rose-600">{{currency_symbol}}0</h3>
            </div>

            <!-- Credit Card Fee/ PayPal -->
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1 w-full max-w-[350px]">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>Credit Card Fee/ PayPal</h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Credit Card Fee/ PayPal"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-neutral-800" i18n>
                     {{credit_card_fee_paypal_percentage}} % of total order | Gateway charge
                     {{currency_symbol}}{{gateway_charge_vat}}
                  </h3>
               </div>
               <h3 class="text-base font-semibold text-neutral-800">
                  -{{currency_symbol}}{{credit_card_fee_paypal}}
               </h3>
               <h3 class="text-base font-semibold text-neutral-800">{{currency_symbol}}{{gateway_charge}}</h3>
            </div>
            <div class="flex justify-end items-center px-6 py-4">
               <h3></h3>
               <div class="flex flex-row items-center justify-between w-60">
                  <h3 class="text-lg font-bold text-neutral-800" i18n>Net Amount</h3>
                  <h3 class="text-lg font-bold text-neutral-600">{{currency_symbol}}{{net_amount}}</h3>
               </div>
            </div>
         </div>

         <!-- Share Detail -->
         <div class="card border border-neutral-100 w-full">
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>
                        Share Detail</h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Share Detail"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-neutral-800" i18n>Instructor Group Shares</h3>
               </div>
               <h3 class="text-base font-bold text-neutral-800" i18n>Share</h3>
            </div>

            <!-- Thkee Share -->
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>
                        Thkee Share
                     </h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Thkee Share" class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-neutral-600">{{platform_share_percentage}}% of net amount</h3>
               </div>
               <h3 class="text-base font-semibold text-neutral-600">{{currency_symbol}}{{platform_share}}</h3>
            </div>

            <!-- Affiliate Share -->
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>
                        Affiliate Share
                        <span class="text-blue-600 font-normal">#{{params['transactionId']}} </span>
                     </h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Affiliate Share"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-rose-600">0% off amount Charged</h3>
               </div>
               <h3 class="text-base font-semibold text-rose-600">{{currency_symbol}}{{0}}</h3>
            </div>

            <!-- Instructors Share -->
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>
                        Instructors Share
                        <span class="text-blue-600 font-normal">#{{params['transactionId']}}</span>
                     </h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructors Share"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-neutral-600" i18n>Instructor Group - {{instructor_share_percentage}}%
                     of Net Amount</h3>
               </div>
               <h3 class="text-base font-semibold text-neutral-600">{{currency_symbol}}{{instructor_share}}</h3>
            </div>

            <!-- Amount Refunded -->
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800" i18n>
                        Amount Refunded
                     </h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Amount Refunded"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-neutral-600">Transactions can be Refunded within 30 days or before
                     50% course Progress</h3>
               </div>
               <h3 class="text-base font-semibold text-neutral-600">{{currency_symbol}}{{refunded_amount}}</h3>
            </div>
         </div>
      </div>
   </div>
</div>
