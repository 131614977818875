import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { PricingGeoAPIService } from 'thkee-common';
import { PricingGeoService } from './geo-store.service';
import { PricingGeoActions } from './geo.actions';
@Injectable()
export class PricingGeoEffects {
  constructor(
    private actions$: Actions,
    private pricingService: PricingGeoService,
    private pricingGeoAPIService: PricingGeoAPIService,
    private router: Router
  ) {}

  loadGeoLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingGeoActions.loadGeoLocation),
      switchMap((params) =>
        this.pricingService.getGeoLocList(params).pipe(
          map((data) => PricingGeoActions.loadGeoLocationSuccess({ data })),
          catchError((error) => of(PricingGeoActions.loadGeoLocationFail({ error })))
        )
      )
    )
  );

  addGeoLoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingGeoActions.addGeoLoc),
      switchMap((params) =>
        this.pricingService.addGeoLoc(params).pipe(
          map((data) => PricingGeoActions.addGeoLocSuccess({ data })),
          catchError((error) => of(PricingGeoActions.addGeoLocFail({ error })))
        )
      )
    )
  );

  getGeoLoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingGeoActions.getGeoLoc),
      switchMap((params) =>
        this.pricingService.getGeoLoc(params).pipe(
          map((data) => PricingGeoActions.getGeoLocSuccess({ data })),
          catchError((error) => of(PricingGeoActions.getGeoLocFail({ error })))
        )
      )
    )
  );

  // Geo Pricing Tiers
  loadGeoPricingTiers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingGeoActions.loadGeoPricingTiers),
      switchMap((params) =>
        this.pricingService.getGeoPricingTiers(params).pipe(
          map((data) => PricingGeoActions.loadGeoPricingTiersSuccess({ data })),
          catchError((error) => of(PricingGeoActions.loadGeoPricingTiersFail({ error })))
        )
      )
    )
  );
}
