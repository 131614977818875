export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/login';

export const AUTO_SAVE_DEBOUNCE_TIME_MS = 1000;

export const COURSE_OVERVIEW_COLUMN = [
  { name: 'Course ID', visible: true },
  { name: 'Course Name', visible: true },
  { name: 'Instructor Name', visible: true },
  { name: 'Course Category', visible: true },
  { name: 'Course Curriculum', visible: true },
  { name: 'Sale Price', visible: true },
  { name: 'Course Sold', visible: true },
  { name: 'Review', visible: true },
  { name: 'Course Type', visible: true },
  { name: 'Course Status', visible: true },
];

export const COURSE_STATISTIC_COLUMN = [
  { name: 'Course ID', visible: true },
  { name: 'Course Name', visible: true },
  { name: 'Minute watch time', visible: true },
  { name: 'Sales', visible: true },
  { name: 'Enrollments', visible: true },
  { name: 'Refund', visible: true },
  { name: 'Students Completed Course', visible: true },
];

export const RECENTLY_UPDATED_COURSE_COLUMN = [
  { name: 'Course ID', visible: true },
  { name: 'Course Name', visible: true },
  { name: 'Instructor Name', visible: true },
  { name: 'Course Category', visible: true },
  { name: 'Course Changes', visible: true },
  { name: 'Sale Price', visible: true },
  { name: 'Course Sold', visible: true },
  { name: 'Course Type', visible: true },
  { name: 'Course Status', visible: true },
];

export const COURSES_REVIEWS_COLUMN = [
  { name: 'ID', visible: true },
  { name: 'Review', visible: true },
  { name: 'Student Rating', visible: true },
  { name: 'Student Name', visible: true },
  { name: 'Created Date', visible: true },
  { name: 'Action', visible: true },
];

export const COURSES_TABLE_SEARCH = [
  {
    id: 0,
    title: 'Course Id',
    value: 'id',
  },
  {
    id: 1,
    title: ' Project Id',
    value: 'project_id',
  },
  {
    id: 2,
    title: 'Product Id',
    value: 'product_id',
  },
  {
    id: 3,
    title: 'Course Title',
    value: 'title',
  },
  {
    id: 4,
    title: 'First Name',
    value: 'first_name',
  },
  {
    id: 5,
    title: 'Last Name',
    value: 'last_name',
  },
  {
    id: 6,
    title: 'Email',
    value: 'email',
  },
];

export const RECENT_UPDATE_TABLE_SEARCH = [
  // project.id, course.id, course.title, user.first_name, user.last_name, user.email
  {
    id: 0,
    title: 'Course Id',
    value: 'id',
  },
  {
    id: 1,
    title: ' Project Id',
    value: 'project_id',
  },
  {
    id: 3,
    title: 'Course Title',
    value: 'title',
  },
  {
    id: 4,
    title: 'First Name',
    value: 'first_name',
  },
  {
    id: 5,
    title: 'Last Name',
    value: 'last_name',
  },
  {
    id: 6,
    title: 'Email',
    value: 'email',
  },
];

// Interface
export interface Column {
  name: string;
  visible: boolean;
}

export interface BarChartData {
  data: Array<number>;
  backgroundColor: string;
  fill: string;
  barPercentage: number;
  height: number;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  borderRadius: number;
}

export interface TableSearch {
  id: number;
  title: string;
  value: string;
}

export interface FilterKey {
  key1: string;
  key2: string;
}
export interface FilterOptions {
  category_slug: FilterOptionsValues[];
  reviews: FilterOptionsValues[];
}
export interface FilterOptionsOverview {
  category: FilterOptionsValues[];
  rating: FilterOptionsValues[];
}
export interface FilterOptionsValues {
  value: string;
  label: string;
}

// Recently update
export interface CoursesResult {
  count: number;
  next: null | string;
  previous: null | string;
  results: CourseInfos[];
}
export interface CourseInfos {
  course_id: string;
  course_title: string;
  course_category: string;
  course_sale_price: number;
  course_sold: number;
  course_type: string;
  course_status: string;
  instructor: CourseInstructorInfos;
  course_changes: CourseChanges;
}
export interface CourseInstructorInfos {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
}
interface CourseChanges {
  lectures_added: number;
  sections_added: number;
  quizzes_added: number;
}

export interface AdminFilterParams {
  page: number;
  page_size: number;
  search?: number;
  project_state?: string;
}

// All types
export type Status = 'Draft' | 'Published' | 'Unlisted' | 'Pending' | 'Ended' | 'In Review' | 'Rejected';
