<ng-container *ngIf="{
    hasData: hasData,
    priceCurrencyData: (priceCurrency$ | async) ?? priceCurrencyDataDefault,
    status: (status$ | async) ?? '',
    pricingCountryList: (pricingCountryList$ | async) ?? []
  } as vm">
  <div class="price-currencies-new p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>View {{ vm.priceCurrencyData.name ? vm.priceCurrencyData.name : 'Currencies' }}</h3>
      </div>

      <div>
        <a routerLink="./edit">
          <button class="btn-amber-soft">
            <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
            <span>Edit</span>
          </button>
        </a>
      </div>
    </div>

    <div class="tier-form">
      <div class="card rounded-lg border border-neutral-100 px-6 py-9">
        <!-- <app-form-price-currency (onSubmit)="onSubmit($event)" [model]="vm.priceCurrencyData"></app-form-price-currency> -->
        <app-form-price-currency *ngIf="vm.priceCurrencyData" (init)="onInit($event)" [model]="vm.priceCurrencyData"
          [countries]="vm.pricingCountryList"></app-form-price-currency>
      </div>
    </div>
  </div>
</ng-container>