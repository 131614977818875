<div class="price-currencies-new p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>Add Instructor Group</h3>
    </div>
  </div>

  <div class="group-form">
    <div class="card rounded-lg border border-neutral-100 px-6 py-9">
      <h4 class="mb-4">Group Settings</h4>
      <app-form-user-instructor-group (submitEvent)="onSubmit($event)"></app-form-user-instructor-group>
    </div>
  </div>
</div>