import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { RouterStoreService } from 'thkee-common';

@Component({
  selector: 'app-refund-details',
  templateUrl: './refund-details.component.html',
  styleUrls: ['./refund-details.component.scss'],
})
export class RefundDetailsComponent implements OnInit {
  params: any = {};

  constructor(private routerStore: RouterStoreService, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Sales',
          url: '/ecommerce/sales/transactions',
        },
        {
          label: 'Transaction',
          url: '/ecommerce/sales/transactions',
        },
        {
          label: `Transaction #${params['transactionId']}`,
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  // Transaction Detail
  transactionDetail: any = {
    total: 69.3,
    list: [
      {
        title: 'Listed Price',
        sub_title: 'Original Price (Tier 1)',
        code: '123',
        ammounts: 100,
        discount_ammounts: 0,
      },
      {
        title: 'Discount: Coupon',
        sub_title: '-10%',
        code: '123',
        ammounts: 90,
        discount_ammounts: 10,
      },
      {
        title: 'Sale Price ',
        sub_title: 'Actual Amount Charged by Payment Gateway',
        code: null,
        ammounts: 90,
        discount_ammounts: 0,
      },
      {
        title: 'Vat',
        sub_title: '14% of price after coupon',
        code: null,
        ammounts: 77.4,
        discount_ammounts: 12.6,
      },
      {
        title: 'Credit Card Fee/ PayPal',
        sub_title: '2.9 % of total order',
        code: null,
        ammounts: 77,
        discount_ammounts: 0.4,
      },
    ],
  };

  // Share Detail
  shareDetail: any = [
    {
      title: 'Thkee Share',
      sub_title: '38% of net amount',
      code: null,
      ammounts: 20.8,
    },
    {
      title: 'Affiliate Share',
      sub_title: '2% off amount Charged',
      code: '123',
      ammounts: 4.9,
    },
    {
      title: 'Instructors Share',
      sub_title: 'Instructor Group - 60% of Net Amount',
      code: '123',
      ammounts: 43.6,
    },
    {
      title: 'Amount Refunded',
      sub_title: 'Transactions can be Refunded within 30 days or before 50% course Progress',
      code: null,
      ammounts: 0,
    },
  ];
}
