import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavItem, SIDEBAR_CONSTANTS } from '@shared';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'app-sidebar' },
})
export class SidebarComponent implements OnInit {
  items: NavItem[] = SIDEBAR_CONSTANTS;
  @Input() NavItems: NavItem[] = [];
  @Input() activeItem: NavItem | null = null;
  @Input() activeId: string | number = '0';
  @Input() activeIds: (string | number)[] = [];

  constructor(private router: Router, private route: ActivatedRoute) {
    if (this.NavItems.length) this.items = this.NavItems; // Override Items if input found.
  }

  ngOnInit(): void {
    if (this.activeId) {
      this.active(this.activeId);
    }
  }

  active(id: any) {
    this.activeIds = [id];
    const parentsOfItem = this.findParentItems(this.items, id)?.map((item) => item.id);
    if (parentsOfItem) {
      this.activeIds = [...parentsOfItem, id];
    }
  }

  isActive(id: string | number) {
    return this.activeIds.includes(id);
  }

  findParentItems(navItems: NavItem[], itemId: string, parents: NavItem[] = []): NavItem[] | null {
    for (const item of navItems) {
      if (item.id === itemId) {
        // If the item with the given ID is found, return its parents
        return parents;
      }
      if (item.children) {
        const foundInChildren = this.findParentItems(item.children, itemId, [...parents, item]);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null; // Return null if the item is not found
  }
}
