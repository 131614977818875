import { FormlyModule } from '@ngx-formly/core';
import { FormlyButtonTypeComponent } from './types/button';
import { FormlyFieldCheckbox } from './types/checkbox';
import { FormlyFieldEditor } from './types/editor';
import { FormlyFieldFile } from './types/file.component';
import { FormlyFieldInput } from './types/input';
import { FormlyFieldInputPassword } from './types/input-password';
import { FormlyFieldMultiCheckbox } from './types/multicheckbox';
import { FormlyFieldRadio } from './types/radio';
import { RepeatTypeComponent } from './types/repeat';
import { RepeatGroupTypeComponent } from './types/repeat-group';
import { FormlyFieldSelect } from './types/select';
import { FormlyFieldSimpleSelect } from './types/simple-select';
import { FormlyFieldTextArea } from './types/textarea';
import { FormlyFieldToggle } from './types/toggle';
import * as validation from './validations';
import { FormlyWrapperCourseFeedback } from './wrapper/course-feedback.wrapper';
import { FormlyWrapperFeedbackField } from './wrapper/feedback-field.wrapper';
import { FormlyWrapperFormField } from './wrapper/form-field.wrapper';
import { FormlyWrapperIframeComponent } from './wrapper/formly-wrapper-iframe';

export const FormlySetup = FormlyModule.forRoot({
  types: [
    { name: 'input', component: FormlyFieldInput, wrappers: ['form-field'] },
    { name: 'checkbox', component: FormlyFieldCheckbox, wrappers: ['form-field'] },
    { name: 'toggle', component: FormlyFieldToggle, wrappers: ['form-field'] },
    { name: 'multicheckbox', component: FormlyFieldMultiCheckbox, wrappers: ['form-field'] },
    { name: 'radio', component: FormlyFieldRadio, wrappers: ['form-field'] },
    { name: 'textarea', component: FormlyFieldTextArea, wrappers: ['form-field'] },
    { name: 'editor', component: FormlyFieldEditor, wrappers: ['form-field'] },
    { name: 'select', component: FormlyFieldSelect, wrappers: ['form-field'] },
    // { name: 'simple-select', component: FormlyFieldSimpleSelect, wrappers: ['form-field'] },
    { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
    { name: 'repeat', component: RepeatTypeComponent, wrappers: ['form-field'] },
    { name: 'repeatgroup', component: RepeatGroupTypeComponent, wrappers: ['form-field'] },
    { name: 'input-password', component: FormlyFieldInputPassword, wrappers: ['form-field'] },
    { name: 'button', component: FormlyButtonTypeComponent, wrappers: ['form-field'] },
  ],
  wrappers: [
    { name: 'form-field', component: FormlyWrapperFormField },
    { name: 'feedback-field', component: FormlyWrapperFeedbackField },
    { name: 'iframe-wrapper', component: FormlyWrapperIframeComponent },
    // { name: 'course-feedback', component: FormlyWrapperCourseFeedback }, Remove Soon
  ],
  validators: [
    { name: 'email', validation: validation.emailValidator },
    { name: 'minWords', validation: validation.minWordsValidator },
    { name: 'maxWords', validation: validation.maxWordsValidator },
    { name: 'thkeeUrl', validation: validation.urlValidator },
    { name: 'thkeeConfirmation', validation: validation.confirmationValidator },
    { name: 'noInitialSpace', validation: validation.noInitialSpaceValidator },
    { name: 'checkboxValidation', validation: validation.checkboxValidation },
  ],
  validationMessages: [
    { name: 'email', message: validation.emailValidatorMessage },
    { name: 'required', message: 'This field is required.' },
    { name: 'minLength', message: validation.minLengthValidationMessage },
    { name: 'maxLength', message: validation.maxLengthValidationMessage },
    { name: 'minWords', message: validation.minWordsValidatorMessage },
    { name: 'maxWords', message: validation.maxWordsValidatorMessage },
    { name: 'thkeeUrl', message: validation.urlValidatorMessage },
    { name: 'thkeeConfirmation', message: validation.confirmationValidatorMessage },
    { name: 'noInitialSpace', message: validation.noInitialSpaceValidatorMessage },
    { name: 'checkboxValidation', message: validation.checkboxValidationMessage },
  ],
});

export const FormlyComponents = [
  FormlyFieldInput,
  FormlyWrapperFormField,
  FormlyWrapperFeedbackField,
  FormlyWrapperCourseFeedback,
  FormlyFieldCheckbox,
  FormlyFieldToggle,
  FormlyFieldMultiCheckbox,
  FormlyFieldRadio,
  FormlyFieldTextArea,
  FormlyFieldEditor,
  FormlyFieldSelect,
  FormlyFieldFile,
  FormlyFieldSimpleSelect,
  RepeatGroupTypeComponent,
  RepeatTypeComponent,
  FormlyFieldInputPassword,
  FormlyWrapperIframeComponent,
];
