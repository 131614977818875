<div class="price-currencies-new p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>Edit Instructor Group</h3>
    </div>
  </div>

  <!-- Form -->
  <div class="group-form mb-4">
    <div class="card rounded-lg border border-neutral-100 px-6 py-9">
      <div class="mb-4 flex items-center justify-between">
        <div>
          <h4>Group Settings</h4>
        </div>
        <div>
          <a routerLink="/price-management/shares/organics">
            <button class="btn-amber-soft btn-sm px-2">
              <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
              <span>Edit Shares</span>
            </button>
          </a>
        </div>
      </div>
      <app-form-user-instructor-group (submitEvent)="onSubmit($event)" [model]="groupData">
      </app-form-user-instructor-group>
    </div>
  </div>
  <!-- Shared Record -->
  <div class="user-groups mb-4">
    <app-data-table title="Instructor Groups" badge="{{ sharePricingData.length }} Instructor Group">
      <ng-container table>
        <app-form-price-shares [data]="sharePricingData" [enableEdit]="false"></app-form-price-shares>
      </ng-container>
    </app-data-table>
  </div>
</div>