import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-common-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {
  @ViewChild('modal')
  modal!: ModalComponent;

  @Input()
  title?: string = 'Confirmation';
  @Input()
  message: string = 'Are you sure you want to perform this action?';
  @Input()
  confirmText?: string = 'Confirm';
  @Input()
  hasCancel?: boolean = true;

  @Input() confirmColor: 'primary' | 'secondary' | 'red' | 'green' | 'dark' = 'primary';

  @Output()
  confirm: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  protected onConfirm() {
    this.confirm.emit();
  }

  open() {
    this.modal.open();
  }
}
