<div class="course-heading mb-4 flex items-center justify-between gap-4">
  <div class="course-details" *ngIf="course">
    <h3 class="mb-3 text-neutral-800">{{ course.title }}</h3>
    <div class="course-meta inline-flex items-center gap-[10px] text-sm">
      <div class="category">{{course.category_name}}</div>
      <svg-icon class="h-[10px] w-[10px] text-neutral-300" src="@assets/images/icons/dot.svg"></svg-icon>
      <div class="last-update text-neutral-600" i18n> Last edited <span class="font-semibold">{{ course.date_updated | date }}</span>
      </div>
      <svg-icon class="h-[10px] w-[10px] text-neutral-300" src="@assets/images/icons/dot.svg"></svg-icon>
      <div class="status">
        <div *ngIf="course.status ==='published'" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm" i18n>
          Published
        </div>
        <div *ngIf="course.status ==='in_review'" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm" i18n>
          In Review
        </div>
        <div *ngIf="course.status ==='draft'" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm" i18n>
            Draft
        </div>
        <div *ngIf="course.status ==='rejected'" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm" i18n>
            Rejected
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>