import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_COMMON_SHARE_PRICING, SharePricing, SharePricingPayload, SharePricingUpdatePayload } from '../../models';
import { encodeURL } from '../../utils';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root',
})
export class SharePricingService {
  constructor(private readonly http: HttpService) {}

  getAllSharePricing(params = {} as object): Observable<SharePricing[]> {
    return this.http.get<SharePricing[]>(encodeURL(API_COMMON_SHARE_PRICING, params));
  }

  getSharePricing(params = {} as any): Observable<SharePricing> {
    return this.http.get<SharePricing>(API_COMMON_SHARE_PRICING + params?.id + '/');
  }

  addSharePricing(payload = {} as SharePricingPayload): Observable<SharePricing> {
    return this.http.post<SharePricing>(API_COMMON_SHARE_PRICING, payload);
  }

  updateSharePricing(id: number | string, payload = {} as SharePricingUpdatePayload): Observable<SharePricing> {
    return this.http.patch<SharePricing>(API_COMMON_SHARE_PRICING + id + '/', payload);
  }

  //   removePricingTier(params = {} as PricingTierLoadRemovePayload): Observable<any> {
  //     return this.http.delete<any>(API_PRICING_LIST + params?.id + '/');
  //   }
}
