import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { concat, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LectureV2, PartialEntity } from '../../models';
import { AnnotationService, InstructorService, LoaderService, PaginationService } from '../../services';
import { isDefined } from '../../utils';
import { LearningLabActions } from '../learning-lab';
import { annotationKey } from './annotation.entity';
import { selectAnnotationByKey } from './annotation.selectors';
import { selectAssignmentQuestionsByAssignmentId } from './assignment-question.selectors';
import { selectAssignment } from './assignment.selectors';
import { selectCourseDetail } from './course-detail.selectors';
import { CourseActions } from './course.actions';
import { selectCourse } from './course.selectors';
import { selectLectureResoucesByLectureId } from './lecture-resource.selectors';
import { selectLecture } from './lecture.selectors';
import { selectQuizAnswersByQuizQuestionId } from './quiz-answer.selectors';
import { selectQuizQuestion } from './quiz-question.selectors';
import { selectQuiz } from './quiz.selectors';
import { selectSection } from './section.selectors';
import { selectSubsection } from './subsection.selectors';

@Injectable()
export class CourseEffects {
  loadCourses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.loadCourses),
      switchMap((payload) => {
        setTimeout(() => {
          this.loaderService.loaderAction('loading-instructor-courses', 'loading');
        });
        return this.instructorService.getCourses(payload.params).pipe(
          map((courses) => {
            this.loaderService.loaderAction('loading-instructor-courses', 'success');
            this.paginationService.getPager(courses.count, payload.params.page, payload.params.page_size);
            return CourseActions.loadCoursesSuccess({ courses: courses.results.reverse() });
          })
        );
      })
    )
  );

  loadCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.loadCourse),
      switchMap(({ courseId }) =>
        this.instructorService
          .getCourseData(courseId)
          .pipe(map((courseData) => CourseActions.loadCourseSuccess({ courseData })))
      )
    )
  );

  loadCourseSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.loadCourseSuccess),
      concatLatestFrom(() => this.store.select(selectCourseDetail)),
      mergeMap(([, courseDetail]) => {
        return of(
          CourseActions.loadHistory({ projectId: courseDetail.project.id }),
          LearningLabActions.init({ courseDetail })
        );
      })
    )
  );

  createCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.createCourse),
      switchMap(({ title, categoryId, userId }) =>
        this.instructorService.createCourse({ title, category: categoryId, user: userId }).pipe(
          tap((course) => this.router.navigate(['/instructor/course', course.id, 'intended-learners'])),
          map((course) => CourseActions.createCourseSuccess({ course: course })),
          catchError(() => of(CourseActions.createCourseError()))
        )
      )
    )
  );

  upsertCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.upsertCourse),
      withLatestFrom(this.store.select(selectCourse)),
      mergeMap(([, course]) => {
        return this.instructorService
          .updateCourse(course.id, {
            ...course,
            user: course.user?.id,
          })
          .pipe(
            map((course) => CourseActions.upsertCourseSuccess({ course })),
            catchError(() => of(CourseActions.upsertCourseError()))
          );
      })
    )
  );

  upsertSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.upsertSection),
      concatLatestFrom(({ section }) => this.store.select(selectSection(section.id))),
      map(([, section]) => section),
      filter(isDefined),
      switchMap((section: any) => {
        let payload = {
          id: section.id,
          course: section.course,
          title: section.title,
          position: section.position,
        };
        return this.instructorService.upsertSection(payload).pipe(
          map(() => CourseActions.upsertSectionSuccess()),
          catchError(() => of(CourseActions.upsertSectionError()))
        );
      })
    )
  );

  deleteSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.deleteSection),
      switchMap(({ sectionId, params }) =>
        this.instructorService.deleteSection(sectionId, params).pipe(
          map(() => CourseActions.deleteSectionSuccess()),
          catchError(() => of(CourseActions.deleteSectionError()))
        )
      )
    )
  );

  deleteSubsection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.deleteSubsection),
      switchMap(({ subsectionId }) =>
        this.instructorService.deleteSubsection(subsectionId).pipe(
          map(() => CourseActions.deleteSubsectionSuccess()),
          catchError(() => of(CourseActions.deleteSubsectionError()))
        )
      )
    )
  );

  createQuiz$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.createQuiz),
      concatLatestFrom(({ subsection, quiz }) => [
        this.store.select(selectSubsection(subsection.id)),
        this.store.select(selectQuiz(quiz.id)),
      ]),
      switchMap(([, subsection, quiz]) =>
        concat(
          this.instructorService.upsertSubsection(_.omit(subsection!, 'quiz')),
          this.instructorService.upsertQuiz(quiz!),
          this.instructorService.upsertSubsection({ id: subsection!.id, quiz: quiz!.id })
        ).pipe(
          map(() => CourseActions.createQuizSuccess()),
          catchError(() => of(CourseActions.createQuizError()))
        )
      )
    )
  );

  upsertQuiz$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.upsertQuiz),
      concatLatestFrom(({ quiz }) => this.store.select(selectQuiz(quiz.id))),
      map(([, quiz]) => quiz),
      filter(isDefined),
      switchMap((quiz) =>
        this.instructorService.upsertQuiz(quiz).pipe(
          map(() => CourseActions.upsertQuizSuccess()),
          catchError(() => of(CourseActions.upsertQuizError()))
        )
      )
    )
  );

  upsertQuizQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.upsertQuizQuestion),
      concatLatestFrom(({ quizQuestion }) => [
        this.store.select(selectQuizQuestion(quizQuestion.id)),
        this.store.select(selectQuizAnswersByQuizQuestionId(quizQuestion.id)),
      ]),
      switchMap(([, quizQuestion, quizAnswers]) =>
        concat(
          // need to remove null values, i.e. lecture since it's not required
          this.instructorService.upsertQuizQuestion(_.omitBy(quizQuestion!, _.isNil) as any),
          ...quizAnswers?.map((quizAnswer) => this.instructorService.upsertQuizAnswer(quizAnswer!))
        ).pipe(
          map(() => CourseActions.upsertQuizQuestionSuccess()),
          catchError(() => of(CourseActions.upsertQuizQuestionError()))
        )
      )
    )
  );

  deleteQuizQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.deleteQuizQuestion),
      switchMap(({ quizQuestionId }) =>
        this.instructorService.deleteQuizQuestion(quizQuestionId).pipe(
          map(() => CourseActions.deleteQuizQuestionSuccess()),
          catchError(() => of(CourseActions.deleteQuizQuestionError()))
        )
      )
    )
  );

  deleteQuizAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.deleteQuizAnswer),
      switchMap(({ quizAnswerId }) =>
        this.instructorService.deleteQuizAnswer(quizAnswerId).pipe(
          map(() => CourseActions.deleteQuizAnswerSuccess()),
          catchError(() => of(CourseActions.deleteQuizAnswerError()))
        )
      )
    )
  );

  createAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.createAssignment),
      concatLatestFrom(({ subsection, assignment }) => [
        this.store.select(selectSubsection(subsection.id)),
        this.store.select(selectAssignment(assignment.id)),
      ]),
      switchMap(([, subsection, assignment]) =>
        concat(
          this.instructorService.upsertSubsection(_.omit(subsection!, 'assignment')),
          this.instructorService.upsertAssignment(assignment!),
          this.instructorService.upsertSubsection({ id: subsection!.id, assignment: assignment!.id })
        ).pipe(
          map(() => CourseActions.createAssignmentSuccess()),
          catchError(() => of(CourseActions.createAssignmentError()))
        )
      )
    )
  );

  upsertAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.upsertAssignment),
      concatLatestFrom(({ assignment }) => [
        this.store.select(selectAssignment(assignment.id)),
        this.store.select(selectAssignmentQuestionsByAssignmentId(assignment.id)),
      ]),
      switchMap(([, assignment, assignmentQuestions]) => {
        return concat(
          // need to remove null values, i.e. lecture since it's not required
          this.instructorService.upsertAssignment(_.omitBy(assignment!, _.isNil) as any),
          ...assignmentQuestions?.map((assignmentQuestion) =>
            this.instructorService.upsertAssignmentQuestion(assignmentQuestion!)
          )
        ).pipe(
          map(() => CourseActions.upsertAssignmentSuccess()),
          catchError(() => of(CourseActions.upsertAssignmentError()))
        );
      })
    )
  );

  deleteAssignmentQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.deleteAssignmentQuestion),
      switchMap(({ assignmentQuestionId }) =>
        this.instructorService.deleteAssignmentQuestion(assignmentQuestionId).pipe(
          map(() => CourseActions.deleteAssignmentQuestionSuccess()),
          catchError(() => of(CourseActions.deleteAssignmentQuestionError()))
        )
      )
    )
  );

  createLecture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.createLecture),
      concatLatestFrom(({ subsection, lecture }) => [
        this.store.select(selectSubsection(subsection.id)),
        this.store.select(selectLecture(lecture.id)),
      ]),
      switchMap(([, subsection, lecture]) =>
        concat(
          this.instructorService.upsertLecture(lecture!),
          this.instructorService.upsertSubsection(subsection!)
          // this.instructorService.upsertSubsection({ id: subsection!.id, lecture: lecture!.id }),
        ).pipe(
          map(() => CourseActions.createLectureSuccess({ subsection, lecture })),
          catchError(() => of(CourseActions.createLectureError()))
        )
      )
    )
  );

  // createLectureSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CourseActions.createLectureSuccess),
  //     concatLatestFrom(({ subsection }) => [this.store.select(selectSubsection(subsection.id))]),
  //     switchMap(([, subsection]) =>
  //       concat(this.instructorService.upsertSubsection(subsection!)).pipe(
  //         catchError(() => of(CourseActions.createLectureError()))
  //       )
  //     )
  //   )
  // );

  upsertLecture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.upsertLecture),
      concatLatestFrom(({ lecture }) => [
        this.store.select(selectLecture(lecture.id)),
        this.store.select(selectLectureResoucesByLectureId(lecture.id)),
      ]),
      switchMap(([, lecture, lectureResources]) =>
        concat(
          // need to remove null values, i.e. lecture since it's not required
          this.instructorService.upsertLecture(_.omitBy(lecture!, _.isNil) as PartialEntity<LectureV2>).pipe(
            map((lecture) => CourseActions.upsertLectureSuccess({ lecture })),
            catchError(() => of(CourseActions.upsertLectureError()))
          ),
          ...lectureResources.map((lectureResource) =>
            this.instructorService.upsertLectureResource(lectureResource).pipe(
              map(() => CourseActions.upsertLectureResourceSuccess()),
              catchError(() => of(CourseActions.upsertLectureResourceError()))
            )
          )
        )
      )
    )
  );

  deleteLectureResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.deleteLectureResource),
      switchMap(({ lectureResourceId }) =>
        this.instructorService.deleteLectureResource(lectureResourceId).pipe(
          map(() => CourseActions.deleteLectureResourceSuccess()),
          catchError(() => of(CourseActions.deleteLectureResourceError()))
        )
      )
    )
  );

  upsertAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.upsertAnnotation),
      switchMap(({ annotation }) =>
        this.annotationService.upsertAnnotation(annotation).pipe(
          map(() => CourseActions.upsertAnnotationSuccess()),
          catchError(() => of(CourseActions.upsertAnnotationError()))
        )
      )
    )
  );

  loadHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.loadHistory),
      switchMap(({ projectId }) =>
        this.annotationService.getAnnotationsByProjectId(projectId).pipe(
          map(({ courses, annotations }) => CourseActions.loadHistorySuccess({ courses, annotations })),
          catchError(() => of(CourseActions.loadHistoryError()))
        )
      )
    )
  );

  resolveUnresolveAnnotation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CourseActions.resolveFeedback, CourseActions.unresolveFeedback),
        concatLatestFrom(({ annotation }) => this.store.select(selectAnnotationByKey(annotationKey(annotation)))),
        switchMap(([, annotation]) =>
          this.annotationService.upsertAnnotation({
            id: annotation.id,
            is_resolved: annotation.is_resolved,
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store: Store,
    private readonly instructorService: InstructorService,
    private readonly annotationService: AnnotationService,
    private paginationService: PaginationService,
    private loaderService: LoaderService
  ) {}
}
