<div class="p-6">
  <form [formGroup]="form" (submit)="submit()" #myForm="ngForm">
    <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
  </form>
</div>
<div class="flex justify-end gap-2 px-6 py-4">
  <button class="btn-neutral" (click)="cancel()">
    <span>Cancel</span>
  </button>
  <button class="btn btn-teal" (click)="submit()">
    <span>Add Section</span>
  </button>
</div>
