import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ROUTE_HOME, ROUTE_LOGIN } from '@shared/models';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { AuthActions } from './auth.actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(({ email, password, remember }) =>
        this.authService.login({ email, password, remember }).pipe(
          map((resp) => AuthActions.loginSuccess({ refresh: resp.refresh, access: resp.token })),
          catchError((error) => of(AuthActions.loginError({ error: error.message })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      tap(() => {
        this.router.navigate([ROUTE_HOME]);
      }),
      switchMap(() => this.authService.getUser().pipe(map((user) => AuthActions.userLoaded({ user }))))
    )
  );

  userLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userLoad),
      switchMap(() => this.authService.getUser().pipe(map((user) => AuthActions.userLoaded({ user }))))
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        switchMap(() => this.authService.logout()),
        tap(() => {
          this.router.navigate([ROUTE_LOGIN]);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private router: Router,
    private store: Store
  ) {}
}
