import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderActions } from '../components/course-header/course-header.component';
import { CourseIntendedComponent } from '../intended/intended.component';

@Component({
  selector: 'app-course-revision',
  templateUrl: './course-revision.component.html',
  styleUrls: ['./course-revision.component.scss'],
})
export class CourseRevisionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  // Stepper
  currentStep = 0;
  steps = [{ title: 'Intended Learner' }, { title: 'Detail' }, { title: 'Curriculum' }, { title: 'Pricing' }];

  setStep(step: number) {
    this.currentStep = step;
  }

  nextStep() {
    if (this.currentStep < this.steps.length) {
      this.currentStep++;
    }
  }

  prevStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  submit() {
    alert('Form submitted');
  }

  // Header action
  @ViewChild('intend') private intend!: CourseIntendedComponent;
  actionEvent(event: HeaderActions) {
    this.intend.actionEvent(event);
  }
}
