import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  PricingCurrencyService,
  PricingTierActions,
  PricingTierCreateUpdatePayload,
  PricingTierState,
  PricingTierStatus,
  selectPricingTier,
  selectPricingTierCRUDStatus,
} from '@shared';
import { Observable, combineLatest } from 'rxjs';
import { Currency, PricingTier, ToastService } from 'thkee-common';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class PriceTiersEditComponent implements OnInit {
  id = '';
  priceTier$: Observable<PricingTierState> = this.store.select(selectPricingTier);
  priceTierStatus$: Observable<PricingTierStatus> = this.store.select(selectPricingTierCRUDStatus);

  currencyList$: Observable<Currency[]> = new Observable();
  priceTierData: PricingTier = {
    id: '',
    price: 0,
    name: '',
    price_tier_status: false,
    currency: 'usd',
  };
  hasData = false;
  pringTierUpdatePayload!: PricingTierCreateUpdatePayload;
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private currencyStore: PricingCurrencyService,
    private router: Router,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(PricingTierActions.getPricingTier({ id: this.id }));
    this.currencyList$ = this.currencyStore.getCurrencyList();
    combineLatest([this.priceTier$])
      .pipe()
      .subscribe(([price]) => {
        this.priceTierData = { ...price?.pricingTierData } || {};
        if (this.priceTierData?.id !== '') {
          this.hasData = true;
        }
      });
    this.priceTierStatus$.subscribe((status) => {
      console.log('status', status);
      if (status === PricingTierStatus.Success) {
        this.toastService.message({
          message: `Pricing Tier updated successfully`,
        });
        this.router.navigate(['/price-management/tiers']);
      }
    });
  }

  onSubmit(event: any) {
    // console.log(event?.values);
    let values = event?.values;
    values.currency_id = values.currency;
    this.pringTierUpdatePayload = values;
    this.store.dispatch(PricingTierActions.updatePricingTier(this.pringTierUpdatePayload));
  }
}
