import { Component, Input, OnInit } from '@angular/core';

interface pagination {
  total: number;
  perpage: number;
  currentPage: number;
  totalPage: number;
}

interface navigation {
  url: string;
  title: string;
  active: boolean;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  @Input() title: string = '';
  @Input() badge: string = '';
  @Input() navigation: navigation[] = [];
  @Input() pagination: pagination = { total: 0, perpage: 10, currentPage: 1, totalPage: 1 };

  constructor() {}

  ngOnInit(): void {
    if (this.pagination.total) {
      this.pagination.totalPage = this.pagination.total / this.pagination.perpage;
    }
  }
}
