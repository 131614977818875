<ng-container *ngIf="!isLoading">
   <div class="course-overview p-4 pt-0">
      <!-- Order Summery -->
      <div class="page-heading mb-4">
         <h3 class="text text-3xl text-neutral-800 font-semibold">{{orderDetails.user.fullname}}'s Order </h3>
      </div>
      <app-order-details-overview [orderDetails]="orderDetails" [id]="params['orderId']"></app-order-details-overview>
   </div>
</ng-container>

<ng-container *ngIf="isLoading">
   <ng-container *ngTemplateOutlet="loader"></ng-container>
</ng-container>
<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>