import { Injectable } from '@angular/core';
import { API_USERS_DETAIL, API_USERS_LOGIN, API_USERS_LOGOUT } from 'projects/thkee-common/src/lib/models/constants';
import { Observable, map, of } from 'rxjs';
import { Credentials, CredentialsService, HttpService, LoginContext, LoginResponse, hashPassword } from 'thkee-common';

export type User = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_instructor: boolean;
  fullname?: string;
  handle?: string;
  headline?: string;
  photo_url?: string;
  photo?: string;
};

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private credentialsService: CredentialsService, private readonly http: HttpService) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    console.log('CONTEXT---', context);
    return this.http
      .post<LoginResponse, LoginContext>(
        API_USERS_LOGIN,
        { ...context, password: hashPassword(context.password, context.email) },
        undefined,
        { publicResources: true }
      )
      .pipe(
        map((res) => {
          const credentials: Credentials = {
            refresh: res.refresh,
            token: res.access,
          };
          this.credentialsService.setCredentials(credentials, context.remember);
          return credentials;
        })
      );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    this.http.post(API_USERS_LOGOUT, {}).subscribe();
    this.credentialsService.setCredentials();
    return of(true);
  }

  getUser(): Observable<User> {
    return this.http.get<User>(API_USERS_DETAIL);
  }
}
