<div class="logo mb-6 px-[18px] py-[10px]">
  <div class="max-w-[100px]">
    <svg-icon src="@assets/logo.svg" class="h-auto w-full"></svg-icon>
  </div>
</div>

<div class="vertical-scroll">
  <nav class="items">
    <ul>
      <li *ngFor="let item of items" [id]="'item-' + item.id" [class.hidden]="item.hidden">
        <a [id]="'item-link-' + item.id" [routerLink]="item.route" (click)="active(item.id)"
          [ngClass]="'item-link-' + item.id" class="item" [class.active]="isActive(item.id)"
          [ngClass]="item.children ? 'haveSub' : 'noSub'">
          <svg-icon [src]="'@assets/images/icons/' + item.iconName + '.svg'" class="inline-block h-6 w-6"></svg-icon>
          <span class="grow">{{ item.displayName }}</span>
          <svg-icon *ngIf="item.children" src="@assets/images/icons/chevron-right.svg"
            class="arrow inline-block h-6 w-6"></svg-icon>
        </a>
        <ng-container *ngIf="item.children && isActive(item.id)">
          <ng-container
            *ngTemplateOutlet="subItem; context: { parentIdx: item.id, parent: item, items: item.children }">
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </nav>

  <div class="footer mt-auto p-[18px] text-sm">
    <div class="mb-1"><strong>Thkee Admin Dashboard</strong></div>
    <div class="text-neutral-700">© 2022 All Rights Reserved</div>
  </div>
</div>

<!-- Modualar Template -->
<ng-template #subItem let-parentIdx="parentIdx" let-parent="parent" let-items="items">
  <div class="sub-items">
    <ul>
      <li *ngFor="let item of items" [id]="'item-' + parentIdx + '_' + item.id" [class.hidden]="item.hidden">
        <a [id]="'item-' + parentIdx + '_' + item.id" [routerLink]="item.route" (click)="active(item.id)"
          [ngClass]="'item-' + parentIdx + '_' + item.id" class="item" [class.active]="isActive(item.id)"
          [ngClass]="item.children ? 'haveSub' : 'noSub'">
          <span class="grow">{{ item.displayName }}</span>
          <div class="count" *ngIf="item.count">{{ item.count }}</div>
          <svg-icon *ngIf="item.children" src="@assets/images/icons/chevron-right.svg"
            class="arrow inline-block h-6 w-6"></svg-icon>
        </a>
        <ng-container *ngIf="item.children && isActive(item.id)">
          <ng-container
            *ngTemplateOutlet="subItem; context: { parentIdx: item.id, parent: item, items: item.children }">
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </div>
</ng-template>