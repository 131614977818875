<ng-container *ngIf="{
    fieldId: (fieldId$ | async) ?? '',
    fieldKey: (fieldKey$ | async) ?? '',
    fieldModel: (fieldModel$ | async) ?? undefined
  } as vm">
  <div class="p-4">
    <form [formGroup]="formFeedback">
      <formly-form [form]="formFeedback" [fields]="fieldsFeedback" [model]="modelFeedback"></formly-form>
    </form>
    <div class="flex justify-end gap-4">
      <button class="btn-neutral" (click)="onCancel()">Cancel</button>
      <button class="btn-teal" (click)="onSubmit()">Submit</button>
    </div>
  </div>
  <div class="line-division z-10 flex items-center">
    <div class="line h-px grow bg-neutral-200"></div>
    <div class="flex items-center gap-1 rounded-full bg-neutral-50 px-5 py-[10px] hover:bg-neutral-100">
      <span class="text-sm">Older Update</span>
      <svg-icon src="@assets/images/icons/chevron-double-down.svg" class="block h-4 w-4"></svg-icon>
    </div>
    <div class="line h-px grow bg-neutral-200"></div>
  </div>
  <app-common-history-field [fieldModel]="vm.fieldModel" [fieldKey]="vm.fieldKey" [fieldId]="vm.fieldId">
  </app-common-history-field>
</ng-container>