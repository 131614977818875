import { Component, Input, OnInit } from '@angular/core';

export interface DropdownItem {
  title: string;
  link?: string;
  icon?: string;
  subItem?: DropdownItem[];
}

@Component({
  selector: 'app-common-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  show: boolean = false;
  @Input() items: DropdownItem[] = [];
  @Input() alignment: 'left' | 'right' = 'left';

  constructor() {}

  ngOnInit(): void {
    // this.items = [
    //   {
    //     title: 'No icon',
    //     link: '/search?category__slug=syndicate-front-end-deliverables',
    //   },
    //   {
    //     title: 'Print',
    //     link: '/search?category__slug=syndicate-front-end-deliverables',
    //     icon: '@assets/images/icons/bell.svg',
    //   },
    //   {
    //     title: 'Export',
    //     link: '/search?category__slug=syndicate-front-end-deliverables',
    //     subItem: [
    //       {
    //         title: 'No icon',
    //         link: '/search?category__slug=syndicate-front-end-deliverables',
    //       },
    //       {
    //         title: 'Print',
    //         link: '/search?category__slug=syndicate-front-end-deliverables',
    //         icon: '@assets/images/icons/bell.svg',
    //       },
    //       {
    //         title: 'Export',
    //         link: '/search?category__slug=syndicate-front-end-deliverables',
    //       },
    //       {
    //         title: 'Settings',
    //         link: '/search?category__slug=syndicate-front-end-deliverables',
    //       },
    //     ],
    //   },
    //   {
    //     title: 'Settings',
    //     link: '/search?category__slug=syndicate-front-end-deliverables',
    //   },
    // ];
  }

  handleClickOutside() {
    this.show = false;
  }
}
