import { ContentChildren, Directive, EventEmitter, Output, QueryList } from '@angular/core';
import { NestedCheckboxChildDirective } from './nested-checkbox-child.directive';

@Directive({
  selector: '[thkNestedCheckbox]'
})
export class NestedCheckboxDirective {
  @ContentChildren(NestedCheckboxChildDirective, { descendants: true })
  nestedCheckedChildren!: QueryList<NestedCheckboxChildDirective>;

  @Output() selectedChange = new EventEmitter<any[]>();

  constructor() { }

  markForCheck(targetCheckboxDirective: NestedCheckboxChildDirective) {
    if (targetCheckboxDirective.leader) {
      this.nestedCheckedChildren
        .filter(c => c !== targetCheckboxDirective)
        .forEach(checkbox =>  targetCheckboxDirective.checked ? checkbox.check() : checkbox.uncheck());
    } else {
      const isAllMembersChecked = this.memberCheckboxes.every(c => c.checked);
      this.leaderCheckboxes.forEach(
        leader => isAllMembersChecked ? leader.check() : leader.uncheck()
      );
    }

    this.notifySelectedChange();
  }

  uncheck() {
    const checkedCheckboxes = this.nestedCheckedChildren.filter(c => c.checked);
    checkedCheckboxes.forEach(c => c.uncheck());
    if (checkedCheckboxes.length) {
      this.notifySelectedChange();
    }
  }

  check() {
    const uncheckedBoxes = this.nestedCheckedChildren.filter(c => !c.checked);
    uncheckedBoxes.forEach(c => c.check());
    if (uncheckedBoxes.length) {
      this.notifySelectedChange();
    }
  }

  private get memberCheckboxes() {
    return this.nestedCheckedChildren.filter(c => !c.leader);
  }

  private get leaderCheckboxes() {
    return this.nestedCheckedChildren.filter(c => !!c.leader)
  }

  private notifySelectedChange() {
    this.selectedChange.emit(
      this.nestedCheckedChildren
        .filter(c => c.checked && !c.leader && c.data !== undefined)
        .map(c => c.data)
    );
  }
}
