import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter } from 'rxjs';
import { selectCurrentRoute } from 'thkee-common';

export interface Breadcrumb {
  label: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbs: Breadcrumb[] = [];

  private _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  public breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router, private store: Store) {
    this.store.select(selectCurrentRoute).subscribe((sample) => {
      console.log('selectCurrentRoute--', sample);
    });

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
    onNavigationEnd.subscribe((event) => {
      const rootRoute = this.router.routerState.snapshot.root;
      this.breadcrumbs = this.createBreadcrumbs(rootRoute);
      this._breadcrumbs$.next(this.breadcrumbs);
    });
  }

  private createBreadcrumbs(
    route: ActivatedRouteSnapshot,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRouteSnapshot[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.url.map((segment) => segment.path).join('/');
      let newUrl = url;
      if (routeURL !== '') {
        newUrl += `/${routeURL}`;
      }

      breadcrumbs.push({ label: child.data['breadcrumb'], url: newUrl });

      // Recursively process child routes
      this.createBreadcrumbs(child, newUrl, breadcrumbs);
    }

    return breadcrumbs;
  }

  getBreadcrumbs(): Breadcrumb[] {
    return this.breadcrumbs;
  }

  setBreadcrumbs(data: any): any {
    this._breadcrumbs$.next(data);
  }
}
