import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  API_GEO_LOCATION,
  API_GEO_PRICING,
  API_GEO_PRICING_TIER,
  GeoLocation,
  GeoLocationList,
  GeoLocationPayload,
  GeoPricing,
  GeoPricingPayload,
  GeoPricingTier,
  GeoPricingTierPayload,
  HttpService,
  encodeURL,
} from 'thkee-common';
import { PricingGeoCreateUpdatePayload, PricingGeoLoadRemovePayload } from './geo-state.model';

@Injectable({
  providedIn: 'root',
})
export class PricingGeoService {
  constructor(private readonly http: HttpService) {}

  // Geo Locations
  getGeoLocList(params = {} as object): Observable<GeoLocationList[]> {
    return this.http.get<GeoLocationList[]>(encodeURL(API_GEO_LOCATION, params));
  }

  addGeoLoc(params = {} as object): Observable<PricingGeoCreateUpdatePayload> {
    return this.http.post<any>(API_GEO_LOCATION, params);
  }

  getGeoLoc(params = {} as PricingGeoLoadRemovePayload): Observable<GeoLocation> {
    const url = `${API_GEO_LOCATION}${params?.id}`;
    return this.http.get<GeoLocation>(url);
  }

  updateGeoLoc(id = '' as string | number, payload: GeoLocationPayload): Observable<GeoLocation> {
    return this.http.patch(`${API_GEO_LOCATION}${id}/`, payload);
  }

  deleteGeoLoc(id = '' as string | number): Observable<PricingGeoCreateUpdatePayload> {
    return this.http.delete<any>(API_GEO_LOCATION + id + '/');
  }

  // Geo Pricing
  addGeoPricing(params = {} as object): Observable<PricingGeoCreateUpdatePayload> {
    return this.http.post<any>(API_GEO_PRICING, params);
  }

  updateGeoPricing(id = '' as string | number, payload: GeoPricingPayload): Observable<GeoPricing> {
    return this.http.patch(`${API_GEO_PRICING}${id}/`, payload);
  }

  // Pricing Tiers
  getGeoPricingTiers(params = {} as object): Observable<GeoPricingTier[]> {
    return this.http.get<GeoPricingTier[]>(encodeURL(API_GEO_PRICING_TIER, params));
  }

  addGeoPricingTier(payload = {} as GeoPricingTierPayload): Observable<GeoPricingTier> {
    console.log('addGeoPricingTier--', API_GEO_PRICING_TIER);
    return this.http.post<GeoPricingTier>(API_GEO_PRICING_TIER, payload);
  }

  updateGeoPricingTiers(id = '' as string | number, payload: GeoPricingTierPayload): Observable<GeoPricingTier> {
    return this.http.patch(`${API_GEO_PRICING_TIER}${id}/`, payload);
  }
}
