import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export * from './failed-payout-details/failed-payout-details.component';
export * from './failed-payout.component';

import { FailedPayoutDetailsComponent } from './failed-payout-details/failed-payout-details.component';
import { FailedPayoutComponent } from './failed-payout.component';

export const Components = [FailedPayoutComponent, FailedPayoutDetailsComponent];

export const Routing = [
  {
    path: 'payout/failed-payouts',
    component: FailedPayoutComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Failed Payout' },
  },
  {
    path: 'payout/:payoutId/failed-payouts',
    component: FailedPayoutDetailsComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Failed Payout Details' },
  },
];
