import { Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { NestedCheckboxDirective } from './nested-checkbox.directive';

@Directive({
  selector: 'input[type=checkbox][thkNestedCheckboxChild]'
})
export class NestedCheckboxChildDirective {
  @Input() leader?: boolean;
  @Input() data?: any;

  @HostListener('change', ['$event'])
  changeListener(event: MouseEvent) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {

    }
    this.parentCheckbox.markForCheck(this)
  }

  constructor(
    private elRef: ElementRef<HTMLInputElement>,
    @Optional() private parentCheckbox: NestedCheckboxDirective
  ) {
    if (!parentCheckbox) {
      throw 'Please use this directive along with at least one NestedCheckboxDirective (thkNestCheckbox)'
    }
  }

  check() {
    if (!this.elRef.nativeElement.checked) {
      this.elRef.nativeElement.checked = true;
    }
  }

  uncheck() {
    if (this.elRef.nativeElement.checked) {
      this.elRef.nativeElement.checked = false;
    }
  }

  get checked() {
    return this.elRef.nativeElement.checked;
  }
}
