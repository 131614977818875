import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CurrencyCRUDStatus,
  FormPriceCurrencyComponent,
  ModalComponent,
  PricingCountryActions,
  PricingCountryPayload,
  PricingCurrencyActions,
  PricingCurrencyCreateUpdatePayload,
  selectCurrencyCRUDStatus,
  selectExchangeRateList,
  selectLoadedPricingCurrencyData,
  selectPricingCountryList,
} from '@shared';
import { Observable } from 'rxjs';
import { ExchangRateData, PricingCountryData, PricingCurrency, ToastService } from 'thkee-common';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
})
export class PriceCurrenciesNewComponent implements OnInit {
  @ViewChild('liveRates') liveRates!: ModalComponent;
  @ViewChild('currencyForm') currencyForm!: FormPriceCurrencyComponent;

  enableLiveRate: boolean = false;

  pricingCurrencyPayload!: PricingCurrencyCreateUpdatePayload;
  priceCurrency$: Observable<PricingCurrency> = this.store.select(selectLoadedPricingCurrencyData);
  status$: Observable<CurrencyCRUDStatus> = this.store.select(selectCurrencyCRUDStatus);
  priceCurrencyDataDefault: PricingCurrency = {
    id: '',
    name: '',
    currency_code: '',
    rate: '',
    rounding_type: '',
    is_primary_exchange_rate_currency: false,
    is_primary_store_currency: false,
    pricing_tier_status: false,
    force: false,
    published: false,
    country: [],
    symbol: '',
    primary_currency: '',
  };

  formData: any = {};

  pricingCountryList$: Observable<PricingCountryData[]> = this.store.select(selectPricingCountryList);
  pricingCountryList: any = [];
  pricingCountryPayload!: PricingCountryPayload;

  // Live Rates
  exchangeRateList$: Observable<ExchangRateData[]> = this.store.select(selectExchangeRateList);
  exchangeRateList!: ExchangRateData[];

  constructor(private store: Store, private router: Router, private readonly toastService: ToastService) {}

  ngOnInit(): void {
    this.pricingCountryPayload = {
      page: 1,
      page_size: 100,
    };
    this.store.dispatch(PricingCountryActions.loadPricingCountryList(this.pricingCountryPayload));
    this.store.dispatch(PricingCurrencyActions.clearCurrencyCRUDStatus());
  }

  onSubmit(event: any) {
    const idArray = event?.selectedCountries.map((item: PricingCountryData) => item.id);

    if (event.valid) {
      // const payload = {
      //   name: event?.values.name,
      //   currency_code: event?.values.currency_code,
      //   rate: event?.values.rate,
      //   rounding_type: event?.values.rounding_type,
      //   is_primary_exchange_rate_currency: false,
      //   is_primary_store_currency: false,
      //   pricing_tier_status: event?.values.pricing_tier_status,
      //   force: event?.values.force,
      //   published: event?.values.published,
      //   country: idArray,
      // };

      let values = event?.values;
      this.pricingCurrencyPayload = { ...values };
      // this.pricingCurrencyPayload.rate = Number(this.pricingCurrencyPayload.rate).toFixed(6);

      // console.log('payload', payload);
      this.store.dispatch(PricingCurrencyActions.addPricingCurrency(this.pricingCurrencyPayload));
      this.status$.subscribe((status) => {
        console.log('STATUS', status);
        if (status === CurrencyCRUDStatus.Success) {
          this.toastService.message({
            message: `Currency saved successfully`,
          });
          this.router.navigate(['/price-management/currencies']); // Navigate to the countries list
          // this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList({ detailed: false }));
        }

        if (status === CurrencyCRUDStatus.Failed) {
          this.toastService.error({
            message: `Something went wrong. Please try again.`,
          });
        }
      });
    }

    // this.toastService.message({
    //   message: `Currency saved successfully`,
    // });
  }

  // Depricated
  // handleApply(rateData: CurrencyRate[] | CurrencyRate): void {
  //   console.log('Apply clicked!', rateData);
  //   // Your logic when the button is clicked
  // }

  clearFilter(filters: any) {
    if (filters) {
      filters.forEach((filter: any) => {
        filter.reset();
      });
    }
  }

  getLivesRates() {
    if (this.formData['currency_code']) {
      this.currencyForm.getLivesRates(this.formData['currency_code']);
    } else {
      alert('Please select currency');
    }

    // if (this.formData['currency_code']) {
    //   this.exchangeRateList$.subscribe((rates) => {
    //     this.exchangeRateList = rates;
    //     console.log('Fileter and set--', this.formData, this.exchangeRateList);
    //     const selectedRate = _.find(this.exchangeRateList, { to_currency: this.formData['currency_code'] });
    //     console.log('selectedRate--', selectedRate);
    //     this.formData['rate'] = selectedRate?.rate;
    //   });
    //   if (!this.exchangeRateList.length) {
    //     this.store.dispatch(
    //       ExchangRateActions.loadExchangeRatesList({
    //         page: 1,
    //         page_size: 10,
    //       })
    //     );
    //   }
    // }
    // Call form live rates
  }

  onChange(values: any) {
    this.formData = values;
    this.enableLiveRate = false;
    if (values) {
      if (values['currency_code']) {
        this.enableLiveRate = true;
      }
    }
  }
}
