import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ExchangRateState } from './exchange-rate-state.model';

export const selectExchangeRateState = createFeatureSelector<ExchangRateState>('exchange-rate');

export const selectExchangeRate = createSelector(selectExchangeRateState, (entities) => entities);

export const selectExchangeRateList = createSelector(selectExchangeRateState, (entities) => {
  return entities.exchangeRateList;
});

export const selectExchangeRateData = createSelector(selectExchangeRateState, (entities) => entities.exchangeRateData);

export const selectExchangeRateCRUDStatus = createSelector(selectExchangeRateState, (entities) => entities.status);
