import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginationService } from 'thkee-common';

@Component({
  selector: 'app-common-pagination',
  templateUrl: './common-pagination.component.html',
  styleUrls: ['./common-pagination.component.scss'],
})
export class CommonPaginationComponent implements OnInit {
  @Input() type: string = 'static';
  paginationData: any;
  @Output() paginatorData: EventEmitter<any> = new EventEmitter();
  constructor(private paginationService: PaginationService) {}

  ngOnInit(): void {
    this.paginationService.getPageItems().subscribe((res) => {
      this.paginationData = res;
      // console.log(this.paginationData);
    });
  }

  paginateAction(type: string) {
    if (type === 'next') {
      this.paginationData.currentPage += 1;
      this.paginatorData.emit(this.paginationData.currentPage);
    } else {
      this.paginationData.currentPage -= 1;
      this.paginatorData.emit(this.paginationData.currentPage);
    }
  }

  switchPage(page: number) {
    this.paginatorData.emit(page);
  }
}
