import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export * from './paid-payout-all/paid-payout-all.component';
export * from './paid-payout-all/paid-payout-transaction/paid-payout-transaction.component';
export * from './paid-payout-monthly/paid-payout-monthly-details/paid-payout-monthly-details.component';
export * from './paid-payout-monthly/paid-payout-monthly-transaction/paid-payout-monthly-transaction.component';
export * from './paid-payout-monthly/paid-payout-monthly.component';
export * from './paid-payout-ondemand/paid-payout-ondemand.component';

import { Route } from '@angular/router';
import { PaidPayoutAllComponent } from './paid-payout-all/paid-payout-all.component';
import { PaidPayoutTransactionComponent } from './paid-payout-all/paid-payout-transaction/paid-payout-transaction.component';
import { PaidPayoutMonthlyDetailsComponent } from './paid-payout-monthly/paid-payout-monthly-details/paid-payout-monthly-details.component';
import { PaidPayoutMonthlyTransactionComponent } from './paid-payout-monthly/paid-payout-monthly-transaction/paid-payout-monthly-transaction.component';
import { PaidPayoutMonthlyComponent } from './paid-payout-monthly/paid-payout-monthly.component';
import { PaidPayoutOndemandComponent } from './paid-payout-ondemand/paid-payout-ondemand.component';

export const Components = [
  PaidPayoutAllComponent,
  PaidPayoutTransactionComponent,
  PaidPayoutMonthlyComponent,
  PaidPayoutOndemandComponent,
  PaidPayoutMonthlyDetailsComponent,
  PaidPayoutMonthlyTransactionComponent,
];

export const Routing: Route[] = [
  {
    path: 'payout/paid-payout',
    redirectTo: 'payout/paid-payout/all',
    pathMatch: 'full',
  },
  {
    path: 'payout/paid-payout/all',
    component: PaidPayoutAllComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Paid Payout' },
  },
  {
    path: 'payout/paid-payout/:payoutId/all',
    component: PaidPayoutTransactionComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Payout All Transaction' },
  },
  {
    path: 'payout/paid-payout/ondemand',
    component: PaidPayoutOndemandComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Paid Payout Ondemand' },
  },
  {
    path: 'payout/paid-payout/:payoutId/ondemand',
    component: PaidPayoutTransactionComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Payout Ondemand Transaction' },
  },
  {
    path: 'payout/paid-payout/monthly',
    component: PaidPayoutMonthlyComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Paid Payout Monthly' },
  },
  {
    path: 'payout/paid-payout/monthly/:payoutId/details',
    component: PaidPayoutMonthlyDetailsComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Paid Payout Monthly Details' },
  },
  {
    path: 'payout/paid-payout/monthly/:payoutId/transaction',
    component: PaidPayoutMonthlyTransactionComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Paid Payout Monthly Transaction' },
  },
];
