import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export * from './upcoming-payout-monthly/upcoming-payout-monthly.component';
export * from './upcoming-payout-transaction-hold/upcoming-payout-transaction-hold.component';
export * from './upcoming-payout-transaction/upcoming-payout-transaction.component';

import { UpcomingPayoutMonthlyComponent } from './upcoming-payout-monthly/upcoming-payout-monthly.component';
import { UpcomingPayoutTransactionHoldComponent } from './upcoming-payout-transaction-hold/upcoming-payout-transaction-hold.component';
import { UpcomingPayoutTransactionComponent } from './upcoming-payout-transaction/upcoming-payout-transaction.component';

export const Components = [
  UpcomingPayoutMonthlyComponent,
  UpcomingPayoutTransactionComponent,
  UpcomingPayoutTransactionHoldComponent,
];

export const Routing = [
  {
    path: 'payout/upcoming-payouts/monthly',
    component: UpcomingPayoutMonthlyComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Upcoming Payouts Monthly' },
  },
  {
    path: 'payout/upcoming-payouts/transactions/:instructorId',
    component: UpcomingPayoutTransactionComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Upcoming Payouts Transactions' },
  },
  {
    path: 'payout/upcoming-payouts/transactions/:instructorId/hold',
    component: UpcomingPayoutTransactionHoldComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Upcoming Payouts Transactions Hold' },
  },
];
