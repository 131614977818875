import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Editor, commandsCtx, defaultValueCtx, editorViewCtx, editorViewOptionsCtx, rootCtx } from '@milkdown/core';
import { history } from '@milkdown/plugin-history';
import { listener, listenerCtx } from '@milkdown/plugin-listener';
import {
  commonmark,
  toggleEmphasisCommand,
  toggleStrongCommand,
  wrapInBulletListCommand,
  wrapInOrderedListCommand,
} from '@milkdown/preset-commonmark';
import { nord } from '@milkdown/theme-nord';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig } from '@ngx-formly/core';

interface EditorProps extends FormlyFieldProps {
  cols?: number;
  rows?: number;
  maxHeight?: string;
  minHeight?: string;
  disabled?: boolean;
}

@Component({
  selector: 'formly-field-editor',
  template: `
    <div
      [ngClass]="isDisabled ? 'cursor-not-allowed opacity-[0.5]' : ''"
      class="textEditor"
      [class.full]="resizeEditor"
    >
      <div class="toolbar flex items-center justify-between">
        <div class="text-formatter flex items-center gap-3">
          <ng-container *ngTemplateOutlet="textFormatter"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="reSize"></ng-container>
      </div>
      <div class="editorContainer ar:text-right grow" #editorRef></div>
    </div>
    <ng-template #textFormatter>
      <div>
        <svg
          (click)="action('bold', $event)"
          xmlns="http://www.w3.org/2000/svg"
          class="no-highlight h-3.5 w-3.5"
          viewBox="0 0 384 512"
          fill="currentColor"
        >
          <path
            d="M0 64C0 46.3 14.3 32 32 32H80 96 224c70.7 0 128 57.3 128 128c0 31.3-11.3 60.1-30 82.3c37.1 22.4 62 63.1 62 109.7c0 70.7-57.3 128-128 128H96 80 32c-17.7 0-32-14.3-32-32s14.3-32 32-32H48V256 96H32C14.3 96 0 81.7 0 64zM224 224c35.3 0 64-28.7 64-64s-28.7-64-64-64H112V224H224zM112 288V416H256c35.3 0 64-28.7 64-64s-28.7-64-64-64H224 112z"
          />
        </svg>
      </div>
      <div (click)="action('italic', $event)">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 384 512" fill="currentColor">
          <path
            d="M128 64c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H293.3L160 416h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H90.7L224 96H160c-17.7 0-32-14.3-32-32z"
          />
        </svg>
      </div>
      <!-- <div (click)="action('underline')">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 448 512" fill="currentColor">
          <path
            d="M16 64c0-17.7 14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H128V224c0 53 43 96 96 96s96-43 96-96V96H304c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H384V224c0 88.4-71.6 160-160 160s-160-71.6-160-160V96H48C30.3 96 16 81.7 16 64zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32z"
          />
        </svg>
      </div> -->
      <div (click)="action('list', $event)">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 512 512" fill="currentColor">
          <path
            d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"
          />
        </svg>
      </div>
      <div (click)="action('numberlist', $event)">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 512 512" fill="currentColor">
          <path
            d="M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM224 64H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
          />
        </svg>
      </div>
    </ng-template>

    <ng-template #reSize>
      <!-- Resize Editor -->
      <div class="cursor-pointer" (click)="resizeEditor = !resizeEditor">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-4 w-4"
          *ngIf="!resizeEditor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-4 w-4"
          *ngIf="resizeEditor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
          />
        </svg>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        @apply w-full;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldEditor extends FieldType<FieldTypeConfig<EditorProps>> implements OnInit, AfterViewInit {
  @ViewChild('editorRef') editorRef!: ElementRef;

  resizeEditor: boolean = false;
  isDisabled: boolean = false;

  defaultEditorValue = ``;
  editor!: Editor;

  private editorView: any;

  constructor(private elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
    if (this.formControl.defaultValue) {
      this.defaultEditorValue = this.formControl.defaultValue;
    } else {
      if (this.formControl.value) {
        this.defaultEditorValue = this.formControl.value;
      }
    }
  }

  async ngAfterViewInit() {
    this.isDisabled = this.props.disabled ? this.props.disabled : false;
    this.editor = await Editor.make()
      .config((ctx) => {
        ctx.set(rootCtx, this.editorRef.nativeElement);
        ctx.set(defaultValueCtx, this.defaultEditorValue);
        // ctx.set(paragraphAttr.key, () => ({
        //   'data-test-id': generateUuid(),
        //   class: 'paragraph',
        // }));
        ctx.update(editorViewOptionsCtx, (prev) => ({
          ...prev,
          editable: () => !this.isDisabled,
        }));
        ctx.get(listenerCtx).markdownUpdated((ctx, markdown, prevMarkdown) => {
          this.field.formControl.setValue(markdown);
        });
      })
      .config(nord)
      .use(listener)
      .use(commonmark)
      // .use(underline)
      .use(history)
      .create();

    this.addInputEventListener();
  }

  addInputEventListener() {
    const editorElement = this.editorRef.nativeElement;
    editorElement.addEventListener('input', this.handleInputEvent.bind(this));
  }

  handleInputEvent(event: Event) {
    const editorView = this.editor.ctx.get(editorViewCtx);
    const editorState = editorView.state;
    const tr = editorState.tr;
    const textContent = editorState.doc.textContent;

    if (textContent && textContent.startsWith(' ')) {
      const newContent = textContent.trimStart();
      tr.insertText(newContent, 0, textContent.length + 1);
      editorView.dispatch(tr);
    }
  }

  action(action: string, event: Event) {
    event.preventDefault();
    this.editor.action((ctx) => {
      const commandManager = ctx.get(commandsCtx);
      if (!this.isDisabled) {
        if (action == 'bold') {
          commandManager.call(toggleStrongCommand.key);
        }
        if (action == 'italic') {
          commandManager.call(toggleEmphasisCommand.key);
        }
        // if (action == 'underline') { // Underline is not support by markdown
        //   commandManager.call(wrapInSampleCommand.key);
        // }
        if (action == 'list') {
          commandManager.call(wrapInBulletListCommand.key);
        }
        if (action == 'numberlist') {
          commandManager.call(wrapInOrderedListCommand.key);
        }
      }
    });
  }
}
