import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export * from './instructor-balance-details/instructor-balance-details.component';
export * from './instructor-balance.component';

import { Route } from '@angular/router';
import { InstructorBalanceDetailsComponent } from './instructor-balance-details/instructor-balance-details.component';
import { InstructorBalanceRequestsComponent } from './instructor-balance-requests/instructor-balance-requests.component';
import { InstructorBalanceTransactionsComponent } from './instructor-balance-transactions/instructor-balance-transactions.component';
import { InstructorBalanceComponent } from './instructor-balance.component';

export const Components = [InstructorBalanceComponent, InstructorBalanceDetailsComponent];

export const Routing: Route[] = [
  {
    path: 'payout/instructor-balances',
    component: InstructorBalanceComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Instructor Balances' },
  },
  {
    path: 'payout/instructor-balance/:instructorId',
    component: InstructorBalanceDetailsComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Instructor Balances Details' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'requests'
      },
      {
        path: 'transactions',
        component: InstructorBalanceTransactionsComponent
      },
      {
        path: 'requests',
        component: InstructorBalanceRequestsComponent
      },
    ]
  },
];
