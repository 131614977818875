import { Update } from '@ngrx/entity';
import { ActionCreator, createFeature, createReducer, on, ReducerTypes } from '@ngrx/store';
import * as _ from 'lodash';
import { Annotation, AnnotationDetail, ContentType, PartialEntity } from '../../models';
import { annotationAdapter, annotationKey } from './annotation.entity';
import { annotationMapper } from './annotation.mapper';
import { assignmentQuestionAdapter } from './assignment-question.entity';
import { assignmentAdapter } from './assignment.entity';
import { contentTypeAdapter } from './content-type.entity';
import { CourseState, initialCoursesState, initialFeedbackState, UploadState } from './course-state.model';
import { CourseActions } from './course.actions';
import { courseAdapter } from './course.entity';
import { learnerAdapter } from './learner.entity';
import { lectureResourceAdapter } from './lecture-resource.entity';
import { lectureAdapter } from './lecture.entity';
import { objectiveAdapter } from './objective.entity';
import { quizAnswerAdapter } from './quiz-answer.entity';
import { quizQuestionAdapter } from './quiz-question.entity';
import { quizAdapter } from './quiz.entity';
import { requirementAdapter } from './requirement.entity';
import { sectionAdapter } from './section.entity';
import { subsectionAdapter } from './subsection.entity';

/**
 * TODO JHJ:
 * - some delete actions will not delete child entities in store, but it's okay since they will not be referenced anyway
 * - also delete child entities when deleting parent entities
 * - try to implement EntityAdapter service that auto delets children entities
 */

const courseReducers: ReducerTypes<CourseState, readonly ActionCreator[]>[] = [
  on(CourseActions.loadCoursesSuccess, (state, { courses }) => {
    return {
      ...state,
      courses,
    };
  }),
  on(CourseActions.loadCourseSuccess, (state, { courseData }): CourseState => {
    const emptyState = initialCoursesState;
    const contentTypeMap = _.keyBy(courseData.content_types, (ct) => ct.id);
    return {
      ...emptyState,
      project: courseData.project,
      course: courseData.course,
      courseDetailed: courseData.course_detail,
      annotations: annotationAdapter.upsertMany(
        courseData.annotations.map((annotation) =>
          annotationMapper.toStore(annotation, { contentTypes: contentTypeMap })
        ),
        emptyState.annotations
      ),
      contentTypes: contentTypeAdapter.upsertMany(courseData.content_types, emptyState.contentTypes),
      sections: sectionAdapter.upsertMany(courseData.sections, emptyState.sections),
      subsections: subsectionAdapter.upsertMany(courseData.subsections, emptyState.subsections),
      quizzes: quizAdapter.upsertMany(courseData.quizzes, emptyState.quizzes),
      quizQuestions: quizQuestionAdapter.upsertMany(courseData.quiz_questions, emptyState.quizQuestions),
      quizAnswers: quizAnswerAdapter.upsertMany(courseData.quiz_answers, emptyState.quizAnswers),
      assignments: assignmentAdapter.upsertMany(courseData.assignments, emptyState.assignments),
      assignmentQuestions: assignmentQuestionAdapter.upsertMany(
        courseData.assignment_questions,
        emptyState.assignmentQuestions
      ),
      lectures: lectureAdapter.upsertMany(courseData.lectures, emptyState.lectures),
      lectureResources: lectureResourceAdapter.upsertMany(courseData.lecture_resources, emptyState.lectureResources),
      objectives: objectiveAdapter.upsertMany(courseData.objectives, emptyState.objectives),
      requirements: requirementAdapter.upsertMany(courseData.requirements, emptyState.requirements),
      learners: learnerAdapter.upsertMany(courseData.learners, emptyState.learners),
    };
  }),
  on(CourseActions.createCourseSuccess, (state, { course }) => {
    return {
      ...state,
      courses: [...state.courses, course],
      course: course,
    };
  }),
  on(CourseActions.upsertCourse, CourseActions.upsertCourseSuccess, (state, { course }) => {
    return {
      ...state,
      course: {
        ...state.course,
        ...course,
      },
    };
  }),
];

const activityReducers: ReducerTypes<CourseState, readonly ActionCreator[]>[] = [
  on(CourseActions.createActivity, (state, { sectionId }) => {
    return {
      ...state,
      selectedSectionId: sectionId,
      selectedSubsectionId: '',
      selectedActivityId: '',
      activityState: undefined,
      isActivityModalOpen: true,
    };
  }),
  on(CourseActions.editActivity, (state, { sectionId, subsectionId, activityId, activityState }) => {
    return {
      ...state,
      selectedSectionId: sectionId,
      selectedSubsectionId: subsectionId,
      selectedActivityId: activityId,
      activityState,
      isActivityModalOpen: true,
    };
  }),
  on(CourseActions.closeActivity, (state) => {
    return {
      ...state,
      selectedSectionId: '',
      selectedSubsectionId: '',
      selectedActivityId: '',
      activityState: undefined,
      isActivityModalOpen: false,
    };
  }),
  on(CourseActions.modalNext, (state, { activityState }) => {
    return {
      ...state,
      activityState,
    };
  }),
  on(CourseActions.reloadModal, (state, { activityState }) => {
    return {
      ...state,
      activityState: activityState === 'type_lecture' ? undefined : activityState,
    };
  }),
];

const curriculumReducers: ReducerTypes<CourseState, readonly ActionCreator[]>[] = [
  // Update section state
  on(CourseActions.upsertSectionState, (state, { section }) => {
    return {
      ...state,
      sections: sectionAdapter.upsertOne(
        { ...section, date_created: section.date_created ?? new Date().toISOString() },
        state.sections
      ),
    };
  }),

  // Update subsection/lecture state
  on(CourseActions.upsertSubSectionState, (state, { subsection }) => {
    return {
      ...state,
      // selectedSubsectionId: subsection.id,
      subsections: subsectionAdapter.upsertOne(
        { ...subsection, date_created: subsection.date_created ?? new Date().toISOString() },
        state.subsections
      ),
    };
  }),

  on(CourseActions.upsertSection, (state, { section }) => {
    return {
      ...state,
      sections: sectionAdapter.upsertOne(
        { ...section, date_created: section.date_created ?? new Date().toISOString() },
        state.sections
      ),
    };
  }),
  on(CourseActions.deleteSection, (state, { sectionId }) => {
    return {
      ...state,
      sections: sectionAdapter.removeOne(sectionId, state.sections),
    };
  }),
  on(CourseActions.deleteSubsection, (state, { subsectionId }) => {
    return {
      ...state,
      subsections: subsectionAdapter.removeOne(subsectionId, state.subsections),
    };
  }),
  on(CourseActions.createQuiz, (state, { subsection, quiz }) => {
    return {
      ...state,
      selectedSubsectionId: subsection.id,
      selectedActivityId: quiz.id,
      subsections: subsectionAdapter.upsertOne(
        { ...subsection, date_created: subsection.date_created ?? new Date().toISOString() },
        state.subsections
      ),
      quizzes: quizAdapter.upsertOne(
        { ...quiz, date_created: new Date().toISOString(), date_updated: new Date().toISOString() },
        state.quizzes
      ),
    };
  }),
  on(CourseActions.upsertQuiz, (state, { quiz }) => {
    return {
      ...state,
      quizzes: quizAdapter.upsertOne(quiz, state.quizzes),
    };
  }),
  on(CourseActions.upsertQuizQuestion, (state, { quizQuestion, quizAnswers }) => {
    return {
      ...state,
      quizQuestions: quizQuestionAdapter.upsertOne(quizQuestion, state.quizQuestions),
      quizAnswers: quizAnswerAdapter.upsertMany(quizAnswers, state.quizAnswers),
    };
  }),
  on(CourseActions.deleteQuizQuestion, (state, { quizQuestionId }) => {
    return {
      ...state,
      quizQuestions: quizQuestionAdapter.removeOne(quizQuestionId, state.quizQuestions),
      quizAnswers: quizAnswerAdapter.removeMany((e) => e.quiz_question === quizQuestionId, state.quizAnswers),
    };
  }),
  on(CourseActions.deleteQuizAnswer, (state, { quizAnswerId }) => {
    return {
      ...state,
      quizAnswers: quizAnswerAdapter.removeOne(quizAnswerId, state.quizAnswers),
    };
  }),
  on(CourseActions.createAssignment, (state, { subsection, assignment }) => {
    return {
      ...state,
      selectedSubsectionId: subsection.id,
      selectedActivityId: assignment.id,
      subsections: subsectionAdapter.upsertOne(
        { ...subsection, date_created: subsection.date_created ?? new Date().toISOString() },
        state.subsections
      ),
      assignments: assignmentAdapter.upsertOne(
        {
          ...assignment,
          date_created: new Date().toISOString(),
          date_updated: new Date().toISOString(),
        },
        state.assignments
      ),
    };
  }),
  on(CourseActions.upsertAssignment, (state, { assignment, assignmentQuestions }) => {
    return {
      ...state,
      assignments: assignmentAdapter.upsertOne(assignment, state.assignments),
      assignmentQuestions: assignmentQuestionAdapter.upsertMany(assignmentQuestions, state.assignmentQuestions),
    };
  }),
  on(CourseActions.deleteAssignmentQuestion, (state, { assignmentQuestionId }) => {
    return {
      ...state,
      assignmentQuestions: assignmentQuestionAdapter.removeOne(assignmentQuestionId, state.assignmentQuestions),
    };
  }),
  on(CourseActions.createLecture, (state, { subsection, lecture }) => {
    return {
      ...state,
      selectedSubsectionId: subsection.id,
      selectedActivityId: lecture.id,
      subsections: subsectionAdapter.upsertOne(
        { ...subsection, date_created: subsection.date_created ?? new Date().toISOString() },
        state.subsections
      ),
      lectures: lectureAdapter.upsertOne(
        { ...lecture, date_created: new Date().toISOString(), date_updated: new Date().toISOString() },
        state.lectures
      ),
    };
  }),
  on(CourseActions.upsertLecture, (state, { lecture, lectureResources }) => {
    return {
      ...state,
      lectures: lectureAdapter.upsertOne(lecture, state.lectures),
      lectureResources: lectureResourceAdapter.upsertMany(lectureResources, state.lectureResources),
    };
  }),
  on(CourseActions.upsertLectureSuccess, (state, { lecture }) => {
    return {
      ...state,
      lectures: lectureAdapter.upsertOne(lecture, state.lectures),
    };
  }),
  on(CourseActions.deleteLectureResource, (state, { lectureResourceId }) => {
    return {
      ...state,
      lectureResources: lectureResourceAdapter.removeOne(lectureResourceId, state.lectureResources),
    };
  }),
];

const feedbackReducers: ReducerTypes<CourseState, readonly ActionCreator[]>[] = [
  on(CourseActions.upsertAnnotation, (state, { annotation }) => {
    const annotationDetail = annotationMapper.toStore(annotation as Annotation, {
      contentTypes: state.contentTypes.entities as Record<string, ContentType>,
    });
    return {
      ...state,
      annotations: annotationAdapter.upsertOne(annotationDetail, state.annotations),
      annotationHistory: annotationAdapter.upsertOne(annotationDetail, state.annotationHistory),
      feedback: {
        ...state.feedback,
        isFeedbackModalOpen: false,
      },
    };
  }),
  on(CourseActions.loadHistorySuccess, (state, { courses, annotations }) => {
    return {
      ...state,
      courseHistory: courseAdapter.upsertMany(courses, state.courseHistory),
      annotationHistory: annotationAdapter.upsertMany(
        annotations.map((annotation) =>
          annotationMapper.toStore(annotation, {
            contentTypes: state.contentTypes.entities as Record<string, ContentType>,
          })
        ),
        state.annotationHistory
      ),
    } as CourseState;
  }),
  on(CourseActions.openFeedbackModal, (state, { fieldId, fieldKey, fieldModel, fieldValue }) => {
    return {
      ...state,
      feedback: {
        ...state.feedback,
        isFeedbackModalOpen: true,
        fieldId,
        fieldKey,
        fieldModel,
        fieldValue,
      },
    };
  }),
  on(CourseActions.closeFeedbackModal, (state) => {
    return {
      ...state,
      feedback: {
        ...initialFeedbackState,
      },
    };
  }),
  on(CourseActions.resolveFeedback, (state, { annotation }) => {
    const update: Update<PartialEntity<AnnotationDetail>> = {
      id: annotationKey(annotation),
      changes: {
        is_resolved: true,
      },
    };
    return {
      ...state,
      annotations: annotationAdapter.updateOne(update, state.annotations),
      annotationHistory: annotationAdapter.updateOne(update, state.annotationHistory),
    };
  }),
  on(CourseActions.unresolveFeedback, (state, { annotation }) => {
    const update: Update<PartialEntity<AnnotationDetail>> = {
      id: annotationKey(annotation),
      changes: {
        is_resolved: false,
      },
    };
    return {
      ...state,
      annotations: annotationAdapter.updateOne(update, state.annotations),
      annotationHistory: annotationAdapter.updateOne(update, state.annotationHistory),
    };
  }),
];

const uploadReducers: ReducerTypes<CourseState, readonly ActionCreator[]>[] = [
  on(CourseActions.uploadStart, (state, { referenceId, fileName, fileType }) => {
    return {
      ...state,
      uploads: {
        ...state.uploads,
        [referenceId]: {
          referenceId,
          fileName,
          fileType,
        } as UploadState,
      },
    };
  }),
  on(CourseActions.uploadProgress, (state, { referenceId, bytesTotal, bytesUploaded, progressPercent }) => {
    if (!state.uploads[referenceId]) {
      return state;
    }
    return {
      ...state,
      uploads: {
        ...state.uploads,
        [referenceId]: {
          ...state.uploads[referenceId],
          bytesTotal,
          bytesUploaded,
          progressPercent,
        } as UploadState,
      },
    };
  }),
  on(CourseActions.uploadComplete, CourseActions.uploadAbort, (state, { referenceId }) => {
    return {
      ...state,
      uploads: _.omit(state.uploads, referenceId),
    };
  }),
];

export const courseReducer = createReducer(
  initialCoursesState,
  ...curriculumReducers,
  ...courseReducers,
  ...activityReducers,
  ...uploadReducers,
  ...feedbackReducers
);

export const courseFeature = createFeature({
  name: 'course',
  reducer: courseReducer,
});
