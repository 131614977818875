import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { InstructoGroupService, SharePricing, SharePricingService } from 'thkee-common';

let dummyData: SharePricing[] = [
  {
    id: 1,
    date_created: '2024-01-04T03:53:31.630Z',
    date_updated: '2024-01-04T03:53:31.630Z',
    code: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    share_types: 'STUDENT_REFERRAL',
    instructor_share: 60,
    platform_share: 40,
    affiliate_share: 100,
    thkee_credit: 100,
    group_name: 'Instructor (Default)',
    group: 0,
  },
  {
    id: 2,
    date_created: '2024-01-04T03:53:31.630Z',
    date_updated: '2024-01-04T03:53:31.630Z',
    code: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    share_types: 'STUDENT_REFERRAL',
    instructor_share: 90,
    platform_share: 10,
    affiliate_share: 100,
    thkee_credit: 100,
    group_name: 'Premium Instructor',
    group: 0,
  },
  {
    id: 3,
    date_created: '2024-01-04T03:53:31.630Z',
    date_updated: '2024-01-04T03:53:31.630Z',
    code: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    share_types: 'STUDENT_REFERRAL',
    instructor_share: 70,
    platform_share: 30,
    affiliate_share: 100,
    thkee_credit: 100,
    group_name: 'Partner Instructor',
    group: 0,
  },
];

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss'],
})
export class SharesStudentComponent implements OnInit {
  title: string = 'Shares';
  sharePricingData: SharePricing[] = dummyData;

  isEditSettings: boolean = false;
  form = new FormGroup({});
  model = { referal_discount_to: 'all', friend_discount_to: 'all' };
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid grid-cols-12 gap-16 border-b-2 border-b-neutral-100 mb-4',
      fieldGroup: [
        {
          className: 'col-span-5',
          template:
            '<h6 class="mb-2">Referal Discount</h6><div class="text-neutral-600"><p>Referal discount is a discount that a user can receive by referring a friend</p></div>',
        },
        {
          className: 'col-span-7',
          fieldGroup: [
            {
              type: 'input',
              key: 'referal_discount',
              props: {
                placeholder: 'Percentage (%)',
                hideLabel: true,
                disabled: true,
              },
            },
            {
              className: 'mb-3 font-semibold',
              template: '<div>Applies to:</div>',
            },
            {
              key: 'referal_discount_to',
              type: 'radio',
              templateOptions: {
                hideLabel: true,
                options: [{ value: 'specific_category', label: 'Specific Categories' }],
                wrapClass: 'mb-3',
                disabled: true,
              },
            },
            {
              key: 'referal_discount_specific',
              type: 'select',
              props: {
                hideLabel: true,
                options: [
                  { label: 'Iron Man', value: 'iron_man' },
                  { label: 'Captain America', value: 'captain_america' },
                  { label: 'Black Widow', value: 'black_widow' },
                  { label: 'Hulk', value: 'hulk' },
                  { label: 'Captain Marvel', value: 'captain_marvel' },
                ],
                placeholder: 'Choose',
                stayPlaceholder: true,
                multiple: true,
                wrapClass: 'mb-3',
                disabled: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return field.model?.referal_discount_to === 'all';
                },
              },
            },
            {
              key: 'referal_discount_to',
              type: 'radio',
              templateOptions: {
                hideLabel: true,
                options: [{ value: 'all', label: 'All Products' }],
                wrapClass: 'mb-4',
                disabled: true,
              },
            },
          ],
        },
      ],
    },
    {
      fieldGroupClassName: 'grid grid-cols-12 gap-16 border-b-2 border-b-neutral-100 mb-4',
      fieldGroup: [
        {
          className: 'col-span-5',
          template:
            '<h6 class="mb-2">Friend Discount</h6><div class="text-neutral-600"><p>The friend will also receive a discount for signing up through the referral link.</p></div>',
        },
        {
          className: 'col-span-7',
          fieldGroup: [
            {
              type: 'input',
              key: 'friend_discount',
              props: {
                placeholder: 'Percentage (%)',
                hideLabel: true,
                disabled: true,
              },
            },
            {
              className: 'mb-3 font-semibold',
              template: '<div>Applies to:</div>',
            },
            {
              key: 'friend_discount_to',
              type: 'radio',
              templateOptions: {
                hideLabel: true,
                options: [{ value: 'specific_category', label: 'Specific Categories' }],
                wrapClass: 'mb-3',
                disabled: true,
              },
            },
            {
              key: 'friend_discount_specific',
              type: 'select',
              props: {
                hideLabel: true,
                options: [
                  { label: 'Iron Man', value: 'iron_man' },
                  { label: 'Captain America', value: 'captain_america' },
                  { label: 'Black Widow', value: 'black_widow' },
                  { label: 'Hulk', value: 'hulk' },
                  { label: 'Captain Marvel', value: 'captain_marvel' },
                ],
                placeholder: 'Choose',
                stayPlaceholder: true,
                multiple: true,
                wrapClass: 'mb-3',
                disabled: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return field.model?.friend_discount_to === 'all';
                },
              },
            },
            {
              key: 'friend_discount_to',
              type: 'radio',
              templateOptions: {
                hideLabel: true,
                options: [{ value: 'all', label: 'All Products' }],
                wrapClass: 'mb-4',
                disabled: true,
              },
            },
          ],
        },
      ],
    },
    {
      fieldGroupClassName: 'grid grid-cols-12 gap-16 border-b-2 border-b-neutral-100 mb-4',
      fieldGroup: [
        {
          className: 'col-span-5',
          template:
            '<h6 class="mb-2">How many rewards can the referrer earn?</h6><div class="text-neutral-600"><p>Referrer will have a percentage of reward from the friend or referral for signing up through the referral link.</p></div>',
        },
        {
          className: 'col-span-7',
          fieldGroup: [
            {
              type: 'input',
              key: 'rewards',
              defaultValue: '',
              props: {
                placeholder: 'Percentage (%)',
                hideLabel: true,
                disabled: true,
              },
            },
          ],
        },
      ],
    },
    {
      fieldGroupClassName: 'grid grid-cols-12 gap-16 border-b-2 border-b-neutral-100 mb-4',
      fieldGroup: [
        {
          className: 'col-span-5',
          template:
            '<h6 class="mb-2">Headlines</h6><div class="text-neutral-600"><p>Headline wording for referral share offer</p></div>',
        },
        {
          className: 'col-span-7',
          fieldGroup: [
            {
              type: 'input',
              key: 'headlines',
              defaultValue: '',
              props: {
                placeholder: 'Input the headlines..',
                hideLabel: true,
                maxLength: 50,
                disabled: true,
              },
            },
          ],
        },
      ],
    },
    {
      fieldGroupClassName: 'grid grid-cols-12 gap-16 border-b-2 border-b-neutral-100 mb-4',
      fieldGroup: [
        {
          className: 'col-span-5',
          template:
            '<h6 class="mb-2">Description</h6><div class="text-neutral-600"><p>Description wording for referral share offer</p></div>',
        },
        {
          className: 'col-span-7',
          fieldGroup: [
            {
              type: 'textarea',
              key: 'description',
              defaultValue: '',
              props: {
                placeholder: 'Description',
                hideLabel: true,
                maxLength: 500,
                minHeight: '60px',
                maxHeight: '150px',
                disabled: true,
              },
            },
          ],
        },
      ],
    },
  ];

  constructor(
    private sharePricingService: SharePricingService,
    private instructorGroupService: InstructoGroupService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.sharePricingService.getAllSharePricing(),
      this.instructorGroupService.getAllInstructorGroup(),
    ]).subscribe(([sharePricing, group]) => {
      // Update group name of share pricing based on it's instructor group
      let shareData = sharePricing.map((item: any) => {
        if (group['results']) {
          const selectedGroup = _.find(group['results'], { id: item.group });
          if (selectedGroup) {
            return {
              ...item,
              group_name: selectedGroup.group_name,
            };
          }
        }
        return {
          ...item,
        };
      });
      shareData = _.filter(shareData, { share_types: 'STUDENT_REFERRAL' }); // Filter organics
      this.sharePricingData = _.sortBy(shareData, 'id'); // Sort by id
    });
  }

  editSettings(mode: boolean = false) {
    if (!mode) {
      console.log('Reset value here');
    }
    this.isEditSettings = mode;
    this.fields = this.toggleDisabledFields(this.fields, mode);
  }

  saveSettings() {
    if (this.form) {
      this.form.markAllAsTouched(); // Mark all fields as touched
      if (this.form.valid) {
        console.log('API Call Here', this.model);
        // Perform any action you need after successful form submission
        this.isEditSettings = false;
        this.fields = this.toggleDisabledFields(this.fields, false);
      } else {
        console.log('Form has validation errors.', this.model);
        // Handle validation errors if needed
      }
    }
  }

  toggleDisabledFields(config: any[], enable: boolean = true): any[] {
    const modifiedConfig = config.map((group) => {
      if (group.fieldGroup) {
        group.fieldGroup = this.toggleDisabledFields(group.fieldGroup, enable);
      } else if (
        (group.type === 'input' || group.type === 'radio' || group.type === 'select' || group.type === 'textarea') &&
        group.props
      ) {
        group.props.disabled = !enable;
      }
      return group;
    });

    return modifiedConfig;
  }
}
