<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4">
      <h3 class="text-2xl font-semibold text-neutral-800">Transactions</h3>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown(1)" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown[1] ? '':'hidden'"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto left-0">
               <div class="flex flex-col">
                  <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                        Channel
                     </div>
                     <app-filter-option #filterChannel key="channel" [options]="filterOptions.channel"
                        [defaultValue]="filterValues.channel" (filterEvent)="filterHander($event)">
                     </app-filter-option>
                  </div>
                  <!-- <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                        Coupon
                     </div>
                     <app-filter-option #filterCoupon key="coupon" [options]="filterOptions.coupon"
                        [defaultValue]="filterValues.coupon" (filterEvent)="filterHander($event)">
                     </app-filter-option>
                  </div> -->
                  <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                        Status
                     </div>
                     <app-filter-option #filterStatus key="transaction_type" [options]="filterOptions.transaction_type"
                        [defaultValue]="filterValues.transaction_type" (filterEvent)="filterHander($event)">
                     </app-filter-option>
                  </div>
                  <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                        Sale price
                     </div>
                     <app-filter-option #filterSalePrice [formType]="'input'" [keyObj]="salePriceKey"
                        (filterEvent)="filterHander($event)">
                     </app-filter-option>
                  </div>
                  <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                        Platform Earning
                     </div>
                     <app-filter-option #filterPlatformEarning [formType]="'input'" [keyObj]="platformEarningKey"
                        (filterEvent)="filterHander($event)">
                     </app-filter-option>
                  </div>
                  <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                     <button
                        (click)="clearFilter([filterChannel, filterSalePrice, filterStatus, filterSalePrice, filterPlatformEarning])"
                        type="button" class="btn btn-outline-dark border-neutral-600">
                        <span>Reset</span>
                     </button>
                     <button
                        (click)="submitFilter([filterChannel, filterSalePrice, filterStatus, filterSalePrice, filterPlatformEarning])"
                        class="btn btn-dark bg-neutral-600 text-white">
                        <span>Filter</span>
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <div class="input-combine flex">
            <div class="select2">
               <div class="relative">
                  <a (click)="dropdown(2)" type="button" aria-haspopup="listbox" aria-expanded="true"
                     aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
                     <div class="selected-item truncate">{{selectedItems.title}}</div>
                     <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
                  </a>
                  <ul [ngClass]="isDropdown[2]?'':'hidden'" tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                     aria-activedescendant="listbox-option-3" class="select-items absolute right-0 min-w-fit"
                     ng-reflect-ng-class="hidden">
                     <li *ngFor="let type of searchType" (click)="dropdown(2); selectItems(type)" id="listbox-option-0"
                        role="option" class="item">{{type.title}}</li>
                  </ul>
               </div>
            </div>
            <div class="input-group">
               <div class="group rounded-l-none">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input (keyup.enter)="search($event)" placeholder="Enter your full name" />
               </div>
            </div>
         </div>
      </div>
      <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
         <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
         <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
      </button>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex items-center gap-1">
               <h6>Transaction List</h6>
               <div class="tag tag-sm text-slate-600">{{totalTransactions}} Transactions</div>
            </div>
            <div class="actions relative">
               <button (click)="dropdown(3)" class="btn-neutral px-2">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button>
               <div [ngClass]="isDropdown[3] ? '':'hidden'"
                  class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
                  <div *ngFor="let col of columns" class="input-check w-48 my-2">
                     <input [checked]="col.visible" (change)="toggleColumn(col)" type="checkbox" id="default-checkbox">
                     <label for="default-checkbox">{{col.name}}</label>
                  </div>
               </div>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list relative">
               <ng-container *ngIf="isLoading">
                  <ng-container *ngTemplateOutlet="loader"></ng-container>
               </ng-container>
               <ng-container *ngIf="transactionLists.length === 0 && !isLoading; else isTransactions">
                  <span class="text-center text-2xl my-11 block">No Transaction Founded!</span>
               </ng-container>
               <ng-template #isTransactions>
                  <ng-container *ngTemplateOutlet="listTransactions; context: { items: transactionLists }">
                  </ng-container>
               </ng-template>
               <!-- Pagination -->
               <div class="z-30 relative">
                  <app-common-pagination (paginatorData)="paginat($event)" [type]="'dynamic'"></app-common-pagination>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Table -->
<ng-template #listTransactions let-items="items">
   <div class="datagrid-table relative overflow-x-auto min-h-[936px]">
      <table class="w-full text-left">
         <thead class="heading">
            <tr>
               <ng-container *ngFor="let column of columns">
                  <th *ngIf="column.visible" scope="col" class="">
                     <div class="col-name">{{column.name}}</div>
                  </th>
               </ng-container>
            </tr>
         </thead>
         <tbody>
            <tr class="hover:cursor-pointer" *ngFor="let transaction of items"
               [routerLink]="'/ecommerce/sales/transaction/'+transaction.id">
               <td *ngIf="columns[0].visible">
                  <span class="course-id">
                     <span class="text-blue-600 underline cursor-pointer">
                        #{{ transaction.id }}
                     </span>
                  </span>
               </td>
               <td *ngIf="columns[1].visible">
                  <div class="flex min-h-[69px] max-w-[170px] items-center">
                     <span (click)="showDrawer('product', $event)"
                        class="course-name line-clamp-3 text-blue-600 underline cursor-pointer">
                        {{ transaction.product.title }}
                     </span>
                  </div>
               </td>
               <td *ngIf="columns[2].visible" class="max-w-[170px]">
                  <span (click)="showDrawer('buyer', $event)"
                     class="whitespace-nowrap text-blue-600 underline cursor-pointer">
                     {{ transaction.buyer.fullname }}
                  </span>
               </td>
               <td *ngIf="columns[3].visible">
                  {{transaction.date_created | date}}
               </td>
               <td *ngIf="columns[4].visible" class="max-w-[170px]">
                  <div class="flex flex-col gap-1">
                     {{transaction.currency_symbol}}{{transaction.sale_price}}
                     <div *ngIf="transaction.status==='refunded'" class="flex flex-row gap-1 items-center">
                        <span class="w-[7px] h-[7px] bg-rose-600 rounded-full"></span>
                        <span class="text-neutral-500 font-normal text-sm">Refunded</span>
                     </div>
                  </div>
               </td>
               <td *ngIf="columns[5].visible">
                  <span class="course-price">{{ transaction?.channel_type }}</span>
               </td>
               <td *ngIf="columns[6].visible">
                  <span class="course-price">{{transaction.currency_symbol}}{{ transaction.platform_share }}</span>
               </td>
               <td *ngIf="columns[7].visible">
                  <span class="course-price">{{transaction.currency_symbol}}{{ transaction.instructor_share }}</span>
               </td>
               <td *ngIf="columns[8].visible">
                  <!-- <span class="course-price">Sale {{ transaction.coupon }}%</span> -->
               </td>
               <td *ngIf="columns[9].visible">
                  <div *ngIf="transaction.transaction_type === 'sale'"
                     class="tag tag-{{ getStatusTagColor(transaction.transaction_type) }} tag-sm" i18n>
                     Sale
                  </div>
                  <div *ngIf="transaction.transaction_type === 'refund'"
                     class="tag tag-{{ getStatusTagColor(transaction.transaction_type) }} tag-sm" i18n>
                     Refund
                  </div>
                  <div *ngIf="transaction.transaction_type === 'forced_refund'"
                     class="tag tag-{{ getStatusTagColor(transaction.transaction_type) }} tag-sm" i18n>
                     Forced Refund
                  </div>
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</ng-template>

<!-- Product drawer -->
<app-drawer #productDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h3 class="text-xl text-black font-semibold">#SAP MM Material Management</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to course detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="productDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>
   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-semibold underline text-base text-blue-600">Omar Sherif</h3>
            <h5 class="text-sm font-semibold text-neutral-600">Premium Instructor</h5>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Students Enrolled</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Students Enrolled"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">535</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Finished</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Finished"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Incomplete</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Incomplete"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">35</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100">
         <ng-container *ngFor="let tab of productTabLists">
            <button [ngClass]="tab.displayName==='General'?'active':''" class="tab-border">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Form -->
      <div class="py-6 flex flex-col gap-4">
         <div class="form">
            <form [formGroup]="productForm">
               <formly-form [form]="productForm" [fields]="productFormFields" [model]="productFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
</app-drawer>

<!-- Buyer drawer -->
<app-drawer #buyerDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
         <h3 class="text-xl text-black font-semibold">Faris Friansyah</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to student detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="buyerDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>
   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">ID Student</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">#325</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Purchased Course</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">25</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Total Spending</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">$500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Join Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">Nov 08, 2022, 2:03 PM</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100 overflow-x-auto">
         <ng-container *ngFor="let tab of studentTabLists">
            <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border min-w-max">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Details -->
      <div class="flex flex-col p-6 pb-0">
         <!-- Settings Tab -->
         <div class="section-label block my-3 w-full">
            <h5 class="text-2xl font-semibold text-black">Settings</h5>
         </div>
         <div class="flex gap-2.5 flex-row py-6 border-b border-b-neutral-100">
            <ng-container *ngFor="let tab of studentSettingsTabLists">
               <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border border-none">
                  {{tab.displayName}}
               </button>
            </ng-container>
         </div>

         <!-- Profile -->
         <app-student-profile></app-student-profile>

      </div>
   </ng-container>
</app-drawer>

<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>