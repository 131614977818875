<div class="course-overview p-4 pt-0">
   <h3 class="text-2xl font-semibold text-neutral-800 mb-3">{{instructorBalance?.instructor_name}}'s Balances</h3>
   <div class="page-heading mb-4 flex items-center justify-between">
      <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
         <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
         <span class="text-blue-600 font-semibold text-sm">Export CSV</span>
      </button>
   </div>

   <div class="flex flex-col gap-4">
      <div>
         <h3 class="text-2xl text-neutral-900 font-semibold p-4">Summary</h3>
         <div class="flex gap-4">
            <div *ngIf="!instructorBalance" class="w-full py-5" [thkLibProcessing]="true"></div>
            <ng-container *ngIf="instructorBalance">
               <app-metric-card class="w-full" *ngFor="let item of summaryItems" [data]="{ title: item.title, value: (instructorBalance[item.field] || 0) | currency:instructorBalance.currency_symbol }"></app-metric-card>
            </ng-container>
         </div>
      </div>

      <div class="flex gap-4 bg-slate-50 p-2 rounded-md">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral btn-sm">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="showingDropdown === 'filter' ? '' : 'hidden'"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <formly-form [form]="filterForm" [fields]="filterFormFields">
                  </formly-form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
               </div>
            </div>
         </div>
         <input type="text" class="input-field input-sm" placeholder="Eg: 12,34" [ngModel]="query?.search || ''" #searchInput (keyup.enter)="search(searchInput.value)">
      </div>
      <!-- Table -->
      <div class="courses-list w-full flex-1">
         <div class="card rounded-lg border border-neutral-100">
            <div class="card-subheader flex items-center justify-between px-6 py-5">
               <div class="flex items-center gap-1">
                  <h6>Recent Withdrawn</h6>
                  <div class="tag tag-sm">Requests</div>
               </div>
               <div class="actions flex flex-row gap-4">
                  <button class="btn-neutral px-3">
                     <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
                  </button>
               </div>
            </div>
            <div class="card-body">
               <div class="courses-list">
                  <!-- Table -->
                  <div class="datagrid-table relative overflow-x-auto">
                     <table class="w-full text-left">
                        <thead class="heading">
                           <tr>
                              <th scope="col" class="w-[150px]">
                                 <div class="col-name">Date</div>
                              </th>
                              <th scope="col" class="w-[150px]">
                                 <div class="col-name">Type</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Description</div>
                              </th>
                              <th scope="col" class="w-[70px]">
                                 <div class="col-name">Amount</div>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr class="hover:cursor-pointer" *ngFor="let data of withdrawsPagination?.results">
                              <td>
                                 <div class="course-id flex flex-col gap-1">
                                    <div class="">
                                       {{ data.date_created | date:'d MMMM y' }}
                                    </div>
                                    <div class="text-slate-500">{{ data.date_created | date:'h:mm:ss a' }}</div>
                                 </div>
                              </td>
                              <td>
                                 <span class="">
                                    {{ data.event }}
                                 </span>
                              </td>
                              <td>
                                 <a *ngIf="data.event === 'refund'" [routerLink]="['/', 'ecommerce', 'refund', data.payout_request_id || '']" target="_blank" rel="noopener noreferrer" class="text-primary underline">Requsting Payout - Payout Request ID #{{data.payout_request_id}}</a>
                                 <div *ngIf="data.event !== 'requested'">{{data.event}}</div>
                              </td>
                              <td
                                 [ngClass]="data.event === 'Withdrawal' || data.event === 'Payout Request'?'text-teal-600':'text-rose-600'">
                                 {{data.amount | currency:data.currency_symbol}}
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div class="p-4">
                     <thk-pagination *ngIf="withdrawsPagination?.count" (paginationChange)="paginate($event)" [page]="query?.page" [size]="query?.page_size || 5" [sizeChangable]="true" [total]="withdrawsPagination?.count || 0"></thk-pagination>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Transaction Table -->
   <div class="flex-3 w-full overflow-hidden card rounded-lg border border-neutral-100 mt-4">
      <thk-tabs [items]="tabItems"></thk-tabs>
      <router-outlet></router-outlet>
   </div>
</div>


