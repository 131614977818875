import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_COMMON_INSTRUCTOR_GROUP, InstructorGroup } from '../../models';
import { encodeURL } from '../../utils';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root',
})
export class InstructoGroupService {
  constructor(private readonly http: HttpService) {}

  getAllInstructorGroup(params = {} as object): Observable<InstructorGroup[]> {
    return this.http.get<InstructorGroup[]>(encodeURL(API_COMMON_INSTRUCTOR_GROUP, params));
  }

  //   getPricingTier(params = {} as PricingTierLoadRemovePayload): Observable<PricingTierData> {
  //     return this.http.get<PricingTierData>(API_PRICING_LIST + params?.id + '/');
  //   }

  //   addPricingTier(params = {} as object): Observable<any> {
  //     return this.http.post<any>(API_PRICING_LIST, params);
  //   }

  //   updatePricingTier(params = {} as PricingTierCreateUpdatePayload): Observable<any> {
  //     return this.http.patch<any>(API_PRICING_LIST + params?.id + '/', params);
  //   }

  //   removePricingTier(params = {} as PricingTierLoadRemovePayload): Observable<any> {
  //     return this.http.delete<any>(API_PRICING_LIST + params?.id + '/');
  //   }
}
