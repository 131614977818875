import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-form-user-instructor-group',
  templateUrl: './user-instructor-group.component.html',
  styleUrls: ['./user-instructor-group.component.scss'],
})
export class FormUserInstructorGroupComponent implements OnInit {
  @Input() model: any = {}; //TODO: Update model here
  @Output() init: EventEmitter<FormlyFieldConfig[]> = new EventEmitter<FormlyFieldConfig[]>();
  @Output() submitEvent: EventEmitter<Partial<any>> = new EventEmitter<Partial<any>>(); //TODO: Update model here

  formGroup = new FormGroup({});
  formGroupModel: any = {}; //TODO: Update model here
  formGroupFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid grid-cols-12 gap-4',
      fieldGroup: [
        {
          className: 'col-span-6',
          key: 'group_name',
          type: 'input',
          props: {
            label: 'Instructor Group Name',
            tips: 'This is a tip message for course title',
            placeholder: 'Enter your instructor group name',
            required: true,
            maxLength: 50,
          },
        },
      ],
    },
    {
      fieldGroupClassName: '',
      fieldGroup: [
        {
          template: '<label class="block mb-2 font-bold">Status</label>',
        },
        {
          key: 'is_active',
          type: 'toggle',
          props: {
            label: 'Active',
            tips: 'This is a tip message for course title',
          },
        },
      ],
    },
  ];

  showSubmit: boolean = true;

  constructor() {}

  ngOnInit(): void {
    if (this.model) {
      this.formGroupModel = this.model;
    }
    this.init.emit(this.formGroupFields);
  }

  submit() {
    this.submitEvent.emit({
      valid: this.formGroup.valid,
      values: this.formGroupModel,
    });
  }
}
