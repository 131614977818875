<ng-container
  *ngIf="{
    formTierFields: (formTierFields$ | async) ?? null
  } as vm"
>
  <div *ngIf="isLoading" class="text-center">Loading...</div>
  <form [formGroup]="formTier" (submit)="submit()" *ngIf="!isLoading">
    <formly-form [form]="formTier" [fields]="formTierFields" [model]="formTierModel" *ngIf="model"></formly-form>
    <button type="submit" class="btn-blue btn-lg" [disabled]="isLoading" *ngIf="showSubmit">Submit</button>
  </form>
</ng-container>
