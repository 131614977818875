<div class="flex h-screen w-screen bg-neutral-100">
  <div
    class="login-container custom-scrollbar m-auto max-h-full w-[500px] max-w-full overflow-auto rounded-2xl bg-white p-4 sm:p-9"
  >
    <div class="flex flex-col gap-6">
      <div>
        <div class="text-sm">Welcome to Admin</div>
        <h2>Login</h2>
      </div>

      <app-messages [messages]="messages" class="mb-4" *ngIf="messages"></app-messages>

      <div>
        <form [formGroup]="form" (submit)="onLogin()">
          <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
          <button type="submit" class="btn btn-blue btn-lg mt-4 w-full" [disabled]="loggingIn" translate>Login</button>
        </form>
      </div>
    </div>
  </div>
</div>
