import { Component, Input, OnInit } from '@angular/core';
import { AdminReadyRequestDetail, PayoutRequestStatus } from 'thkee-common';

@Component({
  selector: 'app-ready-request-detail',
  templateUrl: './ready-request-detail.component.html',
  styleUrls: ['./ready-request-detail.component.scss']
})
export class ReadyRequestDetailComponent implements OnInit {
  @Input() request!: AdminReadyRequestDetail;
  actionTitles: Record<PayoutRequestStatus, string> = {
    requested: 'Created by',
    ready: 'Approved by',
    deactivated: 'Deactivated by',
    processing: 'Processing by',
    failed: 'Fail',
    paid: 'Paid by',
  }

  constructor() { }

  ngOnInit(): void {
  }

}
