<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4 flex items-center justify-between">
      <h3 class="text-2xl text-neutral-800 font-semibold">Order #{{params['orderId']}}</h3>
   </div>

   <ng-container *ngIf="isLoading; else loaded">
      <ng-container *ngTemplateOutlet="loader"></ng-container>
   </ng-container>
   <ng-template #loaded>
      <!-- Order -->
      <!-- NOTE: If 3rd condisition apply -->
      <!-- <div class="courses-list mb-8">
         <div class="card rounded-lg border border-neutral-100">
            <div class="card-subheader flex items-center justify-between px-6 py-5">
               <div class="flex flex-col gap-3">
                  <div class="flex gap-x-2.5 items-center relative">
                     <h3 class="text-sm font-normat text-neutral-800">Order</h3>
                  </div>
                  <h6 class="text-xl font-semibold text-neutral-900 text-end">#{{params['orderId']}}</h6>
               </div>
            </div>
         </div>
      </div> -->
      <!-- Eligible -->
      <div class="courses-list">
         <div class="card rounded-lg border border-neutral-100">
            <!-- Card Header -->
            <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
               <div class="flex flex-row gap-14">
                  <div class="flex flex-col gap-3">
                     <div class="flex gap-x-2.5 items-center relative">
                        <h3 class="text-sm font-normat text-neutral-800">Transaction ID</h3>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID"
                           class="w-5 h-5">
                        </svg-icon>
                     </div>
                     <h6 class="text-xl font-semibold text-neutral-900">#{{refundDetails.transaction_id}}</h6>
                  </div>
                  <div class="flex flex-col gap-3">
                     <div class="flex gap-x-2.5 items-center relative">
                        <h3 class="text-sm font-normat text-neutral-800">Product</h3>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                           tooltip="The name of the product (course, coaching purchased by the student."
                           class="w-5 h-5">
                        </svg-icon>
                     </div>
                     <h6 class="text-xl font-semibold text-neutral-900">{{refundDetails.product.title}}
                        (<span (click)="showDrawer('product', $event)"
                           class="text-blue-600 cursor-pointer">#{{refundDetails.product.id}} </span>)
                     </h6>
                  </div>
               </div>
               <div class="flex flex-col gap-3">
                  <div class="flex gap-x-2.5 items-center relative">
                     <h3 class="text-sm font-normat text-neutral-800">Refund Requested?</h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Student requesting refund"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h6 class="text-xl font-semibold text-neutral-900 text-end">Yes</h6>
               </div>
            </div>

            <!-- Summery -->
            <div class="border-b border-b-neutral-100">
               <div class="flex">
                  <div class="flex flex-row gap-12 flex-wrap flex-1 p-6">
                     <div class="flex flex-col gap-1">
                        <div class="flex flex-row items-center gap-2 relative">
                           <h5 class="text-sm font-semibold text-neutral-600">Instructor</h5>
                           <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                              tooltip="Select a rejectional reason category" class="text-black w-5 h-5">
                           </svg-icon>
                        </div>
                        <h3 class="font-bold text-base text-neutral-900">{{refundDetails.instructor.fullname}}
                           ( <span (click)="showDrawer('instructor', $event)"
                              class="text-blue-600 cursor-pointer">#{{refundDetails.instructor.id}}
                           </span> )
                        </h3>
                     </div>
                     <div class="flex flex-col gap-1">
                        <div class="flex flex-row items-center gap-2 relative">
                           <h5 class="text-sm font-semibold text-neutral-600">Buyer</h5>
                           <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                              tooltip="The name of the student who purchased the course." class="text-black w-5 h-5">
                           </svg-icon>
                        </div>
                        <h3 class="font-bold text-base text-neutral-900">{{refundDetails.user.fullname}}
                           ( <span (click)="showDrawer('buyer', $event)"
                              class="text-blue-600 cursor-pointer">#{{refundDetails.user.id}}</span>
                           )
                        </h3>
                     </div>
                     <div class="flex flex-col gap-1">
                        <div class="flex flex-row items-center gap-2 relative">
                           <h5 class="text-sm font-semibold text-neutral-600">Date</h5>
                           <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                              tooltip=" The day of the week, day of the month, and time (your local time) at which the transaction took place"
                              class="text-black w-5 h-5">
                           </svg-icon>
                        </div>
                        <h3 class="font-bold text-base text-neutral-900">{{refundDetails.date | date}}</h3>
                     </div>
                     <div class="flex flex-col gap-1">
                        <div class="flex flex-row items-center gap-2 relative">
                           <h5 class="text-sm font-semibold text-neutral-600">Amount</h5>
                           <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                              tooltip="The actual amount paid by student" class="text-black w-5 h-5">
                           </svg-icon>
                        </div>
                        <h3 class="font-bold text-base text-neutral-900">
                           {{refundDetails.currency_symbol}}{{refundDetails.refund_amount}}</h3>
                     </div>
                     <div class="flex flex-col gap-1">
                        <div class="flex flex-row items-center gap-2 relative">
                           <h5 class="text-sm font-semibold text-neutral-600">Preferred Method</h5>
                           <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                              tooltip="The actual amount paid by student" class="text-black w-5 h-5">
                           </svg-icon>
                        </div>
                        <h3 class="font-bold text-base text-neutral-900">{{refundDetails.payment_mode.mode}}</h3>
                     </div>
                  </div>
                  <div class="w-96 p-6">
                     <!-- Progress bar -->
                     <div class="flex flex-col gap-6 mb-8">
                        <div class="flex justify-between items-center">
                           <h5 class="text-neutral-700 font-semibold text-sm">Course Progress:</h5>
                           <h4 class="text-neutral-700 font-semibold text-base">{{refundDetails.course_progress}}%</h4>
                        </div>
                        <div class="w-full h-2 rounded-3xl bg-neutral-200 overflow-hidden relative">
                           <span [ngStyle]="{'width': refundDetails.course_progress+'%'}"
                              class="h-full transition-all rounded-3xl bg-neutral-700 absolute left-0"></span>
                        </div>
                     </div>
                     <div class="flex justify-between items-center mb-1.5">
                        <h5 class="text-neutral-700 font-semibold text-sm">Time Remaining</h5>
                        <h4 class="text-neutral-600 font-bold text-sm">Eligibility</h4>
                     </div>
                     <div class="flex justify-between items-center mb-1.5">
                        <h4 class="text-neutral-900 font-bold text-base">{{refundDetails.remaining_days}} Days</h4>
                        <h4 *ngIf="refundDetails.eligiblity" class="text-teal-600 font-bold text-base">Eligible</h4>
                        <h4 *ngIf="!refundDetails.eligiblity" class="text-rose-600 font-bold text-base">Not Eligible
                        </h4>
                     </div>
                  </div>
               </div>

               <div class="flex flex-col gap-1 p-6 pt-3">
                  <div class="flex flex-row items-center gap-2 relative">
                     <h5 class="text-sm font-semibold text-neutral-600">Refund Reason</h5>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Refund Reason"
                        class="text-black w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="font-normal text-base text-neutral-900">{{refundDetails.reason}}</h3>
               </div>
            </div>
            <ng-container *ngIf="refundDetails.status === 'Pending'; else shoeStatus">
               <div *ngIf="refundDetails.eligiblity" class="flex justify-end gap-4 p-6">
                  <button (click)="refundConfirmed.open()" class="btn bg-teal-50 border-none">
                     <span class="text-teal-600">Approve</span>
                  </button>
                  <button (click)="rejectionConfirmed.open()" class="btn btn-outline-red">
                     <span>Reject</span>
                  </button>
               </div>

               <div *ngIf="!refundDetails.eligiblity" class="flex justify-end gap-4 p-6">
                  <button (click)="refundConfirmation()" class="btn bg-teal-600 border-none">
                     <span class="text-white">Force Approve</span>
                  </button>
                  <button (click)="rejectionConfirmed.open()" class="btn btn-outline-red">
                     <span>Reject</span>
                  </button>
               </div>
            </ng-container>
            <ng-template #shoeStatus>
               <div class="flex justify-end p-6">
                  <div class="tag tag-{{ getStatusTagColor(refundDetails.status) }} tag-sm">
                     <span>{{refundDetails.status}}</span>
                  </div>
               </div>
               <!-- <span class="text-teal-600">{{refundDetails.status}}</span> -->
            </ng-template>
            <!-- NOTE: If 3rd condisition apply -->
            <!-- <div class="flex justify-end p-6">
               <button class="btn bg-rose-600 border-none">
                  <span class="text-white">Force Refund</span>
               </button>
            </div> -->
         </div>
      </div>
   </ng-template>
</div>

<!-- Product drawer -->
<app-drawer #productDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h3 class="text-xl text-black font-semibold">#SAP MM Material Management</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to course detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="productDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>
   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-semibold underline text-base text-blue-600">Omar Sherif</h3>
            <h5 class="text-sm font-semibold text-neutral-600">Premium Instructor</h5>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Students Enrolled</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Students Enrolled"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">535</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Finished</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Finished"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Incomplete</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Incomplete"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">35</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100">
         <ng-container *ngFor="let tab of productTabLists">
            <button [ngClass]="tab.displayName==='General'?'active':''" class="tab-border">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Form -->
      <div class="py-6 flex flex-col gap-4">
         <div class="form">
            <form [formGroup]="productForm">
               <formly-form [form]="productForm" [fields]="productFormFields" [model]="productFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
</app-drawer>

<!-- Buyer drawer -->
<app-drawer #buyerDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
         <h3 class="text-xl text-black font-semibold">Faris Friansyah</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to student detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="buyerDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>
   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">ID Student</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">#325</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Purchased Course</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">25</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Total Spending</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">$500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Join Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">Nov 08, 2022, 2:03 PM</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100 overflow-x-auto">
         <ng-container *ngFor="let tab of studentTabLists">
            <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border min-w-max">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Details -->
      <div class="flex flex-col p-6 pb-0">
         <!-- Settings Tab -->
         <div class="section-label block my-3 w-full">
            <h5 class="text-2xl font-semibold text-black">Settings</h5>
         </div>
         <div class="flex gap-2.5 flex-row py-6 border-b border-b-neutral-100">
            <ng-container *ngFor="let tab of studentSettingsTabLists">
               <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border border-none">
                  {{tab.displayName}}
               </button>
            </ng-container>
         </div>

         <!-- Profile -->
         <app-student-profile></app-student-profile>

      </div>
   </ng-container>
</app-drawer>

<!-- Instructor drawer -->
<app-drawer #instructorDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
         <h3 class="text-xl text-black font-semibold">Alok Awasthi</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to instructor detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="instructorDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>
   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">ID Student</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">#325</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Purchased Course</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">25</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Total Spending</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">$500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Join Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">Nov 08, 2022, 2:03 PM</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100 overflow-x-auto">
         <ng-container *ngFor="let tab of instructorTabLists">
            <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border min-w-max">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Details -->
      <div class="flex flex-col p-6 pb-0">
         <!-- Settings Tab -->
         <div class="section-label block my-3 w-full">
            <h5 class="text-2xl font-semibold text-black">Settings</h5>
         </div>
         <div class="flex gap-2.5 flex-row py-6 border-b border-b-neutral-100">
            <ng-container *ngFor="let tab of instructorSettingsTabLists">
               <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border border-none">
                  {{tab.displayName}}
               </button>
            </ng-container>
         </div>

         <!-- Profile -->
         <app-student-profile [account_type]="'instructor'"></app-student-profile>

      </div>
   </ng-container>
</app-drawer>

<!-- Refund confirm modal -->
<common-modal #refundConfirmed customClass="sm:w-full sm:max-w-[935px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl">Approval</h1>
         </div>
         <button (click)="refundConfirmed.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div *ngIf="refundDetails"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Transaction ID</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">#{{refundDetails.transaction_id}}</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Product</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Product" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">{{refundDetails.product.title}}
                  (<span (click)="showDrawer('product', $event)"
                     class="text-blue-600 cursor-pointer">#{{refundDetails.product.id}} </span>)
               </h6>
            </div>
         </div>
      </div>
      <div *ngIf="refundDetails"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14 w-full">
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Instructor</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Select a rejectional reason category" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h3 class="font-bold text-base text-neutral-900">{{refundDetails.instructor.fullname}}
                  ( <span (click)="showDrawer('instructor', $event)"
                     class="text-blue-600 cursor-pointer">#{{refundDetails.instructor.id}}
                  </span> )
               </h3>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Buyer</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The name of the student who purchased the course." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h3 class="font-bold text-base text-neutral-900">{{refundDetails.user.fullname}}
                  ( <span (click)="showDrawer('buyer', $event)"
                     class="text-blue-600 cursor-pointer">#{{refundDetails.user.id}}</span>
                  )
               </h3>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Date</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip=" The day of the week, day of the month, and time (your local time) at which the transaction took place"
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h3 class="font-bold text-base text-neutral-900">{{refundDetails.date | date}}</h3>
            </div>
         </div>
      </div>
      <div *ngIf="refundDetails"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Prefered Method</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The actual amount paid by student" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h3 class="font-bold text-base text-neutral-900">{{refundDetails.payment_mode.mode}}</h3>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Amount</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The actual amount paid by student" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h3 class="font-bold text-base text-neutral-900">
                  {{refundDetails.currency_symbol}}{{refundDetails.refund_amount}}</h3>
            </div>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="refundConfirmed.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
            </button>
            <button (click)="confirmRefund()" class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold">Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- Rejection confirm modal -->
<common-modal #rejectionConfirmed customClass="sm:w-full sm:max-w-[788px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl">Rejectional Reason</h1>
         </div>
         <button (click)="rejectionConfirmed.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div *ngIf="refundDetails"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Transaction ID</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">#{{refundDetails.transaction_id}}</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Product</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Product" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">{{refundDetails.product.title}}
                  (<span (click)="showDrawer('product', $event)"
                     class="text-blue-600 cursor-pointer">#{{refundDetails.product.id}} </span>)
               </h6>
            </div>
         </div>
      </div>
      <div class="px-6 py-5 border-b border-b-neutral-100">
         <div class="form">
            <form [formGroup]="rejectReasonForm">
               <formly-form [form]="rejectReasonForm" [fields]="rejectReasonFormFields" [model]="rejectReasonFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="rejectionConfirmed.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold" i18n>Cancel</span>
            </button>
            <button (click)="confirmRejection()" [disabled]="rejectReasonForm.invalid"
               class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold" i18n>Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>