<!-- Profile -->
<div class="section-label block mt-3 mb-6 w-full">
   <h5 class="text-2xl font-semibold text-black mt-6">Profile</h5>
</div>
<div class="pb-6">
   <img src="../../../../assets/images/profile-img.png" class="rounded-full w-36 h-36" alt="">
</div>
<!-- Form -->
<div class="form">
   <form [formGroup]="accountSettingsForm">
      <formly-form [form]="accountSettingsForm" [fields]="accountSettingsFormFields" [model]="accountSettingsFormModel">
      </formly-form>
   </form>
</div>

<!-- Activity -->
<div class="border-b-2 border-b-neutral-100 py-4">
   <ng-container *ngIf="account_type==='student'">
      <h5 class="text-2xl font-semibold text-black mb-4">Recent activity</h5>
      <div class="pb-6 ps-6 ms-2 border-l border-dashed border-l-neutral-900 relative">
         <span class="absolute w-2 h-2 rounded-full bg-neutral-900 left-[-4px] top-0"></span>
         <span class="absolute w-2 h-2 rounded-full bg-neutral-900 left-[-4px] bottom-[-4px]"></span>
         <p class="text-sm text-neutral-900 font-normal mb-1">User changed value of Last Name from Faris to
            Friansyah
         </p>
         <p class="text-xs text-neutral-700 font-normal mb-1">Nov 12, 2022, 2:03 PM</p>
      </div>
      <div class="pb-6 ps-6 ms-2 border-l border-dashed border-l-neutral-900 relative">
         <span class="absolute w-2 h-2 rounded-full bg-neutral-900 left-[-4px] bottom-[-4px]"></span>
         <p class="text-sm text-neutral-900 font-normal mb-1">User changed value of Gender from Female to Male</p>
         <p class="text-xs text-neutral-700 font-normal mb-1">Nov 09, 2022, 5:06 PM</p>
      </div>
      <div class="pb-6 ps-6 ms-2">
         <p class="text-sm text-neutral-900 font-normal mb-1">User was created</p>
         <p class="text-xs text-neutral-700 font-normal mb-1">Nov 08, 2022, 2:03 PM</p>
      </div>
   </ng-container>
</div>

<!-- Account Settings -->
<div class="py-6">
   <h5 class="text-2xl font-semibold text-black mb-4">Account Settings</h5>
   <ng-container *ngIf="account_type==='instructor'">
      <div class="px-4 py-6 mb-4 border-b-2 border-b-neutral-100">
         <div class="flex flex-row items-center gap-2.5">
            <h3 class="text-lg text-black font-bold">Payout Activation Status</h3>
            <span class="w-2.5 h-2.5 rounded-full bg-neutral-300"></span>
            <span class="px-2 py-[2px] rounded-2xl bg-teal-600 text-xs font-normal text-white">Active</span>
         </div>
         <p class="text-base text-neutral-600 font-normal mb-0">Disable payout activation will prevent Instructor
            receive
            their payout because have an any problem</p>
      </div>
      <div class="px-4 py-6 mb-4 border-b-2 border-b-neutral-100">
         <div class="flex flex-row items-center gap-2.5">
            <h3 class="text-lg text-black font-bold">Co-Instructor Invitation</h3>
            <span class="w-2.5 h-2.5 rounded-full bg-neutral-300"></span>
            <span class="px-2 py-[2px] rounded-2xl bg-teal-600 text-xs font-normal text-white">Active</span>
         </div>
         <p class="text-base text-neutral-600 font-normal mb-0">Disable Co-instructor invitation will prevent Instructor
            send invitation to other instructor to be Co-Instructor</p>
      </div>
   </ng-container>
   <div class="px-4 py-6 rounded-2xl bg-rose-50">
      <div class="flex flex-row items-center gap-2.5">
         <h3 class="text-lg text-black font-bold">Account Access</h3>
         <span class="w-2.5 h-2.5 rounded-full bg-neutral-300"></span>
         <span class="px-2 py-[2px] rounded-2xl bg-teal-600 text-xs font-normal text-white">Active</span>
      </div>
      <p class="text-base text-neutral-600 font-normal mb-0">Disable instructor account will prevent Instructor
         access to their account</p>
   </div>
</div>