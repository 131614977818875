import { Route, Routes } from '@angular/router';

import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthenticationGuard } from '@shared/services';
import { ShellComponent } from './shell.component';

/**
 * Provides helper methods to create routes.
 */
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
    };
  }

  static childCoursesRoutes(routes: Routes): Route {
    return {
      path: 'courses',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      data: { title: marker('Courses'), breadcrumb: 'Courses' },
    };
  }

  static childCourseRoutes(routes: Routes): Route {
    return {
      path: 'course',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      data: { title: marker('Courses'), breadcrumb: 'Courses' },
    };
  }

  static childPriceRoutes(routes: Routes): Route {
    return {
      path: 'price-management',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      data: { title: marker('Price Management'), breadcrumb: 'Price Management' },
    };
  }

  static childECommerceRoutes(routes: Routes): Route {
    return {
      path: 'e-commerce',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      data: { title: marker('E-Commerce'), breadcrumb: 'E-Commerce' },
    };
  }

  static childUserRoutes(routes: Routes): Route {
    return {
      path: 'users',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      data: { title: marker('Users'), breadcrumb: 'Users' },
    };
  }

  static childCommerceRoutes(routes: Routes): Route {
    return {
      path: 'ecommerce',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      data: { title: marker('E-Commerce'), breadcrumb: 'E-Commerce' },
    };
  }

  static childSettingsRoutes(routes: Routes): Route {
    return {
      path: 'settings',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      data: { title: marker('Settings'), breadcrumb: 'Settings' },
    };
  }
}
