import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PricingCountryState } from './country-state.model';

export const selectPricingCountryState = createFeatureSelector<PricingCountryState>('pricing-country');

export const selectPricingCountry = createSelector(selectPricingCountryState, (entities) => entities);

export const selectPricingCountryList = createSelector(
  selectPricingCountryState,
  (entities) => entities.pricingCountryList
);

export const selectLoadedPricingCountryData = createSelector(
  selectPricingCountryState,
  (entities) => entities.pricingCountryData
);

export const selectCountryCRUDStatus = createSelector(selectPricingCountryState, (entities) => entities.status);
