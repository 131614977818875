import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

import * as _ from 'lodash';
import { LANGUAGES } from 'thkee-common';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss'],
})
export class StudentProfileComponent implements OnInit {
  @Input() account_type: string = 'student';
  // Create product form
  accountSettingsForm = new FormGroup({});
  accountSettingsFormModel: any = {};
  accountSettingsFormFields: FormlyFieldConfig[] = [];

  constructor() {}

  ngOnInit(): void {
    if (this.account_type === 'student') {
      this.accountSettingsFormFields = [
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6 mb-3',
          fieldGroup: [
            {
              key: 'first_name',
              type: 'input',
              defaultValue: 'Faris',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'First Name',
                placeholder: '',
                disabled: true,
                tips: 'First Name',
                required: true,
              },
              validation: {
                messages: {
                  minLength: 'First Name is required.',
                },
              },
              expressions: {},
            },
            {
              key: 'last_name',
              type: 'input',
              defaultValue: 'Friansyah',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Last Name',
                placeholder: '',
                disabled: true,
                tips: 'Last Name',
                required: true,
              },
              validation: {
                messages: {
                  minLength: 'Last Name is required.',
                },
              },
              expressions: {},
            },
            {
              key: 'headline',
              type: 'input',
              defaultValue: 'Senior Product Manager',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Headline',
                placeholder: '',
                disabled: true,
                tips: 'Headline',
                required: true,
              },
              validation: {
                messages: {
                  minLength: 'Headline is required.',
                },
              },
              expressions: {},
            },
            {
              key: 'language',
              type: 'select',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Language',
                placeholder: 'Select language',
                stylish: true,
                options: _.sortBy(
                  _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                  (lang) => lang.label
                ),
                disabled: true,
                tips: 'This is a tip message for course title',
                updated: {
                  by: 'Instructor',
                  date: 'Aug 03, 2022 10:24:00 AM',
                },
                // openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
              },
              expressions: {},
            },
          ],
        },
        {
          key: 'biography',
          type: 'textarea',
          defaultValue: 'Lorem Ipsum is simply dummy text of the printing and ',
          props: {
            label: 'Biography',
            minLength: 100,
            maxLength: 1000,
            placeholder: 'biography',
            minHeight: '170px',
            maxHeight: '300px',
            disabled: true,
            tips: 'Biography',
          },
          expressions: {},
        },
        {
          className: 'border-t-2 border-t-neutral-100 mt-6 py-6 w-full',
          template: '<h5 class="text-2xl font-semibold text-black">Socials</h5>',
        },
        {
          key: 'website',
          type: 'input',
          className: 'mb-6',
          defaultValue: 'www.farisfriansyah.com',
          props: {
            wrapAppendClass: ['border-none', '!mb-0'],
            label: 'Website',
            placeholder: '',
            disabled: true,
            tips: 'Website',
          },
          validation: {},
          expressions: {},
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6 pb-3 border-b-2 border-b-neutral-100',
          fieldGroup: [
            {
              key: 'twitter',
              type: 'input',
              defaultValue: '@farisfriansyah',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Twitter',
                placeholder: '',
                disabled: true,
                tips: 'Twitter',
              },
              validation: {},
              expressions: {},
            },
            {
              key: 'facebook',
              type: 'input',
              defaultValue: 'farisfriansyah',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Facebook',
                placeholder: '',
                disabled: true,
                tips: 'Facebook',
              },
              validation: {},
              expressions: {},
            },
            {
              key: 'linkedIn',
              type: 'input',
              defaultValue: 'farisfriansyah',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'LinkedIn',
                placeholder: '',
                disabled: true,
                tips: 'LinkedIn',
              },
              validation: {},
              expressions: {},
            },
            {
              key: 'youtube',
              type: 'input',
              defaultValue: 'farisfriansyah',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'YouTube',
                placeholder: '',
                disabled: true,
                tips: 'YouTube',
              },
              validation: {},
              expressions: {},
            },
          ],
        },
      ];
    } else if (this.account_type === 'instructor') {
      this.accountSettingsFormFields = [
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6 mb-3',
          fieldGroup: [
            {
              key: 'first_name',
              type: 'input',
              defaultValue: 'Faris',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'First Name',
                placeholder: '',
                disabled: true,
                tips: 'First Name',
                required: true,
              },
              validation: {
                messages: {
                  minLength: 'First Name is required.',
                },
              },
              expressions: {},
            },
            {
              key: 'last_name',
              type: 'input',
              defaultValue: 'Friansyah',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Last Name',
                placeholder: '',
                disabled: true,
                tips: 'Last Name',
                required: true,
              },
              validation: {
                messages: {
                  minLength: 'Last Name is required.',
                },
              },
              expressions: {},
            },
            {
              key: 'headline',
              type: 'input',
              defaultValue: 'Senior Product Manager',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Headline',
                placeholder: '',
                disabled: true,
                tips: 'Headline',
                required: true,
              },
              validation: {
                messages: {
                  minLength: 'Headline is required.',
                },
              },
              expressions: {},
            },
            {
              key: 'language',
              type: 'select',
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Language',
                placeholder: 'Select language',
                stylish: true,
                options: _.sortBy(
                  _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                  (lang) => lang.label
                ),
                disabled: true,
                tips: 'This is a tip message for course title',
                updated: {
                  by: 'Instructor',
                  date: 'Aug 03, 2022 10:24:00 AM',
                },
                // openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
              },
              expressions: {},
            },
          ],
        },
        {
          key: 'biography',
          type: 'textarea',
          defaultValue: 'Lorem Ipsum is simply dummy text of the printing and ',
          props: {
            label: 'Biography',
            minLength: 100,
            maxLength: 1000,
            placeholder: 'biography',
            minHeight: '170px',
            maxHeight: '300px',
            disabled: true,
            tips: 'Biography',
          },
          expressions: {},
        },
      ];
    } else {
    }
  }
}
