import { FilterOptionsValues } from './constants';

interface User {
  id: string;
  fullname: string;
}
interface Instructor {
  id: string;
  fullname: string;
}
export interface OrderItems {
  id: string;
  user: User;
  gross_amount: string;
  date_created: string;
  status: string;
  currency_code: string;
  order_id: string;
  currency_symbol: string;
  products: number;
}

export interface OrderResponse {
  count: number;
  next?: string;
  previous?: string;
  results: OrderItems[];
}

// Filter
export interface FilterOptionsOrder {
  status: FilterOptionsValues[];
}

export interface FilterEventOrder {
  action: string;
  data: object;
}

// Params
export interface OrderQueryParams {
  page?: number;
  page_size?: number;
  search?: string;
  order_id?: string;
}

// Table column
export const ORDER_TABLE_COLUMN = [
  { name: 'Order ID', visible: true },
  { name: 'Orders Transaction ID', visible: true },
  { name: 'Buyer', visible: true },
  { name: 'Date', visible: true },
  { name: 'Transactions (Items)', visible: true },
  { name: 'Sale Price', visible: true },
  { name: 'Status', visible: true },
];

// Table seach
export const ORDER_TABLE_SEARCH = [
  {
    id: 0,
    title: 'Transaction ID',
    value: 'id',
  },
  {
    id: 1,
    title: 'Date',
    value: 'date',
  },
  {
    id: 2,
    title: 'User',
    value: 'user',
  },
  {
    id: 3,
    title: 'Order Id',
    value: 'order_id',
  },
];

// Order details
export interface OrderDetails {
  id: string;
  user: User;
  status: string;
  instructor_earnings: number;
  platform_earnings: number;
  payment_method: any; // TODO: Will be change, while confirmed the type
  currency_code: string;
  currency_symbol: string;
  payment_gateway: string;
  products: number;
  gross_amount: string;
}

export interface OrderDetailsResponse {
  count: number;
  next?: string;
  previous?: string;
  results: OrderDetailsItems[];
}

interface Product {
  id: string;
  title: string;
}
export interface OrderDetailsItems {
  id: string;
  user: User;
  product: Product;
  currency_symbol: string;
  currency_code: string;
  gross_amount: string;
  transaction_type: string;
  instructor_share: string;
  platform_share: string;
  related_transaction: null | any;
  channel_type: string;
  date_created: string;
  transaction_id: string;
  checked: boolean;
  sale_price?: string;
  channel: Channel;
  status: string;
  refund_status: string;
  refunded: boolean;
  instructor: Instructor;
}

interface Channel {
  id: number;
  code: string;
  group: number;
  group_name: string;
  share_types: string;
  date_created: string;
  date_updated: string;
  thkee_credit: number;
  platform_share: number;
  affiliate_share: number;
  instructor_share: number;
}

// Table column
export const ORDER_DETAILS_TABLE_COLUMN = [
  { name: 'Transaction ID', visible: true },
  { name: 'Product', visible: true },
  { name: 'Instructor', visible: true },
  { name: 'Date', visible: true },
  { name: 'Channel', visible: true },
  { name: 'Sale Price', visible: true },
  { name: 'Instructor Earning', visible: true },
  { name: 'Platform Earning', visible: true },
  { name: 'Status', visible: true },
  { name: 'Action', visible: true },
];
