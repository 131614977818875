import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import {
  CountryCRUDStatus,
  PricingCountryActions,
  PricingCountryCreateUpdatePayload,
  PricingCountryService,
  UntilDestroy,
  selectCountryCRUDStatus,
  selectLoadedPricingCountryData,
  untilDestroyed,
} from '@shared';
import { Observable, combineLatest } from 'rxjs';
import { ConstantCountryOption, PricingCountry, ToastService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class PriceCountryEditComponent implements OnInit {
  id = '';
  priceCountry$: Observable<PricingCountry> = this.store.select(selectLoadedPricingCountryData);
  status$: Observable<CountryCRUDStatus> = this.store.select(selectCountryCRUDStatus);
  countriesOptions$: Observable<ConstantCountryOption[]> = new Observable();
  priceCountryDataDefault: PricingCountry = {
    id: '',
    name: '',
    two_letter_iso_code: '',
    three_letter_iso_code: '',
    numeric_iso_code: '',
    subject_to_vat: false,
    allow_billing: false,
    currency_code: '',
    sort_number: 0,
  };
  hasData = false;
  formData: any = {};
  pringCountryUpdatePayload!: PricingCountryCreateUpdatePayload;
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private pricingCountryService: PricingCountryService,
    private router: Router,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.countriesOptions$ = this.pricingCountryService.getConstantCountries();
    this.store.dispatch(PricingCountryActions.getPricingCountry({ id: this.id }));

    combineLatest([this.countriesOptions$, this.priceCountry$, this.status$])
      .pipe(untilDestroyed(this))
      .subscribe(([countries, country, status]) => {
        this.formData = country;
        this.hasData = true;

        // Status handler
        if (status === CountryCRUDStatus.Success) {
          this.toastService.message({
            message: `Country updated successfully`,
          });
          this.router.navigate(['/price-management/countries']); // Navigate to the countries list
        }

        if (status === CountryCRUDStatus.Failed) {
          this.toastService.error({
            message: `Something went wrong. Please try again. `,
          });
        }
      });
  }

  onSubmit(event: any) {
    let values = event?.values;
    this.pringCountryUpdatePayload = values;
    this.store.dispatch(PricingCountryActions.updatePricingCountry(this.pringCountryUpdatePayload));
  }
}
