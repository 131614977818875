<ng-container header>
  <div class="modal-header flex items-center border-b border-neutral-50 bg-neutral-50 px-6 py-4">
    <div class="text-lg font-bold">{{ isEditQuiz ? (isQA ? 'Multiple Choice' : 'Edit Quiz') : 'Add Quiz' }}</div>
  </div>
</ng-container>

<ng-container>
  <ng-container *ngIf="!isQA">
    <ng-container *ngTemplateOutlet="quiz"></ng-container>
  </ng-container>
  <ng-container *ngIf="isQA">
    <ng-container *ngTemplateOutlet="qa"></ng-container>
  </ng-container>
</ng-container>

<ng-template #quiz>
  <div class="p-6">
    <form [formGroup]="form" #formQuiz="ngForm">
      <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
    </form>
    <div class="quiz-items flex flex-col gap-3" *ngIf="isEditQuiz">
      <div *ngFor="let qa of qaData"
        class="quiz-item flex justify-between rounded-lg bg-neutral-50 px-4 py-3 text-sm hover:bg-neutral-100">
        <div>{{ qa.question }}</div>
        <div class="options flex gap-3">
          <button (click)="qaID = qa.id; initQA()" class="text-neutral-500 hover:text-neutral-800">
            <svg-icon src="@assets/images/icons/pencil-square.svg" class="block w-4"></svg-icon>
          </button>
          <button (click)="deleteQuestionModal.open()" class="text-neutral-500 hover:text-rose-500">
            <svg-icon src="@assets/images/icons/trash.svg" class="block w-4"></svg-icon>
          </button>
          <app-common-modal-confirm #deleteQuestionModal title="Delete Question"
            message="Are you sure you want to delete this question?" confirmText="Delete Question"
            (confirm)="deleteQA(qa.id)"></app-common-modal-confirm>
        </div>
      </div>
      <div>
        <button class="btn btn-sm border-none" (click)="isQA = !isQA; qaID = ''">
          <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
          <span>Add Question</span>
        </button>
      </div>
    </div>
  </div>
  <div class="flex justify-end gap-2 border-t border-neutral-50 px-6 py-4">
    <button class="btn btn-neutral" (click)="cancel()">
      <span>Cancel</span>
    </button>
    <button *ngIf="!isEditQuiz" class="btn btn-teal" (click)="submitQuiz('new')">
      <span>Add Quiz</span>
    </button>
    <button *ngIf="isEditQuiz" class="btn btn-teal" (click)="submitQuiz('save')">
      <span>Save</span>
    </button>
  </div>
</ng-template>

<ng-template #qa>
  <div class="p-6">
    <form [formGroup]="formQA" (submit)="submitQA()" #fformQA="ngForm">
      <formly-form [form]="formQA" [fields]="fieldsQA" [model]="modelQA" [options]="optionsQA"></formly-form>
    </form>
  </div>
  <div class="flex justify-end gap-2 border-t border-neutral-200 px-6 py-4">
    <button class="btn btn-neutral" (click)="cancelQA()">
      <span>Cancel</span>
    </button>
    <button class="btn btn-teal" (click)="submitQA()">
      <span>Save</span>
    </button>
  </div>
</ng-template>