import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ActivationDetailsComponent } from './activation-details/activation-details.component';
import { PayoutActivationComponent } from './payout-activation.component';

export * from './activation-details/activation-details.component';
export * from './payout-activation.component';

export const Components = [PayoutActivationComponent, ActivationDetailsComponent];

export const Routing = [
  {
    path: 'payout-activation',
    component: PayoutActivationComponent,
    data: { title: marker('Payout Activation'), breadcrumb: 'Payout Activation' },
  },
  {
    path: 'payout-activation/details/:instructorId',
    component: ActivationDetailsComponent,
    data: { title: marker('Payout Activation Details'), breadcrumb: 'Payout Activation Details' },
  },
];
