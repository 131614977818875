import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  CountryCRUDStatus,
  PricingCountryActions,
  PricingCountryPayload,
  selectCountryCRUDStatus,
  selectPricingCountryList,
} from '@shared';

import { Observable } from 'rxjs';

import { ModalService, PricingCountry, PricingCountryData, ToastService } from 'thkee-common';

import { UntilDestroy } from '@shared';

@UntilDestroy()
@Component({
  selector: 'app-price-countries',
  templateUrl: './price-countries.component.html',
  styleUrls: ['./price-countries.component.scss'],
})
export class PriceCountriesComponent implements OnInit {
  title: string = 'Countries';

  pricingCountryList$: Observable<PricingCountryData[]> = this.store.select(selectPricingCountryList);
  priceTierData: Partial<PricingCountry>[] = [];

  pricingCountryList: any = [];
  pricingCountryPayload!: PricingCountryPayload;

  status$: Observable<CountryCRUDStatus> = this.store.select(selectCountryCRUDStatus);

  constructor(
    private store: Store,
    private readonly modalService: ModalService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.pricingCountryPayload = {
      page: 1,
      page_size: 10,
    };

    this.store.dispatch(PricingCountryActions.loadPricingCountryList(this.pricingCountryPayload));
    this.store.dispatch(PricingCountryActions.clearCountryCRUDStatus());
  }

  remove(data: PricingCountryData) {
    this.store.dispatch(PricingCountryActions.removePricingCountry({ id: data?.id }));

    this.status$.subscribe((status) => {
      console.log('Status: ', status);
      if (status === CountryCRUDStatus.Success) {
        this.toastService.message({
          message: `Country removed successfully`,
        });
      }

      if (status === CountryCRUDStatus.Failed) {
        this.toastService.error({
          message: `Something went wrong. Please try again.`,
        });
      }
    });

    console.log('Delete this: ', { id: data?.id });
  }

  deleteConfirmation(data: PricingCountryData) {
    this.modalService.confirm({
      title: 'Delete Confirmation',
      message: `Are you sure?`,
      onConfirm: () => {
        // Con confirm action here
        this.remove(data);
      },
    });
  }
}
