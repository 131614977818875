import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export * from './hold-transaction.component';

import { HoldTransactionComponent } from './hold-transaction.component';

export const Components = [HoldTransactionComponent];

export const Routing = [
  {
    path: 'payout/hold-transactions',
    component: HoldTransactionComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Hold Transactions' },
  },
];
