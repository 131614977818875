import { createAction, props } from '@ngrx/store';
import { FetchExchangeRatePayload } from './exchange-rate-state.model';
import { PricingCurrencyCreateUpdatePayload } from '../currency';

export const ExchangRateActions = {
  clearExchangeRateCRUDStatus: createAction('[EchangeRates] Clear the Echange-Rates CRUD status'),

  // loadExchangeRatesList: createAction('[EchangeRates] Process list', props<FetchExchangeRatePayload>()),
  // loadExchangeRatesListSuccess: createAction('[EchangeRates]Load Echange-Rates list success', props<any>()),
  // loadExchangeRatesListFail: createAction('[EchangeRates] Load Echange-Rates list fail', props<{ error: unknown }>()),


  loadExchangeRatesList: createAction('[EchangeRate] Process list', props<FetchExchangeRatePayload>()),
  loadExchangeRatesListSuccess: createAction('[EchangeRate]Load exchange-rate list success', props<any>()),
  loadExchangeRatesListFail: createAction(
    '[EchangeRate] Load exchange-rate list fail',
    props<{ error: unknown }>()
  ),

  updateCurrencyRate: createAction(
    '[EchangeRate] Update Process Currency',
    props<PricingCurrencyCreateUpdatePayload>()
  ),
  updateCurrencyRateSuccess: createAction('[EchangeRate] Update Process Currency Success', props<any>()),
  updateCurrencyRateFail: createAction(
    '[EchangeRate] Update exchange-rates list fail',
    props<{ error: unknown }>()
  ),
};
