import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalComponent, selectUserId, UntilDestroy, untilDestroyed } from '@shared';
import { AuthenticationService } from '@shared/services';
import { PayoutService } from '@shared/services/payout/payout.service';
import { catchError, of } from 'rxjs';
import { AdminPayoutRequest, FileProps, generateUuid, ToastService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-mark-payout-as-paid',
  templateUrl: './mark-payout-as-paid.component.html',
  styleUrls: ['./mark-payout-as-paid.component.scss']
})
export class MarkPayoutAsPaidComponent implements OnInit {
  @Output() response = new EventEmitter<boolean>();

  @ViewChild('modal') modal!: ModalComponent;
  payout?: AdminPayoutRequest;
  markAsPaidForm = new FormGroup({});
  markAsPaidFormFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'textarea',
      props: {
        label: 'Description',
        labelClass: 'font-semibold text-black text-lg',
        placeholder: 'Enter a description',
        required: true,
        rows: 3,
        minHeight: '110px',
        maxHeight: '100%',
      },
      validation: {
        messages: {
          required: $localize`Reason is required!`,
        },
      },
    },
    {
      key: 'reference_id',
      type: 'input',
      props: {
        label: 'Reference ID',
        labelClass: 'font-semibold text-black text-lg',
        required: true,
        minHeight: '110px',
        maxHeight: '100%',
      },
      validation: {
        messages: {
          required: $localize`Reason is required!`,
        },
      },
    },
    {
      key: 'receipt',
      type: 'file',
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Upload Receipt',
        placeholder: 'Supported files are .jpg, .jpeg, .png or .pdf.',
        preview: true,
        previewType: 'image',
        allowedTypes: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
        uploadType: 'dashboard',
        disabled: false,
        metadataFn: () => {
          return {
            library: generateUuid(),
            content_type: 'payout_receipt',
            payout: this.payout?.id,
            user: this.userId,
            public: false
          }
        }
      } as FileProps,
      validation: {
        messages: {
          required: $localize`Reason is required!`,
        },
      },
    },
  ];
  requestingChange?: boolean;
  userId?: number;

  constructor(
    private toastService: ToastService,
    private payoutService: PayoutService,
    private authService: AuthenticationService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.select(selectUserId).pipe(untilDestroyed(this)).subscribe(userId => this.userId = userId)
  }

  show(payout: AdminPayoutRequest) {
    this.payout = payout;
    this.modal.open();
  }

  hide() {
    this.modal.close();
  }

  markAsPaid() {
    if (!this.payout?.id || this.markAsPaidForm.invalid) {
      return;
    }
    const { reason, receipt, reference_id } = this.markAsPaidForm.value as any;

    this.requestingChange = true;
    this.payoutService
      .markPayoutRequestAsPaid(this.payout!.id, { reason, receipt, reference_id })
      .pipe(catchError(() => of(false)))
      .subscribe((ok) => {
        this.response.next(!!ok);
        this.requestingChange = false;
        if (ok) {
          this.modal.close();
          this.toastService.message({
            message: 'Request is marked as paid',
            type: 'message',
          });
        }
      });
  }
}
