<ng-container *ngIf="{
    currencyList: (currencyList$ | async) ?? []
  } as vm">
  <div class="price-tiers-new p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>Edit Pricing Tiers</h3>
      </div>
    </div>

    <div class="tier-form">
      <div class="card rounded-lg border border-neutral-100 px-6 py-9">
        <app-form-price-tier *ngIf="hasData && vm.currencyList.length > 0" (submitEvent)="onSubmit($event)"
          [model]="priceTierData" [currencies]="vm.currencyList"></app-form-price-tier>
      </div>
    </div>
  </div>
</ng-container>