<div *ngIf="!chartStyle">
    No Chart Found!
</div>
<!-- Line chart -->
<ng-container *ngIf="chartStyle === 'lineChart'">
    <div class="flex items-center justify-end mb-4 gap-3">
        <div *ngFor="let dataset of lineChartData.datasets; let i = index" class="input-check">
            <input class="!border-slate-700 checked:!bg-slate-700" id="default2-checkbox" type="checkbox" checked
                (change)="toggleDatasetVisibility($event ,i)">
            <label class="flex items-center gap-2" for="default2-checkbox">
                {{ dataset.label }}
                <span [style.background-color]="dataset.borderColor" class="w-4 h-1 block rounded-lg"></span>
            </label>
        </div>
    </div>
    <canvas #linechart baseChart class="chart max-h-[250px] w-full h-auto" [data]="lineChartData"
        [options]="lineChartOptions" [type]="lineChartType" (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)">
    </canvas>
</ng-container>

<!-- Bar chart -->
<ng-container *ngIf="chartStyle === 'barChart'">
    <canvas baseChart class="chart" [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins"
        [type]="barChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
    </canvas>
</ng-container>

<canvas baseChart class="chart max-h-[175px] w-full h-auto" [data]="pieChartData" [options]="pieChartOptions"
    [type]="pieChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"
    *ngIf="chartStyle === 'pieChart'">
</canvas>
<!-- <table>
    <tr>
        <th *ngFor="let label of lineChartData.labels">{{ label }}</th>
    </tr>
    <tr *ngFor="let d of lineChartData.datasets; let i = index" [class]="'line-' + i">
        <td *ngFor="let label of lineChartData.labels; let j = index">
            {{ d && d.data[j] }}
        </td>
    </tr>
</table> -->
<!-- <div class="button-row">
    <button mat-button mat-raised-button color="primary" (click)="randomize()">
        Randomize
    </button>
    <button mat-button mat-raised-button color="primary" (click)="pushOne()">
        Push
    </button>
    <button mat-button mat-raised-button color="primary" (click)="changeColor()">
        Recolor
    </button>
    <button mat-button mat-raised-button color="primary" (click)="hideOne()">
        Toggle Series B
    </button>
    <button mat-button mat-raised-button color="primary" (click)="changeLabel()">
        Change Label
    </button>
</div> -->