import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CURRENCY_LIST, API_EXCHANGE_RATES, ExchangRate, HttpService, encodeURL } from 'thkee-common';
import { PricingCurrencyCreateUpdatePayload } from '../currency';

@Injectable({
  providedIn: 'root',
})
export class ExchangRateService {
  constructor(private readonly http: HttpService) {}

  loadExchangeRatesList(params = {} as object): Observable<ExchangRate[]> {
    return this.http.get<ExchangRate[]>(encodeURL(API_EXCHANGE_RATES, params));
  }

  updateCurrencyRate(params = {} as PricingCurrencyCreateUpdatePayload): Observable<any> {
    return this.http.patch<any>(API_CURRENCY_LIST + params?.id + '/', params);
  }
}
