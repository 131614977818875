<app-course-header [courseState]="courseState"></app-course-header>

<div class="course-intended p-4">
   <app-course-heading></app-course-heading>
   <div class="courses-list">

      <div *ngIf="qna_lists" class="card rounded-lg border border-neutral-100">
         <div class="border-b-2 border-b-neutral-200 card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex flex-col gap-1">
               <h6>Question & Answer</h6>
               <div class="text-sm text-neutral-400">{{qna_lists.count}} Total Question</div>
            </div>
         </div>

         <div class="card-body">
            <div *ngFor="let review of qna_lists.results; let i = index"
               class="border-b-neutral-200 px-6 border-b last:border-b-0">
               <div class="courses-list py-6">
                  <div class="reply-item mb-3 flex gap-4">
                     <div
                        class="inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-white">
                        <img src="https://via.placeholder.com/32x32" class="h-full w-full" alt="" />
                     </div>
                     <div class="flex items-center gap-2.5 text-sm">
                        <div class="name font-bold">{{review.user.fullname}}</div>
                        <span class="w-1 h-1 rounded-full bg-neutral-200"></span>
                        <div class="text-xs text-slate-500">{{review.date_created| date}}</div>
                     </div>
                  </div>
                  <div class="text-sm font-normal text-black">
                     {{review.description}}
                  </div>
               </div>
               <div class="border-s-[3px] border-neutral-300 mb-6">
                  <div *ngFor="let reply of review.QnAReply" class="courses-list p-6 pt-0 last:pb-0">
                     <div class="reply-item mb-3 flex gap-4">
                        <div
                           class="inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-white">
                           <img src="https://via.placeholder.com/32x32" class="h-full w-full" alt="" />
                        </div>
                        <div class="flex items-center gap-2.5 text-sm">
                           <div class="name font-bold">{{reply.user.fullname}}</div>
                           <div *ngIf="reply.user.is_instructor" class="tag tag-sm bg-blue-50 text-blue-500">Instructor
                           </div>
                           <span class="w-1 h-1 rounded-full bg-neutral-200"></span>
                           <div class="text-xs text-slate-500">{{reply.date_created | date}}</div>
                        </div>
                     </div>
                     <div class="text-sm font-normal text-black">
                        {{reply.comment}}
                     </div>
                     <div *ngFor="let reportReply of reply.reports_qna_reply"
                        class=" bg-red-50 p-4 rounded-lg flex flex-col gap-2 mt-4">
                        <div class="flex items-center gap-2.5 text-sm">
                           <div class="name font-bold text-sm text-rose-600">{{reportReply.user.fullname}}</div>
                           <div class="name text-sm text-rose-600 flex gap-1 items-center">
                              <svg-icon src="@assets/images/icons/flag-outline.svg" class="block h-3 w-3">
                              </svg-icon>
                              <span class="font-light">Reported by</span>
                              <span class="font-bold">{{reportReply.user.fullname}}</span>
                           </div>
                           <span class="w-1 h-1 rounded-full bg-rose-400"></span>
                           <div class="text-sm text-rose-400">{{reportReply.date_created| date}}</div>
                        </div>
                        <p class="text-sm text-rose-600 mb-0">{{reportReply.description}}</p>
                     </div>
                  </div>
                  <ng-container *ngIf="review.qna_replies_count > 2 && !isShowAllReply[i]">
                     <span (click)="showAllReply(review, i)" class="text-neutral-600 font-bold cursor-pointer px-6">
                        See {{review.qna_replies_count - 2}} Other Answer
                     </span>
                  </ng-container>
               </div>
            </div>
         </div>
      </div>

   </div>
</div>