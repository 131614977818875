import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { PricingCountryAPIService } from 'thkee-common';
import { PricingCountryService } from './country-store.service';
import { PricingCountryActions } from './country.actions';
@Injectable()
export class PricingCountryEffects {
  constructor(
    private actions$: Actions,
    private pricingService: PricingCountryService,
    private pricingCountryAPIService: PricingCountryAPIService,
    private router: Router
  ) {}

  loadPricingCountryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCountryActions.loadPricingCountryList),
      switchMap((params) =>
        this.pricingService.getPricingCountryList(params).pipe(
          map((data) => PricingCountryActions.loadPricingCountryListSuccess({ data })),
          catchError((error) => of(PricingCountryActions.loadPricingCountryListFail({ error })))
        )
      )
    )
  );

  addPricingCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCountryActions.addPricingCountry),
      switchMap((params) =>
        this.pricingService.addPricingCountry(params).pipe(
          map((data) => PricingCountryActions.addPricingCountrySuccess({ data })),
          catchError((error) => of(PricingCountryActions.addPricingCountryFailed()))
        )
      )
    )
  );

  // addPricingCountry$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(PricingCountryActions.addPricingCountry),
  //     mergeMap((params) =>
  //       this.pricingService.addPricingCountry(params).pipe(
  //         map((data) => PricingCountryActions.addPricingCountrySuccess({ data })),
  //         catchError((error) => {
  //           // Check for 400 Bad Request
  //           if (error.status === 400) {
  //             // Handle your specific logic for 400 Bad Request
  //             // You can also dispatch a specific action for 400 errors if needed
  //             console.log('ERROR: ', error.status);
  //           }
  //           return of(PricingCountryActions.addPricingCountryFailed());
  //         })
  //       )
  //     )
  //   )
  // );

  getPricingCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCountryActions.getPricingCountry),
      switchMap((params) =>
        this.pricingService.getPricingCountry(params).pipe(
          map((data) => PricingCountryActions.getPricingCountrySuccess({ data })),
          catchError((error) => of(PricingCountryActions.getPricingCountryFail({ error })))
        )
      )
    )
  );

  updatePricingCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCountryActions.updatePricingCountry),
      switchMap((params) =>
        this.pricingService.updatePricingCountry(params).pipe(
          map((data) => PricingCountryActions.updatePricingCountrySuccess({ data })),
          catchError((error) => of(PricingCountryActions.updatePricingCountryFailed()))
        )
      )
    )
  );

  removePricingCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCountryActions.removePricingCountry),
      switchMap((params) =>
        this.pricingService.removePricingCountry(params).pipe(
          map((data) => PricingCountryActions.removePricingCountrySuccess(params)),
          catchError((error) => of(PricingCountryActions.removePricingCountryFail({ error })))
        )
      )
    )
  );
}
