import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PriceCurrenciesEditComponent } from './edit/edit.component';
import { PriceCurrenciesNewComponent } from './new/new.component';
import { PriceCurrenciesComponent } from './price-currencies.component';
import { PriceCurrenciesViewComponent } from './view/view.component';

export * from './edit/edit.component';
export * from './new/new.component';
export * from './view/view.component';

export const Components = [
  PriceCurrenciesViewComponent,
  PriceCurrenciesNewComponent,
  PriceCurrenciesEditComponent,
  PriceCurrenciesComponent,
];

export const Routing = [
  {
    path: 'currencies',
    component: PriceCurrenciesComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Currencies' },
  },
  {
    path: 'currencies/new',
    component: PriceCurrenciesNewComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Add Currencies' },
  },
  {
    path: 'currencies/:id',
    component: PriceCurrenciesViewComponent,
    data: { title: marker('Price Management'), breadcrumb: 'View Currencies' },
  },
  {
    path: 'currencies/:id/edit',
    component: PriceCurrenciesEditComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Edit Currencies' },
  },
];
