import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

@Component({
  selector: 'app-course-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit {
  @ViewChild('myForm') myForm!: NgForm;
  @Output() createEvent = new EventEmitter<Partial<any>>();
  @Output() cancelEvent = new EventEmitter<Partial<any>>();

  readonly form: FormGroup = new FormGroup({});
  readonly options: FormlyFormOptions = {};
  readonly model: Partial<any> = {
    title: '',
  };
  readonly fields: FormlyFieldConfig[] = [
    {
      key: 'title',
      type: 'input',
      props: {
        label: 'Section Title',
        placeholder: '',
        required: true,
        minLength: 6,
        maxLength: 60,
        hideRequiredMarker: true,
      },
      validation: {
        messages: {
          minLength: "Title can't be lower than 6 characters.",
        },
      },
    },
  ];
  constructor() {}

  ngOnInit(): void {}

  submit(): void {
    this.form.markAsTouched();
    (this.myForm as any).submitted = true;
    if (this.form.valid) {
      this.createEvent.emit(this.model);
      this.options.resetModel && this.options.resetModel();
    }
  }

  cancel(): void {
    this.cancelEvent.emit(this.model);
    this.options.resetModel && this.options.resetModel();
  }
}
