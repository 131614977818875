import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NavItem, SIDEBAR_CONSTANTS, ShellCom, TabItem, UntilDestroy, untilDestroyed } from '@shared';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { CourseActions, Logger, RouterStoreService } from 'thkee-common';
import { SidebarComponent } from './sidebar/sidebar.component';

const log = new Logger('ShellComponent');

@UntilDestroy()
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  @ViewChild('sideBar') sideBar!: SidebarComponent;
  sidebarItems: NavItem[] = SIDEBAR_CONSTANTS;
  activeItem: NavItem | null = null;
  activeId: string | number = '0';
  tabItems: TabItem[] = [];
  tabTitle: string = '';
  activeTabItem: string = '/';
  shellComponents: ShellCom = {};

  constructor(private router: Router, private routerStore: RouterStoreService, private store: Store) {}

  ngOnInit() {
    this.initActive();
    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
    onNavigationEnd.subscribe((event) => {
      this.initActive(event);
    });

    // load course data if shell is used for /courses/:courseId/* routes
    combineLatest([this.routerStore.getParam('courseId'), this.routerStore.getUrl()])
      .pipe(
        tap(([courseId, url]) => log.debug(`courseId[${courseId}] url[${url}]`)),
        filter(
          ([courseId, url]) => (url.startsWith('/course/') && !!courseId) || (url.startsWith('/courses/') && !!courseId)
        ),
        map(([courseId]) => courseId),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((courseId) => {
        this.store.dispatch(CourseActions.loadCourse({ courseId }));
      });
  }

  initActive(route: any = this.router) {
    // let courseId = this.getID(route.url, 2); // TODO: Get match url with dynamic parameter id make this dynamic based on router - Thanks
    // replace courseId/UUID to ":courseId" so that it can be compared with routes in sidebar-constants.ts
    let URL = route.url.replace(/\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/[^\/]+/, '/:courseId');
    const active = this.findIndicesByRoute(this.sidebarItems, URL);
    this.activeId = active.item?.id;
    this.activeItem = active.item;
    if (active.item) {
      this.tabItems = active.item.tabMenu ? active.item.tabMenu : [];
      this.tabTitle = active.item.displayName;
    } else {
      this.tabItems = [];
    }

    // Get Active Tab
    if (this.activeItem?.shell) {
      this.shellComponents = this.activeItem?.shell;
    } else {
      const lastPatch = '/' + _.last(this.router.url.split('/'));
      const tabItem = _.find(this.tabItems, { route: lastPatch });
      this.shellComponents = tabItem?.shell ? tabItem?.shell : {};
    }
  }

  findIndicesByRoute(data: any, route: string, currentPath: any = [], indices: any = [], items: any = []) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const newPath = [...currentPath, i];
      if (item.route === route) {
        indices.push(newPath);
        items.push(item);
      }
      if (item.children) {
        this.findIndicesByRoute(item.children, route, newPath, indices, items);
      }
    }
    return { id: _.join(_.flatten(indices), '_'), item: _.last(items) as NavItem };
  }

  // getID(url: string, position: number = 2): string | null {
  //   // TODO: Change to dynamic routing param
  //   const parts = url.split('/');
  //   if (position >= 0 && position < parts.length) {
  //     return parts[position];
  //   }
  //   return null;
  // }
}
