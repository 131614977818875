import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  INSTRUCTOR_DRAWER_TAB,
  INSTRUCTOR_SETTINGS_DRAWER_TAB,
  ModalComponent,
  TabItem,
  UntilDestroy,
  untilDestroyed,
} from '@shared';
import {
  CombinationSingleInputItem,
  CombinationSingleInputValue,
} from '@shared/components/combination-single-input/combination-single-input.component';
import { PAYOUT_METHOD_IDS, PAYOUT_METHOD_NAMES } from '@shared/constants/payout';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { catchError, debounceTime, of } from 'rxjs';
import {
  AdminInactivePayoutsQuery,
  AdminPayoutRequest,
  Pagination,
  QueryFormConverter,
  QueryStringFilterService,
  RouterStoreService,
  ToastService,
} from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-inactive-payout',
  templateUrl: './inactive-payout.component.html',
  styleUrls: ['./inactive-payout.component.scss'],
  providers: [QueryStringFilterService.forComponent()],
})
export class InactivePayoutComponent implements OnInit, AfterViewInit {
  searchValue: CombinationSingleInputValue | undefined;
  params: any = {};

  //Student TAB
  instructorTabLists: TabItem[] = INSTRUCTOR_DRAWER_TAB;

  //Student Settings TAB
  instructorSettingsTabLists: TabItem[] = INSTRUCTOR_SETTINGS_DRAWER_TAB;

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Account Status</div>`,
    },
    {
      key: 'account_status',
      fieldGroup: [
        {
          key: 'active',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Active',
            required: true,
          },
        },
        {
          key: 'inactive',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Inactive',
            required: true,
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Feature Status</div>`,
    },
    {
      key: 'payout_feature_status',
      fieldGroup: [
        {
          key: 'active',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Active',
            required: true,
          },
        },
        {
          key: 'inactive',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Inactive',
            required: true,
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Method</div>`,
    },
    {
      key: 'payout_method',
      fieldGroup: [
        {
          key: PAYOUT_METHOD_IDS.paypal,
          type: 'checkbox',
          className: '',
          props: {
            label: PAYOUT_METHOD_NAMES.paypal,
            required: true,
          },
        },
        {
          key: PAYOUT_METHOD_IDS.payoneer,
          type: 'checkbox',
          className: '',
          props: {
            label: PAYOUT_METHOD_NAMES.payoneer,
            required: true,
          },
        },
        {
          key: PAYOUT_METHOD_IDS.bank_account,
          type: 'checkbox',
          className: '',
          props: {
            label: PAYOUT_METHOD_NAMES.bank_account,
            required: true,
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'requested_amount_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'requested_amount_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'no_of_transactions_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'no_of_transactions_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  query?: AdminInactivePayoutsQuery;

  // Active Payout Confirm
  activePayoutForm = new FormGroup({});
  activePayoutFormModel: any = {};
  activePayoutFormFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'textarea',
      props: {
        label: 'Description',
        labelClass: '!text-base',
        tips: 'Marke As Paid Confirm',
        placeholder: 'Enter a description...',
        minHeight: '110px',
        maxHeight: '150px',
      },
    },
  ];
  payoutsPagination?: Pagination<AdminPayoutRequest>;
  showingDropdown = '';
  searchOptions: CombinationSingleInputItem[] = [
    {
      id: 'id',
      title: 'ID',
      placeholder: 'Enter ID',
    },
    {
      id: 'name',
      title: 'Instructor Name',
      placeholder: 'Enter Instructor Name',
    },
  ];
  processingPayout?: AdminPayoutRequest;

  private queryFormConverter = new QueryFormConverter<AdminInactivePayoutsQuery>([
    'account_status',
    'payout_method',
    'payout_feature_status',
  ]);
  requesting: boolean = false;

  constructor(
    private toastService: ToastService,
    private routerStore: RouterStoreService,
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
    private queryStringFilterService: QueryStringFilterService<AdminInactivePayoutsQuery>
  ) {}

  ngOnInit(): void {
    this.getSummary();
    this.initialUiData();
    this.getInactivePayouts();
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  handleMarkAsPaid(ok: boolean) {
    if (ok) {
      this.queryStringFilterService.refresh();
    }
  }

  activate(modal: ModalComponent) {
    this.requesting = true;
    this.payoutService
      .activatePayoutRequest(this.processingPayout!.id, this.activePayoutForm.value as any)
      .pipe(catchError(() => of(false)))
      .subscribe((ok) => {
        this.requesting = false;
        this.processingPayout = undefined;
        if (ok) {
          this.queryStringFilterService.refresh();
          modal.close();
          this.toastService.message({
            type: 'message',
            message: 'Activate payout successfully',
          });
        }
      });
  }

  openConfirmation(modal: ModalComponent, payout: AdminPayoutRequest) {
    this.processingPayout = payout;
    modal.open();
  }

  search($event: CombinationSingleInputValue) {
    const { key, value } = $event;
    if (key && value) {
      this.queryStringFilterService.patch({ search: [key, value].join(':') });
      return;
    }

    this.queryStringFilterService.remove(['search']);
  }

  paginate($event: PaginationChange) {
    this.queryStringFilterService.patch($event);
  }

  resetFilter() {
    this.filterForm.reset();
  }

  private initialUiData() {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Inactive Payout',
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  toggleDropdown(type: string) {
    if (type && type !== this.showingDropdown) {
      this.showingDropdown = type;
      return;
    }

    this.showingDropdown = '';
  }

  // Status methode
  getStatus(status?: boolean) {
    let style = { bg: '', text: '' };
    if (status) {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }

    return style;
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 2,
      title: 'Total inactive Amount',
      value: 2191,
      type: 'currency',
      tooltip: 'Total inactive Amount',
    },
    {
      id: 1,
      title: 'Inactive Payouts',
      value: 8,
      type: 'number',
      tooltip: 'Inactive Payouts',
    },
    {
      id: 3,
      title: 'Total Transactions',
      value: 28,
      type: 'number',
      tooltip: 'Total Transactions',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      period: '2024-02-20T10:30:31.398Z',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      account_status: 'Active',
      payout_methode: 'Paypal',
      inactive_reason: 'Methode not set up',
      payout_amount: 344,
      transaction: 20,
      boldby: 'Frank',
    },
    {
      id: '123',
      period: '2024-02-20T10:30:31.398Z',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      account_status: 'Active',
      payout_methode: 'Paypal',
      inactive_reason: 'Methode not set up',
      payout_amount: 344,
      transaction: 20,
      boldby: 'Ibrahim',
    },
    {
      id: '123',
      period: '2024-02-20T10:30:31.398Z',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      account_status: 'Inactive',
      payout_methode: 'Paypal',
      inactive_reason: 'Methode not set up',
      payout_amount: 344,
      transaction: 20,
      boldby: 'Emran',
    },
  ];

  private getSummary() {}

  private getInactivePayouts() {
    this.queryStringFilterService.valueChanges.pipe(debounceTime(400), untilDestroyed(this)).subscribe((query) => {
      this.payoutService
        .getInactivePayouts(this.toSearchValue(query))
        .subscribe((data) => (this.payoutsPagination = data));
    });
  }

  private toSearchValue(query: AdminInactivePayoutsQuery) {
    const fields: (keyof AdminInactivePayoutsQuery)[] = ['account_status', 'payout_feature_status'];
    const fomattedValue = fields
      .filter((f) => query[f])
      .reduce((r, f) => {
        const value: string[] = [].concat(query[f] as any);
        if (value.length !== 1) {
          return { ...r, [f]: undefined };
        }
        return { ...r, [f]: value.includes('active') };
      }, {});
    return { ...query, ...fomattedValue };
  }

  private setupFilter() {
    this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.queryStringFilterService.patch(this.queryFormConverter.toQuery(value as any));
    });

    this.queryStringFilterService.initialValue.subscribe((query) => {
      this.query = query;
      if (query.search) {
        const [key, value] = query.search.split(':');
        if (key && value) {
          this.searchValue = { key, value };
        }
      }
      this.filterForm.patchValue(this.queryFormConverter.toForm(query));
    });
  }
}
