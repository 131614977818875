import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export * from './inactive-payout-instructor/inactive-payout-instructor.component';
export * from './inactive-payout.component';

import { InactivePayoutInstructorComponent } from './inactive-payout-instructor/inactive-payout-instructor.component';
import { InactivePayoutComponent } from './inactive-payout.component';

export const Components = [InactivePayoutComponent, InactivePayoutInstructorComponent];

export const Routing = [
  {
    path: 'payout/inactive-payouts',
    component: InactivePayoutComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Inactive Payout' },
  },
  {
    path: 'payout/inactive-payouts/:payoutId',
    component: InactivePayoutInstructorComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Inactive Payout' },
  },
];
