import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { BarChartData } from '@shared';
import Annotation from 'chartjs-plugin-annotation';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-common-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() chartStyle: string = '';

  private newLabel? = 'New label';

  constructor() {
    Chart.register(Annotation);
  }

  ngOnInit(): void {
    this.lineChartData.datasets = this.inputLineChartData;
    this.lineChartData.labels = this.inputLineChartLabels;
    this.barChartData.datasets = this.inputBarChartData;
    this.barChartData.labels = this.inputBarChartLabels;
  }

  // ----------------------------
  // Chart Type : Linechart
  public lineChartType: ChartType = 'line';
  @ViewChild('linechart') chartRef!: ElementRef;
  @Input() tickYValues: string[] = ['$0.00', '$250.00', '$500.00', '$750.00'];
  @Input() tickYMin: number = 0;
  @Input() tickYMax: number = 750;
  @Input() tickYStepSize: number = 250;
  @Output() chartFilter: EventEmitter<string> = new EventEmitter();
  @Input() inputLineChartData: any[] = [];
  @Input() inputLineChartLabels: string[] = [];

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [],
    labels: [],
  };
  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    elements: {
      line: {
        tension: 0.1,
      },
    },
    scales: {
      x: {
        position: 'left',
        grid: {
          color: () => 'rgba(0, 0, 0, 0.1)', // Color of x-axis grid lines
          tickWidth: 1,
          tickColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
      y: {
        position: 'left',
        min: this.tickYMin,
        max: this.tickYMax,
        ticks: {
          stepSize: this.tickYStepSize,
          color: '#475569',
          callback: (tickValue: string | number, index: number, ticks: any[]) => {
            if (index < this.tickYValues.length) {
              return this.tickYValues[index];
            } else {
              return '';
            }
          },
        },
      },
    },
    plugins: {
      legend: { display: false },
      annotation: {
        annotations: [],
      },
    },
  };

  // Toggle visibility of the dataset based on its index
  toggleDatasetVisibility(event: any, datasetIndex: number): void {
    const chart: any = Chart.getChart(this.chartRef.nativeElement);
    if (event.target.checked) {
      chart.data.datasets[datasetIndex].hidden = false;
    } else {
      chart.data.datasets[datasetIndex].hidden = true;
    }
    chart.update();
  }

  // ----------------------------
  // Bar Chart
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];
  @Input() barChartTickYValues: string[] = ['0', '1', '2', '3', '4', '5'];
  @Input() barChartTickYMin: number = 0;
  @Input() barChartTickYMax: number = 1000;
  @Input() barChartTickYStepSize: number = 200;
  @Input() inputBarChartData: BarChartData[] = [];
  @Input() inputBarChartLabels: string[] = [];

  public barChartData: ChartData<'bar'> = {
    datasets: [],
    labels: [],
  };

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {
        position: 'left',
        grid: {
          color: () => 'rgba(0, 0, 0, 0.1)', // Color of x-axis grid lines
          tickWidth: 1,
          tickColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
      y: {
        position: 'left',
        min: this.barChartTickYMin,
        max: this.barChartTickYMax,
        ticks: {
          stepSize: this.barChartTickYStepSize,
          color: '#475569',
          callback: (tickValue: string | number, index: number, ticks: any[]) => {
            if (index < this.barChartTickYValues.length) {
              return this.barChartTickYValues[index];
            } else {
              return '';
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  // ----------------------------
  // Pie Chart
  public pieChartData: ChartData<'pie'> = {
    labels: ['Thkee Earning', 'Instructor Earning'],
    datasets: [
      {
        data: [40, 60],
        label: 'Data',
        backgroundColor: ['#323232', '#CCCCCC'],
      },
    ],
  };

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
        text: '',
      },
      datalabels: {
        display: true,
        align: 'bottom',
        backgroundColor: '#ccc',
        borderRadius: 20,
        font: {
          size: 12,
        },
      },
    },
  };

  public pieChartType: ChartType = 'pie';

  /************************************
   *******Chart common functions*******
   *************************************/
  @ViewChild(BaseChartDirective) chartLine?: BaseChartDirective;

  // Linechart hover
  public chartHovered({ event, active }: { event?: ChartEvent; active?: object[] }): void {
    // console.log(event, active);
  }

  private static generateNumber(i: number): number {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }

  public randomize(): void {
    for (let i = 0; i < this.lineChartData.datasets.length; i++) {
      for (let j = 0; j < this.lineChartData.datasets[i].data.length; j++) {
        this.lineChartData.datasets[i].data[j] = ChartComponent.generateNumber(i);
      }
    }
    this.chartLine?.update();
  }

  // Chart click events
  public chartClicked({ event, active }: { event?: ChartEvent; active?: object[] }): void {
    console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chartLine?.isDatasetHidden(1);
    this.chartLine?.hideDataset(1, !isHidden);
  }

  public pushOne(): void {
    this.lineChartData.datasets.forEach((x, i) => {
      const num = ChartComponent.generateNumber(i);
      x.data.push(num);
    });
    this.lineChartData?.labels?.push(`Label ${this.lineChartData.labels.length}`);

    this.chartLine?.update();
  }

  public changeColor(): void {
    this.lineChartData.datasets[2].borderColor = 'green';
    this.lineChartData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

    this.chartLine?.update();
  }

  public changeLabel(): void {
    const tmp = this.newLabel;
    this.newLabel = this.lineChartData.datasets[2].label;
    this.lineChartData.datasets[2].label = tmp;

    this.chartLine?.update();
  }
}
