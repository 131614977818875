<div class="fixed right-5 top-5 z-[1000]" *ngIf="isOpened">
  <div id="toast-success"
    class="mb-4 flex w-full max-w-xs items-center rounded-lg p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
    role="alert" [class.bg-teal-100]="type === 'message'" [class.bg-rose-100]="type === 'error'">
    <div
      class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
      <svg-icon *ngIf="type === 'message'" src="@assets/images/icons/check-circle-solid.svg" class="block h-6 w-6">
      </svg-icon>
      <svg-icon *ngIf="type === 'error'" src="@assets/images/icons/exclamation-circle.svg" class="block h-6 w-6">
      </svg-icon>
      <span class="sr-only">Check icon</span>
    </div>
    <div class="mr-1.5 ms-1 min-w-[100px] text-sm font-normal">{{ message }}</div>
    <button (click)="close()" type="button"
      class="-my-1.5 ml-3 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-neutral-100 p-1.5 text-gray-400 hover:bg-neutral-200 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
      data-dismiss-target="#toast-success" aria-label="Close">
      <span class="sr-only">Close</span>
      <svg-icon src="@assets/images/icons/x-mark.svg" class="block h-3 w-3"></svg-icon>
    </button>
  </div>
</div>
<!-- 
<div
  id="toast-danger"
  class="mb-4 flex w-full max-w-xs items-center rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
  role="alert"
>
  <div
    class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200"
  >
    <svg class="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"
      />
    </svg>
    <span class="sr-only">Error icon</span>
  </div>
  <div class="ms-3 text-sm font-normal">Item has been deleted.</div>
  <button
    type="button"
    class="-mx-1.5 -my-1.5 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
    data-dismiss-target="#toast-danger"
    aria-label="Close"
  >
    <span class="sr-only">Close</span>
    <svg class="h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
  </button>
</div>
<div
  id="toast-warning"
  class="flex w-full max-w-xs items-center rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
  role="alert"
>
  <div
    class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200"
  >
    <svg class="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"
      />
    </svg>
    <span class="sr-only">Warning icon</span>
  </div>
  <div class="ms-3 text-sm font-normal">Improve password difficulty.</div>
  <button
    type="button"
    class="-mx-1.5 -my-1.5 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
    data-dismiss-target="#toast-warning"
    aria-label="Close"
  >
    <span class="sr-only">Close</span>
    <svg class="h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
  </button>
</div> -->