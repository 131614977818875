import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { I18nModule } from '@app/i18n';
import { SharedModule } from '@shared';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, I18nModule, AuthRoutingModule, SharedModule],
  declarations: [LoginComponent, LogoutComponent],
})
export class AuthModule {}
