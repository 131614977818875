import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { ExchangRateService } from './exchange-rate-store.service';
import { ExchangRateActions } from './exchange-rate.actions';
@Injectable()
export class ExchangeRateEffects {
  constructor(private actions$: Actions, private service: ExchangRateService, private router: Router) {}

  loadExchangeRatesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExchangRateActions.loadExchangeRatesList),
      switchMap(() =>
        this.service.loadExchangeRatesList().pipe(
          map((data) => ExchangRateActions.loadExchangeRatesListSuccess({ data })),
          catchError((error) => of(ExchangRateActions.loadExchangeRatesListFail({ error })))
        )
      )
    )
  );

  
  updateCurrencyRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExchangRateActions.updateCurrencyRate),
      switchMap((params) =>
        this.service.updateCurrencyRate(params).pipe(
          map((data) => ExchangRateActions.updateCurrencyRateSuccess({ data })),
          catchError((error) => of(ExchangRateActions.updateCurrencyRateFail({ error })))
        )
      )
    )
  );
}
