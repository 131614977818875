import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  API_CURRENCIES_ALL,
  API_CURRENCIES_LIVE_RATE,
  API_CURRENCY_LIST,
  API_TIRE_EXCHANGE_RATE,
  ConstantCurrencyOption,
  Currency,
  HttpService,
  PricingCurrency,
  PricingCurrencyData,
  PricingCurrencyList,
  TireConversationRate,
  encodeURL,
} from 'thkee-common';
import { PricingCurrencyCreateUpdatePayload, PricingCurrencyLoadRemovePayload } from './currency-state.model';

@Injectable({
  providedIn: 'root',
})
export class PricingCurrencyService {
  constructor(private readonly http: HttpService) {}

  getPricingCurrencyList(params = {} as object): Observable<PricingCurrencyList[]> {
    return this.http.get<PricingCurrencyList[]>(encodeURL(API_CURRENCY_LIST, params));
  }

  getCurrencyList(params = {} as object): Observable<Currency[]> {
    return this.http.get<Currency[]>(encodeURL(API_CURRENCY_LIST, params));
  }

  addPricingCurrency(params = {} as object): Observable<PricingCurrencyCreateUpdatePayload> {
    return this.http.post<any>(API_CURRENCY_LIST, params);
  }

  getPricingCurrency(params = {} as PricingCurrencyLoadRemovePayload): Observable<PricingCurrencyData> {
    const url = `${API_CURRENCY_LIST}${params?.id}/?detailed=true`;
    return this.http.get<PricingCurrencyData>(url);
  }

  updatePricingCurrency(params = {} as PricingCurrencyCreateUpdatePayload): Observable<any> {
    return this.http.put<any>(API_CURRENCY_LIST + params?.id + '/', params);
  }

  removePricingCurrency(params = {} as PricingCurrencyLoadRemovePayload): Observable<any> {
    return this.http.delete<any>(API_CURRENCY_LIST + params?.id + '/');
  }

  getCurrenciesLiveRate(params = {} as object): Observable<PricingCurrency[]> {
    return this.http.get<PricingCurrency[]>(encodeURL(API_CURRENCIES_LIVE_RATE, params));
  }

  getAllCurrencies(params = {} as object): Observable<ConstantCurrencyOption[]> {
    return this.http.get<ConstantCurrencyOption[]>(encodeURL(API_CURRENCIES_ALL, params));
  }

  getTireExchangeRate(tireId: string): Observable<TireConversationRate[]> {
    return this.http.get<TireConversationRate[]>(API_TIRE_EXCHANGE_RATE.replace('<TIRE_ID>', tireId));
  }
}
