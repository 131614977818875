import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  CountryCRUDStatus,
  PricingCountryActions,
  PricingCountryCreateUpdatePayload,
  PricingCountryService,
  UntilDestroy,
  selectCountryCRUDStatus,
  selectLoadedPricingCountryData,
  untilDestroyed,
} from '@shared';
import { Observable, combineLatest } from 'rxjs';
import { ConstantCountryOption, PricingCountry } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class PriceCountryViewComponent implements OnInit {
  id = '';
  priceCountry$: Observable<PricingCountry> = this.store.select(selectLoadedPricingCountryData);
  status$: Observable<CountryCRUDStatus> = this.store.select(selectCountryCRUDStatus);
  countriesOptions$: Observable<ConstantCountryOption[]> = new Observable();
  priceCountryDataDefault: PricingCountry = {
    id: '',
    name: '',
    two_letter_iso_code: '',
    three_letter_iso_code: '',
    numeric_iso_code: '',
    subject_to_vat: false,
    allow_billing: false,
    currency_code: '',
    sort_number: 0,
  };
  hasData = false;
  formData: any = {};
  pringCountryUpdatePayload!: PricingCountryCreateUpdatePayload;
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private pricingCountryService: PricingCountryService,
    private router: Router // Inject the Router
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') || '';

    this.store.dispatch(PricingCountryActions.getPricingCountry({ id: this.id }));
    this.countriesOptions$ = this.pricingCountryService.getConstantCountries();

    combineLatest([this.countriesOptions$, this.priceCountry$, this.status$])
      .pipe(untilDestroyed(this))
      .subscribe(([countries, country, status]) => {
        this.formData = country;
        this.hasData = true;

        // Status
        if (status === CountryCRUDStatus.Success) {
          console.log('Country saved successfully', this.formData);
          this.router.navigate(['/price-management/countries']); // Navigate to the countries list
        }
      });
  }

  onInit(fields: FormlyFieldConfig[]) {
    this.disableFiels(fields);
  }

  disableFiels(objects: FormlyFieldConfig[]): FormlyFieldConfig[] {
    return objects.map((obj) => {
      if (obj.key && obj.type) {
        obj.props = {
          ...obj.props,
          disabled: true,
        };
      }
      if (obj.fieldGroup && obj.fieldGroup.length > 0) {
        obj.fieldGroup = this.disableFiels(obj.fieldGroup);
      }
      return obj;
    });
  }

  onSubmit(event: any) {
    let values = event?.values;
    this.pringCountryUpdatePayload = values;
    this.store.dispatch(PricingCountryActions.updatePricingCountry(this.pringCountryUpdatePayload));
  }
}
