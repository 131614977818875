<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'hidden':''"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[200px] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <formly-form [form]="filterForm" [fields]="filterFormFields">
                  </formly-form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
               </div>
            </div>
         </div>
         <div class="input-group">
            <div class="group">
              <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
               <input type="text" [ngModel]="query?.search || ''" (keyup.enter)="search(instructorNameInput.value)" #instructorNameInput class="" placeholder="Enter Instructor Name">
            </div>
          </div>
         <!-- Search With Dropdown -->
         <!-- <div class="input-combine flex">
            <div class="select2">
               <div class="relative">
                  <a (click)="dropdown('course')" type="button" aria-haspopup="listbox" aria-expanded="true"
                     aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
                     <div class="selected-item truncate">Instructor ID</div>
                     <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
                  </a>
                  <ul [ngClass]="isDropdown['course']?'':'hidden'" tabindex="-1" role="listbox"
                     aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3" class="select-items "
                     ng-reflect-ng-class="hidden">
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 1</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 2</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 3</li>
                  </ul>
               </div>
            </div>
            <div class="input-group">
               <div class="group !rounded-l-none">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input placeholder="Enter your full name" />
               </div>
            </div>
         </div> -->
      </div>

      <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
         <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
         <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
      </button>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex items-center gap-1">
               <h6>Instructors</h6>
               <div class="tag tag-sm">{{balancesPagination?.count || 0}} instructors</div>
            </div>
            <div class="actions flex flex-row gap-4">
               <button class="btn-neutral px-3">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list">
               <!-- Table -->
               <div class="datagrid-table relative overflow-x-auto">
                  <table class="w-full text-left">
                     <thead class="heading">
                        <tr>
                           <th scope="col" class="">
                              <div class="col-name">Instructor Name</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Payout Type</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Pending Balance</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Total Refunds</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Total Withdrawn</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Reqst. Balance</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Available Balance</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Total Earnings</div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="hover:cursor-pointer" *ngFor="let data of balancesPagination?.results">
                           <td>
                              <span class="course-id">
                                 <span [routerLink]="'/ecommerce/payout/instructor-balance/'+data.instructor_id"
                                    class="text-blue-600 cursor-pointer">
                                    {{ data.instructor_name }}
                                 </span>
                              </span>
                           </td>
                           <td class="max-w-[170px]">
                              <span class="">
                                 {{ data.payout_type }}
                              </span>
                           </td>
                           <td class="text-teal-600">
                              +{{(data.pending_balance || 0) | currency:data.currency_symbol}}
                           </td>
                           <td>
                              {{(data.total_refund || 0) | currency:data.currency_symbol}}
                           </td>
                           <td>
                              {{(data.total_withdraw || 0) | currency:data.currency_symbol}}
                           </td>
                           <td>
                              {{(data.requested_balance || 0) | currency:data.currency_symbol}}
                           </td>
                           <td>
                              {{(data.available_balance || 0) | currency:data.currency_symbol}}
                           </td>
                           <td>
                              {{(data.total_earnings || 0) | currency:data.currency_symbol}}
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <!-- Pagination -->
               <div class="pagination flex p-5">
                  <thk-pagination class="w-full" (paginationChange)="paginate($event)" [sizeChangable]="true" [page]="query?.page || 1" [size]="query?.page_size || 5" [total]="balancesPagination?.count || 0"></thk-pagination>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
