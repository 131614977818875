<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4">
      <h3 class="text-2xl font-semibold text-neutral-800">
         July 2020 was paid on September 3rd 2020 12:00 AM Succesfully!
      </h3>
   </div>
   <div class="flex flex-col gap-4 mb-4">
      <div class="flex flex-row gap-4">
         <div *ngFor="let payment of paymentHistory"
            class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-[#666666]">{{payment.title}}</h5>
            <h5 *ngIf="payment.type=== 'date'" class="text-lg font-bold text-[#3941FE]">
               {{payment.value | date:'MMMM y'}}</h5>
            <h5 *ngIf="payment.type=== 'currency'" class="text-lg font-bold text-[#3941FE]">{{payment.value | currency}}
            </h5>
            <h5 *ngIf="payment.type=== 'number'" class="text-lg font-bold text-[#3941FE]">{{payment.value}}</h5>
            <svg-icon src="@assets/images/icons/question-mark-circle.svg"
               class="btn-icon absolute right-4 text-amber-600"></svg-icon>
         </div>
      </div>
      <div class="flex flex-row gap-4">
         <div *ngFor="let payment of paymentHistory2"
            class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-[#666666]">{{payment.title}}</h5>
            <h5 *ngIf="payment.type=== 'date'" class="text-lg font-bold text-[#3941FE]">
               {{payment.value | date:'MMMM y'}}</h5>
            <h5 *ngIf="payment.type=== 'currency'" class="text-lg font-bold text-[#3941FE]">{{payment.value | currency}}
            </h5>
            <h5 *ngIf="payment.type=== 'number'" class="text-lg font-bold text-[#3941FE]">{{payment.value}}</h5>
            <svg-icon src="@assets/images/icons/question-mark-circle.svg"
               class="btn-icon absolute right-4 text-amber-600"></svg-icon>
         </div>
      </div>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'hidden':''"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <form [formGroup]="filterForm">
                     <formly-form [form]="filterForm" [fields]="filterFormFields" [model]="filterFormModel">
                     </formly-form>
                  </form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
                  <button class="btn btn-dark bg-neutral-600 text-white">
                     <span>Filter</span>
                  </button>
               </div>
            </div>
         </div>
         <!-- Search With Dropdown -->
         <div class="input-combine flex">
            <div class="select2">
               <div class="relative">
                  <a (click)="dropdown('course')" type="button" aria-haspopup="listbox" aria-expanded="true"
                     aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
                     <div class="selected-item truncate">Course ID</div>
                     <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
                  </a>
                  <ul [ngClass]="isDropdown['course']?'':'hidden'" tabindex="-1" role="listbox"
                     aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3" class="select-items "
                     ng-reflect-ng-class="hidden">
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 1</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 2</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 3</li>
                  </ul>
               </div>
            </div>
            <div class="input-group">
               <div class="group !rounded-l-none">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input placeholder="Enter your full name" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-4 py-5">
            <div class="flex items-center gap-1">
               <h6>Payouts</h6>
               <div class="tag tag-sm">100 Payee</div>
            </div>
            <div class="actions flex flex-row gap-4">
               <button class="btn border-0 bg-teal-50 text-teal-600">
                  <span>Ready</span>
               </button>
               <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
                  <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
                  <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
               </button>
               <button class="btn-neutral px-3">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list">
               <!-- Table -->
               <div class="datagrid-table relative overflow-x-auto">
                  <table class="w-full text-left">
                     <thead class="heading">
                        <tr>
                           <th scope="col" class="w-28 pl-4">
                              <div class="col-name flex items-center gap-2">
                                 <input type="checkbox" [checked]="isAllCheckBoxChecked()"
                                    (change)="checkAllCheckBox($event)">
                                 {{checkedItemsCount()}} Selected
                              </div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Instructor</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Transactions</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Payout Method</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Payout ID</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Payout Amount</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Status</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Receipt</div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="hover:cursor-pointer" *ngFor="let data of dummyData; let i = index"
                           [routerLink]="'/ecommerce/payout/paid-payout/monthly/'+data.id+'/transaction'">
                           <td>
                              <input (click)="checked($event)" [(ngModel)]="dummyData[i].checked" type="checkbox"
                                 name="" id="">
                           </td>
                           <td>
                              <span class="course-id">
                                 <span class="text-blue-600 cursor-pointer">
                                    {{ data.instructor.title }}
                                 </span>
                              </span>
                           </td>
                           <td class="max-w-[170px]">
                              <span class="">
                                 {{ data.transactions }}
                              </span>
                           </td>
                           <td>
                              {{data.payment_methode}}
                           </td>
                           <td>
                              #{{data.payout_id}}
                           </td>
                           <td>
                              {{data.payout_amount | currency}}
                           </td>
                           <td>
                              <div [ngClass]="getStatus(data.status).bg"
                                 class="flex flex-col px-2.5 py-[5px] rounded-2xl">
                                 <span [ngClass]="getStatus(data.status).text" class="text-sm">{{data.status}}</span>
                              </div>
                           </td>
                           <td>
                              <button class="btn p-3 bg-blue-50 rounded-[4px] gap-2 border-0">
                                 <svg-icon src="@assets/images/icons/document-report.svg" class="text-blue-600">
                                 </svg-icon>
                              </button>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <!-- Pagination -->
               <div class="pagination flex items-center justify-between px-5">
                  <div class="flex flex-row gap-4 items-center">
                     <span class="text text-neutral-700 font-normal text-sm">Show Items:</span>
                     <div class="form">
                        <form [formGroup]="paginationForm">
                           <formly-form [form]="paginationForm" [fields]="paginationFormFields"
                              [model]="paginationFormModel">
                           </formly-form>
                        </form>
                     </div>
                     <span class="text text-neutral-700 font-normal text-sm">entries</span>
                  </div>
                  <div class="navigation">
                     <nav aria-label="Page navigation example">
                        <ul class="inline-flex">
                           <li>
                              <a href="#" class="btn btn-outline">
                                 <svg-icon src="@assets/images/icons/arrow-left.svg" class="btn-icon h-5 w-5">
                                 </svg-icon>
                                 <span>Previous</span>
                              </a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">1</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">2</a>
                           </li>
                           <li>
                              <a href="#" aria-current="page" class="btn btn-outline active">3</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">...</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">4</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">5</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">
                                 <span>Next</span>
                                 <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5">
                                 </svg-icon>
                              </a>
                           </li>
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>