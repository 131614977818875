import { GeoLocation, GeoPricingTier } from 'thkee-common';

// Define the enum for status
export enum GeoCRUDStatus {
  Empty = '',
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export type PricingGeoState = {
  error: object;
  pricingGeoList: GeoLocation[];
  pricingGeoData: GeoLocation;
  pricingGeoTiers: GeoPricingTier[];
  status: GeoCRUDStatus;
};

export const initialPricingGeoState: PricingGeoState = {
  error: {},
  pricingGeoList: [],
  pricingGeoData: {
    id: 0,
    date_created: '',
    date_updated: '',
    status: false,
    is_deleted: false,
    country: 0,
    currency: 0,
    country_str: '',
    currency_str: '',
    currency_convert: false,
  },
  pricingGeoTiers: [],
  status: GeoCRUDStatus.Empty,
};

export interface PricingGeoPayload {
  page: number;
  page_size: number;
}

export interface PricingGeoCreateUpdatePayload {
  id?: string;
  status: boolean;
  country: number;
  currency: number;
}

export interface PricingGeoLoadRemovePayload {
  id: string;
}

export interface GeoData {
  id: number;
  date_created: string;
  date_updated: string;
  status: boolean;
  is_deleted: boolean;
  country: number;
  currency: number;
}
