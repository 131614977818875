<div class="w-full mx-auto p-4">
   <div style="background-image: url('../../../../assets/images/stepper-bg.png')"
      class="flex flex-col gap-4 mb-8 p-6 rounded-xl bg-no-repeat bg-cover">
      <div class="flex items-center justify-center text-white">
         {{currentStep>3?'Review Finished':'Step'+(currentStep+1)}}
      </div>
      <div class="flex items-center justify-center">
         <div *ngFor="let step of steps; let i = index" class="flex items-center">
            <div class="relative flex items-center justify-center w-8 h-8 rounded-full border-2" [ngClass]="
                  {'border-green-500': currentStep > i, 
                  'border-white': currentStep === i, 
                  'border-neutral-300': currentStep !== i && currentStep < i}">
               <span *ngIf="currentStep <= i" class="text-white text-xs">{{ i + 1 }}</span>
               <svg *ngIf="currentStep > i" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500"
                  viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                     d="M16.707 5.293a1 1 0 010 1.414L8 15.414l-4.707-4.707a1 1 0 111.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                     clip-rule="evenodd" />
               </svg>
            </div>
            <div class="ml-2 text-sm text-neutral-300"
               [ngClass]="{'!text-green-500': currentStep > i,'text-white': currentStep === i,'text-neutral-300': currentStep !== i && currentStep < i}">
               {{ step.title }}
            </div>
            <div *ngIf="i < steps.length - 1" class="flex-1 h-[2px] w-8 bg-neutral-300 mx-2"
               [ngClass]="{'!bg-green-500': currentStep > i,'bg-white': currentStep === i,'bg-neutral-300': currentStep !== i && currentStep < i}">
            </div>
         </div>
      </div>
   </div>

   <div class="">
      <ng-container [ngSwitch]="currentStep">
         <div *ngSwitchCase="0">
            <app-intended [isDefaultView]="false"></app-intended>
         </div>
         <div *ngSwitchCase="1">
            <app-general [isDefaultView]="false"></app-general>
         </div>
         <div *ngSwitchCase="2">
            <app-curriculum [isDefaultView]="false"></app-curriculum>
         </div>
         <div *ngSwitchCase="3">
            <app-pricing [isDefaultView]="false"></app-pricing>
         </div>
         <div *ngSwitchCase="4" class="flex justify-center">
            <app-intended #intend [isDefaultView]="false" class="hidden"></app-intended>
            <ng-container *ngTemplateOutlet="finishedReview"></ng-container>
         </div>
      </ng-container>
   </div>

   <div [ngClass]="currentStep>3?'justify-center':'justify-between'" class="mt-4 flex">
      <button *ngIf="currentStep > 0" (click)="prevStep()"
         class="px-6 py-1 text-neutral-800 border border-neutral-800 rounded-md flex items-center gap-1">
         <svg-icon src="@assets/images/icons/arrow-left.svg" class="btn-icon w-4"></svg-icon>
         Back
      </button>
      <span></span>
      <ng-container *ngIf="currentStep < 3; else isFinished">
         <button (click)="nextStep()" class="px-6 py-1 bg-teal-50 text-teal-600 rounded-md flex items-center gap-1">
            Next
            <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon w-4"></svg-icon>
         </button>
      </ng-container>
      <ng-template #isFinished>
         <button *ngIf="currentStep < 4" (click)="nextStep()"
            class="px-6 py-1 bg-teal-50 text-teal-600 rounded-md">Finished</button>
      </ng-template>
   </div>

   <!-- Finishing -->
   <ng-template #finishedReview>
      <div class="flex flex-col items-center gap-4 bg-neutral-50 rounded-xl p-6 shadow-lg max-w-xl">
         <h3 class="text-center">You have successfully completed the review process!</h3>
         <div class=" flex items-center gap-3">
            <button class="btn-outline" (click)="actionEvent('reject')">
               <span>Reject</span>
            </button>
            <button class="btn-teal" (click)="actionEvent('accept')">
               <span>Accept</span>
            </button>
         </div>
      </div>
   </ng-template>
</div>