<ng-container *ngIf="{
    pricingGeoList: (pricingGeoList$ | async) ?? []
  } as vm">
  <div class="price-tiers p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>Geolocations</h3>
        <a routerLink="./new">
          <button class="btn-blue">
            <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
            <span>Add Geolocation</span>
          </button>
        </a>
      </div>
      <div class="actions flex gap-4">
        <!-- TODO: Convert to formly -->
        <div>
          <button class="btn-neutral">
            <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
            <span>Filter</span>
          </button>
        </div>
        <div class="input-combine flex">
          <div class="select2">
            <div class="relative">
              <a type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
                class="btn-neutral rounded-r-none">
                <div class="selected-item truncate">Geolocation ID</div>
                <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
              </a>
              <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"
                class="select-items hidden" ng-reflect-ng-class="hidden">
                <li id="listbox-option-0" role="option" class="item">Item 1</li>
                <li id="listbox-option-0" role="option" class="item">Item 2</li>
                <li id="listbox-option-0" role="option" class="item">Item 3</li>
              </ul>
            </div>
          </div>
          <div class="input-group">
            <div class="group !rounded-l-none">
              <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
              <input placeholder="Enter your full name" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tier-list">
      <app-data-table title="Geolocation List" [badge]="vm.pricingGeoList.length + ' Items'">
        <ng-container table>
          <table class="w-full text-left">
            <thead class="heading">
              <tr>
                <th scope="col" class="w-20">
                  <div class="col-name">ID</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Currency</div>
                </th>
                <th scope="col" class="">
                  <div class="sortable">
                    <div class="col-name">Country</div>
                  </div>
                </th>
                <th scope="col" class="">
                  <div class="sortable">
                    <div class="col-name">Currency</div>
                  </div>
                </th>
                <th scope="col" class="w-28">
                  <div class="col-name">Status</div>
                </th>
                <th scope="col" class="w-28">
                  <div class="col-name">Option</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-left" *ngFor="let item of vm.pricingGeoList">
                <td class="">
                  <span class="course-id">{{ item.id }} </span>
                </td>
                <td>
                  <div class="flex max-w-[170px] items-center">
                    <a routerLink="./{{ item.id }}" class="course-name text-blue-600 underline">{{ item.currency_str }}
                    </a>
                  </div>
                </td>
                <td>
                  <span class="course-price">{{ item.country_str }} </span>
                </td>
                <td>
                  <span class="course-price">{{ item.currency_str }} </span>
                </td>
                <td>
                  <div class="tag-green tag-sm" *ngIf="item.status">Active</div>

                  <div class="tag-red tag-sm" *ngIf="!item.status">Inactive</div>
                </td>
                <td>
                  <div class="flex justify-center gap-2">
                    <a routerLink="./{{ item.id }}/edit">
                      <button class="btn-amber-soft btn-sm px-2">
                        <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
                      </button>
                    </a>
                    <button class="btn-outline-red btn-sm px-2" (click)="deleteConfirmation(item)">
                      <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon h-4 w-4"></svg-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </app-data-table>
    </div>
  </div>
</ng-container>