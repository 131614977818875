import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  API_CONSTANTS_COUNTRIES,
  API_COUNTRY_LIST,
  ConstantCountryOption,
  HttpService,
  PricingCountryData,
  PricingCountryList,
  encodeURL,
} from 'thkee-common';

import { PricingCountryCreateUpdatePayload, PricingCountryLoadRemovePayload } from './country-state.model';
@Injectable({
  providedIn: 'root',
})
export class PricingCountryService {
  constructor(private readonly http: HttpService) {}

  getPricingCountryList(params = {} as object): Observable<PricingCountryList[]> {
    return this.http.get<PricingCountryList[]>(encodeURL(API_COUNTRY_LIST, params));
  }

  addPricingCountry(params = {} as object): Observable<any> {
    return this.http.post<any>(API_COUNTRY_LIST, params);
  }

  getConstantCountries(params = {} as object): Observable<ConstantCountryOption[]> {
    return this.http.get<ConstantCountryOption[]>(encodeURL(API_CONSTANTS_COUNTRIES, params));
  }

  getPricingCountry(params = {} as PricingCountryLoadRemovePayload): Observable<PricingCountryData> {
    const url = `${API_COUNTRY_LIST}${params?.id}/`;
    // console.log('url', url);
    return this.http.get<PricingCountryData>(url);
  }

  updatePricingCountry(params = {} as PricingCountryCreateUpdatePayload): Observable<any> {
    return this.http.patch<any>(API_COUNTRY_LIST + params?.id + '/', params);
  }

  removePricingCountry(params = {} as PricingCountryLoadRemovePayload): Observable<any> {
    return this.http.delete<any>(API_COUNTRY_LIST + params?.id + '/');
  }
}
