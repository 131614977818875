<div class="price-shares p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>{{ title }}</h3>
    </div>
  </div>

  <!-- Data record -->
  <div class="user-groups mb-4">
    <app-data-table title="User Groups" badge="{{ sharePricingData.length }} Instructor Group">
      <ng-container table>
        <app-form-price-shares [data]="sharePricingData"></app-form-price-shares>
      </ng-container>
    </app-data-table>
  </div>
  <!-- Notes -->
  <div class="notes mb-4">
    <div class="card flex flex-col gap-3 rounded-lg border border-neutral-100 px-6 py-8">
      <h4>Note</h4>
      <div class="border-b-2 border-b-neutral-100">
        <h6 class="mb-2">Instructor Shares %</h6>
        <div class="text-neutral-600">
          <p>
            The percentage share for instructor once transaction fees and platform share have been deducted from the net
            revenue of the sale.
          </p>
        </div>
      </div>
      <div class="border-b-2 border-b-neutral-100">
        <h6 class="mb-2">Platform Shares %</h6>
        <div class="text-neutral-600">
          <p>
            The percentage share earned by the Platform once transaction fees and instructor share have been deducted
            from the net revenue of the sale.
          </p>
          <p><small>Note: Platform Share % + Instructor Share % = %100 of Net Amuont.</small></p>
        </div>
      </div>
      <div>
        <h6 class="mb-2">Thkee Credits</h6>
        <div class="text-neutral-600">
          <p>Credits earned by the student who made the referral.</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Settings -->
  <div class="settings">
    <div class="card flex flex-col gap-3 rounded-lg border border-neutral-100 px-6 py-8">
      <div class="mb-1 flex items-center justify-between border-b-2 border-b-neutral-100">
        <div>
          <h6 class="mb-2">Settings</h6>
          <div class="text-neutral-600">
            <p>Sharing settings for referral</p>
          </div>
        </div>
        <div>
          <div *ngIf="isEditSettings === false">
            <button class="btn-amber-soft" (click)="editSettings(true)">
              <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
              Edit
            </button>
          </div>
          <div *ngIf="isEditSettings === true" class="flex gap-2">
            <button class="btn-neutral" (click)="editSettings(false)">Cancel</button>
            <button class="btn-teal" (click)="saveSettings()">Save</button>
          </div>
        </div>
      </div>

      <form [formGroup]="form" (submit)="saveSettings()">
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      </form>
    </div>
  </div>
</div>