import { createFeature, createReducer, on } from '@ngrx/store';
import { GeoCRUDStatus, initialPricingGeoState } from './geo-state.model';
import { PricingGeoActions } from './geo.actions';

export const pricingGeoReducer = createReducer(
  initialPricingGeoState,
  on(PricingGeoActions.loadGeoLocationSuccess, (state, { data }) => ({
    ...state,
    pricingGeoData: {
      id: 0,
      date_created: '',
      date_updated: '',
      status: false,
      is_deleted: false,
      country: 0,
      currency: 0,
      country_str: '',
      currency_str: '',
      currency_convert: false,
    },
    pricingGeoList: data,
  })),
  on(PricingGeoActions.addGeoLocSuccess, (state, { data }) => {
    return {
      ...state,
      pricingGeoList: { ...state?.pricingGeoList },
      status: GeoCRUDStatus.Success,
    };
  }),
  on(PricingGeoActions.addGeoLocFail, (state, {}) => ({
    ...state,
    status: GeoCRUDStatus.Failed,
  })),
  on(PricingGeoActions.getGeoLocSuccess, (state, { data }) => {
    return {
      ...state,
      pricingGeoData: data,
      status: GeoCRUDStatus.Success,
    };
  }),
  on(PricingGeoActions.loadGeoPricingTiersSuccess, (state, { data }) => {
    return {
      ...state,
      pricingGeoTiers: data,
    };
  })
);

export const pricingGeoFeature = createFeature({
  name: 'pricing-geo',
  reducer: pricingGeoReducer,
});
