<ng-container *ngIf="{
    priceCurrencyData: (priceCurrency$ | async) ?? priceCurrencyDataDefault,
    pricingCountryList: (pricingCountryList$ | async) ?? []
  } as vm">
  <div class="price-tiers-new p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="mb-4 flex w-full justify-between gap-4">
        <div>
          <h3>Add Currency</h3>
        </div>
        <div>
          <button [disabled]="!enableLiveRate" type="button" class="btn-blue" (click)="getLivesRates()">
            <svg-icon class="btn-icon" src="@assets/images/icons/arrow-trending-up.svg"></svg-icon> Get Lives Rates
          </button>
        </div>
      </div>
    </div>

    <div class="tier-form">
      <div class="card rounded-lg border border-neutral-100 px-6 py-9">
        <!-- {{ vm.priceCountryData | json }} -->
        <!-- <app-form-price-tier (onSubmit)="onSubmit($event)"></app-form-price-tier> -->
        <app-form-price-currency #currencyForm *ngIf="vm.priceCurrencyData" (submitEvent)="onSubmit($event)"
          [model]="formData" [countries]="vm.pricingCountryList" (changeEvent)="onChange($event)">
        </app-form-price-currency>
      </div>
    </div>
  </div>
</ng-container>