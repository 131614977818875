import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CurrencyData, ExchangRateActions, PricingCurrencyService, selectExchangeRateList } from '@shared/store';
import * as _ from 'lodash';
import { Observable, Subject, Subscription, skip, take } from 'rxjs';
import {
  CURRENCIES,
  ConstantCurrencyOption,
  CurrencyModel,
  ExchangRateData,
  ModalService,
  PricingCountryData,
} from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-form-price-currency',
  templateUrl: './price-currency.component.html',
  styleUrls: ['./price-currency.component.scss'],
})
export class FormPriceCurrencyComponent implements OnInit, OnDestroy {
  @Input() model: any = {}; //TODO: Update model here
  @Input() countries: PricingCountryData[] = [];
  @Output() init: EventEmitter<FormlyFieldConfig[]> = new EventEmitter<FormlyFieldConfig[]>();
  @Output() changeEvent: EventEmitter<Partial<any>> = new EventEmitter<Partial<any>>();
  @Output() submitEvent: EventEmitter<Partial<any>> = new EventEmitter<Partial<any>>(); //TODO: Update model here

  currencies: ConstantCurrencyOption[] = CURRENCIES;
  currencies2: ConstantCurrencyOption[] = [];

  countryModel!: CurrencyData;
  formTierFields$: Subject<FormlyFieldConfig[]> = new Subject();

  formTier = new FormGroup({});
  formTierModel: CurrencyModel = {
    name: '',
    currency_code: '',
    country: [],
    rate: 0,
    rounding_type: '',
    pricing_tier_status: false,
    force: false,
    published: false,
  };
  formTierFields: FormlyFieldConfig[] = [];

  showSubmit: boolean = true;

  selectedCountries: any = [];
  selectedCurrencyCode: string = '';

  // Live Rates
  exchangeRateList$: Observable<ExchangRateData[]> = this.store.select(selectExchangeRateList);
  exchangeRateList!: ExchangRateData[];

  currenciesOption: { label: string; value: string | number }[] = [];
  private currencyRateSubscription: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private store: Store,
    private readonly modalService: ModalService,
    private readonly pricingCurrencyService: PricingCurrencyService
  ) {}

  currencyFormInit() {
    this.formTierFields = [
      {
        fieldGroupClassName: 'grid grid-cols-12 gap-6',
        fieldGroup: [
          {
            className: 'col-span-3',
            key: 'name',
            type: 'select',
            props: {
              label: 'Currency',
              placeholder: 'Currency Name',
              required: true,
              options: this.currenciesOption,
              labelProp: 'label',
              stylish: false,
              hideRequiredMarker: true,
            },
            hooks: {
              onInit: (field) => {
                if (field.formControl) {
                  // if (typeof this.model.name !== 'undefined') {
                  //   this.initializeCountry(field, this.model.name);
                  // }
                  this.currencyRateSubscription.add(
                    field.formControl.valueChanges.pipe(take(1)).subscribe((value) => {
                      this.initializeCountry(field, value);
                    })
                  );
                }
              },
            },
          },
          {
            className: 'col-span-3',
            key: 'currency_code',
            type: 'input',
            props: {
              label: 'Currency Code',
              tips: 'This is a tip message for course title',
              placeholder: 'Currency Code',
              required: true,
              maxLength: 3,
            },
          },
          {
            className: 'col-span-6',
            fieldGroupClassName: 'grid grid-cols-12',
            fieldGroup: [
              {
                className: 'col-span-12',
                key: 'country',
                type: 'select',
                defaultValue: [],
                props: {
                  label: 'Supported Countries',
                  placeholder: 'Choose countries',
                  multiple: true,
                  stayPlaceholder: true,
                  tips: 'This is a tip message for course title',
                  stylish: true,
                  options: this.countries.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                },
              },
              {
                className: 'col-span-12',
                key: 'button-popup',
                type: 'button',
                props: {
                  inputAppendClass: ['b-0'],
                  wrapClass: 'mb-0 -mt-1',
                  template: `
                  <div class="text-sm flex flex-row gap-1 items-center hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 w-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>                
                    Set Default
                  </div>`,
                  click: this.resetSupportedCountry.bind(this),
                },
                expressions: {
                  hide: 'false',
                },
              },
            ],
          },
        ],
      },
      {
        fieldGroupClassName: 'grid grid-cols-12 gap-6 mt-10',
        fieldGroup: [
          {
            className: 'col-span-2',
            key: 'rate',
            type: 'input',
            defaultValue: 1,
            props: {
              label: 'Rate',
              tips: 'This is a tip message for course title',
              placeholder: 'Rate',
              required: true,
              maxLength: 10,
              // disabled: true,
              blur: (a, event) => {
                const val = Number(String(event.target.value).replace(/[^0-9.]/g, ''));
                event.target.value = val.toFixed(6);
              },
            },
          },
          {
            className: 'col-span-4',
            key: 'rounding_type',
            type: 'select',
            defaultValue: 'decimal>=0.5',
            props: {
              label: 'Rounding Type',
              placeholder: '',
              required: true,

              options: [
                { label: 'Default Rounding', value: 'decimal>=0.5' },
                {
                  label: 'Roundind with 1.00 intervals (1.01-1.49 round to 1.00, 1.50-1.99 round to 2.00)',
                  value: '1.49=<decimal>=1.50',
                },
                {
                  label: 'Roundind with 1.00 intervals (1.01-1.99 round to 2.00)',
                  value: 'decimal>=1.01',
                },
              ],
              tips: 'This is a tip message for course title',
            },
          },

          {
            className: 'col-span-2',
            fieldGroupClassName: 'grid grid-cols-1',
            fieldGroup: [
              {
                template: '<label class="block mb-2 font-bold">Pricing Tier Status</label>',
              },
              {
                key: 'pricing_tier_status',
                type: 'toggle',
                defaultValue: false,
                props: {
                  label: '',
                  tips: 'This is a tip message for course title',
                },
              },
            ],
          },

          {
            className: 'col-span-2',
            fieldGroupClassName: 'grid grid-cols-1',
            fieldGroup: [
              {
                template: '<label class="block mb-2 font-bold">Force</label>',
              },
              {
                key: 'force',
                type: 'toggle',
                defaultValue: false,
                props: {
                  label: '',
                  tips: 'This is a tip message for course title',
                },
              },
            ],
          },
          {
            className: 'col-span-2',
            fieldGroupClassName: 'grid grid-cols-1',
            fieldGroup: [
              {
                template: '<label class="block mb-2 font-bold">Published</label>',
              },
              {
                key: 'published',
                type: 'toggle',
                defaultValue: false,
                props: {
                  label: '',
                  tips: 'This is a tip message for course title',
                },
              },
            ],
          },
        ],
      },
    ];
  }

  isLoading: boolean = false;
  selectedCountry: Array<number> | undefined = [];

  ngOnInit(): void {
    // setTimeout(() => {
    //   if (this.model && this.currencies.length > 0) {
    //     // this.formTierModel = this.model;
    //     this.formTierModel = { ...this.model };
    //     this.formTierFields$.next(this.getFormlyFields());
    //     this.formTierFields = this.getFormlyFields();
    //     this.init.emit(this.formTierFields);

    //     this.formTier.valueChanges.pipe(skip(1), untilDestroyed(this)).subscribe((value) => {
    //       this.changeEvent.emit(value);
    //     });
    //   }

    //   if (!this.isAllDisabled(this.formTierFields).includes(false)) {
    //     console.log('isAllDisabled');
    //     this.showSubmit = false;
    //   }
    // }, 500);

    this.isLoading = true;
    this.pricingCurrencyService.getAllCurrencies().subscribe({
      next: (res) => {
        this.currencies2 = res;
        this.currenciesOption = res.map((currencies) => ({
          label: _.startCase(currencies.name),
          value: currencies.name !== undefined ? currencies.name : '',
        }));

        this.formTierModel = {
          ...this.model,
          ...this.formTier.value,
          name: res.find((r) => r.name === this.model.name)?.name,
        };
        this.formTier.patchValue(this.formTierModel);
        this.currencyFormInit();
        this.selectedCountry = this.model.country;
        // this.formTierFields$.next(this.getFormlyFields());
        // this.formTierFields = this.getFormlyFields();
        this.init.emit(this.formTierFields);

        this.formTier.valueChanges.pipe(skip(1), untilDestroyed(this)).subscribe((value) => {
          this.changeEvent.emit(value);
        });

        if (!this.isAllDisabled(this.formTierFields).includes(false)) {
          this.showSubmit = false;
        }

        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  submit() {
    this.submitEvent.emit({
      valid: this.formTier.valid,
      selectedCountries: this.selectedCountries,
      values: {
        ...this.model,
        ...this.formTier.value,
      },
    });
  }

  isAllDisabled(objects: FormlyFieldConfig[]): boolean[] {
    const disabledValues: boolean[] = [];
    for (const obj of objects) {
      if (obj.key && obj.type) {
        if (obj.props) {
          disabledValues.push(!!obj.props.disabled);
        }
      }
      if (obj.fieldGroup && obj.fieldGroup.length > 0) {
        disabledValues.push(...this.isAllDisabled(obj.fieldGroup));
      }
    }
    return disabledValues;
  }

  initializeCountry(field: any, selectedCurrency: string) {
    if (selectedCurrency) {
      if (field.parent?.fieldGroup) {
        const selectedOption = this.currencies2.find((currency) => currency.name === selectedCurrency);
        this.selectedCountry = selectedOption?.countries;
        if (selectedOption) {
          this.formTier.patchValue({ currency_code: selectedOption.currency_code });
          this.formTier.patchValue({ country: selectedOption?.countries });
          setTimeout(() => {
            this.currencyFormInit();
          }, 500);

          const filteredCountries = this.countries.filter(
            (country) => country.currency_code === selectedOption.currency_code
          );
          this.selectedCountries = filteredCountries;
        }
      }
    }
  }

  getLivesRates(currency: string) {
    if (currency) {
      this.exchangeRateList$.subscribe((rates) => {
        this.exchangeRateList = rates;
        const selectedRate = _.find(this.exchangeRateList, { to_currency: currency });
        if (selectedRate) {
          // this.formTierModel.rate = selectedRate.rate;
          // this.formTier.controls['rate'].setValue(selectedRate.rate);
          this.formTier.patchValue({ rate: selectedRate.rate });
        }
      });
      if (!this.exchangeRateList.length) {
        this.store.dispatch(
          ExchangRateActions.loadExchangeRatesList({
            page: 1,
            page_size: 10,
          })
        );
      }
    }
  }

  resetSupportedCountry() {
    this.formTier.patchValue({ country: this.selectedCountry });
    this.currencyFormInit();
  }

  ngOnDestroy(): void {
    this.currencyRateSubscription.unsubscribe();
  }
}
