<div class="course-overview p-4 pt-0">
   <div class="flex flex-col gap-4 mb-4 relative min-h-[100px]">
      <ng-container *ngIf="isLoadingSummary; else loadedSummary">
         <ng-container *ngTemplateOutlet="loader"></ng-container>
      </ng-container>
      <ng-template #loadedSummary>
         <div class="flex flex-row gap-4">
            <div class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
               <span class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-[#6B7280]"></span>
               <h5 class="text-xs font-normal text-[#4B5563]" i18n>Bank Under Review</h5>
               <h5 class="text-lg font-bold text-[#6B7280]">{{payoutActivationSummary.bank_under_review}}</h5>
            </div>
            <div class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
               <span class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-[#6B7280]"></span>
               <h5 class="text-xs font-normal text-[#4B5563]" i18n>Pending Applications</h5>
               <h5 class="text-lg font-bold text-[#6B7280]">{{payoutActivationSummary.pending_applications}}</h5>
            </div>
            <div class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
               <span class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-[#6B7280]"></span>
               <h5 class="text-xs font-normal text-[#4B5563]" i18n>Unverified Instructors</h5>
               <h5 class="text-lg font-bold text-[#6B7280]">{{payoutActivationSummary.unverified_instructors}}</h5>
            </div>
            <div class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
               <span class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-[#6B7280]"></span>
               <h5 class="text-xs font-normal text-[#4B5563]" i18n>Verified Instructors</h5>
               <h5 class="text-lg font-bold text-[#6B7280]">{{payoutActivationSummary.verified_instructors}}</h5>
            </div>
            <div class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
               <span class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-[#6B7280]"></span>
               <h5 class="text-xs font-normal text-[#4B5563]" i18n>Rejected Applications</h5>
               <h5 class="text-lg font-bold text-[#6B7280]">{{payoutActivationSummary.rejected_applications}}</h5>
            </div>
         </div>
      </ng-template>
   </div>

   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <div class="input-combine flex gap-2">
            <div class="input-group">
               <div class="group rounded-l-none">
                  <input (change)="selectSubmitDate($event)" type="date" placeholder="Submited Date" />
               </div>
            </div>
            <div class="input-group">
               <div class="group rounded-l-none">
                  <input (change)="selectVerifiedDate($event)" type="date" placeholder="Verified Date" />
               </div>
            </div>
            <div class="input-group">
               <div class="group rounded-l-none">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input (keyup.enter)="search($event)" placeholder="Enter your full name" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Table -->
   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-start px-6 py-5">
            <div class="flex items-center gap-1">
               <h6>Verification Applications </h6>
               <div class="tag tag-sm text-slate-600">{{payoutActivation?.count}} Payee</div>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list relative">
               <ng-container *ngIf="isLoading">
                  <ng-container *ngTemplateOutlet="loader"></ng-container>
               </ng-container>
               <ng-container *ngIf="payoutActivation?.results?.length === 0; else isTransactions">
                  <span class="text-center text-2xl my-11 block">No Payout Activation Founded!</span>
               </ng-container>
               <ng-template #isTransactions>
                  <ng-container *ngTemplateOutlet="listTransactions; context: { items: payoutActivation?.results }">
                  </ng-container>
               </ng-template>
               <!-- Pagination -->
               <div class="z-30 relative">
                  <app-common-pagination (paginatorData)="paginat($event)" [type]="'dynamic'"></app-common-pagination>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Table -->
<ng-template #listTransactions let-items="items">
   <div class="datagrid-table relative overflow-x-auto min-h-[336px]">
      <table class="w-full text-left">
         <thead class="heading">
            <tr>
               <th scope="col" class="">
                  <div class="col-name">Instructor Name</div>
               </th>
               <th scope="col" class="">
                  <div class="col-name">Submited Date</div>
               </th>
               <th scope="col" class="">
                  <div class="col-name">Verification Status</div>
               </th>
               <th scope="col" class="">
                  <div class="col-name">Verified / Rejected Date</div>
               </th>
               <th scope="col" class="text-center">
                  <div class="col-name">Payouts Methods Status</div>
               </th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let activation of payoutActivation?.results">
               <td>
                  <span class="course-id">
                     <span class="text-slate-600">
                        <a class="cursor-pointer hover:underline" routerLink="/users/payout-activation/details/{{activation.id}}" [queryParams]="{'params':'profile'}">
                           {{ activation.instructor_name }}
                        </a>
                     </span>
                  </span>
               </td>
               <td>
                  <div class="flex flex-col items-start">
                     <span
                        class="course-name line-clamp-3 text-slate-600">
                        {{ activation.submited_date | date:'MMMM d, y' }}
                     </span>
                     <span
                        class="course-name line-clamp-3 text-slate-400 text-xs">
                        {{ activation.submited_date | date:'h:mm a' }}
                     </span>
                  </div>
               </td>
               <td class="max-w-[170px]">
                  <span class="tag tag-{{ getStatusTagColor(activation.status) }} tag-sm">
                     {{ activation.status }}
                  </span>
               </td>
               <td>
                  <div *ngIf="activation.verified_date" class="flex flex-col items-start">
                     <span class="course-name line-clamp-3 text-slate-600">
                           {{ activation.verified_date | date:'MMMM d, y' }}
                        </span>
                     <span class="course-name line-clamp-3 text-slate-400 text-xs">
                        {{ activation.verified_date | date:'h:mm a' }}
                     </span>
                  </div>
               </td>
               <td class="max-w-[170px] text-center">
                  <div *ngIf="activation.payout_method_status === 'Action required'; else noActionRequired" class=" text-blue-600 underline cursor-pointer">
                     {{activation.payout_method_status}}
                  </div>
                  <ng-template #noActionRequired>
                     <div class="">
                        {{activation.payout_method_status}}
                     </div>
                  </ng-template>
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</ng-template>


<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>