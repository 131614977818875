<ng-container *ngIf="isDefaultView">
  <app-course-header [courseState]="courseState" (clickEvent)="actionEvent($event)"></app-course-header>
</ng-container>
<div class="course-intended p-4">
  <app-course-heading>
    <ng-container actions>
      <ng-container *ngIf="isDefaultView">
        <button *ngIf="courseState === '0'" class="btn-amber-soft btn-lg"
          (click)="readOnly = !readOnly; initFields(readOnly)">
          <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
          <span>Edit</span>
        </button>
      </ng-container>
    </ng-container>
  </app-course-heading>
  <div>
    <div>
      <div class="card mb-4 block rounded-[10px] border border-neutral-100 px-6 py-9">
        <form [formGroup]="form">
          <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
        </form>
        <common-modal #feedbackModal title="Feedback for Instructor" customClass="sm:w-full sm:max-w-3xl"
          (closeEvent)="onFeedbackCancel()">
          <ng-container content *ngIf="feedbackModal.isOpened">
            <app-common-feedback (submitEvent)="onFeedbackSubmit($event)" (cancel)="onFeedbackCancel()">
            </app-common-feedback>
          </ng-container>
        </common-modal>
      </div>
    </div>
  </div>
</div>