import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CountryCRUDStatus,
  PricingCountryActions,
  PricingCountryCreateUpdatePayload,
  PricingCountryService,
  selectCountryCRUDStatus,
  selectLoadedPricingCountryData,
} from '@shared';
import { Observable } from 'rxjs';
import { ConstantCountryOption, PricingCountry, ToastService } from 'thkee-common';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
})
export class PriceCountriesNewComponent implements OnInit {
  pricingcountryPayload!: PricingCountryCreateUpdatePayload;
  countriesOptions$: Observable<ConstantCountryOption[]> = new Observable();
  priceCountry$: Observable<PricingCountry> = this.store.select(selectLoadedPricingCountryData);
  status$: Observable<CountryCRUDStatus> = this.store.select(selectCountryCRUDStatus);

  priceCountryDataDefault: PricingCountry = {
    id: '',
    name: '',
    two_letter_iso_code: '',
    three_letter_iso_code: '',
    numeric_iso_code: '',
    subject_to_vat: false,
    allow_billing: false,
    currency_code: '',
    sort_number: 0,
  };

  formData: any = {};
  country: string = '';

  constructor(
    private store: Store,
    private pricingCountryService: PricingCountryService,
    private readonly toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.countriesOptions$ = this.pricingCountryService.getConstantCountries();
    this.store.dispatch(PricingCountryActions.clearCountryCRUDStatus());
  }

  onSubmit(event: any) {
    let values = event?.values;
    this.pricingcountryPayload = values;
    this.country = this.pricingcountryPayload.name;
    this.store.dispatch(PricingCountryActions.addPricingCountry(this.pricingcountryPayload));

    this.status$.subscribe((status) => {
      console.log('STATUS', status);
      if (status === CountryCRUDStatus.Success) {
        this.toastService.message({
          message: `${this.country} country saved successfully`,
        });
        this.router.navigate(['/price-management/countries']); // Navigate to the countries list
      }

      if (status === CountryCRUDStatus.Failed) {
        this.toastService.error({
          message: `Something went wrong. Please try again. `,
        });
      }
    });
  }
}
