import * as failedPayout from './failed-payout';
import * as upcomingPayout from './hold-transaction';
import * as inactivePayout from './inactive-payout';
import * as instructorBalance from './instructor-balance';
import * as paidPayout from './paid-payout';
import * as payoutRequest from './payout-request';
import * as holdTransaction from './upcoming-payout';

export const Components = [
  ...paidPayout.Components,
  ...instructorBalance.Components,
  ...payoutRequest.Components,
  ...upcomingPayout.Components,
  ...holdTransaction.Components,
  ...inactivePayout.Components,
  ...failedPayout.Components,
];

export const Routing = [
  ...paidPayout.Routing,
  ...instructorBalance.Routing,
  ...payoutRequest.Routing,
  ...upcomingPayout.Routing,
  ...holdTransaction.Routing,
  ...inactivePayout.Routing,
  ...failedPayout.Routing,
];
