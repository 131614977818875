import { PricingCountryData } from 'thkee-common';

// Define the enum for status
export enum CountryCRUDStatus {
  Empty = '',
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
  Message = '',
}

export type PricingCountryState = {
  error: object;
  pricingCountryList: PricingCountryData[];
  pricingCountryData: PricingCountryData;
  status: CountryCRUDStatus;
};

export const initialPricingCountryState: PricingCountryState = {
  error: {},
  pricingCountryList: [],
  pricingCountryData: {
    id: '',
    name: '',
    two_letter_iso_code: '',
    three_letter_iso_code: '',
    numeric_iso_code: '',
    subject_to_vat: false,
    allow_billing: false,
    currency_code: '',
    sort_number: 0,
  },
  status: CountryCRUDStatus.Empty,
};

export interface PricingCountryPayload {
  page: number;
  page_size: number;
}

export interface PricingCountryCreateUpdatePayload {
  id?: string;
  name: string;
  two_letter_iso_code: string;
  three_letter_iso_code: string;
  numeric_iso_code: string;
  allow_billing: boolean;
  price_tier_status: boolean;
  currency_code: string;
}

export interface PricingCountryLoadRemovePayload {
  id: string;
}

export interface CountryData {
  alpha3: string;
  code: string;
  name: string;
  numeric: number;
  currency_code: string;
}
