import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import videojs from 'video.js';
// import { tracks } from '../../../../../assets/video/texttracks';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, Subject } from 'rxjs';
import { LoaderService, VideoData } from 'thkee-common';
import data from '../../../../assets/video/texttracks.json';
import '../../../../videojs/plugins/es/nuevo';
import '../../../../videojs/plugins/es/videojs.hotkeys';
// declare var videojs: any; // Declare videojs as a global variable

export interface InputData {
  currentTime?: number;
  currentDuration?: number;
}
export interface SpriteThumbnail {
  slideImage: string;
  slideType?: string;
  slideWidth?: number;
  slideHeight?: number;
}

@UntilDestroy()
@Component({
  selector: 'app-thk-video-player',
  templateUrl: './thk-video-player.component.html',
  styleUrls: ['./thk-video-player.component.scss'],
})
export class ThkVideoPlayerComponent implements OnInit, OnDestroy {
  @Output() event = new EventEmitter<{ action: string; data: InputData }>();
  @ViewChild('video', { static: true }) videoElement!: ElementRef<HTMLVideoElement>;

  player: any;
  // @Input() sources: URLSType[] = [];
  @Input() sources$: Subject<VideoData> = new Subject<VideoData>();
  @Input() settingsButton: boolean = true;
  @Input() relatedMenu: boolean = true;
  @Input() relatedVideos: any[] = [];
  @Input() qualityMenu: boolean = true;
  @Input() buttonRewind: boolean = true;
  @Input() controlbar: boolean = true;
  @Input() theaterButton: boolean = true;
  @Input() autoplay: boolean = true;
  @Input() singlePlayer: boolean = false;
  // @Input() poster: string = '';
  @Input() poster: string = 'https://cdnzone.nuevodevel.com/video/hls/tos/tearsd.jpg';

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.sources$.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe({
      next: (res: VideoData) => {
        console.log('🚀 ~ ThkVideoPlayerComponent ~ this.sources$.pipe ~ res:', res);
        if (res?.video_url?.length > 0) {
          setTimeout(() => {
            this.initializePlayer(res);
          });
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  private initializePlayer(videoData: VideoData) {
    // Video Thumbnail sprite
    const videoThumbSprite = {
      slideImage: videoData?.thumbnails?.url,
      slideType: 'vertical', //optional
      slideWidth: 160, //optional
      slideHeight: 90, //optional
    };

    // Video Source
    const sources = videoData?.video_url.map((video) => {
      let type;
      switch (video.type) {
        case 'mp4':
          type = 'video/mp4';
          break;
        case 'mpeg-dash':
          type = 'application/dash+xml';
          break;
        case 'm3u8':
          type = 'application/x-mpegURL';
          break;
        default:
          type = 'video/mp4';
      }
      return { src: video.url, type };
    });

    const videojs_options = {
      poster: this.poster,
      sources: sources,
    };

    window.videojs = videojs;

    this.player = videojs('video_holder', videojs_options, () => {
      console.log('Player ready!');
    });

    const nuevo_options = {
      video_id: 'Nuevo E1',
      url: 'https://www.nuevodevel.com',
      title: 'Video.js Angular 16 Demo (Hls,Captions,Chapters)',
      controlbar: this.controlbar,
      settingsButton: this.settingsButton,
      relatedMenu: this.relatedMenu,
      related: this.relatedVideos,
      qualityMenu: this.qualityMenu,
      buttonRewind: this.buttonRewind,
      resOnly: true,
      singlePlay: this.singlePlayer,
      ...videoThumbSprite,
      theaterButton: this.theaterButton,
    };

    this.player.nuevo(nuevo_options);
    this.player.hotkeys({
      volumeStep: 0.1,
      seekStep: 5,
    });

    this.player.on('loadeddata', () => {
      this.player.loadTracks(data);
      this.emitEvent('loadedmetadata', {
        currentTime: this.player.currentTime(),
        currentDuration: this.player.duration(),
      });
    });

    this.player.on('play', () => {
      this.emitEvent('play', { currentTime: this.player.currentTime(), currentDuration: this.player.duration() });
    });

    this.player.on('pause', () => {
      this.emitEvent('pause', { currentTime: this.player.currentTime(), currentDuration: this.player.duration() });
    });

    this.player.on('ended', () => {
      this.emitEvent('ended', { currentTime: this.player.currentTime(), currentDuration: this.player.duration() });
    });
    this.player.on('mode', (event: any, mode: string) => {
      if (mode == 'large') {
        this.loaderService.loaderAction('theater-button-action', 'success');
      } else if (mode == 'normal') {
        this.loaderService.loaderAction('theater-button-action', 'loading');
      }
    });
  }

  private emitEvent(action: string, data: InputData = {}) {
    this.event.emit({ action, data });
  }

  onPlay() {
    this.player.play();
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
