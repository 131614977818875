import { ShellCom } from './sidebar-constant';

export interface TabItem {
  id: number;
  displayName: string;
  route: string;
  hasChanges?: boolean;
  shell?: ShellCom;
}

export const COURSES_OVERVIEW: TabItem[] = [
  {
    id: 1,
    displayName: 'All Course List',
    route: '/overview',
  },
  {
    id: 2,
    displayName: 'Statistics',
    route: '/statistics',
  },
];

export const COURSE_TAB: TabItem[] = [
  {
    id: 1,
    displayName: 'Statistics',
    route: '/statistics',
  },
  {
    id: 2,
    displayName: 'Details',
    hasChanges: true,
    route: '/details',
  },
  {
    id: 3,
    displayName: 'Intended Learners',
    route: '/intended-learners',
  },
  {
    id: 4,
    displayName: 'Curriculum',
    route: '/curriculum',
  },
  {
    id: 5,
    displayName: 'Pricing',
    route: '/pricing',
  },
  {
    id: 6,
    displayName: 'Q & A',
    route: '/qa',
  },
  {
    id: 7,
    displayName: 'Reviews',
    route: '/reviews',
  },
  {
    id: 8,
    displayName: 'Activities',
    route: '/activities',
  },
];

export const PRICESHARE_TAB: TabItem[] = [
  {
    id: 1,
    displayName: 'Organics',
    route: '/organics',
  },
  {
    id: 2,
    displayName: 'Ads',
    route: '/ads',
  },
  {
    id: 3,
    displayName: 'Instructor Referral',
    route: '/instructor-referral',
  },
  {
    id: 4,
    displayName: 'Affiliate',
    route: '/affiliate',
  },
  {
    id: 5,
    displayName: 'Student Referral',
    route: '/student-referral',
  },
];

// Product details drawer tab
export const PRODUCT_DRAWER_TAB: TabItem[] = [
  {
    id: 0,
    displayName: 'General',
    route: '',
  },
  {
    id: 1,
    displayName: 'Description',
    route: '',
  },
  {
    id: 2,
    displayName: 'Course Curriculum',
    route: '',
  },
  {
    id: 3,
    displayName: 'Pricing',
    route: '',
  },
  {
    id: 4,
    displayName: 'Q & A',
    route: '',
  },
  {
    id: 5,
    displayName: 'Reviews',
    route: '',
  },
  {
    id: 6,
    displayName: 'Activities',
    route: '',
  },
];

// Dashboard tab
export const DASHBOARD_TAB: TabItem[] = [
  {
    id: 0,
    displayName: 'Transaction',
    route: '',
  },
  {
    id: 1,
    displayName: 'Statements',
    route: '',
  },
  {
    id: 2,
    displayName: 'Instructor Statements',
    route: '',
  },
  {
    id: 3,
    displayName: 'Orders',
    route: '',
  },
  {
    id: 4,
    displayName: 'Refund Request',
    route: '',
  },
];

// Student details drawer tab
export const STUDENT_DRAWER_TAB: TabItem[] = [
  {
    id: 0,
    displayName: 'Profile',
    route: '',
  },
  {
    id: 1,
    displayName: 'Payment Methods',
    route: '',
  },
  {
    id: 2,
    displayName: 'Orders',
    route: '',
  },
  {
    id: 3,
    displayName: 'Refund',
    route: '',
  },
  {
    id: 4,
    displayName: 'Messages',
    route: '',
  },
  {
    id: 5,
    displayName: 'Purchased',
    route: '',
  },
  {
    id: 6,
    displayName: 'Progress',
    route: '',
  },
  {
    id: 7,
    displayName: 'Issues Report',
    route: '',
  },
];

// Student Settings details drawer tab
export const STUDENT_SETTINGS_DRAWER_TAB: TabItem[] = [
  {
    id: 0,
    displayName: 'Profile',
    route: '',
  },
  {
    id: 1,
    displayName: 'Security',
    route: '',
  },
  {
    id: 2,
    displayName: 'Privacy',
    route: '',
  },
  {
    id: 3,
    displayName: 'Notification',
    route: '',
  },
];

// Instructor details drawer tab
export const INSTRUCTOR_DRAWER_TAB: TabItem[] = [
  {
    id: 0,
    displayName: 'Profile',
    route: '',
  },
  {
    id: 1,
    displayName: 'Courses',
    route: '',
  },
  {
    id: 2,
    displayName: 'Payout',
    route: '',
  },
  {
    id: 3,
    displayName: 'Hold Transaction',
    route: '',
  },
  {
    id: 4,
    displayName: 'Messages',
    route: '',
  },
  {
    id: 5,
    displayName: 'Issues Report',
    route: '',
  },
];

// Instructor Settings details drawer tab
export const INSTRUCTOR_SETTINGS_DRAWER_TAB: TabItem[] = [
  {
    id: 0,
    displayName: 'Profile',
    route: '',
  },
  {
    id: 1,
    displayName: 'Instructor Group',
    route: '',
  },
  {
    id: 2,
    displayName: 'Security',
    route: '',
  },
  {
    id: 3,
    displayName: 'Privacy',
    route: '',
  },
  {
    id: 4,
    displayName: 'Notification',
    route: '',
  },
];

// export const PAYOUT_REQUEST_TAB: TabItem[] = [
//   {
//     id: 1,
//     displayName: 'All',
//     route: '/all',
//   },
//   {
//     id: 2,
//     displayName: 'Monthly',
//     route: '/monthly',
//   },
//   {
//     id: 3,
//     displayName: 'On Demand',
//     route: '/ondemand',
//   },
// ];

export const UPCOMING_PAYOUT_TAB: TabItem[] = [
  {
    id: 1,
    displayName: 'Monthly',
    route: '/monthly',
  },
];
