<div class="course-overview p-4 pt-0">

   <div class="flex flex-col gap-4 mb-4">
      <div class="flex flex-row gap-4">
         <div *ngIf="!summary" class="flex w-full py-4 justify-center" [thkLibProcessing]="true"></div>
         <ng-container *ngIf="summary">
            <app-metric-card class="w-[300px]" [data]="{ title: 'Total amount of hold transactions.', value: summary.total_amount }"></app-metric-card>
            <app-metric-card class="w-[300px]" [data]="{ title: 'Total Hold Transactions', value: summary.total_hold_transactions | currency:summary.currency_symbol }"></app-metric-card>
         </ng-container>
      </div>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'hidden':''"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <form [formGroup]="filterForm">
                     <formly-form [form]="filterForm" [fields]="filterFormFields" [model]="filterFormModel">
                     </formly-form>
                  </form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">Reset</button>
               </div>
            </div>
         </div>
         <!-- Search With Dropdown -->
         <app-combination-single-input [keys]="searchOptions" [model]="search" (valueChanges)="handleSearch($event)"></app-combination-single-input>
      </div>
   </div>

   <div class="flex-3 w-full overflow-hidden card rounded-lg border border-neutral-100">
      <div class="card-subheader flex items-center justify-between px-6 py-5">
         <div class="flex items-center gap-1">
            <h6>Transactions</h6>
            <div *ngIf="transactionsPagination" class="tag tag-sm">{{transactionsPagination.count}} transactions</div>
         </div>
         <div class="actions flex flex-row gap-4">
            <button class="btn-neutral px-3">
               <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
            </button>
         </div>
      </div>
      <div class="card-body">
         <div class="courses-list">
            <!-- Table -->
            <div class=" datagrid-table relative overflow-x-auto">
               <table class="w-full min-w-[850px] text-left">
                  <thead class="heading">
                     <tr>
                        <th scope="col" class="">
                           <div class="col-name">Trans. ID</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Course Name</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Instructor</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Period</div>
                        </th>
                        <th scope="col" class="w-28">
                           <div class="col-name">Payout Method</div>
                        </th>
                        <th scope="col" class="w-28">
                           <div class="col-name">Payout Type</div>
                        </th>
                        <th scope="col" class="w-28">
                           <div class="col-name">Total Amount</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Hold By</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Actions</div>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <div *ngIf="!transactionsPagination" class="flex py-4 justify-center" [thkLibProcessing]="true"></div>
                     <tr class="hover:cursor-pointer" *ngFor="let data of transactionsPagination?.results">
                        <td>
                           <span (click)="openTransactionDrawer(data, transactionDrawer)" class="course-id">
                              <span class="text-blue-600 cursor-pointer">
                                 {{ data.transaction_id }}
                              </span>
                           </span>
                        </td>
                        <td>
                           <span (click)="productDrawer.open()" class="course-id">
                              <span class="text-blue-600 cursor-pointer">
                                 {{ data.course?.title }}
                              </span>
                           </span>
                        </td>
                        <td>
                           <span (click)="buyerDrawer.open()" class="course-id">
                              <span class="text-blue-600 cursor-pointer">
                                 {{ data.instructor?.fullname }}
                              </span>
                           </span>
                        </td>
                        <td>
                           <span class="course-id">
                              <span class="">
                                 {{ data.date_created | date:'d MMMM y' }}
                              </span>
                           </span>
                        </td>
                        <td class="max-w-[170px]">
                           <span class="">
                              {{ data.payment_method }}
                           </span>
                        </td>
                        <td>
                           {{data.payout_status | currency}}
                        </td>
                        <td>
                           {{data.sale_price | currency:data.currency_symbol}}
                        </td>
                        <td>
                           <span class="rounded-full px-2 py-[2px] bg-amber-100 text-amber-600 text-xs">
                              {{ data.hold_by?.fullname }}
                           </span>
                        </td>
                        <td>
                           <button (click)="openUnholdConfirmationModal(data, unholdConfirmationModal)" class="btn bg-teal-600 text-white btn-sm">Unhold</button>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <!-- Pagination -->
            <div *ngIf="transactionsPagination && transactionsPagination.count" class="pagination flex items-center justify-between px-5 py-6">
               <thk-pagination class="w-full" (paginationChange)="paginate($event)" [page]="page" [sizeChangable]="true" [total]="transactionsPagination.count" [size]="size"></thk-pagination>
            </div>
         </div>
      </div>
   </div>
</div>


<!-- Unhold confirm modal -->
<!-- <common-modal #unHoldConfirmed customClass="sm:w-full sm:max-w-[788px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl">Unhold Transaction</h1>
         </div>
         <button (click)="unHoldConfirmed.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Transaction ID</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 routerLink="/ecommerce/sales/transaction/0123521"
                  class="text-xl font-semibold text-blue-600 cursor-pointer">#0123521</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Sale Price</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Sale Price which is actual amount paid by the customer" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  $30
               </h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Net Amount</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Net Price which is actual amount paid by the customer " class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  $23
               </h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Instructor Share</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The total amount (in USD) earned by all instructors once transaction fees and platform/affiliate commissions have been deducted from the net revenue of the sale."
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  $730
               </h6>
            </div>
         </div>
      </div>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-4 w-full">
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Instructor</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The name of the instructor associated with the course purchased." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Omar Sherif ( <span class="text-blue-600">#12568</span> )
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Date</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The day of the week, day of the month, and time (your local time) at which the transaction took place."
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Oktober 28, 2022
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payment Gateway</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Filter if the transaction was made via credit card or PayPal." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Paypal
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payment Method</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Method" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Credit Card
               </h6>
            </div>
         </div>
      </div>

      <div class="card-subheader flex flex-col items-center justify-center px-6 py-5 border-b border-b-neutral-100">
         <h3 class="text-neutral-800 text-base font-normal">Are you sure to Unhold this transaction and </h3>
         <h3 class="text-neutral-800 text-base font-normal">will procees to Upcoming Payout at <b>July 2022?</b></h3>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="unHoldConfirmed.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
            </button>
            <button (click)="unHoldConfirmed.close()" class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold">Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal> -->

<!-- Transaction details drawer -->
<app-drawer #transactionDrawer (closeDrawer)="true">
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h1 class="text-black font-semibold text-xl">Transaction Details</h1>
      </div>
   </ng-container>
   <ng-container content>
      <app-transaction-details-overview *ngIf="transactionData" [id]="transactionData.id" [isSidebarComponent]="true">
      </app-transaction-details-overview>
   </ng-container>
</app-drawer>


<!-- Buyer drawer -->
<app-drawer #buyerDrawer>
   <!-- Header -->
   <ng-container header>
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
         <h3 class="text-xl text-black font-semibold">Faris Friansyah</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to Instructor detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="buyerDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>

   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">ID Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">#325</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Courses</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">25</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Total Income</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">$500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Join Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">Nov 08, 2022, 2:03 PM</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100">
         <ng-container *ngFor="let tab of instructorTabLists">
            <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Details -->
      <div class="flex flex-col p-6 pb-0">
         <!-- Settings Tab -->
         <div class="section-label block my-3 w-full">
            <h5 class="text-2xl font-semibold text-black">Settings</h5>
         </div>
         <div class="flex gap-2.5 flex-row py-6 border-b border-b-neutral-100">
            <ng-container *ngFor="let tab of instructorSettingsTabLists">
               <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border border-none">
                  {{tab.displayName}}
               </button>
            </ng-container>
         </div>
         <!-- Profile -->
         <app-student-profile [account_type]="'instructor'"></app-student-profile>
      </div>
   </ng-container>
</app-drawer>

<!-- Product drawer -->
<app-drawer #productDrawer>
   <!-- Header -->
   <ng-container header>
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h3 class="text-xl text-black font-semibold">#SAP MM Material Management</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to course detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="productDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>
   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-semibold underline text-base text-blue-600">Omar Sherif</h3>
            <h5 class="text-sm font-semibold text-neutral-600">Premium Instructor</h5>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Students Enrolled</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Students Enrolled"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">535</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Finished</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Finished"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Incomplete</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Incomplete"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">35</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100">
         <ng-container *ngFor="let tab of productTabLists">
            <button [ngClass]="tab.displayName==='General'?'active':''" class="tab-border">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Form -->
      <div class="py-6 flex flex-col gap-4">
         <div class="form">
            <form [formGroup]="productForm">
               <formly-form [form]="productForm" [fields]="productFormFields" [model]="productFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
</app-drawer>

<common-modal #unholdConfirmationModal (closeEvent)="closeUnholdConfirmationModal()" title="Unhold Confirmation">
   <div content class="flex flex-col p-6 gap-4 border-b border-b-neutral-100">
     <div class="flex flex-col gap-2">
       <div class="font-semibold">Are you sure to Unhold this transaction?</div>
       <span class="text-slate-500 text-sm">This process sets selected transactions to "Available" status, enabling instructors to claim or withdraw them in upcoming payout requests.</span>
     </div>
   </div>
   <div footer class="flex justify-end gap-2 p-6">
      <button class="btn btn-white" (click)="unholdConfirmationModal.close()">Cancel</button>
      <button class="btn btn-teal" [thkLibProcessing]="isUnholding" (click)="unholdTransaction(unholdConfirmationModal)">Yes, Confirm</button>
   </div>
 </common-modal>
