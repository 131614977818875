<div *ngIf="orderDetails" class="course-overview">
   <!-- Order Summery -->
   <div [ngClass]="isSidebarComponent?'':'rounded-lg border border-neutral-100'" class="card">
      <!-- Card Header -->
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-normat text-neutral-800">Order</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Order" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">#{{id}}</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-normat text-neutral-800">Buyer Name</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Buyer Name" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">{{orderDetails.user.fullname}} </h6>
            </div>
         </div>
      </div>

      <!-- Summary graph -->
      <div class="px-6">
         <div class="flex flex-row gap-4 my-6 overflow-x-auto">
            <div class="min-w-[212px] flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full border border-neutral-100">
               <span class="w-[42px] h-11 bg-amber-600 rounded-[5px] flex justify-center items-center">
                  <svg-icon src="@assets/images/icons/order-details/bx_book-bookmark.svg" class="text-black">
                  </svg-icon>
               </span>
               <div class="flex flex-col gap-1 flex-1">
                  <h5 class="text-sm font-semibold text-neutral-600">No. Of Products</h5>
                  <h3 class="font-bold text-base text-neutral-900">{{orderDetails.products}}</h3>
               </div>
            </div>
            <div class="min-w-[212px] flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full border border-neutral-100">
               <span class="w-[42px] h-11 bg-amber-400 rounded-[5px] flex justify-center items-center">
                  <svg-icon src="@assets/images/icons/order-details/el_shopping-cart.svg" class="text-black">
                  </svg-icon>
               </span>
               <div class="flex flex-col gap-1 flex-1">
                  <h5 class="text-sm font-semibold text-neutral-600">Purchase Price</h5>
                  <h3 class="font-bold text-base text-neutral-900">
                     {{orderDetails.currency_symbol}}{{orderDetails.gross_amount}}</h3>
               </div>
            </div>
            <div class="min-w-[212px] flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full border border-neutral-100">
               <span class="w-[42px] h-11 bg-rose-600 rounded-[5px] flex justify-center items-center">
                  <svg-icon src="@assets/images/icons/order-details/fa-solid_chalkboard-teacher.svg" class="text-black">
                  </svg-icon>
               </span>
               <div class="flex flex-col gap-1 flex-1">
                  <h5 class="text-sm font-semibold text-neutral-600">Instructor Earnings</h5>
                  <h3 class="font-bold text-base text-neutral-900">
                     {{orderDetails.currency_symbol}}{{orderDetails.instructor_earnings}}</h3>
               </div>
            </div>
            <div class="min-w-[212px] flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full border border-neutral-100">
               <span class="w-[42px] h-11 bg-blue-600 rounded-[5px] flex justify-center items-center">
                  <svg-icon src="@assets/images/icons/order-details/ic_baseline-payment.svg" class="text-black">
                  </svg-icon>
               </span>
               <div class="flex flex-col gap-1 flex-1">
                  <h5 class="text-sm font-semibold text-neutral-600">Platform Earnings</h5>
                  <h3 class="font-bold text-base text-neutral-900">
                     {{orderDetails.currency_symbol}}{{orderDetails.platform_earnings}}</h3>
               </div>
            </div>
            <div class="min-w-[212px] flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full border border-neutral-100">
               <span class="w-[42px] h-11 bg-teal-600 rounded-[5px] flex justify-center items-center">
                  <svg-icon src="@assets/images/icons/order-details/fa-solid_money-check-alt.svg" class="text-black">
                  </svg-icon>
               </span>
               <div class="flex flex-col gap-1 flex-1">
                  <h5 class="text-sm font-semibold text-neutral-600">Payment Method</h5>
                  <h3 class="font-bold text-base text-neutral-900">{{orderDetails.payment_gateway}}</h3>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Transactions Summery -->
   <div [ngClass]="isSidebarComponent?'course-overview p-4 pt-0':''">
      <div class="page-heading my-4 flex justify-between items-center">
         <h3 class="text text-2xl text-neutral-800 font-semibold">Transactions: {{totalItems}}</h3>
         <button [routerLink]="'/ecommerce/order/'+id+'/invoice'"
            class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
            <svg-icon src="@assets/images/icons/invoice-icon.svg" class="text-blue-600"></svg-icon>
            <span class="text-blue-600 font-semibold text-sm">View Invoice</span>
         </button>
      </div>
      <div class="courses-list">
         <div class="card rounded-lg border border-neutral-100">
            <div class="card-subheader flex items-center justify-between px-4 py-5">
               <div class="flex items-center gap-1">
                  <h6>Transactions</h6>
                  <div class="tag tag-sm">{{totalItems}} Transaction</div>
               </div>
               <div class="actions flex gap-3 items-center">
                  <button class="btn btn-outline-red">
                     <span>Issue a Refund</span>
                  </button>
                  <div class="relative">
                     <button (click)="dropdown(3)" class="btn-neutral px-2">
                        <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
                     </button>
                     <div [ngClass]="isDropdown[3] ? '':'hidden'"
                        class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-40 border border-neutral-100 max-h-80 overflow-y-auto right-0">
                        <div *ngFor="let col of columns; let i = index" class="input-check w-48 my-2">
                           <input [checked]="col.visible" (change)="toggleColumn(col)" type="checkbox"
                              [id]="'default-checkbox'+i">
                           <label [for]="'default-checkbox'+i">{{col.name}}</label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="card-body">
               <div class="courses-list relative">
                  <ng-container *ngIf="isLoading">
                     <ng-container *ngTemplateOutlet="loader"></ng-container>
                  </ng-container>
                  <ng-container *ngIf="orderItems.length === 0 && !isLoading; else isItems">
                     <span class="text-center text-2xl my-11 block">No Items Founded!</span>
                  </ng-container>
                  <ng-template #isItems>
                     <ng-container *ngTemplateOutlet="listTransactions; context: { items: orderItems }">
                     </ng-container>
                  </ng-template>
                  <!-- Pagination -->
                  <div class="z-30 relative">
                     <app-common-pagination (paginatorData)="paginat($event)" [type]="'dynamic'">
                     </app-common-pagination>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Table -->
<ng-template #listTransactions let-items="items">
   <div class="courses-list">
      <div class="datagrid-table relative overflow-x-auto">
         <table class="w-full text-left">
            <thead class="heading">
               <tr>
                  <th *ngIf="!isSidebarComponent" scope="col" class="pl-4">
                     <div class="col-name flex justify-start gap-2">
                        <input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)">
                        {{checkedItemsCount()}} Selected
                     </div>
                  </th>
                  <ng-container *ngFor="let column of columns">
                     <th *ngIf="column.visible" scope="col" class="">
                        <div class="col-name">{{column.name}}</div>
                     </th>
                  </ng-container>
               </tr>
            </thead>
            <tbody>
               <tr class="hover:cursor-pointer" *ngFor="let order of orderItems; let i = index">
                  <td *ngIf="!isSidebarComponent">
                     <div class="input-check">
                        <input [(ngModel)]="orderItems[i].checked" id="default-checkbox" type="checkbox" value="">
                     </div>
                  </td>
                  <td *ngIf="columns[0].visible">
                     <div class="flex min-h-[69px] max-w-[170px] items-center">
                        <span (click)="colAction($event, order, 'transaction_id')"
                           class="course-name line-clamp-3 text-blue-600 underline cursor-pointer">
                           {{ order.id}}
                        </span>
                     </div>
                  </td>
                  <td *ngIf="columns[1].visible" class="max-w-fit">
                     <span (click)="colAction($event, order, 'product_title')"
                        class="whitespace-nowrap text-blue-600 underline cursor-pointer">
                        {{ order.product.title | truncate:36:'...' }}
                     </span>
                  </td>
                  <td *ngIf="columns[2].visible" class="max-w-[170px]">
                     <span (click)="colAction($event, order, 'instructor_title')"
                        class="flex items-center whitespace-nowrap text-slate-600 underline cursor-pointer">
                        {{order.instructor.fullname}}
                        <button class="btn btn-transparent p-1">
                           <svg-icon src="@assets/images/icons/down-circle.svg" tooltip="Co-Instructor List"
                              class="w-5 h-5">
                           </svg-icon>
                        </button>
                     </span>
                  </td>
                  <td *ngIf="columns[3].visible">
                     {{order.date_created | date}}
                  </td>
                  <td *ngIf="columns[4].visible">
                     <span class="course-price">{{ order.channel.share_types }}</span>
                  </td>
                  <td *ngIf="columns[5].visible">
                     <span class="course-price">{{ order.sale_price }}</span>
                  </td>
                  <td *ngIf="columns[6].visible">
                     <span class="course-price flex items-center">
                        <button class="btn btn-transparent p-1">
                           <svg-icon src="@assets/images/icons/down-circle.svg" tooltip="Co-Instructor Earning"
                              class="w-5 h-5">
                           </svg-icon>
                        </button>
                        {{ order.instructor_share }}
                     </span>
                  </td>
                  <td *ngIf="columns[7].visible">
                     <span class="course-price">{{ order.platform_share }}</span>
                  </td>
                  <!-- <td *ngIf="columns[8].visible">
                     <div [ngClass]="order.transaction_type==='Na'?'bg-rose-50':'bg-teal-50'"
                        class="px-2.5 py-[5px] rounded-md">
                        <span [ngClass]="order.transaction_type==='Na'?'text-rose-600':'text-teal-600'"
                           class="text-sm">{{order.transaction_type}}</span>
                        <span *ngIf="order.transaction_type==='Refunded'" class="text-blue-600 text-sm">#</span>
                     </div>
                  </td> -->
                  <td *ngIf="columns[8].visible">
                     <div *ngIf="order.refund_status"
                        class="tag tag-{{ getStatusTagColor(order.refund_status) }} tag-sm">
                        <div class="flex flex-col">
                           <span>{{order.refund_status }}</span>
                           <span *ngIf="order.status === 'Refunded'" class="text-blue-600 text-sm">
                              &#64;System
                           </span>
                        </div>
                     </div>
                  </td>
                  <td>
                     <ng-container *ngIf="!order.refunded">
                        <button (click)="refundConfirmedPopup(order)"
                           class="btn btn-sm btn-outline-primary border-amber-400 text-amber-400 hover:bg-amber-400 hover:text-white">
                           <span>Refund</span>
                        </button>
                     </ng-container>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</ng-template>

<!-- Product drawer -->
<app-drawer #productDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h3 class="text-xl text-black font-semibold">#SAP MM Material Management</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to course detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="productDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>
   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-semibold underline text-base text-blue-600">Omar Sherif</h3>
            <h5 class="text-sm font-semibold text-neutral-600">Premium Instructor</h5>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Students Enrolled</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Students Enrolled"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">535</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Finished</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Finished"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Incomplete</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Incomplete"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">35</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100">
         <ng-container *ngFor="let tab of productTabLists">
            <button [ngClass]="tab.displayName==='General'?'active':''" class="tab-border">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Form -->
      <div class="py-6 flex flex-col gap-4">
         <div class="form">
            <form [formGroup]="productForm">
               <formly-form [form]="productForm" [fields]="productFormFields" [model]="productFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
</app-drawer>

<!-- Instructor drawer -->
<app-drawer #instructorDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
         <h3 class="text-xl text-black font-semibold">Alok Awasthi</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to instructor detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="instructorDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>
   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">ID Student</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">#325</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Purchased Course</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">25</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Total Spending</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">$500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Join Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">Nov 08, 2022, 2:03 PM</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100 overflow-x-auto">
         <ng-container *ngFor="let tab of instructorTabLists">
            <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border min-w-max">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Details -->
      <div class="flex flex-col p-6 pb-0">
         <!-- Settings Tab -->
         <div class="section-label block my-3 w-full">
            <h5 class="text-2xl font-semibold text-black">Settings</h5>
         </div>
         <div class="flex gap-2.5 flex-row py-6 border-b border-b-neutral-100">
            <ng-container *ngFor="let tab of instructorSettingsTabLists">
               <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border border-none">
                  {{tab.displayName}}
               </button>
            </ng-container>
         </div>

         <!-- Profile -->
         <app-student-profile [account_type]="'instructor'"></app-student-profile>

      </div>
   </ng-container>
</app-drawer>

<!-- Refund confirm modal -->
<common-modal #refundConfirmed customClass="sm:w-full sm:max-w-[935px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl">Refund</h1>
         </div>
         <button (click)="refundConfirmed.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div *ngIf="refundItem"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Transaction ID</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">#{{refundItem.id}}</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Product</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Product" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  {{refundItem.product.title}} ( <span class="text-blue-600">#{{refundItem.product.id.substring(0,
                     8)}}</span> )
               </h6>
            </div>
         </div>
      </div>
      <div *ngIf="refundItem"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14 w-full">
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Instructor</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold !text-slate-600">
                  {{refundItem.instructor.fullname}} ( <span class="text-blue-600">#{{refundItem.instructor.id}}</span> )
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Buyer</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Buyer" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  {{refundItem.user.fullname}} ( <span class="text-blue-600">#{{refundItem.user.id}}</span> )
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Date</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Buyer" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  {{refundItem.date_created | date}}
               </h6>
            </div>
         </div>
      </div>
      <div *ngIf="refundItem && orderDetails"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payment Method</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Method" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">{{orderDetails.payment_gateway}}</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Amount</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Amount" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  {{refundItem.sale_price}}
               </h6>
            </div>
         </div>
      </div>
      <div class="px-6 py-5 border-b border-b-neutral-100">
         <div class="form">
            <form [formGroup]="refundReasonForm">
               <formly-form [form]="refundReasonForm" [fields]="refundReasonFormFields" [model]="refundReasonFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="refundConfirmed.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold" i18n>Cancel</span>
            </button>
            <button (click)="refundConfirmedSubmit()" [disabled]="refundReasonForm.invalid"
               class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold" i18n>Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>
