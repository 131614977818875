import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export * from './payout-request-all/payout-request-all.component';
export * from './payout-request-monthly/payout-request-monthly.component';
export * from './payout-request-ondemand/payout-request-ondemand.component';
export * from './payout-request-transaction-hold/payout-request-transaction-hold.component';
export * from './payout-request-transaction/payout-request-transaction.component';

import { Route } from '@angular/router';
import { PayoutRequestAllComponent } from './payout-request-all/payout-request-all.component';
import { PayoutRequestMonthlyComponent } from './payout-request-monthly/payout-request-monthly.component';
import { PayoutRequestOndemandComponent } from './payout-request-ondemand/payout-request-ondemand.component';
import { PayoutRequestTransactionHoldComponent } from './payout-request-transaction-hold/payout-request-transaction-hold.component';
import { PayoutRequestTransactionComponent } from './payout-request-transaction/payout-request-transaction.component';

export const Components = [
  PayoutRequestAllComponent,
  PayoutRequestMonthlyComponent,
  PayoutRequestOndemandComponent,
  PayoutRequestTransactionComponent,
  PayoutRequestTransactionHoldComponent,
];

export const Routing: Route[] = [
  {
    path: 'payout/payouts-requests/all',
    component: PayoutRequestAllComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Payouts Requests All' },
  },
  {
    path: 'payout/payouts-requests/monthly',
    component: PayoutRequestMonthlyComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Payouts Requests Monthly' },
  },
  {
    path: 'payout/payouts-requests/ondemand',
    component: PayoutRequestOndemandComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Payouts Requests On-Demand' },
  },
  {
    path: 'payout/payouts-requests/transactions/:instructorId/hold',
    component: PayoutRequestTransactionHoldComponent,
    data: { title: marker('E-Commerce'), breadcrumb: 'Payouts Requests Transactions Hold' },
  },
  {
    path: 'payout/payouts-requests/:payoutRequestId',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'all'
      },
      {
        path: ':payoutStatus',
        component: PayoutRequestTransactionComponent,
        data: { title: marker('E-Commerce'), breadcrumb: 'Payouts Requests Transactions' },
      },
    ]
  },
];
