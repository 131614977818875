import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AuthModule } from '@app/auth';
import { I18nModule } from '@app/i18n';
import { AngularSvgIconModule } from 'angular-svg-icon';
// import { HeaderComponent } from '../@shared/components/header/header.component';
import { ShellComponent } from './shell.component';
import { SidebarComponent } from './sidebar/sidebar.component';

import { SharedModule } from '@shared';

@NgModule({
  imports: [CommonModule, TranslateModule, AuthModule, I18nModule, RouterModule, AngularSvgIconModule, SharedModule],
  declarations: [ShellComponent, SidebarComponent],
})
export class ShellModule {}
