import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalComponent, TransactionItems } from '@shared';
import { TransactionsService } from '@shared/services';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { Subscription } from 'rxjs';
import { RouterStoreService, ToastService } from 'thkee-common';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
})
export class TransactionDetailsComponent implements OnInit, OnDestroy {
  params: Params = {};
  breadcrumb: any[] = [];
  private subscriptions = new Subscription();
  private routerSubscriptions = new Subscription();
  isLoading: boolean = false;
  transactionDetails!: TransactionItems;

  // Rejection Reason form
  refundReasonForm = new FormGroup({});
  refundReasonFormModel = { reason: 'Reason1', description: '' };
  refundReasonFormFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'select',
      defaultValue: ['Outdated Course Material'],
      props: {
        label: $localize`Reason`,
        placeholder: $localize`Choose topic`,
        multiple: false,
        stayPlaceholder: true,
        tips: $localize`Refund reason`,
        stylish: true,
        options: [
          { value: 'Outdated Course Material', label: $localize`Outdated Course Material` },
          { value: 'Instructor Performance Issues', label: $localize`Instructor Performance Issues` },
          { value: 'Course Too Difficult', label: $localize`Course Too Difficult` },
          { value: 'Course Too Easy', label: $localize`Course Too Easy` },
          { value: 'Course Not Needed', label: $localize`Course Not Needed` },
          { value: 'Technical Issues', label: $localize`Technical Issues` },
          { value: 'Unauthorized Transaction', label: $localize`Unauthorized Transaction` },
          { value: 'Better Price Found', label: $localize`Better Price Found` },
          { value: 'Incorrect Charge', label: $localize`Incorrect Charge` },
          { value: 'Duplicate Charge', label: $localize`Duplicate Charge` },
          { value: 'Exceptional Circumstances', label: $localize`Exceptional Circumstances` },
        ],
      },
      expressions: {},
    },
    {
      key: 'description',
      type: 'textarea',
      props: {
        label: $localize`Description`,
        labelClass: 'font-bold text-black text-lg pb-2.5',
        tips: $localize`Reason description`,
        placeholder: $localize`Enter a description...`,
        required: true,
        minHeight: '100px',
        maxHeight: '150px',
      },
    },
  ];

  @ViewChild('refundConfirmed') refundConfirmed!: ModalComponent;
  private refundSubscriptions = new Subscription();

  show_refunded_mark: boolean = false;
  show_refund_pending_mark: boolean = false;
  show_refund_button: boolean = false;
  show_refund_mark_bottom: boolean = false;

  constructor(
    private routerStore: RouterStoreService,
    private breadcrumbService: BreadcrumbService,
    private transactionsService: TransactionsService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.routerSubscriptions.add(
      this.routerStore.getParams().subscribe((params) => {
        this.params = params;
      })
    );
    this.getTransactionDetails();
  }

  // Get data
  getTransactionDetails() {
    this.isLoading = true;
    const params = { order_id: this.params['transactionId'] };
    this.subscriptions.add(
      this.transactionsService.getTransactionDetails(this.params['transactionId']).subscribe({
        next: (res) => {
          this.transactionDetails = res;
          this.breadcrumbGenerator(res.transaction_id);
          this.isLoading = false;
          if (res.transaction_type === 'refund') {
            if (!res.is_refunded && res.refund) {
              this.show_refund_pending_mark = true;
            } else if (res.transaction_type === 'refund' && res.is_refunded && res.refund) {
              this.show_refund_mark_bottom = true;
            } else {
              this.show_refunded_mark = true;
            }
          } else if (res.transaction_type === 'sale') {
            if (res.is_refunded) {
              this.show_refunded_mark = true;
            } else if (!res.is_refunded && res.refund) {
              this.show_refund_pending_mark = true;
            } else if (res.transaction_type === 'sale' && !res.is_refunded && !res.refund) {
              this.show_refund_button = true;
            }
          }
        },
        error: (error) => {
          this.isLoading = false;
        },
      })
    );
  }

  breadcrumbGenerator(id: string) {
    this.breadcrumb = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Sales',
        url: '/ecommerce/sales/transactions',
      },
      {
        label: 'Transaction',
        url: '/ecommerce/sales/transactions',
      },
      {
        label: `Transaction #${id}`,
        url: '',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(this.breadcrumb);
  }

  get transaction_id() {
    if (this.transactionDetails) {
      return this.transactionDetails.transaction_id;
    }
    return 0;
  }

  // Refund confirm modal
  refundConfirmedPopup() {
    this.refundConfirmed.open();
  }
  refundConfirmedSubmit() {
    const payload = {
      reason: this.refundReasonFormModel.reason,
      order_line_item: this.transactionDetails.order_line_item,
      description: this.refundReasonFormModel.description,
      payment_mode: {
        mode: this.transactionDetails.payment_gateway,
      },
    };
    this.refundSubscriptions.add(
      this.transactionsService.refundRequest(payload).subscribe({
        next: (res: any) => {
          this.refundConfirmed.close();
          this.toastService.message({
            message: $localize`Order refund success!`,
          });
          this.getTransactionDetails();
        },
        error: (error) => {
          this.refundConfirmed.close();
          this.toastService.error({
            message: error.error.errors[0].detail,
          });
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.routerSubscriptions.unsubscribe();
    this.refundSubscriptions.unsubscribe();
  }
}
