<div class="flex items-center justify-between">
  <div *ngIf="enableBackBtn" class="back-navi">
    <button (click)="back()" class="btn-neutral btn-sm">
      <svg-icon class="btn-icon" src="@assets/images/icons/arrow-left.svg"></svg-icon>
      <span>Back</span>
    </button>
  </div>
  <div class="breadcrumbs">
    <div class="items inline-flex items-center gap-2 text-sm leading-3 text-neutral-800">
      <ng-container *ngFor="let item of items">
        <div>
          <a [routerLink]="item.url">{{ item.label }}</a>
        </div>
        <div>
          <svg-icon src="@assets/images/icons/chevron-right.svg" class="arrow inline-grid h-5 w-5"></svg-icon>
        </div>
      </ng-container>

      <!-- <div><a href="#">Overview</a></div>
      <div>
        <svg-icon src="@assets/images/icons/chevron-right.svg" class="arrow inline-grid h-5 w-5"></svg-icon>
      </div>
      <div class="current">#SAP MM Material Management</div> -->
    </div>
  </div>
</div>
