import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  API_GET_ADMIN_PAYOUT_ACTIVATION,
  API_GET_ADMIN_PAYOUT_ACTIVATION_SUMMARY,
  API_GET_ADMIN_PAYOUT_HISTORY,
  API_GET_ADMIN_PAYOUT_PROFILE_DETAILS,
  API_POST_ADMIN_APPROVED_ACCOUNT,
  API_POST_ADMIN_DISABLE_PAYOUT,
  API_POST_ADMIN_REJECT_ACCOUNT,
  API_POST_ADMIN_REJECT_INSTRUCTOR_PROFILE,
  API_POST_ADMIN_VERIFY_INSTRUCTOR_PROFILE,
  encodeURL,
  HttpService,
  InstructorUserDetails,
  Pagination,
} from 'thkee-common';

export interface PayoutActivationSummary {
  bank_under_review: number;
  pending_applications: number;
  unverified_instructors: number;
  verified_instructors: number;
  rejected_applications: number;
}

export interface PayoutActivationLists {
  id: number;
  instructor_name: string;
  submited_date: string;
  status: string;
  verified_date: string;
  verification_type: string;
  payout_method_status: string;
}
export interface PayoutHistory {
  id?: number;
  date: string;
  action: string;
  reason: string;
  payment_method: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpService) {}

  getPayoutActivationSummary(): Observable<PayoutActivationSummary> {
    return this.http.get<PayoutActivationSummary>(API_GET_ADMIN_PAYOUT_ACTIVATION_SUMMARY);
  }

  getPayoutActivation(query = {} as object): Observable<Pagination<PayoutActivationLists>> {
    return this.http.get<Pagination<PayoutActivationLists>>(encodeURL(API_GET_ADMIN_PAYOUT_ACTIVATION, query));
  }

  getPayoutInstructorProfileDetails(instructorId: string): Observable<InstructorUserDetails> {
    return this.http.get<any>(API_GET_ADMIN_PAYOUT_PROFILE_DETAILS.replace('<INSTRUCTOR_ID>', instructorId));
  }

  getAdminInstructorPayoutHistory(instructorId: string): Observable<PayoutHistory[]> {
    return this.http.get<PayoutHistory[]>(API_GET_ADMIN_PAYOUT_HISTORY.replace('<INSTRUCTOR_ID>', instructorId));
  }

  adminApprovedAccount(paymentMethodId: string) {
    return this.http.post(API_POST_ADMIN_APPROVED_ACCOUNT.replace('<PAYMENT_METHOD_ID>', paymentMethodId), {});
  }

  adminRejectAccount(paymentMethodId: string, body: { reason: string }) {
    return this.http.post(API_POST_ADMIN_REJECT_ACCOUNT.replace('<PAYMENT_METHOD_ID>', paymentMethodId), body);
  }

  adminVerifyInstructorProfile(instructorId: string) {
    return this.http.post(API_POST_ADMIN_VERIFY_INSTRUCTOR_PROFILE.replace('<INSTRUCTOR_ID>', instructorId), {});
  }

  adminRejectedInstructorProfile(instructorId: string) {
    return this.http.post(API_POST_ADMIN_REJECT_INSTRUCTOR_PROFILE.replace('<INSTRUCTOR_ID>', instructorId), {});
  }

  adminDisablePayout(instructorId: string) {
    return this.http.post(API_POST_ADMIN_DISABLE_PAYOUT.replace('<INSTRUCTOR_ID>', instructorId), {});
  }
}
