import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalComponent, UntilDestroy, untilDestroyed } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { AdminPayoutRequest, AdminPayoutRequestDetailSummary, AdminPayoutRequestTransactionQuery, AdminPayoutTransaction, AdminPayoutTransactionAnalytic, Pagination, QueryFormConverter, QueryStringFilterService, ToastService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-ready-request-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  providers: [QueryStringFilterService.forComponent()]
})
export class TransactionsComponent implements OnInit, AfterViewInit {
  @Input() request!: AdminPayoutRequest;

  @ViewChild('holdConfirmed', { read: ModalComponent })
  private holdTranConfirmingModal!: ModalComponent;

  // @ViewChild('holdAllModal', { read: ModalComponent })
  // private holdAllModal!: ModalComponent;

  // private selectedTransactions: Record<number, AdminPayoutTransaction['payout_status'] | undefined> = {};
  requestSummary?: AdminPayoutRequestDetailSummary;
  transactionsPagination?: Pagination<AdminPayoutTransaction>;
  requestId = '';
  requestAnalytics?: AdminPayoutTransactionAnalytic;
  holdingTran?: AdminPayoutTransaction;
  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'payout_status',
      fieldGroup: [
        {
          key: 'paid',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Paid (16)',
            required: true,
          },
        },
        {
          key: 'inactive',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Inactive (16)',
            required: true,
          },
        },
        {
          key: 'failed',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Failed (16)',
            required: true,
          },
        },
      ]
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Channel Types</div>`,
    },
    {
      key: 'channel_type',
      fieldGroup: [
        {
          key: 'ORGANICS',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Organics',
            required: true,
          },
        }
      ]
    },
  ];

  processingHolding = false;

  // holdingAll = false;

  // Hold Reason form
  holdForm = new FormGroup({});
  holdFormModel: any = {};
  holdFormFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'textarea',
      props: {
        label: 'Reason for hold',
        rows: 3,
        labelClass: 'font-bold text-black text-lg',
        placeholder: 'Enter reason',
      }
    },
    {
      key: '__agree',
      type: 'checkbox',
      props: {
        label: 'I understand this action can\'t be undone.',
        required: true,
        labelClass: 'text-slate-600',
      },
      validation: {
        messages: {
          required: $localize`This field is required!`,
        },
      }
    }
  ];

  private formConverter = new QueryFormConverter<AdminPayoutRequestTransactionQuery>(['payout_status', 'channel_type'])

  constructor(
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.getTransactions();
    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Ready Requests',
        url: '/ecommerce/ready',
      },
      {
        label: `Ahmed Hany** #${this.request.id}`,
        url: '',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  holdTransaction() {
    if (!this.holdingTran || this.holdForm.invalid || !(this.holdForm.value as any).__agree) {
      return;
    }

    this.processingHolding = true;
    this.payoutService
      .holdReadyRequestTransaction(
        this.request.id,
        this.holdingTran?.id,
        (this.holdForm.value as any)['reason']
      )
      .subscribe(() => {
        this.triggerFilter$.next({ ...this.triggerFilter$.value });
        this.processingHolding = false;
        this.holdTranConfirmingModal.close();
        this.holdingTran = undefined;
        this.toastService.message({
          type: 'message',
          message: 'Hold transaction successfully'
        })
        this.holdForm.reset();
      })
  }

  closeHoldingConfirmationModal() {
    this.holdingTran = undefined;
    this.processingHolding = false;
    this.holdTranConfirmingModal.close();
  }

  // Dropdown methode
  isDropdown?: 'batchOption' | 'filter' | 'search';
  dropdown(type: 'batchOption' | 'filter' | 'search') {
    this.isDropdown = this.isDropdown === type ? undefined : type;
  }

  // openHoldMany(appliedAll = false) {
  //   if (appliedAll) {
  //     this.dropdown('batchOption');
  //   } else {
  //     this.requestAnalytics = undefined;
  //   }
  //   this.holdAllModal.open();
  // }

  goToTransPage(event: { page: number, page_size: number }) {
    this.triggerFilter$.next({ ...this.triggerFilter$.value, ...event });
  }

  openConfirmHoldingTransaction(tran: AdminPayoutTransaction) {
    this.holdingTran = tran;
    this.holdTranConfirmingModal.open();
  }


  // holdAll() {
  //   if (this.holdForm.invalid) {
  //     return;
  //   }
  //   this.holdingAll = true;
  //   this.payoutService.holdTransactions(
  //     this.request.id,
  //     this.holdForm.value as any
  //   )
  //     .subscribe(() => {
  //       this.holdingAll = false;
  //       this.triggerFilter$.next({ ...this.triggerFilter$.value });
  //       this.approveAllModal.close();
  //       this.toastService.message({
  //         message: 'All transactions are now on hold',
  //         type: 'message'
  //       });
  //       this.holdForm.reset();
  //     });
  // }

  transTrackBy(_: any, trans: AdminPayoutTransaction) {
    return trans.id;
  }

  resetFilter() {
    this.filterForm.reset();
  }

  // toggleSelectTransactions(ev: any) {
  //   if (ev.target.checked) {
  //     this.transactionsPagination?.results.forEach(tran => this.selectedTransactions[tran.id] = tran.payout_status)
  //     return;
  //   }

  //   this.selectedTransactions = {};
  // }

  // isAllCheckBoxChecked() {
  //   return this.transactionsPagination?.results.length &&
  //     this.getCheckedAmount() === this.transactionsPagination.results.length;
  // }

  // getCheckedAmount() {
  //   return Object.values(this.selectedTransactions).filter(Boolean).length;
  // }

  // disableAllButton = false;
  // checked(ev: any, tran: AdminPayoutTransaction) {
  //   ev.stopPropagation();
  //   if (this.selectedTransactions[tran.id]) {
  //     this.selectedTransactions[tran.id] = undefined;
  //   } else {
  //     this.selectedTransactions[tran.id] = tran.payout_status;
  //   }
  //   this.disableAllButton = Object.values(this.selectedTransactions)
  //     .filter(Boolean)
  //     .some(status => status !== 'requested');
  // }

  private triggerFilter$ = new BehaviorSubject<AdminPayoutRequestTransactionQuery>({ page: 1, page_size: 5 });
  private getTransactions() {
    this.triggerFilter$.pipe(
      debounceTime(400),
      untilDestroyed(this)
    ).subscribe(query => {
      this.payoutService.getReadyRequestTransactions(this.request.id, query)
        .subscribe(data => this.transactionsPagination = data);
    });
  }

  private setupFilter() {
    this.filterForm.valueChanges.pipe(untilDestroyed(this))
      .subscribe(value => this.triggerFilter$.next({ ...this.triggerFilter$.value, ...this.formConverter.toQuery(value as any) }));
  }
}
