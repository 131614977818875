import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { RouterStoreService } from 'thkee-common';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
  params: any = {};

  constructor(private routerStore: RouterStoreService, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Orders',
          url: '/ecommerce/orders',
        },
        {
          label: `Order #${params['orderId']}`,
          url: `/ecommerce/order/${params['orderId']}`,
        },
        {
          label: 'Invoice',
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  // Dummy data
  public dummyData: any = [
    {
      transaction_id: '00123521',
      product: {
        id: 'p001',
        title: 'Web 101 Fundamental...',
      },
      buyer: {
        id: 'p001',
        title: 'Ahsam Ahmad',
      },
      date: '2024-02-20T10:30:31.398Z',
      sale_price: 43.6,
    },
    {
      transaction_id: '00123521',
      product: {
        id: 'p001',
        title: 'Web 101 Fundamental...',
      },
      buyer: {
        id: 'p001',
        title: 'Ahsam Ahmad',
      },
      date: '2024-02-20T10:30:31.398Z',
      sale_price: 43.6,
    },
    {
      transaction_id: '00123521',
      product: {
        id: 'p001',
        title: 'Web 101 Fundamental...',
      },
      buyer: {
        id: 'p001',
        title: 'Ahsam Ahmad',
      },
      date: '2024-02-20T10:30:31.398Z',
      sale_price: 43.6,
    },
  ];
}
