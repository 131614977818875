import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { RouterStoreService } from 'thkee-common';

@Component({
  selector: 'app-paid-payout-monthly-details',
  templateUrl: './paid-payout-monthly-details.component.html',
  styleUrls: ['./paid-payout-monthly-details.component.scss'],
})
export class PaidPayoutMonthlyDetailsComponent implements OnInit {
  params: any = {};
  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">Select Period</div>`,
    },
    {
      key: 'period',
      type: 'select',
      defaultValue: '2024-02-20T10:30:31.398Z',
      className: 'col-span-6',
      props: {
        wrapClass: 'mt-3 mb-4 flex flex-col',
        hideLabel: true,
        options: [
          { label: 'June 2020', value: '2024-02-20T10:30:31.398Z' },
          { label: 'July 2020', value: '2024-02-20T10:30:31.398Z' },
          { label: 'August 2020', value: '2024-02-20T10:30:31.398Z' },
        ],
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Method</div>`,
    },
    {
      key: 'on_demand',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paypal (16)',
        required: true,
      },
    },
    {
      key: 'monthly',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Payoneer (16)',
        required: true,
      },
    },
    {
      key: 'monthly',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Direct to Bank (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Status</div>`,
    },
    {
      key: 'paid',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paid (16)',
        required: true,
      },
    },
    {
      key: 'inactive',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Inactive (16)',
        required: true,
      },
    },
    {
      key: 'inactive',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Failed (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Total Refund</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  resetFilter() {
    this.filterForm.reset();
  }

  constructor(private routerStore: RouterStoreService, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Paid Payouts',
          url: '/ecommerce/payout/paid-payout/monthly',
        },
        {
          label: `July 2020`,
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }
  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Failed') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  // Checked function
  checkAllCheckBox(ev: any) {
    this.dummyData.forEach((x: any) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked() {
    this.checkedItemsCount();
    return this.dummyData.every((p: any) => p.checked);
  }
  checkedItemsCount() {
    let count = 0;
    this.dummyData.forEach((x: any) => {
      if (x.checked) count++;
    });
    return count;
  }
  checked(ev: any) {
    ev.stopPropagation();
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 1,
      title: 'Statement Period',
      value: '2024-02-20T10:30:31.398Z',
      type: 'date',
    },
    {
      id: 2,
      title: 'Failed Amount',
      value: 1098,
      type: 'currency',
    },
    {
      id: 3,
      title: 'Hold Amount',
      value: 1098,
      type: 'currency',
    },
    {
      id: 4,
      title: 'Total Transactions',
      value: 228,
      type: 'number',
    },
    {
      id: 5,
      title: 'Total Refund',
      value: 20,
      type: 'currency',
    },
    {
      id: 6,
      title: 'Forced Refund',
      value: 20,
      type: 'currency',
    },
  ];

  public paymentHistory2: any = [
    {
      id: 7,
      title: 'Total Paid',
      value: 2009,
      type: 'currency',
    },
    {
      id: 8,
      title: 'Total of Payees',
      value: 2009,
      type: 'currency',
    },
    {
      id: 9,
      title: 'Total Transactions',
      value: 2009,
      type: 'number',
    },
    {
      id: 10,
      title: 'Paypal Paid Amount',
      value: 2009,
      type: 'currency',
    },
    {
      id: 11,
      title: 'Bank Paid Amount',
      value: 2009,
      type: 'currency',
    },
    {
      id: 12,
      title: 'Payoneer Paid',
      value: 2009,
      type: 'currency',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      transactions: 23,
      payment_methode: 'Paypal',
      payout_id: 124,
      payout_amount: 344,
      status: 'Paid',
      checked: false,
    },
    {
      id: '124',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      transactions: 23,
      payment_methode: 'Paypal',
      payout_id: 124,
      payout_amount: 344,
      status: 'Inactive',
      checked: true,
    },
    {
      id: '125',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      transactions: 23,
      payment_methode: 'Paypal',
      payout_id: 124,
      payout_amount: 344,
      status: 'Failed',
      checked: false,
    },
  ];
}
