<div class="input-combine flex">
  <div class="select2">
     <div class="relative">
        <!-- id:{id},  request_id:{request_id}, name:{instructor_name} -->
        <a (click)="toggleDropdown()" type="button" aria-haspopup="listbox" aria-expanded="true"
           aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
           <div class="selected-item truncate">{{searchOptionMetadata[selectedSearchItem].title}}</div>
           <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
        </a>
        <ul [ngClass]="isDropdownShown ? '' : 'hidden'" tabindex="-1" role="listbox"
           class="select-items min-w-[200px]">
           <li *ngFor="let item of keys" (click)="selectItem(item.id)" role="option" class="item">{{item.title}}</li>
        </ul>
     </div>
  </div>
  <div class="input-group">
     <div class="group !rounded-l-none">
        <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
        <input #searchInput [placeholder]="searchOptionMetadata[selectedSearchItem].placeholder" [(ngModel)]="textSearch" (keyup.enter)="searchRequest()"/>
     </div>
  </div>
</div>
