<div [ngClass]="isOpened?'w-full':'we-0'"
   class="absolute right-0 top-0 h-full overflow-hidden bg-[rgba(0,0,0,0.3)] z-[900]">
   <div [class]="customClass" [ngClass]="isOpened?'slide-right-out':'slide-right-in'"
      class="absolute flex flex-col h-full bg-white z-30 top-0 right-0 border-l border-neutral-100 overflow-y-auto">
      <div class="relative">
         <ng-content select="[header]"></ng-content>
         <div *ngIf="closable" class="absolute right-0 top-[50%] translate-y-[-50%] p-4 flex items-center">
            <button class="btn-icon" (click)="close()">
               <svg-icon src="@assets/images/icons/x-mark.svg"></svg-icon>
            </button>
         </div>
      </div>
      <ng-content select="[content]"></ng-content>
      <ng-content select="[footer]"></ng-content>
   </div>
</div>
