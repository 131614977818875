import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { Currency } from 'thkee-common';

@Component({
  selector: 'app-form-price-tier',
  templateUrl: './price-tier.component.html',
  styleUrls: ['./price-tier.component.scss'],
})
export class FormPriceTierComponent implements OnInit {
  @Input() model: any = {}; //TODO: Update model here
  @Input() currencies: Currency[] = [];
  @Output() init: EventEmitter<FormlyFieldConfig[]> = new EventEmitter<FormlyFieldConfig[]>();
  @Output() submitEvent: EventEmitter<Partial<any>> = new EventEmitter<Partial<any>>(); //TODO: Update model here

  formTier = new FormGroup({});
  formTierModel: any = {}; //TODO: Update model here

  formTierFields$: Subject<FormlyFieldConfig[]> = new Subject();
  formTierFields: FormlyFieldConfig[] = [];

  showSubmit: boolean = true;
  constructor() {}

  getFormlyFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        props: {
          label: 'Name',
          tips: $localize`Enter the tier name.`,
          placeholder: $localize`Tier name...`,
          required: true,
        },
      },
      {
        fieldGroupClassName: 'grid grid-cols-2 gap-6',
        fieldGroup: [
          {
            key: 'currency',
            type: 'select',
            defaultValue: 1,
            props: {
              label: $localize`Currency`,
              placeholder: $localize`Select Currency`,
              required: true,
              options: this.currencies.map((currency) => {
                return {
                  label: `${currency.name} (${currency.currency_code})`,
                  value: currency.id,
                };
              }),
              tips: $localize`Choose currency type.`,
              stylish: true,
              disabled: true,
            },
          },
          {
            key: 'price',
            type: 'input',
            props: {
              label: $localize`Amount`,
              tips: $localize`Enter the tier amount.`,
              placeholder: $localize`Enter amount... (eg : 19.99)`,
              required: true,
            },
          },
        ],
      },

      {
        template: '<label class="block mb-2 font-bold">Pricing Tiers Status</label>',
      },
      {
        key: 'price_tier_status',
        type: 'toggle',
        props: {
          label: $localize`Active`,
        },
      },
    ];
  }

  ngOnInit(): void {
    if (this.model && this.currencies.length > 0) {
      // this.model.currency_id = this.currencies.find((currency) => currency.currency_code === this.model.currency)?.id;
      // this.formTierModel = this.model;
      // this.formTierFields = this.getFormlyFields();

      this.formTierModel = this.model;
      this.formTierFields$.next(this.getFormlyFields());
      this.formTierFields = this.getFormlyFields();

      this.init.emit(this.formTierFields);

      // Get Primary Currency
      const primary = _.find(this.currencies, { is_primary_exchange_rate_currency: true });
      if (primary && primary['id']) {
        this.formTierModel = Object.assign({}, this.formTierModel);
        this.formTierModel['currency'] = primary['id'];
      }
    }
    this.init.emit(this.formTierFields);

    // Check if all of the fields is disable then hide submit
    if (!this.isAllDisabled(this.formTierFields).includes(false)) {
      this.showSubmit = false;
    }
  }

  submit() {
    this.submitEvent.emit({
      valid: this.formTier.valid,
      values: {
        ...this.model,
        ...this.formTierModel,
      },
    });
  }

  isAllDisabled(objects: FormlyFieldConfig[]): boolean[] {
    const disabledValues: boolean[] = [];
    for (const obj of objects) {
      if (obj.key && obj.type) {
        if (obj.props) {
          disabledValues.push(!!obj.props.disabled);
        }
      }
      if (obj.fieldGroup && obj.fieldGroup.length > 0) {
        disabledValues.push(...this.isAllDisabled(obj.fieldGroup));
      }
    }
    return disabledValues;
  }
}
