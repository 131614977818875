import { createAction, props } from '@ngrx/store';

import {
  PricingCurrencyCreateUpdatePayload,
  PricingCurrencyLoadRemovePayload,
  PricingCurrencyPayload,
} from './currency-state.model';

export const PricingCurrencyActions = {
  loadPricingCurrencyList: createAction('[PricingCurrency] Process list', props<PricingCurrencyPayload>()),
  loadPricingCurrencyListSuccess: createAction('[PricingCurrency]Load pricing-Currency list success', props<any>()),
  loadPricingCurrencyListFail: createAction(
    '[PricingCurrency] Load pricing-Currency list fail',
    props<{ error: unknown }>()
  ),

  clearCurrencyCRUDStatus: createAction('[PricingCurrency] Clear the Currency CRUD status'),

  addPricingCurrency: createAction(
    '[PricingCurrency] Add Process Currency',
    props<PricingCurrencyCreateUpdatePayload>()
  ),
  addPricingCurrencySuccess: createAction('[PricingCurrency] Add Process Currency Success', props<any>()),
  addPricingCurrencyFail: createAction('[PricingCurrency] Add pricing-Currency list fail', props<any>()),
  addPricingCurrencyFailed: createAction('[PricingCurrency] Add failed pricing-Currency list'),

  getPricingCurrency: createAction('[PricingCurrency] Process data', props<PricingCurrencyLoadRemovePayload>()),
  getPricingCurrencySuccess: createAction('[PricingCurrency]Load pricing-Currency data success', props<any>()),
  getPricingCurrencyFail: createAction(
    '[PricingCurrency] Load pricing-Currency data fail',
    props<{ error: unknown }>()
  ),

  updatePricingCurrency: createAction(
    '[PricingCurrency] Update Process Currency',
    props<PricingCurrencyCreateUpdatePayload>()
  ),
  updatePricingCurrencySuccess: createAction('[PricingCurrency] Update Process Currency Success', props<any>()),
  updatePricingCurrencyFail: createAction(
    '[PricingCurrency] Update pricing-Currency list fail',
    props<{ error: unknown }>()
  ),
  updatePricingCurrencyFailed: createAction('[PricingCurrency] Update failed pricing-Currency list fail'),

  removePricingCurrency: createAction(
    '[PricingCurrency] Remove Process Currency',
    props<PricingCurrencyLoadRemovePayload>()
  ),
  removePricingCurrencySuccess: createAction(
    '[PricingCurrency] Remove PricingCurrency Process Currency Success',
    props<PricingCurrencyLoadRemovePayload>()
  ),
  removePricingCurrencyFail: createAction(
    '[PricingCurrency] Remove pricing-Currency list fail',
    props<{ error: unknown }>()
  ),
};
