<ng-container *ngIf="{
    hasData: hasData,
    priceGeoData: (priceGeo$ | async)
  } as vm">
  <div class="price-geolocation-view mb-20 p-4">
    <div class="page-heading mb-4 flex items-center justify-between" *ngIf="vm.priceGeoData">
      <div class="flex w-full items-center justify-between gap-4">
        <div>
          <h3>View Geolocation {{ vm.priceGeoData.country_str }}</h3>
        </div>
        <div>
          <button class="btn-amber-soft btn-sm" (click)="editMode()">
            <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
            <span>Edit</span>
          </button>
        </div>
      </div>
    </div>
    <div class="geo-info mb-3">
      <div class="card rounded-lg border border-neutral-100 p-4">
        <div class="grid grid-cols-4 gap-4" *ngIf="vm.priceGeoData">
          <div>
            <div class="label mb-3 text-lg font-bold">Name</div>
            <div class="value mb-2 text-sm">{{ vm.priceGeoData.country_str }}</div>
          </div>
          <div>
            <div class="label mb-3 text-lg font-bold">Currency</div>
            <div class="value mb-2 text-sm">{{ vm.priceGeoData.currency_str }}</div>
          </div>
          <div>
            <div class="label mb-3 text-lg font-bold">Currency Convert</div>
            <div class="value mb-2 text-sm">
              <div class="font-bold">
                {{ vm.priceGeoData?.currency_convert ? 'Active' : 'Inactive' }}
              </div>
            </div>
          </div>
          <div>
            <div class="label mb-3 text-lg font-bold">Status</div>
            <div class="value mb-2 text-sm">
              <div class="font-bold">
                {{ vm.priceGeoData?.status ? 'Active' : 'Inactive' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions mb-4 flex gap-4">
      <!-- TODO: Convert to formly -->
      <div>
        <button class="btn-neutral">
          <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
          <span>Filter</span>
        </button>
      </div>
      <div class="input-combine flex">
        <div class="input-group">
          <div class="group">
            <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
            <input placeholder="Search" />
          </div>
        </div>
      </div>
    </div>

    <div class="tier-list">
      <app-data-table title="Pricing Tiers List" badge="{{ this.geoPricingTiersTable.length }} Tiers">
        <ng-container table>
          <table class="w-full text-left">
            <thead class="heading">
              <tr>
                <th scope="col" class="w-20">
                  <div class="col-name">ID</div>
                </th>
                <th scope="col" class="w-52">
                  <div class="col-name">Name</div>
                </th>
                <th scope="col" class="w-52">
                  <div class="sortable">
                    <div class="col-name">Geo Percentage</div>
                  </div>
                </th>
                <th scope="col" class="w-52">
                  <div class="col-name">Default Tier amount</div>
                </th>
                <th scope="col" class="w-52">
                  <div class="col-name">Geo tire amount</div>
                </th>
                <th scope="col" class="w-32">
                  <div class="col-name">Pricing Tier Status</div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="" class="text-left" *ngFor="let data of geoPricingTiersTable">
                <td class="">
                  <span class="course-id">#{{ data.id }}</span>
                </td>
                <td>
                  <span class="course-id">{{ data.name }}</span>
                </td>
                <td>
                  <span class="course-price">{{ data.percentage }}%</span>
                </td>
                <td>
                  <span class="course-sold">{{ data.currency_code }} {{ data.tierAmount }}</span>
                </td>
                <td>
                  <span class="course-sold">{{ data.currency_code }} {{ data.geoAmount }}</span>
                </td>
                <td class="text-right">
                  <div class="tag-green tag-sm" *ngIf="data.status">Active</div>
                  <div class="tag-red tag-sm" *ngIf="!data.status">Inactive</div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </app-data-table>
    </div>
  </div>
</ng-container>