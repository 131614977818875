<div *ngIf="!summary" class="flex justify-center py-10" [thkLibProcessing]="true"></div>
<div *ngIf="summary" class="flex flex-col p-4 gap-4">
  <div class="grid grid-cols-2 gap-4">
    <div class="flex gap-2">
      <div class="flex text-slate-600">
        <svg-icon src="@assets/images/icons/square-arrow-up-right-round.svg" class="btn-icon h-4 w-4"></svg-icon>
      </div>
      <div>
        <div class="text-xs text-slate-600">Payout Requests</div>
        <div class="font-semibold">{{summary.total_payout_requests}}</div>
      </div>
    </div>
    <div class="flex gap-2">
      <div class="flex text-slate-600">
        <svg-icon src="@assets/images/icons/left-to-right-list-triangle.svg" class="btn-icon h-4 w-4"></svg-icon>
      </div>
      <div>
        <div class="text-xs text-slate-600">Transaction Count</div>
        <div class="font-semibold">{{summary.total_transaction_count}}</div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-4">
    <div class="flex gap-2">
      <div class="flex text-slate-600">
        <svg-icon src="@assets/images/icons/send.svg" class="btn-icon h-4 w-4"></svg-icon>
      </div>
      <div>
        <div class="text-xs text-slate-600">Requested transactions</div>
        <div class="flex items-center gap-1">
          <span class="font-semibold">{{summary.requested_transaction_count}}</span>
          <svg-icon src="@assets/images/icons/question-mark-circle.svg" class="w-5 text-slate-400 h-5 btn-icon flex items-center" tooltip="These transactions's status will be updated to ready"></svg-icon>
        </div>
      </div>
    </div>
    <div class="flex gap-2">
      <div class="flex text-slate-600">
        <svg-icon src="@assets/images/icons/check-circle.svg" class="btn-icon h-4 w-4"></svg-icon>
      </div>
      <div>
        <div class="text-xs text-slate-600">Approved transactions</div>
        <div class="font-semibold">{{summary.approved_transaction_count}}</div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-4">
    <div class="flex gap-2">
      <div class="flex text-slate-600">
        <svg-icon src="@assets/images/icons/pause.svg" class="btn-icon h-4 w-4"></svg-icon>
      </div>
      <div>
        <div class="text-xs text-slate-600">Hold Transactions</div>
        <div class="font-semibold">{{summary.hold_transaction_count}}</div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
