import { FilterOptionsValues } from './constants';

interface User {
  id: string;
  first_name: string;
  last_name: string;
  bio?: string;
  photo?: string;
  fullname: string;
  public_profile: string;
}

interface Category {
  id: string;
  name: string;
  slug: string;
}

interface Product {
  id: string;
  code: string;
  article_count: number;
  slug: string;
  user: User;
  title: string;
  subtitle: string;
  description: Record<string, any>;
  pricing: string;
  currency: string;
  image_url: string;
  desc: string;
  video_info: Record<string, any>;
  status: string;
  duration: number;
  category: Category;
}

interface Stats {
  classes: string[];
  courses: number;
  published_courses: number;
  students: number;
  ratings: number;
}

interface Buyer {
  id: number;
  first_name: string;
  last_name?: string;
  headline?: string;
  bio: string;
  language: string;
  email: string;
  photo?: string;
  is_instructor: boolean;
  fullname?: string;
  stats: Stats;
}

interface Channel {
  share_types: string;
}

interface ShareDetail {
  instructore_share: number;
  refunded_amount: string;
}

export interface TransactionItems {
  buyer: Buyer;
  instructor?: User;
  channel_type: string;
  date_created: string;
  instructor_share: string;
  affiliates_earning: number;
  instructor_share_percentage: number;
  platform_share: string;
  platform_share_percentage: number;
  related_transaction: any; // TODO: Will be changes based on the actual response;
  transaction_type: string;
  pricing_tier: string;
  coupon: string;
  id: number;
  transaction_id: string;
  product: Product;
  sale_price: number;
  instructor_group?: null | any;
  payment_gateway?: string;
  payment_method?: string;
  share_detail?: ShareDetail;
  discount_coupon_code: string;
  discount_coupon: number;
  discount: number;
  gateway_charge_vat: string;
  gateway_charge: string;
  currency_code: string;
  currency_symbol: string;
  net_amount: string;
  refunded_amount: number;
  list_price: number;
  order_line_item: number;
  is_refunded?: boolean;
  refund?: boolean;
}

export interface TransactionResponse {
  count: number;
  next?: string;
  previous?: string;
  results: TransactionItems[];
}

// Filter
export interface FilterOptionsTransactions {
  channel: FilterOptionsValues[];
  coupon: FilterOptionsValues[];
  transaction_type: FilterOptionsValues[];
}

export interface FilterEvent {
  action: string;
  data: object;
}

// Params
export interface QueryParams {
  page?: number;
  page_size?: number;
  search?: string;
}

// Table column
export const TRANSACTIONS_TABLE_COLUMN = [
  { name: 'Transaction ID', visible: true },
  { name: 'Product', visible: true },
  { name: 'Buyer', visible: true },
  { name: 'Date', visible: true },
  { name: 'Sale Price', visible: true },
  { name: 'Channel', visible: true },
  { name: 'Platform Earning', visible: true },
  { name: 'Instructor Earning', visible: true },
  { name: 'Coupon', visible: true },
  { name: 'Transaction Type', visible: true },
];

// Table seach
export const TRANSACTIONS_TABLE_SEARCH = [
  {
    id: 0,
    title: 'Transaction ID',
    value: 'id',
  },
  {
    id: 1,
    title: 'Product',
    value: 'product',
  },
  {
    id: 2,
    title: 'Buyer',
    value: 'buyer',
  },
  {
    id: 3,
    title: 'Channel',
    value: 'channel',
  },
  {
    id: 4,
    title: 'Transaction Type',
    value: 'transaction_type',
  },
];
