import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntilDestroy, untilDestroyed } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { debounceTime } from 'rxjs';
import { AdminInstructorBalance, AdminPayoutInstructorBalancesQuery, Pagination, PayoutType, QueryFormConverter, QueryStringFilterService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-instructor-balance',
  templateUrl: './instructor-balance.component.html',
  styleUrls: ['./instructor-balance.component.scss'],
  providers: [QueryStringFilterService.forComponent()]
})
export class InstructorBalanceComponent implements OnInit, AfterViewInit {
  query?: AdminPayoutInstructorBalancesQuery;
  balancesPagination?: Pagination<AdminInstructorBalance>;

  // Filter form
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    },
    {
      key: 'payout_type',
      fieldGroup: [

        {
          key: PayoutType.onDemand,
          type: 'checkbox',
          className: '',
          props: {
            label: 'On-demand (16)',
            required: true,
          },
        },
        {
          key: PayoutType.monthly,
          type: 'checkbox',
          className: '',
          props: {
            label: 'Monthly (16)',
            required: true,
          },
        },
      ]
    }
  ];

  private formConverter = new QueryFormConverter<AdminPayoutInstructorBalancesQuery>(['payout_type']);

  constructor(
    private breadcrumbService: BreadcrumbService,
    private queryFilterService: QueryStringFilterService<AdminPayoutInstructorBalancesQuery>,
    private payoutService: PayoutService
  ) {}

  ngOnInit(): void {
    this.getInstructorBalances();
    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Instructor Balances',
        url: '/ecommerce/payout/instructor-balances',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }


  search(instructorName: string) {
    if (instructorName.trim()) {
      this.queryFilterService.patch({ search: instructorName.trim() });
      return;
    }

    this.queryFilterService.remove(['search']);
  }

  paginate($event: PaginationChange) {
    this.queryFilterService.patch($event);
  }

  resetFilter() {
    this.filterForm.reset();
  }

  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }
  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Failed') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  private setupFilter() {
    this.queryFilterService.initialValue.subscribe(query => {
      this.query = query;
      this.filterForm.patchValue(this.formConverter.toForm(query));
    });

    this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.queryFilterService.patch(this.formConverter.toQuery(value as any));
    })
  }

  private getInstructorBalances() {
    this.queryFilterService.valueChanges.pipe(
      debounceTime(400),
      untilDestroyed(this)
    ).subscribe(query => {
      this.payoutService.getInstructorBalances(query).subscribe(data => {
        this.balancesPagination = data;
      });
    })
  }
}
