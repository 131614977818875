<div *ngIf="dummyData.length > 0; else notFound" class="course-overview p-4 pt-0">
   <div class="flex flex-col gap-4 mb-4">
      <div class="flex flex-row gap-4">
         <div *ngFor="let payment of paymentHistory"
            class="relative flex flex-col gap-1 border border-neutral-100 rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-neutral-600">{{payment.title}}</h5>
            <h5 *ngIf="payment.type==='currency'" class="text-lg font-bold text-blue-600">{{payment.value | currency}}
            </h5>
            <h5 *ngIf="payment.type==='number'" class="text-lg font-bold text-blue-600">{{payment.value}}</h5>
            <h5 *ngIf="payment.type==='date'" class="text-lg font-bold text-blue-600">
               {{payment.value | date:'MMMM dd, yyyy'}}
            </h5>
            <svg-icon src="@assets/images/icons/question-mark-circle.svg"
               class="btn-icon absolute right-4 text-amber-600" [tooltip]="payment.tooltip"></svg-icon>
         </div>
      </div>
      <div class="flex flex-row gap-4">
         <div *ngFor="let payment of paymentCardHistory"
            class="relative flex flex-col gap-1 border border-neutral-100 rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-neutral-600">{{payment.title}}</h5>
            <h5 *ngIf="payment.type==='currency'" class="text-lg font-bold text-blue-600">{{payment.value | currency}}
            </h5>
            <h5 *ngIf="payment.type==='number'" class="text-lg font-bold text-blue-600">{{payment.value}}</h5>
            <svg-icon src="@assets/images/icons/question-mark-circle.svg"
               class="btn-icon absolute right-4 text-amber-600" [tooltip]="payment.tooltip"></svg-icon>
         </div>
      </div>
      <div class="flex flex-row gap-4">
         <div *ngFor="let payment of paymentMethodHistory"
            class="relative flex flex-col gap-1 border border-neutral-100 rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-neutral-600">{{payment.title}}</h5>
            <h5 class="text-lg font-bold text-blue-600">{{payment.value | currency}}</h5>
            <svg-icon src="@assets/images/icons/question-mark-circle.svg"
               class="btn-icon absolute right-4 text-amber-600" [tooltip]="payment.tooltip"></svg-icon>
         </div>
      </div>
   </div>

   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'hidden':''"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <form [formGroup]="filterForm">
                     <formly-form [form]="filterForm" [fields]="filterFormFields" [model]="filterFormModel">
                     </formly-form>
                  </form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
                  <button class="btn btn-dark bg-neutral-600 text-white">
                     <span>Filter</span>
                  </button>
               </div>
            </div>
         </div>
         <!-- Search With Dropdown -->
         <div class="input-combine flex">
            <div class="select2">
               <div class="relative">
                  <a (click)="dropdown('course')" type="button" aria-haspopup="listbox" aria-expanded="true"
                     aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
                     <div class="selected-item truncate">Statement ID</div>
                     <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
                  </a>
                  <ul [ngClass]="isDropdown['course']?'':'hidden'" tabindex="-1" role="listbox"
                     aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3" class="select-items "
                     ng-reflect-ng-class="hidden">
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 1</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 2</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 3</li>
                  </ul>
               </div>
            </div>
            <div class="input-group">
               <div class="group !rounded-l-none">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input placeholder="Enter your full name" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex items-center gap-1">
               <h6> Upcoming Payouts</h6>
               <div class="tag tag-sm">100 Payee</div>
            </div>
            <div class="actions flex flex-row gap-4">
               <div class="flex flex-col justify-center">
                  <p class="text-xs text-neutral-400 font-normal mb-0">Expected Payout Request Date:</p>
                  <p class="text-xs text-neutral-900 font-normal mb-0">1 Sep 2023 12:00:00 AM</p>
               </div>
               <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
                  <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
                  <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
               </button>
               <button class="btn-neutral px-3">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list">
               <!-- Table -->
               <div class="datagrid-table relative overflow-x-auto">
                  <table class="w-full text-left">
                     <thead class="heading">
                        <tr>
                           <th scope="col" class="w-28 pl-4">
                              <div class="col-name flex items-center gap-2">
                                 <input type="checkbox" [checked]="isAllCheckBoxChecked()"
                                    (change)="checkAllCheckBox($event)">
                                 {{checkedItemsCount()}} Selected
                              </div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Instructor</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Transactions</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Payout Method</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Pending Amount</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Available Amount</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Total Amount</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Expected Payout</div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="hover:cursor-pointer" *ngFor="let data of dummyData; let i = index">
                           <td>
                              <input (click)="checked($event)" [(ngModel)]="dummyData[i].checked" type="checkbox"
                                 name="" id="">
                           </td>
                           <td>
                              <span [routerLink]="'/ecommerce/payout/upcoming-payouts/transactions/'+data.id"
                                 class="course-id">
                                 <span class="text-blue-600 cursor-pointer">
                                    {{ data.instructor.title }}
                                 </span>
                              </span>
                           </td>
                           <td>
                              {{data.transactions}}
                           </td>
                           <td>
                              {{data.payment_methode}}
                           </td>
                           <td>
                              {{ data.pending_amount | currency }}
                           </td>
                           <td>
                              {{ data.available_amount | currency }}
                           </td>
                           <td>
                              {{data.total_amount | currency}}
                           </td>
                           <td>
                              <span class="course-id">
                                 <span class="">
                                    {{ data.request_date | date:'d MMMM y' }}<br>
                                    {{ data.request_date | date:'h:mm:ss a' }}
                                 </span>
                              </span>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <!-- Pagination -->
               <div class="pagination flex items-center justify-between px-5">
                  <div class="flex flex-row gap-4 items-center">
                     <span class="text text-neutral-700 font-normal text-sm">Show Items:</span>
                     <div class="form">
                        <form [formGroup]="paginationForm">
                           <formly-form [form]="paginationForm" [fields]="paginationFormFields"
                              [model]="paginationFormModel">
                           </formly-form>
                        </form>
                     </div>
                     <span class="text text-neutral-700 font-normal text-sm">entries</span>
                  </div>
                  <div class="navigation">
                     <nav aria-label="Page navigation example">
                        <ul class="inline-flex">
                           <li>
                              <a href="#" class="btn btn-outline">
                                 <svg-icon src="@assets/images/icons/arrow-left.svg" class="btn-icon h-5 w-5">
                                 </svg-icon>
                                 <span>Previous</span>
                              </a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">1</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">2</a>
                           </li>
                           <li>
                              <a href="#" aria-current="page" class="btn btn-outline active">3</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">...</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">4</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">5</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">
                                 <span>Next</span>
                                 <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5">
                                 </svg-icon>
                              </a>
                           </li>
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<ng-template #notFound>
   <div style="height: calc(100% - 108px);" class="flex flex-col items-center justify-center">
      <div class="max-w-md text-center">
         <h2 class="text-5xl text-[#9094FF] font-black">Oops!</h2>
         <h5 class="text-base text-neutral-600 font-normal">There is no payout request currently, please check later
         </h5>
      </div>
   </div>
</ng-template>