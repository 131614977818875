import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { InstructorGroupEditComponent } from './edit/edit.component';
import { InstructorGroupComponent } from './instructor-group.component';
import { InstructorGroupMemberComponent } from './member/member.component';
import { InstructorGroupNewComponent } from './new/new.component';
import { InstructorGroupViewComponent } from './view/view.component';

export * from './edit/edit.component';
export * from './instructor-group.component';
export * from './member/member.component';
export * from './new/new.component';
export * from './view/view.component';

export const Components = [
  InstructorGroupComponent,
  InstructorGroupNewComponent,
  InstructorGroupEditComponent,
  InstructorGroupViewComponent,
  InstructorGroupMemberComponent,
];

export const Routing = [
  {
    path: 'instructor-group',
    component: InstructorGroupComponent,
    data: { title: marker('Instructor Group'), breadcrumb: 'Instructor Group' },
  },
  {
    path: 'instructor-group/new',
    component: InstructorGroupNewComponent,
    data: { title: marker('Instructor Group'), breadcrumb: 'Add Instructor Group' },
  },
  {
    path: 'instructor-group/:id',
    component: InstructorGroupViewComponent,
    data: { title: marker('Instructor Group'), breadcrumb: 'View Instructor Group' },
  },
  {
    path: 'instructor-group/:id/edit',
    component: InstructorGroupEditComponent,
    data: { title: marker('Instructor Group'), breadcrumb: 'Edit Instructor Group' },
  },
  {
    path: 'instructor-group/:id/member',
    component: InstructorGroupMemberComponent,
    data: { title: marker('Instructor Group'), breadcrumb: 'Member Instructor Group' },
  },
];
