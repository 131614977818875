import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdminQNA, QnaResult, UntilDestroy, untilDestroyed } from '@shared';
import { CourseService } from '@shared/services';
import { combineLatest } from 'rxjs';
import { Logger, RouterStoreService } from 'thkee-common';
const log = new Logger('CourseDetailsComponent');

@UntilDestroy()
@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss'],
})
export class CourseQAComponent implements OnInit {
  courseState: string = '3';
  qna_lists!: Partial<AdminQNA>;

  courseId: string = '';
  isShowAllReply: boolean[] = [];

  constructor(private store: Store, private courseService: CourseService, private routerStore: RouterStoreService) {}

  ngOnInit(): void {
    combineLatest([this.routerStore.getParam('courseId')])
      .pipe(untilDestroyed(this))
      .subscribe(([courseId]) => {
        this.courseId = courseId;
        if (courseId) {
          this.getData(courseId);
        }
      });
  }

  getData(courseId: string = '') {
    this.courseService
      .getAdminCoursesQNA({}, courseId, false)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.qna_lists = data;
      });
  }

  getQNAReplies(qna: QnaResult) {
    this.courseService
      .getAdminCoursesQNAReplies(qna.id)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        // qna.QnAReply.push(...data.results);
        qna.QnAReply = data.results;
      });
  }

  showAllReply(qna: QnaResult, index: number) {
    this.isShowAllReply[index] = !this.isShowAllReply[index];
    this.getQNAReplies(qna);
  }
}
