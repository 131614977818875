<div class="relative z-[999]" aria-labelledby="modal-title" role="dialog" aria-modal="true" *ngIf="isOpened">
  <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div [class]="alignment"
      class="modal-container flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div [class]="alignment"
        class="modal-box relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8"
        [ngClass]="customClass">
        <ng-container *ngIf="!title; else modalHeader">
          <div #header>
            <ng-content select="[header]"></ng-content>
          </div>
          <ng-container *ngIf="!header.hasChildNodes()">
            <ng-container *ngTemplateOutlet="modalCloseOnly"></ng-container>
          </ng-container>
        </ng-container>
        <div class="bg-white">
          <ng-content select="[content]"></ng-content>
        </div>
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>

<ng-template #modalHeader>
  <div class="modal-header inline-block w-full items-center bg-neutral-50 px-6 py-4"
    [class.bg-rose-50]="color === 'red'">
    <div class="flex">
      <div class="grow text-lg font-bold">{{ title }}</div>
      <div>
        <a (click)="close()"
          class="ml-auto block cursor-pointer rounded-[3px] bg-neutral-100 text-neutral-400 hover:bg-neutral-100 hover:text-neutral-800">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalCloseOnly>
  <a (click)="close()"
    class="absolute right-6 top-4 cursor-pointer rounded-[3px] bg-neutral-100 text-neutral-400 hover:bg-neutral-100 hover:text-neutral-800">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
      stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </a>
</ng-template>

<ng-template #modalFooter>
  <div class="modal-footer border-t border-neutral-200">
    <ng-content select="[footer]"></ng-content>
  </div>
</ng-template>