<video
    id="video_holder"
    #video
    height="100%"
    width="100%"
    class="video-js vjs-fluid vjs-lock-showing"
    controls
    playsinline="true"
    preload="auto"
    [autoplay]="autoplay"
  ></video>