import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PricingTierState } from './pricing-state.model';

export const selectPricingTierState = createFeatureSelector<PricingTierState>('pricing-tier');

export const selectPricingTier = createSelector(selectPricingTierState, (entities) => entities);

export const selectPricingTierCRUDStatus = createSelector(selectPricingTierState, (entities) => entities.status);

export const selectLoadedPricingTierData = createSelector(selectPricingTierState, (entities) => entities.pricingTierData);

export const selectPricingTierList = createSelector(selectPricingTierState, (entities) => entities.pricingTierList);