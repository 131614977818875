import { Component, OnInit } from '@angular/core';
import { InstructorGroup } from 'thkee-common';

let dummyData: InstructorGroup[] = [
  {
    id: 3,
    date_created: '2023-05-24T19:34:25.087673Z',
    date_updated: '2023-05-24T19:34:25.087725Z',
    group_name: 'Premium',
    is_active: true,
    total_users: 34,
  },
  {
    id: 4,
    date_created: '2023-05-24T19:35:17.472140Z',
    date_updated: '2023-05-24T19:35:17.472167Z',
    group_name: 'Partner',
    is_active: true,
    total_users: 12,
  },
  {
    id: 5,
    date_created: '2023-05-25T06:06:04.875583Z',
    date_updated: '2023-05-25T06:06:04.875631Z',
    group_name: 'Default',
    is_active: true,
    total_users: 123,
  },
];

@Component({
  selector: 'app-instructor-group',
  templateUrl: './instructor-group.component.html',
  styleUrls: ['./instructor-group.component.scss'],
})
export class InstructorGroupComponent implements OnInit {
  instructorGroup: InstructorGroup[] = dummyData;
  constructor() {}

  ngOnInit(): void {}
}
