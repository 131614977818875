import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { PricingTierAPIService, ToastService } from 'thkee-common';
import { PricingTierService } from './pricing-store.service';
import { PricingTierActions } from './pricing.actions';
@Injectable()
export class PricingTierEffects {
  constructor(
    private actions$: Actions,
    private pricingTierService: PricingTierService,
    private pricingTierAPIService: PricingTierAPIService,
    private router: Router,
    private toastService: ToastService
  ) {}

  loadPricingTierList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingTierActions.loadPricingTierList),
      switchMap((params) =>
        this.pricingTierService.getPricingTierList(params).pipe(
          map((data) => PricingTierActions.loadPricingTierListSuccess({ data })),
          catchError((error) => of(PricingTierActions.loadPricingTierListFail({ error })))
        )
      )
    )
  );

  getPricingTier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingTierActions.getPricingTier),
      switchMap((params) =>
        this.pricingTierService.getPricingTier(params).pipe(
          map((data) => PricingTierActions.getPricingTierSuccess({ data })),
          catchError((error) => of(PricingTierActions.getPricingTierFail({ error })))
        )
      )
    )
  );

  addPricingTier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingTierActions.addPricingTier),
      switchMap((params) =>
        this.pricingTierService.addPricingTier(params).pipe(
          map((data) => PricingTierActions.addPricingTierSuccess({ data })),
          catchError((error) => {
            this.toastService.error({ message: error.error.errors[0].detail });
            return of(PricingTierActions.addPricingTierFail({ error }));
          })
        )
      )
    )
  );

  updatePricingTier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingTierActions.updatePricingTier),
      switchMap((params) =>
        this.pricingTierService.updatePricingTier(params).pipe(
          map((data) => PricingTierActions.updatePricingTierSuccess({ data })),
          catchError((error) => {
            this.toastService.error({ message: error.error.errors[0].detail });
            return of(PricingTierActions.updatePricingTierFail({ error }));
          })
        )
      )
    )
  );

  removePricingTier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingTierActions.removePricingTier),
      switchMap((params) =>
        this.pricingTierService.removePricingTier(params).pipe(
          map((data) => PricingTierActions.removePricingTierSuccess(params)),
          catchError((error) => {
            this.toastService.error({ message: error.error.errors[0].detail });
            return of(PricingTierActions.removePricingTierFail({ error }));
          })
        )
      )
    )
  );
}
