import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  Column,
  DrawerComponent,
  FilterKey,
  FilterOptionComponent,
  FilterOptionsRefund,
  PRODUCT_DRAWER_TAB,
  REFUND_TABLE_COLUMN,
  REFUND_TABLE_SEARCH,
  RefundItems,
  RefundQueryParams,
  RefundResponse,
  STUDENT_DRAWER_TAB,
  STUDENT_SETTINGS_DRAWER_TAB,
  TabItem,
  TableSearch,
} from '@shared';
import { TransactionsService } from '@shared/services';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { FileProps, LANGUAGES, Logger, PaginationService, UtilsService } from 'thkee-common';
const log = new Logger('CourseDetailsComponent');
const pageSize: number = 10;

@Component({
  selector: 'app-refund-requests',
  templateUrl: './refund-requests.component.html',
  styleUrls: ['./refund-requests.component.scss'],
})
export class RefundRequestsComponent implements OnInit, OnDestroy {
  // Create product form
  productForm = new FormGroup({});
  productFormModel: any = {};
  productFormFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'card block py-9 px-6 mb-4',
      fieldGroup: [
        {
          className: 'section-label block border-b-[2px] border-neutral-200 pb-3 mb-3',
          template: '<h5>General</h5>',
        },
        {
          key: 'title',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: '#SAP MM Material Management',
          props: {
            label: 'Title',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Learn Blender in 60 minutes',
            disabled: true,
            tips: 'This is a tip message for course title',
          },
          validation: {
            messages: {
              minLength: "Title can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'subtitle',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: 'Introduction to SAP MM Material Management',
          props: {
            label: 'Subtitle',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Introduction to SAP MM Material Management!',
            disabled: true,
            tips: 'This is a tip message for course sub title',
          },
          validation: {
            messages: {
              minLength: "This field can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'topics',
          type: 'select',
          defaultValue: ['uiux', 'frontend'],
          wrappers: ['feedback-field'],
          props: {
            label: 'Topics',
            placeholder: 'Choose topic',
            multiple: true,
            stayPlaceholder: true,
            disabled: true,
            tips: 'This is a tip message for course title',
            stylish: true,
            options: [
              { label: 'UI/UX', value: 'uiux' },
              { label: 'Frontend', value: 'frontend' },
              { label: 'Backend', value: 'backend' },
            ],
          },
          expressions: {},
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
          fieldGroup: [
            {
              key: 'skill_level',
              type: 'select',
              defaultValue: 'Beginner',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Level',
                placeholder: 'Choose a level',
                stylish: true,
                options: [
                  { label: 'All', value: 'All' },
                  { label: 'Beginner', value: 'Beginner' },
                  { label: 'Intermediate', value: 'Intermediate' },
                  { label: 'Expert', value: 'Expert' },
                ],
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
            {
              key: 'language',
              type: 'select',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Language',
                placeholder: 'Select language',
                stylish: true,
                options: _.sortBy(
                  _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                  (lang) => lang.label
                ),
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6',
          fieldGroup: [
            {
              key: 'category',
              type: 'select',
              defaultValue: 'business',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Category',
                placeholder: 'Choose a category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Business', value: 'business' },
                  { label: 'Design', value: 'design' },
                  { label: 'Language', value: 'language' },
                ],
              },
              expressions: {},
            },
            {
              key: 'subcategory',
              type: 'select',
              defaultValue: 'graphic-design',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Sub category',
                placeholder: 'Choose sub-category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Graphic Design', value: 'graphic-design' },
                  { label: 'Web Design', value: 'web-design' },
                  { label: 'UI/UX Design', value: 'uiux-design' },
                ],
              },
              expressions: {},
            },
          ],
        },
      ],
    },

    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'image',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Image Cover',
        placeholder: 'Upload your course image here. Supported files are .jpg, .jpeg, or .png.',
        preview: true,
        previewType: 'image',
        allowedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
      } as FileProps,
      expressions: {},
    },
    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'promo_video',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Promotional Video',
        placeholder: "Upload your course's promotional video here. Maximum of 200MB.",
        preview: true,
        previewType: 'video',
        hideLabel: false,
        allowedTypes: ['video/mp4', 'video/mkv', 'video/mov'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
        onUpload: (upload, field) => {
          // log.debug('upload: ', upload);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadStart({
          //       referenceId: `${courseId}-${field.key}`,
          //       fileName: upload.name,
          //       fileType: upload.type,
          //     })
          //   );
          // });
        },
        onProgress: (progress, field) => {
          // log.debug('progress: ', progress);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadProgress({
          //       referenceId: `${courseId}-${field.key}`,
          //       bytesTotal: progress.bytesTotal,
          //       bytesUploaded: progress.bytesUploaded,
          //       progressPercent: progress.progressPercent,
          //     })
          //   );
          // });
        },
        onComplete: (field) => {
          // log.debug('complete');
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadComplete({
          //       referenceId: `${courseId}-${field.key}`,
          //     })
          //   );
          // });
        },
      } as FileProps,
      expressions: {
        // 'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
        //   map(([course, userId]) => ({
        //     user: userId,
        //     course: course.id,
        //   })),
        //   distinctUntilChanged()
        // ),
        // 'props.previewUrl': this.course$.pipe(
        //   map((course) => {
        //     return course.promo_video_url;
        //   }),
        //   distinctUntilChanged()
        // ),
        // 'props.feedback': this.getFeedbackCount('promo_video'),
      },
    },
  ];

  //Product TAB
  productTabLists: TabItem[] = PRODUCT_DRAWER_TAB;

  //Student TAB
  studentTabLists: TabItem[] = STUDENT_DRAWER_TAB;

  //Student Settings TAB
  studentSettingsTabLists: TabItem[] = STUDENT_SETTINGS_DRAWER_TAB;

  // Common variables
  queryParams: RefundQueryParams = { page: 1, page_size: pageSize };
  transactionsKey: FilterKey = { key1: 'transaction_min', key2: 'transaction_max' };
  filterValues: FilterOptionsRefund = {
    status: [],
    refund_type: [],
  };
  filterOptions: FilterOptionsRefund = {
    status: [],
    refund_type: [],
  };
  searchType: TableSearch[] = REFUND_TABLE_SEARCH;
  selectedItems: TableSearch = REFUND_TABLE_SEARCH[0];
  columns: Column[] = REFUND_TABLE_COLUMN;
  isDropdown: boolean[] = [];
  refundItems: RefundItems[] = [];
  isLoading: boolean = false;
  totalOrder: number = 0;

  private subscriptions = new Subscription();

  constructor(
    private breadcrumbService: BreadcrumbService,
    private transactionsService: TransactionsService,
    private utilsService: UtilsService,
    private paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Refund Request',
        url: '',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
    this.getOrders();
    this.setFilterOptions();
  }

  // Sidebar drawer
  @ViewChild('buyerDrawer') private buyerDrawer!: DrawerComponent;
  showDrawer(type: any, event: any) {
    event.stopPropagation();
    this.buyerDrawer.open();
  }

  // Get data
  private getOrders(params: RefundQueryParams = {}) {
    this.isLoading = true;
    this.subscriptions.add(
      this.transactionsService.getRefundLists(this.queryParams, false).subscribe({
        next: (res: RefundResponse) => {
          this.refundItems = res.results;
          this.totalOrder = res.count;
          this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
      })
    );
  }

  // Common UI actions
  // Filter
  setFilterOptions() {
    this.filterOptions.status = [
      { value: 'pending', label: $localize`Pending` },
      { value: 'approved', label: $localize`Approved` },
      { value: 'refunded', label: $localize`Refunded` },
      { value: 'rejected', label: $localize`Rejected` },
      { value: 'cancelled', label: $localize`Cancelled` },
      { value: 'chargeback', label: $localize`Chargeback` },
      { value: 'reconciliation', label: $localize`Reconciliation` },
    ];
    this.filterOptions.refund_type = [
      { value: 'regular_refund', label: $localize`Regular Refund` },
      { value: 'thkee_refund', label: $localize`Thkee Refund` },
    ];
  }

  filterHander(event: { action: string; data: {} }) {
    if (event.action === 'change') {
      const queryParams = this.utilsService.convertQueryParams(event.data);
      this.queryParams = { ...this.queryParams, ...queryParams };
    }
    if (event.action === 'change-input') {
      this.queryParams = { ...this.queryParams, ...event.data };
    }
    if (event.action === 'reset') {
      this.isDropdown[1] = false;
      this.queryParams = {};
      this.clearFilter([]);
      this.getOrders(this.queryParams);
    }
  }

  clearFilter(filters: FilterOptionComponent[]) {
    if (filters) {
      filters.forEach((filter: FilterOptionComponent) => {
        filter.reset();
      });
    }
  }

  submitFilter(filters: FilterOptionComponent[]) {
    this.isDropdown[1] = false;
    this.getOrders(this.queryParams);
    // this.clearFilter(filters);
    // this.queryParams = { page: 1, page_size: pageSize };
  }

  // Search
  selectItems(item: TableSearch) {
    this.queryParams = { page: 1, page_size: pageSize };
    this.selectedItems = item;
  }
  search(event: any) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.search = `${this.selectedItems.value + ':' + event.target.value}`;
    this.getOrders(this.queryParams);
  }

  // Dropdown
  dropdown(type: number) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Show/Hide column
  toggleColumn(col: Column): void {
    col.visible = !col.visible;
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      return this.utilsService.getStatusColor(status);
    }
    return 'green';
  }

  // Pageination
  paginat(page_number: number) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = page_number;
    this.getOrders(this.queryParams);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
