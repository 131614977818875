<div class="flex flex-col gap-5 p-5">
  <div class="p-4">
    <table>
      <tbody>
        <tr>
          <td class="font-semibold p-1 pr-8">Instructor</td>
          <td class="text-slate-400">{{request.instructor.fullname}}</td>
        </tr>
        <tr>
          <td class="font-semibold p-1 pr-8">Payment Method</td>
          <td class="text-slate-400">{{request.payment_method}}</td>
        </tr>
        <tr>
          <td class="font-semibold p-1 pr-8">Total Amount</td>
          <td class="text-slate-400">{{request.amount | currency:request.currency_symbol}}</td>
        </tr>
        <tr>
          <td class="font-semibold p-1 pr-8">Status</td>
          <td class="text-slate-400">{{request.status}}</td>
        </tr>
        <tr>
          <td class="font-semibold p-1 pr-8">Receipt</td>
          <td class="text-sm">
            <a *ngIf="request.receipt" class="text-primary underline" [href]="request.receipt" target="_blank">Click to download</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="p-4 flex flex-col bg-slate-50 gap-10">
    <div class="flex flex-col gap-5">
      <h6 class="font-semibold text-lg">Beneficiary Details</h6>
      <div>
        <table>
          <tbody>
            <tr>
              <td class="font-semibold p-1 w-40 max-w-[200px] min-w-[180px]">Beneficiary Name:</td>
              <td class="text-slate-400">{{request.beneficiary_and_payment_gateway_details.beneficiary_name}}</td>
            </tr>
            <tr>
              <td class="font-semibold p-1 w-40 max-w-[200px] min-w-[180px]">Beneficiary Country:</td>
              <td class="text-slate-400">{{request.beneficiary_and_payment_gateway_details.address}}**</td>
            </tr>
            <tr>
              <td class="font-semibold p-1 w-40 max-w-[200px] min-w-[180px]">Beneficiary email:</td>
              <td class="text-slate-400">{{request.beneficiary_and_payment_gateway_details.address}}**</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex flex-col gap-5">
      <h6 class="font-semibold text-lg">Bank Details</h6>
      <div>
        <table>
          <tbody>
            <tr>
              <td class="font-semibold p-1 w-40 max-w-[200px] min-w-[180px]">Bank Name</td>
              <td class="text-slate-400">{{request.beneficiary_and_payment_gateway_details.bank_name}}</td>
            </tr>
            <tr>
              <td class="font-semibold p-1 w-40 max-w-[200px] min-w-[180px]">Bank Address</td>
              <td class="text-slate-400">{{request.beneficiary_and_payment_gateway_details.address}}**</td>
            </tr>
            <tr>
              <td class="font-semibold p-1 w-40 max-w-[200px] min-w-[180px]">Bank Country</td>
              <td class="text-slate-400">{{request.beneficiary_and_payment_gateway_details.address}}**</td>
            </tr>
            <tr>
              <td class="font-semibold p-1 w-40 max-w-[200px] min-w-[180px]">SWIFT/BIC Code</td>
              <td class="text-slate-400">{{request.beneficiary_and_payment_gateway_details.address}}**</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="p-4 flex flex-col gap-5">
    <h6>Post Activities</h6>
    <div class="activities">
      <div class="flex flex-col">
        <div *ngFor="let event of request.history; let last = last" class="flex gap-6">
          <div class="flex flex-col">
            <thk-avatar [title]="event.actor.fullname" [url]="event.actor.avatar"></thk-avatar>
            <div *ngIf="!last" class="relative w-full flex-1">
              <div class="absolute h-full translate-x-[-50%] left-[50%] w-[1px] border-l-2 border-l-slate-300"></div>
            </div>
          </div>
          <div class="flex flex-col gap-3 pb-2">
            <div class="flex gap-1">
              <span class="text-md font-bold text-slate-500">{{actionTitles[event.action] || event.action}}</span>
              <span class="text-md font-bold">{{event.actor.fullname}}</span>
              <span class="text-md font-bold text-slate-500">.</span>
              <span class="text-slate-400">{{event.created_at | date:'dd MMMM, YYYY'}}</span>
            </div>
            <div class="mr-4 flex flex-col">
              <table>
                <tbody>
                  <tr>
                    <td>Released amount</td>
                    <td>: {{ event.data.processed_amount | currency:request.currency_symbol }}</td>
                  </tr>
                  <tr>
                    <td>Payment method</td>
                    <td>: {{ event.data.payment_method }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
