<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4">
      <h3>Course Categories</h3>
   </div>
   <div class="grid grid-cols-12 gap-4 p-4 rounded-xl border border-neutral-100">
      <div class="col-span-6 flex-col rounded-xl border border-neutral-100 pb-4 flex items-start overflow-hidden">
         <div class="flex flex-col p-4 items-center gap-2.5 self-stretch bg-neutral-100">
            <h3 class=" text-black text-center text-sm font-bold" i18n>Category</h3>
            <button (click)="categoryAction('Add')" class="btn px-6 py-3 bg-white text-black rounded-[4px] gap-2 border-0 w-full">
               <svg-icon src="@assets/images/icons/plus.svg" class="w-5"></svg-icon>
               <span class="font-semibold text-sm" i18n>Add Category</span>
            </button>
         </div>
         <div class="flex flex-col min-h-[300px] max-h-[500px] w-full overflow-y-auto relative">
            <ng-container *ngIf="categoryListLoading; else loadedCategory">
               <ng-container *ngTemplateOutlet="loader"></ng-container>
            </ng-container>
            <ng-template #loadedCategory>
               <ng-container *ngTemplateOutlet="categoryView"></ng-container>
            </ng-template>
         </div>
      </div>
      <div *ngIf="selectedCategory" class="col-span-6 flex-col rounded-xl border border-neutral-100 pb-4 flex items-start overflow-hidden">
         <div class="flex flex-col p-4 items-center gap-2.5 self-stretch">
            <h3 class="text-black text-center text-sm font-bold" i18n>Sub Category : {{selectedCategory.name}}</h3>
            <button (click)="subCategoryAction('Add')" class="btn px-6 py-3 bg-white text-neutral-400 rounded-[4px] gap-2 border-0">
               <svg-icon src="@assets/images/icons/plus.svg" class="w-5"></svg-icon>
               <span class="font-semibold text-sm" i18n>Add Category</span>
            </button>
         </div>
         <div class="flex flex-col min-h-[300px] max-h-[500px] w-full overflow-y-auto relative">
            <ng-container *ngTemplateOutlet="subCategoryView"></ng-container>
         </div>
      </div>
   </div>
</div>

<!-- Category list -->
<ng-template #categoryView>
   <div *ngFor="let category of categoryLists; let i = index" class="category-list">
      <div class="flex flex-row items-center gap-2">
         <p class="mb-0">{{category.name}}</p>
         <span class="bg-amber-50 text-amber-600 rounded-2xl px-2 py-[2px] text-xs">{{category.subcategories?.length}}</span>
      </div>
      <div class="flex flex-row items-center gap-2">
         <div class="flex flex-row gap-1 items-center">
            <div (ClickOutside)="handleClickOutside(i)" class="select2">
               <div class="relative">
                  <button (click)="dropdown(i)" class="btn p-3 bg-white text-black border-0 h-[43px]">
                     <svg-icon src="@assets/images/icons/dots-horizontal.svg" class="w-5"></svg-icon>
                  </button>
                  <ul [ngClass]="isDropdown[i]?'':'hidden'" tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                     aria-activedescendant="listbox-option-3" class="select-items absolute right-0 min-w-fit"
                     ng-reflect-ng-class="hidden">
                     <li (click)="categoryAction('View', category, i)" id="listbox-option-0" role="option" class="item flex flex-row items-center gap-3" i18n>
                        <svg-icon src="@assets/images/icons/eye.svg" class="w-5 text-blue-600"></svg-icon> View
                     </li>
                     <li (click)="categoryAction('Update', category, i)" id="listbox-option-0" role="option" class="item flex flex-row items-center gap-3" i18n>
                        <svg-icon src="@assets/images/icons/pencil-square.svg" class="w-5 text-amber-600"></svg-icon> Edit
                     </li>
                     <li (click)="categoryAction('Delete', category, i)" id="listbox-option-0" role="option" class="item flex flex-row items-center gap-3" i18n>
                        <svg-icon src="@assets/images/icons/trash.svg" class="w-5 text-rose-600"></svg-icon> Delete
                     </li>
                  </ul>
               </div>
            </div>
            <button (click)="onclickCategory(category)" class="btn p-3 bg-white text-black border-0">
               <svg-icon src="@assets/images/icons/chevron-right.svg" class="w-5 arrow-next"></svg-icon>
            </button>
         </div>
      </div>
   </div>
</ng-template>

<!-- Category modal -->
<common-modal #categoryConfirmedModal customClass="sm:w-full sm:max-w-[788px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl" i18n>{{categoryActionType}} Category</h1>
         </div>
         <button (click)="categoryConfirmedModal.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div class="px-6 py-5 border-b border-b-neutral-100">
         <div class="form">
            <form [formGroup]="categoryForm">
               <formly-form [form]="categoryForm" [fields]="categoryFormFields" [model]="categoryFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="categoryConfirmedModal.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold" i18n>Cancel</span>
            </button>
            <button (click)="categoryCreate()" [disabled]="categoryForm.invalid || isSubmittedCategory" class="btn bg-teal-600 border-none relative">
               <span class="text-white text-sm font-semibold" i18n>Save</span>
               <ng-container *ngIf="isSubmittedCategory">
                  <ng-container *ngTemplateOutlet="loaderWhit"></ng-container>
               </ng-container>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>


<!-- Sub category list -->
<ng-template #subCategoryView>
   <div *ngFor="let category of selectedCategory.subcategories; let i = index" class="category-list">
      <div class="flex flex-row items-center gap-2">
         <p class="mb-0">{{category.name}}</p>
         <span class="bg-amber-50 text-amber-600 rounded-2xl px-2 py-[2px] text-xs">0</span>
      </div>
      <div class="flex flex-row items-center gap-2">
         <div class="flex flex-row gap-1 items-center">
            <div (ClickOutside)="handleSubCategoryClickOutside(i)" class="select2">
               <div class="relative">
                  <button (click)="subCategoryDropdown(i)" class="btn p-3 bg-white text-black border-0 h-[43px]">
                     <svg-icon src="@assets/images/icons/dots-horizontal.svg" class="w-5"></svg-icon>
                  </button>
                  <ul [ngClass]="isDropdownSub[i]?'':'hidden'" tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                     aria-activedescendant="listbox-option-3" class="select-items absolute right-0 min-w-fit"
                     ng-reflect-ng-class="hidden">
                     <li (click)="subCategoryAction('View', category, i)" id="listbox-option-0" role="option" class="item flex flex-row items-center gap-3" i18n>
                        <svg-icon src="@assets/images/icons/eye.svg" class="w-5 text-blue-600"></svg-icon> View
                     </li>
                     <li (click)="subCategoryAction('Update', category, i)" id="listbox-option-0" role="option" class="item flex flex-row items-center gap-3" i18n>
                        <svg-icon src="@assets/images/icons/pencil-square.svg" class="w-5 text-amber-600"></svg-icon> Edit
                     </li>
                     <li (click)="subCategoryAction('Delete', category, i)" id="listbox-option-0" role="option" class="item flex flex-row items-center gap-3" i18n>
                        <svg-icon src="@assets/images/icons/trash.svg" class="w-5 text-rose-600"></svg-icon> Delete
                     </li>
                  </ul>
               </div>
            </div>
            <!-- TODO: In the future, If there more subcategory -->
            <!-- <button class="btn p-3 bg-white text-black border-0">
               <svg-icon src="@assets/images/icons/chevron-right.svg" class="w-5 arrow-next"></svg-icon>
            </button> -->
         </div>
      </div>
   </div>
</ng-template>

<!-- Sub Category modal -->
<common-modal #subCategoryFormModal customClass="sm:w-full sm:max-w-[788px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl" i18n>{{subCategoryActionType}} Category</h1>
         </div>
         <button (click)="subCategoryFormModal.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div class="px-6 py-5 border-b border-b-neutral-100">
         <div class="form">
            <form [formGroup]="categoryForm">
               <formly-form [form]="categoryForm" [fields]="categoryFormFields" [model]="categoryFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="subCategoryFormModal.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold" i18n>Cancel</span>
            </button>
            <button (click)="subCategoryCreate()" [disabled]="categoryForm.invalid || isSubmittedSubcategory" class="btn bg-teal-600 border-none relative">
               <span class="text-white text-sm font-semibold" i18n>Save</span>
               <ng-container *ngIf="isSubmittedSubcategory">
                  <ng-container *ngTemplateOutlet="loaderWhit"></ng-container>
               </ng-container>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>

<ng-template #loaderWhit>
   <div class="loader">
      <svg class="text-white m-auto h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
         viewBox="0 0 24 24">
         <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
         <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
         </path>
      </svg>
   </div>
</ng-template>