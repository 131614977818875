import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FilterKey } from '@shared/models';
import { FilterOption } from 'thkee-common';

@Component({
  selector: 'app-filter-option',
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.scss'],
})
export class FilterOptionComponent implements OnInit, OnChanges {
  @Input() defaultValue: any = {};
  @Input() key: string = String(Date.now());
  @Input() keyObj: FilterKey = { key1: '', key2: '' };
  @Input() options: FilterOption[] | null = [];
  @Input() formType: string = 'checkbox';
  @Output() filterEvent = new EventEmitter<{ action: string; data: {} }>();

  form = new FormGroup({});
  model = {};
  optionsF: FormlyFormOptions = {};
  fields: FormlyFieldConfig = {};

  constructor() {}

  ngOnInit(): void {
    if (this.formType === 'checkbox') {
      this.fields = {
        key: this.key,
        type: 'multicheckbox',
        defaultValue: this.defaultValue,
        props: {
          itemClass: 'mb-2.5 capitalize',
          options: this.options ?? [],
          change: (field, $event) => {
            this.emitEvent('change', this.model);
          },
        },
      };
    }
    if (this.formType === 'input') {
      this.fields = {
        fieldGroupClassName: 'flex gap-2 items-center',
        fieldGroup: [
          {
            key: this.keyObj.key1,
            type: 'input',
            className: 'w-[78px]',
            props: {
              placeholder: '1',
              keyup: (field, $event) => {
                this.emitEvent('change-input', this.model);
              },
            },
          },
          {
            template: '-',
          },
          {
            key: this.keyObj.key2,
            type: 'input',
            className: 'w-[78px]',
            props: {
              placeholder: '20',
              keyup: (field, $event) => {
                this.emitEvent('change-input', this.model);
              },
            },
          },
        ],
      };
    }
    this.updateFieldProperties();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('options' in changes) {
      this.updateFieldProperties();
    }
  }

  updateFieldProperties() {
    if (this.formType === 'checkbox') {
      if (this.key) {
        this.fields.key = this.key;
      }
      if (this.options && this.fields && this.fields.props) {
        this.fields.props['options'] = this.options;
      }
      if (this.defaultValue) {
        this.fields.defaultValue = this.defaultValue;
      }
    }
  }

  emitEvent(action: string, data = {}) {
    this.filterEvent.emit({ action, data });
  }

  reset() {
    this.form.reset();
    this.emitEvent('reset', this.model);
  }
}
