import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PricingGeoState } from './geo-state.model';

export const selectPricingGeoState = createFeatureSelector<PricingGeoState>('pricing-geo');

export const selectPricingGeo = createSelector(selectPricingGeoState, (entities) => entities);

export const selectPricingGeoList = createSelector(selectPricingGeoState, (entities) => entities.pricingGeoList);

export const selectLoadedPricingGeoData = createSelector(selectPricingGeoState, (entities) => entities.pricingGeoData);

export const selectGeoCRUDStatus = createSelector(selectPricingGeoState, (entities) => entities.status);

export const selectGeoPricingTiers = createSelector(selectPricingGeoState, (entities) => entities.pricingGeoTiers);
