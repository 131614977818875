<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4 flex items-center justify-between">
      <h3 class="text-2xl text-neutral-800 font-semibold">Transaction #{{params['transactionId']}}</h3>
      <button class="btn btn-outline-red">
         <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="btn-icon">
            <path
               d="M10.3333 3.50016V2.16683C10.3333 1.43045 9.73638 0.833496 9 0.833496H2.33333C1.59695 0.833496 1 1.43045 1 2.16683V6.16683C1 6.90321 1.59695 7.50016 2.33333 7.50016H3.66667M5 10.1668H11.6667C12.403 10.1668 13 9.56988 13 8.8335V4.8335C13 4.09712 12.403 3.50016 11.6667 3.50016H5C4.26362 3.50016 3.66667 4.09712 3.66667 4.8335V8.8335C3.66667 9.56988 4.26362 10.1668 5 10.1668ZM9.66667 6.8335C9.66667 7.56988 9.06971 8.16683 8.33333 8.16683C7.59695 8.16683 7 7.56988 7 6.8335C7 6.09712 7.59695 5.50016 8.33333 5.50016C9.06971 5.50016 9.66667 6.09712 9.66667 6.8335Z"
               stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
         <span>Refund</span>
      </button>
   </div>


   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <!-- Card Header -->
         <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
            <div class="flex flex-row gap-14">
               <div class="flex flex-col gap-3">
                  <div class="flex gap-x-2.5 items-center relative">
                     <h3 class="text-sm font-normat text-neutral-800">Transaction ID</h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h6 class="text-xl font-semibold text-neutral-900">#{{params['transactionId']}}</h6>
               </div>
               <div class="flex flex-col gap-3">
                  <div class="flex gap-x-2.5 items-center relative">
                     <h3 class="text-sm font-normat text-neutral-800">Product</h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h6 class="text-xl font-semibold text-neutral-900">Product Design Course For Beginner (<span
                        class="text-blue-600">#14568 </span>) </h6>
               </div>
               <div class="flex flex-col gap-3">
                  <div class="flex gap-x-2.5 items-center relative">
                     <h3 class="text-sm font-normat text-neutral-800">Status</h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h6 class="text-xl font-semibold text-rose-600">Refunded (<span class="text-blue-600">#14568 </span>)
                  </h6>
               </div>
            </div>

            <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
               <svg-icon src="@assets/images/icons/arrow-down-tray.svg" class="text-blue-600 w-4 h-4"></svg-icon>
               <span class="text-blue-600 font-semibold text-sm">Download PDF</span>
            </button>
         </div>


         <!-- Summary graph -->
         <div class="px-6 pt-6 pb-12">
            <h5 class="text-base text-neutral-900 font-bold">Premium Instructor</h5>
            <div class="flex flex-row gap-2.5 my-4">
               <div class="flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full">
                  <span class="w-[42px] h-11 bg-teal-600 rounded-[5px] flex justify-center items-center">
                     <svg-icon src="@assets/images/icons/dollar-sign.svg" class="text-black">
                     </svg-icon>
                  </span>
                  <div class="flex flex-col gap-1 flex-1">
                     <div class="flex flex-row items-center gap-2 relative">
                        <h5 class="text-sm font-semibold text-neutral-600">Sale Price</h5>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Method"
                           class="text-black w-5 h-5">
                        </svg-icon>
                     </div>
                     <h3 class="font-bold text-base text-neutral-900">$ 100</h3>
                  </div>
               </div>
               <div class="flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full">
                  <span class="w-[42px] h-11 bg-blue-600 rounded-[5px] flex justify-center items-center">
                     <svg-icon src="@assets/images/icons/channel-icon.svg" class="text-black">
                     </svg-icon>
                  </span>
                  <div class="flex flex-col gap-1 flex-1">
                     <div class="flex flex-row items-center gap-2 relative">
                        <h5 class="text-sm font-semibold text-neutral-600">Channel</h5>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Channel"
                           class="text-black w-5 h-5">
                        </svg-icon>
                     </div>
                     <h3 class="font-bold text-base text-neutral-900">Organic</h3>
                  </div>
               </div>
               <div class="flex flex-row gap-2.5 rounded-[5px] p-[18px] w-full">
                  <span class="w-[42px] h-11 bg-amber-600 rounded-[5px] flex justify-center items-center">
                     <svg-icon src="@assets/images/icons/coupon-icon.svg" class="text-black">
                     </svg-icon>
                  </span>
                  <div class="flex flex-col gap-1 flex-1">
                     <div class="flex flex-row items-center gap-2 relative">
                        <h5 class="text-sm font-semibold text-neutral-600">Coupon</h5>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Coupon"
                           class="text-black w-5 h-5">
                        </svg-icon>
                     </div>
                     <h3 class="font-bold text-base text-neutral-900">DISC10</h3>
                  </div>
               </div>
            </div>

            <div class="flex flex-row gap-2.5">
               <div class="flex flex-row items-center gap-2.5 rounded-[5px] px-4 py-6 w-full border border-neutral-100">
                  <app-circle-progressbar [outerStrokeColor]="'#3941FE'" [percent]="38">
                     <ng-container content>
                        <div
                           class="flex flex-col items-center justify-center p-1 w-[70%] h-[70%] absolute bg-white rounded-full">
                           <h3 class="text-base text-blue-600 font-bold">38%</h3>
                           <h5 class="text-xs text-neutral-600 font-normal">Shares</h5>
                        </div>
                     </ng-container>
                  </app-circle-progressbar>
                  <div class="flex flex-col gap-1 flex-1">
                     <div class="flex flex-row items-center gap-2 relative">
                        <h5 class="text-sm font-semibold text-neutral-600">Platform Earning</h5>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Platform Earning"
                           class="text-black w-5 h-5">
                        </svg-icon>
                     </div>
                     <h3 class="font-bold text-base text-neutral-900">$ 20.8</h3>
                  </div>
               </div>
               <div class="flex flex-row items-center gap-2.5 rounded-[5px] px-4 py-6 w-full border border-neutral-100">
                  <app-circle-progressbar [outerStrokeColor]="'#0d9488'" [percent]="60">
                     <ng-container content>
                        <div
                           class="flex flex-col items-center justify-center p-1 w-[70%] h-[70%] absolute bg-white rounded-full">
                           <h3 class="text-base text-teal-600 font-bold">60%</h3>
                           <h5 class="text-xs text-neutral-600 font-normal">Shares</h5>
                        </div>
                     </ng-container>
                  </app-circle-progressbar>
                  <div class="flex flex-col gap-1 flex-1">
                     <div class="flex flex-row items-center gap-2 relative">
                        <h5 class="text-sm font-semibold text-neutral-600">Instructor Earning</h5>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor Earning"
                           class="text-black w-5 h-5">
                        </svg-icon>
                     </div>
                     <h3 class="font-bold text-base text-neutral-900">$43</h3>
                  </div>
               </div>
               <div class="flex flex-row items-center gap-2.5 rounded-[5px] px-4 py-6 w-full border border-neutral-100">
                  <app-circle-progressbar [outerStrokeColor]="'#D97706'" [percent]="2">
                     <ng-container content>
                        <div
                           class="flex flex-col items-center justify-center p-1 w-[70%] h-[70%] absolute bg-white rounded-full">
                           <h3 class="text-base text-amber-600 font-bold">2%</h3>
                           <h5 class="text-xs text-neutral-600 font-normal">Shares</h5>
                        </div>
                     </ng-container>
                  </app-circle-progressbar>
                  <div class="flex flex-col gap-1 flex-1">
                     <div class="flex flex-row items-center gap-2 relative">
                        <h5 class="text-sm font-semibold text-neutral-600">Affiliates Earning</h5>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Affiliates Earning"
                           class="text-black w-5 h-5">
                        </svg-icon>
                     </div>
                     <h3 class="font-bold text-base text-neutral-900">$ 4.9</h3>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- Payment breakdown -->
      <h3 class="text-2xl text-neutral-800 font-semibold my-4">Payment Breakdown</h3>
      <div class="grid grid-cols-2 gap-4">
         <div class="card border border-neutral-100 w-full">
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-row items-center gap-2.5 relative">
                  <h3 class="text-base font-bold text-neutral-800">Transaction Detail</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction Detail"
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h3 class="text-base font-bold text-neutral-800">Total</h3>
            </div>
            <ng-container *ngFor="let transaction of transactionDetail.list">
               <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
                  <div class="flex flex-col gap-1">
                     <div class="flex flex-row items-center gap-2.5 relative">
                        <h3 class="text-base font-bold text-neutral-800">
                           {{transaction.title}}
                           <ng-container *ngIf="transaction?.code">(<span class="text-blue-600 font-normal">
                                 #{{transaction.code}} </span> )</ng-container>
                        </h3>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="{{transaction.title}}"
                           class="w-5 h-5">
                        </svg-icon>
                     </div>
                     <h3 class="text-sm font-normal text-neutral-800">{{transaction.sub_title}}</h3>
                  </div>
                  <h3 *ngIf="transaction.discount_ammounts" class="text-base font-semibold text-rose-600">
                     -{{transaction.discount_ammounts | currency}}</h3>
                  <h3 class="text-base font-semibold text-neutral-800">{{transaction.ammounts | currency}}</h3>
               </div>
            </ng-container>
            <div class="flex justify-end items-center px-6 py-4">
               <h3></h3>
               <div class="flex flex-row items-center justify-between w-60">
                  <h3 class="text-lg font-bold text-neutral-800">Net Amount</h3>
                  <h3 class="text-lg font-bold text-neutral-800">{{transactionDetail.total | currency}}</h3>
               </div>
            </div>
         </div>
         <div class="card border border-neutral-100 w-full">
            <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
               <div class="flex flex-col gap-1">
                  <div class="flex flex-row items-center gap-2.5 relative">
                     <h3 class="text-base font-bold text-neutral-800">
                        Share Detail</h3>
                     <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Share Detail"
                        class="w-5 h-5">
                     </svg-icon>
                  </div>
                  <h3 class="text-sm font-normal text-neutral-800">Instructor Group Shares</h3>
               </div>
               <h3 class="text-base font-bold text-neutral-800">Share</h3>
            </div>
            <ng-container *ngFor="let transaction of shareDetail">
               <div class="flex justify-between items-center px-6 py-4  border-b border-b-neutral-100">
                  <div class="flex flex-col gap-1">
                     <div class="flex flex-row items-center gap-2.5 relative">
                        <h3 class="text-base font-bold text-neutral-800">
                           {{transaction.title}}
                           <ng-container *ngIf="transaction?.code">(<span class="text-blue-600 font-normal">
                                 #{{transaction.code}} </span> )</ng-container>
                        </h3>
                        <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="{{transaction.title}}"
                           class="w-5 h-5">
                        </svg-icon>
                     </div>
                     <h3 class="text-sm font-normal text-neutral-800">{{transaction.sub_title}}</h3>
                  </div>
                  <h3 class="text-base font-semibold text-neutral-800">{{transaction.ammounts | currency}}</h3>
               </div>
            </ng-container>
         </div>
      </div>
   </div>

</div>