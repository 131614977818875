<ng-container *ngIf="{
    pricingCurrencyList: (pricingCurrencyList$ | async) ?? []
  } as vm">
  <div class="price-currencies p-4">
    <div class="mb-4 flex justify-between gap-4">
      <div>
        <h3>{{ title }}</h3>
      </div>
      <div>
        <button type="button" class="btn-blue" (click)="liveRates.open()" i18n>
          <svg-icon class="btn-icon" src="@assets/images/icons/arrow-trending-up.svg"></svg-icon> Get Lives Rates
        </button>
        <common-modal #liveRates title="Live Rates" customClass="sm:w-full sm:max-w-3xl">
          <ng-container content *ngIf="liveRates.isOpened">
            <div class="p-4">
              <app-live-rates-table (applyEvent)="handleApply($event)"></app-live-rates-table>
            </div>
          </ng-container>
        </common-modal>
      </div>
    </div>
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <a routerLink="./new">
          <button class="btn-blue">
            <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
            <span i18n>Add Currency</span>
          </button>
        </a>
      </div>
      <div class="flex gap-4">
        <div (ClickOutside)="handleClickOutside(1)" class="actions relative">
          <button (click)="dropdown(1)" class="btn-neutral">
            <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
            <span i18n>Filter</span>
          </button>
          <div [ngClass]="isDropdown[1] ? '':'hidden'"
              class="bg-white shadow-lg rounded px-2.5 py-5 w-[270px] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
            <div class="form">
              <form [formGroup]="currenciesFilterForm">
                <formly-form [form]="currenciesFilterForm" [fields]="currenciesFilterFormFields" [model]="currenciesFilterFormModel">
                </formly-form>
              </form>
            </div>
            <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
              <button
                 (click)="clearFilter()"
                 type="button" class="btn btn-outline-dark border-neutral-600">
                 <span i18n>Reset</span>
              </button>
              <button
                 (click)="submitFilter()"
                 class="btn btn-dark bg-neutral-600 text-white">
                 <span i18n>Filter</span>
              </button>
           </div>
          </div>
        </div>
        <div class="input-combine flex">
          <div class="input-group">
            <div class="group !rounded-l-none">
              <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
              <input (keyup.enter)="search($event)" placeholder="Enter your full name" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tier-list">
      <app-data-table title="Currencies" badge="{{ vm.pricingCurrencyList.length }} Currencies">
        <ng-container table>
          <table class="w-full text-left">
            <thead class="heading">
              <tr>
                <th scope="col">
                  <div class="col-name w-20">ID</div>
                </th>
                <th scope="col" class="w-40">
                  <div class="col-name">Currency Name</div>
                </th>
                <th scope="col" class="">
                  <div class="sortable">
                    <div class="col-name">Curency Code</div>
                  </div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Rate</div>
                </th>
                <th scope="col" class="w-32">
                  <div class="col-name">Primary Exchange Rate Currency</div>
                </th>
                <th scope="col" class="w-32">
                  <div class="col-name">Primary Store Currency</div>
                </th>
                <!-- <th scope="col" class="">
                <div class="col-name">Sort Number</div>
              </th> -->
                <th scope="col" class="">
                  <div class="col-name">Pricing Tier Status</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Option</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="" class="text-left" *ngFor="let currency of vm.pricingCurrencyList">
                <td class="">
                  <span class="id">#{{ currency.id }}</span>
                </td>
                <td>
                  <div class="flex max-w-[170px] items-center">
                    <a routerLink="./{{ currency.id }}" class="name text-blue-600 underline">{{ currency.name }}</a>
                  </div>
                </td>
                <td>
                  <span class="code">{{ currency.currency_code }}</span>
                </td>
                <td>
                  <span class="rate">{{ currency.rate }}</span>
                </td>
                <td>
                  <!-- <div class="rate-currency">
                    <label class="relative inline-flex cursor-pointer items-center">
                      <input
                        type="checkbox"
                        value="1"
                        class="peer sr-only"
                        (change)="
                          onToggle($event, currency.id, currency.currency_code, 'is_primary_exchange_rate_currency')
                        "
                        [attr.checked]="currency.is_primary_exchange_rate_currency ? '' : null"
                      />
                      <div
                        class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
                      ></div>
                      <span class="ms-3 text-sm">
                        {{ currency.is_primary_exchange_rate_currency ? 'Active' : 'Disabled' }}</span
                      >
                    </label>
                  </div> -->

                  <div class="rate-currency">
                    <label class="relative inline-flex cursor-not-allowed items-center">
                      <input type="checkbox" value="1" class="peer sr-only"
                        [checked]="primary.is_primary_exchange_rate_currency === currency.id"
                        (change)="onChangePrimary($event, 'is_primary_exchange_rate_currency', currency.id)"
                        [disabled]="true" />
                      <div [class.cursor-not-allowed]="primary.is_primary_exchange_rate_currency === currency.id"
                        class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800">
                      </div>
                      <span class="ms-3 text-sm">
                        {{ primary.is_primary_exchange_rate_currency === currency.id ? 'Active' : 'Inactive' }}
                      </span>
                    </label>
                  </div>
                </td>
                <td>
                  <!-- <div class="store-currency">
                    <label class="relative inline-flex cursor-pointer items-center">
                      <input
                        type="checkbox"
                        value="1"
                        class="peer sr-only"
                        (change)="onToggle($event, currency.id, currency.currency_code, 'is_primary_store_currency')"
                        [attr.checked]="currency.is_primary_store_currency ? '' : null"
                      />
                      <div
                        class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
                      ></div>
                      <span class="ms-3 text-sm">
                        {{ currency.is_primary_store_currency ? 'Active' : 'Disabled' }}</span
                      >
                    </label>
                  </div> -->
                  <div class="rate-currency">
                    <label class="relative inline-flex cursor-not-allowed items-center">
                      <input type="checkbox" value="1" class="peer sr-only"
                        [checked]="primary.is_primary_store_currency === currency.id"
                        (change)="onChangePrimary($event, 'is_primary_store_currency', currency.id)"
                        [disabled]="true" />
                      <div [class.cursor-not-allowed]="primary.is_primary_store_currency === currency.id"
                        class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800">
                      </div>
                      <span class="ms-3 text-sm">
                        {{ primary.is_primary_store_currency === currency.id ? 'Active' : 'Inactive' }}
                      </span>
                    </label>
                  </div>
                </td>
                <!-- <td><span class="sort">1</span></td> -->
                <td>
                  <div *ngIf="currency.pricing_tier_status" class="tag-green tag-sm">Active</div>
                  <div *ngIf="!currency.pricing_tier_status" class="tag-red tag-sm">Disabled</div>
                </td>
                <td>
                  <div class="flex justify-center gap-2">
                    <a routerLink="./{{ currency.id }}/edit"><button class="btn-amber-soft btn-sm px-2">
                        <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
                      </button>
                    </a>
                    <button class="btn-outline-red btn-sm px-2" (click)="deleteConfirmation(currency)">
                      <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon h-4 w-4"></svg-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </app-data-table>
    </div>
  </div>
</ng-container>