<ng-container *ngIf="isDefaultView">
   <app-course-header [courseState]="courseState" (clickEvent)="actionEvent($event)"></app-course-header>
</ng-container>
<div class="course-intended p-4">
   <app-course-heading></app-course-heading>
   <ng-container *ngIf="{formTierFields: formTierFields$ | async, courseId: courseId$ | async} as vm">
      <div class="card my-4 block rounded-[10px] border border-neutral-100 px-6 py-9">
         <div class="flex justify-between">
            <div class="flex flex-col">
               <h3 class="text-lg text-black">Price</h3>
               <p class="text-base text-neutral-400">Selected Price Tiers to enroll this course</p>
            </div>
            <div class="form flex items-center gap-3">
               <ng-container *ngIf="isLoadingPrice">
                  <ng-container *ngTemplateOutlet="loader"></ng-container>
               </ng-container>
               <form [formGroup]="formTier">
                  <formly-form [form]="formTier" [fields]="vm.formTierFields ?? []" [model]="formTierModel">
                  </formly-form>
               </form>
               <!-- <button class="btn border-0 bg-blue-100 text-blue-500 mt-2.5">Give a Reason</button> -->
            </div>
         </div>
      </div>
   </ng-container>
</div>

<!-- Price loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>