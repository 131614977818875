import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  PricingCountryActions,
  PricingCountryPayload,
  PricingCountryService,
  PricingCurrencyActions,
  PricingCurrencyPayload,
  selectPricingCountryList,
  selectPricingCurrencyList,
} from '@shared/store';
import * as _ from 'lodash';
import { Observable, combineLatest, filter } from 'rxjs';
import { PricingCountryData, PricingCurrencyData } from 'thkee-common';

@Component({
  selector: 'app-form-price-geolocation',
  templateUrl: './price-geo.component.html',
  styleUrls: ['./price-geo.component.scss'],
})
export class FormPriceGeoComponent implements OnInit {
  @Input() model: any = {}; //TODO: Update model here
  @Input() submitLabel: string = 'Submit';
  @Input() countries: PricingCountryData[] = [];
  @Input() currencies: PricingCurrencyData[] = [];
  @Output() init: EventEmitter<FormlyFieldConfig[]> = new EventEmitter<FormlyFieldConfig[]>();
  @Output() submitEvent: EventEmitter<Partial<any>> = new EventEmitter<Partial<any>>(); //TODO: Update model here

  formGeo = new FormGroup({});
  formGeoModel: any = {}; //TODO: Update model here
  formGeoFields: FormlyFieldConfig[] = [];
  showSubmit: boolean = true;
  // countries: ConstantCountryOption[] = [];

  // Fetch recorded countries
  pricingCountryList$: Observable<PricingCountryData[]> = this.store.select(selectPricingCountryList);
  pricingCountryList: any = [];
  pricingCountryPayload: PricingCountryPayload = { page: 1, page_size: 10 };

  // Fetch recorded currencies
  pricingCurrencyList$: Observable<PricingCurrencyData[]> = this.store.select(selectPricingCurrencyList);
  pricingCurrencyList: any = [];
  pricingCurrencyPayload: PricingCurrencyPayload = { detailed: false };

  constructor(private pricingCountryService: PricingCountryService, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(PricingCountryActions.loadPricingCountryList(this.pricingCountryPayload));
    this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList(this.pricingCurrencyPayload));
    combineLatest([
      this.pricingCountryList$.pipe(filter((pricingCountryList) => !!pricingCountryList)),
      this.pricingCurrencyList$.pipe(filter((pricingCurrencyList) => !!pricingCurrencyList)),
    ]).subscribe(([pricingCountryList, pricingCurrencyList]) => {
      this.countries = pricingCountryList;
      this.currencies = pricingCurrencyList;
      if (this.countries.length > 0 && this.currencies.length > 0) {
        if (this.model) {
          this.formGeoModel = this.model;
          console.log('this.formGeoModel--', this.formGeoModel);
        }
        this.initFields();
      }
    });
  }

  initFields() {
    this.formGeoFields = [
      {
        fieldGroupClassName: 'grid grid-cols-12 gap-6',
        fieldGroup: [
          {
            className: 'col-span-6',
            key: 'country',
            type: 'select',
            props: {
              label: 'Country',
              tips: 'This is a tip message for course title',
              placeholder: 'Select country',
              required: true,
              options: this.countries.map((item) => ({
                label: item.name,
                value: item.id,
              })),
              change: (field, $event) => {
                const cField = _.find(field.parent?.fieldGroup, { key: 'currency' });
                const currencyField = this.formGeo.get('currency') as FormControl<any> | null;
                const selectedC = _.find(this.countries, { id: field.formControl?.value });
                const selectedCurrency = _.find(this.currencies, { currency_code: selectedC?.currency_code });

                // const supportCurrency = this.currencies.filter((item) => //This filter by selected support currency
                //   item.country.includes(Number(field.formControl?.value))
                // );
                const supportCurrency = this.currencies;
                if (!_.isEmpty(supportCurrency)) {
                  // If supportCurrency have available or not yet exist
                  if (cField && cField.props) {
                    cField.props.disabled = true;
                    cField.props.options = supportCurrency.map((item) => ({
                      label: `${item.name} ~ ${item.currency_code}(${item.symbol})`,
                      value: item.id,
                    }));
                    cField.props.disabled = false;
                  }
                  if (selectedC) {
                    currencyField?.setValue(selectedCurrency?.id);
                  } else {
                    currencyField?.setValue('');
                  }
                } else {
                  // This will disable the currency cuz not available on selected country
                  if (cField && cField.props) {
                    const defaultCurreny = _.find(this.currencies, { is_primary_store_currency: true });
                    if (defaultCurreny) {
                      cField.props.disabled = false;
                      cField.props.options = [{ value: defaultCurreny.id, label: defaultCurreny.name }];
                      currencyField?.setValue(defaultCurreny?.id);
                    } else {
                      cField.props.disabled = true;
                      cField.props.options = [{ value: '', label: 'No Support/Default Currency Available' }];
                      currencyField?.setValue('');
                    }
                  }
                }
              },
            },
          },
          {
            className: 'col-span-6',
            key: 'currency',
            type: 'select',
            props: {
              label: 'Supported Currency',
              tips: 'This is a tip message for course title',
              required: true,
              disabled: false,
              options: this.currencies.map((item) => ({
                label: `${item.name} ~ ${item.currency_code}(${item.symbol})`,
                value: item.id,
              })),
            },
            expressions: {
              'props.disabled': () => {
                return this.formGeoModel.country ? false : true;
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'grid grid-cols-1',
        fieldGroup: [
          {
            template: '<label class="block mb-2 font-bold">Status</label>',
          },
          {
            key: 'status',
            type: 'toggle',
            defaultValue: false,
            props: {
              label: 'Active',
              tips: 'This is a tip message for course title',
            },
          },
        ],
      },
    ];
    this.init.emit(this.formGeoFields); // Emitter to overwhite field
  }

  submit() {
    this.submitEvent.emit({
      valid: this.formGeo.valid,
      values: this.formGeoModel,
    });
  }
}
