<ng-container *ngIf="{ assignmentId: (assignmentId$ | async) } as vm">
  <ng-container header>
    <div class="modal-header flex items-center border-b border-neutral-50 bg-neutral-50 px-6 py-4">
      <div class="text-lg font-bold">{{ vm.assignmentId ? 'Edit' : 'Add' }} Assignment</div>
    </div>
  </ng-container>
  <div class="p-6">
    <form [formGroup]="form" #formAssignment="ngForm">
      <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
    </form>
  </div>
  <div class="flex justify-end gap-2 border-t border-neutral-50 px-6 py-4">
    <button class="btn btn-neutral" (click)="cancel()">
      <span>Cancel</span>
    </button>
    <button *ngIf="!vm.assignmentId" class="btn btn-teal" (click)="submitAssignment('new')">
      <span>Add Assignment</span>
    </button>
    <button *ngIf="vm.assignmentId" class="btn btn-teal" (click)="submitAssignment('save')">
      <span>Save</span>
    </button>
  </div>
</ng-container>
