import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PriceCountryEditComponent } from './edit/edit.component';
import { PriceCountriesNewComponent } from './new/new.component';
import { PriceCountriesComponent } from './price-countries.component';
import { PriceCountryViewComponent } from './view/view.component';

export * from './edit/edit.component';
export * from './new/new.component';
export * from './view/view.component';

export const Components = [
  PriceCountriesComponent,
  PriceCountriesNewComponent,
  PriceCountryViewComponent,
  PriceCountryEditComponent,
];

export const Routing = [
  {
    path: 'countries',
    component: PriceCountriesComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Countries' },
  },
  {
    path: 'countries/new',
    component: PriceCountriesNewComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Add Pricing Country' },
  },
  {
    path: 'countries/:id',
    component: PriceCountryViewComponent,
    data: { title: marker('Price Management'), breadcrumb: 'View Pricing Tiers' },
  },
  {
    path: 'countries/:id/edit',
    component: PriceCountryEditComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Edit Pricing Tiers' },
  },
];
