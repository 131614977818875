<ng-container *ngIf="isDefaultView">
  <app-course-header [courseState]="courseState" (clickEvent)="actionEvent($event)"></app-course-header>
</ng-container>
<div class="course-details p-4">
  <app-course-heading>
    <ng-container actions>
      <ng-container *ngIf="isDefaultView">
        <button class="btn-teal-soft btn-lg" (click)="changeHistoryDrawer.open()">
          <svg-icon src="@assets/images/icons/history.svg" class="btn-icon"></svg-icon>
          <span>Changes History</span>
        </button>
        <button *ngIf="courseState === '0'" class="btn-amber-soft btn-lg"
          (click)="readOnly = !readOnly; initFields(readOnly)">
          <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
          <span>Edit</span>
        </button>
      </ng-container>
    </ng-container>
  </app-course-heading>
  <div>
    <form [formGroup]="formDetails">
      <formly-form [form]="formDetails" [fields]="fieldsDetails" [model]="modelDetails" [options]="options">
      </formly-form>
    </form>
    <common-modal #feedbackModal title="Feedback for Instructor" customClass="sm:w-full sm:max-w-3xl"
      (closeEvent)="onFeedbackCancel()">
      <ng-container content *ngIf="feedbackModal.isOpened">
        <app-common-feedback (submitEvent)="onFeedbackSubmit($event)" (cancel)="onFeedbackCancel()">
        </app-common-feedback>
      </ng-container>
    </common-modal>
  </div>
</div>

<!-- Buyer drawer -->
<app-drawer #changeHistoryDrawer customClass="max-w-[600px] !sm:w-full">
  <ng-container header>
    <div class="px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
      <h3 class="text-xl text-black font-semibold">All Changes History</h3>
      <div class="flex justify-between items-center gap-3">
        <button (click)="changeHistoryDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
          <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
          </svg-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container content>
    <ng-container content *ngIf="changeHistoryDrawer.isOpened">
      <app-common-history-course maxHeight="90vh"></app-common-history-course>
    </ng-container>
  </ng-container>
</app-drawer>