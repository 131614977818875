import { Component, OnInit } from '@angular/core';
import { BarChartData, COURSE_TAB, NavItem } from '@shared';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class CourseStatisticsComponent implements OnInit {
  tabItems: NavItem[] = COURSE_TAB;
  select: any = {
    lineChart: false,
  };

  constructor() {}

  ngOnInit(): void {}

  chartFilter(event: string) {}
  lineCharttickYValues: string[] = ['$0.00', '$250.00', '$500.00', '$750.00'];
  lineChartLabels: string[] = ['22/8', '23/8', '24/8', '25/8', '26/8'];
  lineChartData = [
    {
      data: [0, 450, 276, 520, 560, 590, 620, 600, 650, 700, 720, 700],
      label: 'Revenue',
      borderColor: '#000000',
      borderWidth: 2,
      pointStyle: 'circle',
      pointRadius: 4,
      pointHoverRadius: 5,
      pointBorderWidth: 2,
      pointBackgroundColor: '#000000',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#000000',
      pointHoverBorderColor: '#FFFFFF',
      fill: false,
      // tension: 0.4,
      lineJoin: 'round',
    },
    {
      data: [0, 350, 176, 420, 460, 490, 520, 500, 550, 600, 620, 650],
      label: 'Product Sales',
      borderColor: '#E5E5E5',
      borderWidth: 2,
      pointStyle: 'circle',
      pointRadius: 4,
      pointHoverRadius: 5,
      pointBorderWidth: 2,
      pointBackgroundColor: '#E5E5E5',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#E5E5E5',
      pointHoverBorderColor: '#FFFFFF',
      fill: false,
      // tension: 0.1,
      lineJoin: 'round',
    },
  ];

  // Bar chart
  courseDoneTickYValues: string[] = ['0', '1', '2', '3', '4', '5'];
  courseDoneLabels: string[] = ['Course 1', 'Course 2', 'Course 3', 'Course 4', 'Course 5'];
  courseDoneData: BarChartData[] = [
    {
      data: [100, 450, 476, 520, 560],
      backgroundColor: 'rgba(0,0,0,1)',
      fill: 'origin',
      barPercentage: 0.5,
      height: 300,
      barThickness: 10,
      maxBarThickness: 50,
      minBarLength: 2,
      borderRadius: 0,
    },
  ];

  activeUserTickYValues: string[] = ['0', '1', '2', '3', '4', '5'];
  activeUserLabels: string[] = ['Course 1', 'Course 2', 'Course 3', 'Course 4', 'Course 5'];
  activeUserData: BarChartData[] = [
    {
      data: [100, 450, 476, 520, 560],
      backgroundColor: 'rgba(0,0,0,1)',
      fill: 'origin',
      barPercentage: 0.5,
      height: 300,
      barThickness: 10,
      maxBarThickness: 50,
      minBarLength: 2,
      borderRadius: 0,
    },
  ];

  inactiveUserTickYValues: string[] = ['0', '1', '2', '3', '4', '5'];
  inactiveUserLabels: string[] = ['Course 1', 'Course 2', 'Course 3', 'Course 4', 'Course 5'];
  inactiveUserData: BarChartData[] = [
    {
      data: [100, 450, 476, 520, 560],
      backgroundColor: 'rgba(0,0,0,1)',
      fill: 'origin',
      barPercentage: 0.5,
      height: 300,
      barThickness: 10,
      maxBarThickness: 50,
      minBarLength: 2,
      borderRadius: 0,
    },
  ];
}
