<ng-container *ngIf="{
    countriesOptions: (countriesOptions$ | async) ?? [],
    priceCountryData: (priceCountry$ | async) ?? priceCountryDataDefault
  } as vm">
  <div class="price-tiers-new p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>Add Countries</h3>
      </div>
    </div>

    <div class="tier-form">
      <div class="card rounded-lg border border-neutral-100 px-6 py-9">
        <!-- {{ vm.priceCountryData | json }} -->
        <!-- <app-form-price-tier (onSubmit)="onSubmit($event)"></app-form-price-tier> -->
        <app-form-price-country *ngIf="vm.priceCountryData && vm.countriesOptions.length > 0"
          (submitEvent)="onSubmit($event)" [model]="formData" [countries]="vm.countriesOptions">
        </app-form-price-country>
      </div>
    </div>
  </div>
</ng-container>