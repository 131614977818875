<ng-container *ngIf="{
    countriesOptions: (countriesOptions$ | async) ?? [],
    priceCountryData: (priceCountry$ | async) ?? priceCountryDataDefault,
    hasData: hasData
  } as vm">
  <div class="price-tiers-new p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>View Country</h3>
      </div>

      <div>
        <a routerLink="./edit">
          <button class="btn-amber-soft">
            <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
            <span>Edit</span>
          </button>
        </a>
      </div>
    </div>
    <!-- {{ vm.hasData }}
    {{ vm.priceCountryData | json }} -->
    <div *ngIf="!vm.hasData" class="text-center">Loading...</div>
    <div class="tier-form" *ngIf="vm.hasData">
      <div class="card rounded-lg border border-neutral-100 px-6 py-9">
        <app-form-price-country *ngIf="vm.priceCountryData && vm.countriesOptions.length > 0" (init)="onInit($event)"
          [model]="vm.priceCountryData" [countries]="vm.countriesOptions"></app-form-price-country>
      </div>
    </div>
  </div>
</ng-container>