import { Component, Input, OnInit } from '@angular/core';
import { PayoutService } from '@shared/services/payout/payout.service';
import { AdminManyPayoutRequestSummary } from 'thkee-common';

@Component({
  selector: 'app-payouts-confirmation-information',
  templateUrl: './payouts-confirmation-information.component.html',
  styleUrls: ['./payouts-confirmation-information.component.scss'],
})
export class PayoutsConfirmationInformationComponent implements OnInit {
  @Input() ids?: number[];
  // tslint:disable-next-line:no-input-rename
  @Input('summary') inputSummary?: AdminManyPayoutRequestSummary;
  private _summary?: AdminManyPayoutRequestSummary;
  constructor(private payoutService: PayoutService) {}

  ngOnInit(): void {
    if (!this.inputSummary) {
      this.payoutService.getSelectedPayoutRequestsSummary(this.ids).subscribe((data) => (this._summary = data));
    }
  }

  get summary() {
    return this.inputSummary || this._summary;
  }
}
