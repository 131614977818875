import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { INSTRUCTOR_DRAWER_TAB, INSTRUCTOR_SETTINGS_DRAWER_TAB, PRODUCT_DRAWER_TAB, TabItem } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import * as _ from 'lodash';
import { FileProps, LANGUAGES, RouterStoreService } from 'thkee-common';

@Component({
  selector: 'app-failed-payout-details',
  templateUrl: './failed-payout-details.component.html',
  styleUrls: ['./failed-payout-details.component.scss'],
})
export class FailedPayoutDetailsComponent implements OnInit {
  params: any = {};
  //Product TAB
  productTabLists: TabItem[] = PRODUCT_DRAWER_TAB;

  //Student TAB
  instructorTabLists: TabItem[] = INSTRUCTOR_DRAWER_TAB;

  //Student Settings TAB
  instructorSettingsTabLists: TabItem[] = INSTRUCTOR_SETTINGS_DRAWER_TAB;

  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Method</div>`,
    },
    {
      key: 'paypal',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paypal (16)',
        required: true,
      },
    },
    {
      key: 'payoneer',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Payoneer (16)',
        required: true,
      },
    },
    {
      key: 'direct_bank',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Direct to Bank (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Sale Price</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Net Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Instructor Earning</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Thkee Earning</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
  ];
  resetFilter() {
    this.filterForm.reset();
  }
  // Create product form
  productForm = new FormGroup({});
  productFormModel: any = {};
  productFormFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'card block py-9 px-6 mb-4',
      fieldGroup: [
        {
          className: 'section-label block border-b-[2px] border-neutral-200 pb-3 mb-3',
          template: '<h5>General</h5>',
        },
        {
          key: 'title',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: '#SAP MM Material Management',
          props: {
            inputAppendClass: ['border-0', 'bg-transparent', 'bg-none'],
            label: 'Title',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Learn Blender in 60 minutes',
            disabled: true,
            tips: 'This is a tip message for course title',
          },
          validation: {
            messages: {
              minLength: "Title can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'subtitle',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: 'Introduction to SAP MM Material Management',
          props: {
            label: 'Subtitle',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Introduction to SAP MM Material Management!',
            disabled: true,
            tips: 'This is a tip message for course sub title',
          },
          validation: {
            messages: {
              minLength: "This field can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'topics',
          type: 'select',
          defaultValue: ['uiux', 'frontend'],
          wrappers: ['feedback-field'],
          props: {
            label: 'Topics',
            placeholder: 'Choose topic',
            multiple: true,
            stayPlaceholder: true,
            disabled: true,
            tips: 'This is a tip message for course title',
            stylish: true,
            options: [
              { label: 'UI/UX', value: 'uiux' },
              { label: 'Frontend', value: 'frontend' },
              { label: 'Backend', value: 'backend' },
            ],
          },
          expressions: {},
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
          fieldGroup: [
            {
              key: 'skill_level',
              type: 'select',
              defaultValue: 'Beginner',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Level',
                placeholder: 'Choose a level',
                stylish: true,
                options: [
                  { label: 'All', value: 'All' },
                  { label: 'Beginner', value: 'Beginner' },
                  { label: 'Intermediate', value: 'Intermediate' },
                  { label: 'Expert', value: 'Expert' },
                ],
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
            {
              key: 'language',
              type: 'select',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Language',
                placeholder: 'Select language',
                stylish: true,
                options: _.sortBy(
                  _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                  (lang) => lang.label
                ),
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6',
          fieldGroup: [
            {
              key: 'category',
              type: 'select',
              defaultValue: 'business',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Category',
                placeholder: 'Choose a category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Business', value: 'business' },
                  { label: 'Design', value: 'design' },
                  { label: 'Language', value: 'language' },
                ],
              },
              expressions: {},
            },
            {
              key: 'subcategory',
              type: 'select',
              defaultValue: 'graphic-design',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Sub category',
                placeholder: 'Choose sub-category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Graphic Design', value: 'graphic-design' },
                  { label: 'Web Design', value: 'web-design' },
                  { label: 'UI/UX Design', value: 'uiux-design' },
                ],
              },
              expressions: {},
            },
          ],
        },
      ],
    },

    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'image',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Image Cover',
        placeholder: 'Upload your course image here. Supported files are .jpg, .jpeg, or .png.',
        preview: true,
        previewType: 'image',
        allowedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
      } as FileProps,
      expressions: {},
    },
    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'promo_video',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Promotional Video',
        placeholder: "Upload your course's promotional video here. Maximum of 200MB.",
        preview: true,
        previewType: 'video',
        hideLabel: false,
        allowedTypes: ['video/mp4', 'video/mkv', 'video/mov'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
        onUpload: (upload, field) => {
          // log.debug('upload: ', upload);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadStart({
          //       referenceId: `${courseId}-${field.key}`,
          //       fileName: upload.name,
          //       fileType: upload.type,
          //     })
          //   );
          // });
        },
        onProgress: (progress, field) => {
          // log.debug('progress: ', progress);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadProgress({
          //       referenceId: `${courseId}-${field.key}`,
          //       bytesTotal: progress.bytesTotal,
          //       bytesUploaded: progress.bytesUploaded,
          //       progressPercent: progress.progressPercent,
          //     })
          //   );
          // });
        },
        onComplete: (field) => {
          // log.debug('complete');
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadComplete({
          //       referenceId: `${courseId}-${field.key}`,
          //     })
          //   );
          // });
        },
      } as FileProps,
      expressions: {
        // 'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
        //   map(([course, userId]) => ({
        //     user: userId,
        //     course: course.id,
        //   })),
        //   distinctUntilChanged()
        // ),
        // 'props.previewUrl': this.course$.pipe(
        //   map((course) => {
        //     return course.promo_video_url;
        //   }),
        //   distinctUntilChanged()
        // ),
        // 'props.feedback': this.getFeedbackCount('promo_video'),
      },
    },
  ];

  constructor(private routerStore: RouterStoreService, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Failed Payout',
          url: '/ecommerce/payout/failed-payouts',
        },
        {
          label: 'Payout #' + params['payoutId'],
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Transaction details drawer action
  @ViewChild('transactionDrawer') transactionDrawer: any;
  transactionData: any;
  openTransactionDrawer(data: any) {
    this.transactionData = data;
    this.transactionDrawer.open();
  }

  // Order details drawer action
  @ViewChild('orderDrawer') orderDrawer: any;
  orderData: any;
  openOrderDrawer(data: any) {
    this.orderData = data;
    this.orderDrawer.open();
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 2,
      title: 'Total Earnings',
      value: 2191,
      type: 'currency',
      tooltip: 'Total earnings',
    },
    {
      id: 1,
      title: 'Failed Payouts',
      value: 8,
      type: 'number',
      tooltip: 'Failed Payouts',
    },
    {
      id: 3,
      title: 'Total Transactions',
      value: 28,
      type: 'number',
      tooltip: 'Total Transactions',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      order_id: '3341',
      date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      thkee_earning: 344,
      payment_method: 'Paypal',
      payout_status: 'Not Refunded',
    },
    {
      id: '124',
      order_id: '3342',
      date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      thkee_earning: 344,
      payment_method: 'Paypal',
      payout_status: 'Not Refunded',
    },
    {
      id: '125',
      order_id: '3343',
      date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      thkee_earning: 344,
      payment_method: 'Paypal',
      payout_status: 'Not Refunded',
    },
  ];
}
