<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4 flex items-center justify-between">
      <h3 class="text-2xl text-neutral-800 font-semibold">Transaction #{{transaction_id}}</h3>
      <ng-container *ngIf="show_refund_button">
         <button (click)="refundConfirmed.open()" class="btn btn-outline-red">
            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="btn-icon">
               <path d="M10.3333 3.50016V2.16683C10.3333 1.43045 9.73638 0.833496 9 0.833496H2.33333C1.59695 0.833496 1 1.43045 1 2.16683V6.16683C1 6.90321 1.59695 7.50016 2.33333 7.50016H3.66667M5 10.1668H11.6667C12.403 10.1668 13 9.56988 13 8.8335V4.8335C13 4.09712 12.403 3.50016 11.6667 3.50016H5C4.26362 3.50016 3.66667 4.09712 3.66667 4.8335V8.8335C3.66667 9.56988 4.26362 10.1668 5 10.1668ZM9.66667 6.8335C9.66667 7.56988 9.06971 8.16683 8.33333 8.16683C7.59695 8.16683 7 7.56988 7 6.8335C7 6.09712 7.59695 5.50016 8.33333 5.50016C9.06971 5.50016 9.66667 6.09712 9.66667 6.8335Z"
               stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Refund</span>
         </button>
      </ng-container>
      <ng-container *ngIf="show_refunded_mark && transactionDetails.transaction_type === 'sale'">
         <svg-icon src="@assets/images/icons/stamp.svg" class="w-64"></svg-icon>
      </ng-container>
      <ng-container *ngIf="show_refund_pending_mark">
         <span class="px-3 py-2 bg-amber-100 text-amber-500 rounded-md">Pending</span>
      </ng-container>
   </div>
   <ng-container *ngIf="isLoading; else loaded">
      <ng-container *ngTemplateOutlet="loader"></ng-container>
   </ng-container>
   <ng-template #loaded>
      <app-transaction-details-overview [data]="transactionDetails" [id]="params['transactionId']">
      </app-transaction-details-overview>
   </ng-template>
</div>

<!-- Refund confirm modal -->
<common-modal #refundConfirmed customClass="sm:w-full sm:max-w-[935px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl" i18n>Refund</h1>
         </div>
         <button (click)="refundConfirmed.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div *ngIf="transactionDetails"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black" i18n>Transaction ID</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">#{{transactionDetails.id}}</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black" i18n>Product</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Product" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  {{transactionDetails.product.title}} ( <span
                     class="text-blue-600">#{{transactionDetails.product.id.substring(0, 8)}}</span> )
               </h6>
            </div>
         </div>
      </div>
      <div *ngIf="transactionDetails"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14 w-full">
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600" i18n>Instructor</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold !text-red-600">
                  Omar Sherif ( <span class="text-blue-600">#12568</span> )
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600" i18n>Buyer</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Buyer" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  {{transactionDetails.buyer.fullname}} ( <span
                     class="text-blue-600">#{{transactionDetails.buyer.id}}</span> )
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600" i18n>Date</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Buyer" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  {{transactionDetails.date_created | date}}
               </h6>
            </div>
         </div>
      </div>
      <div *ngIf="transactionDetails"
         class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600" i18n>Payment Method</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Method" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">{{transactionDetails.payment_gateway}}</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600" i18n>Amount</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Amount" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  {{transactionDetails.sale_price}}
               </h6>
            </div>
         </div>
      </div>
      <div class="px-6 py-5 border-b border-b-neutral-100">
         <div class="form">
            <form [formGroup]="refundReasonForm">
               <formly-form [form]="refundReasonForm" [fields]="refundReasonFormFields" [model]="refundReasonFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="refundConfirmed.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold" i18n>Cancel</span>
            </button>
            <button (click)="refundConfirmedSubmit()" [disabled]="refundReasonForm.invalid"
               class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold" i18n>Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>
