import { FilterOptionsValues } from './constants';

interface User {
  id: string;
  fullname: string;
  photo?: string | null;
  language?: string;
  bio?: string;
  is_instructor?: boolean;
}

export interface RefundItems {
  id: string;
  user: User;
  gross_amount: string;
  date_created: string;
  status: string;
  currency_code: string;
  order_id: string;
  currency_symbol: string;
  products: number;
  refund_requests: number;
  refund_type: string;
  transaction_id: number;
  transactions: number;
}

export interface RefundResponse {
  count: number;
  next?: string;
  previous?: string;
  results: RefundItems[];
}

// Filter
export interface FilterOptionsRefund {
  status: FilterOptionsValues[];
  refund_type: FilterOptionsValues[];
}

export interface FilterEventRefund {
  action: string;
  data: object;
}

// Params
export interface RefundQueryParams {
  page?: number;
  page_size?: number;
  search?: string;
}

// Table column
export const REFUND_TABLE_COLUMN = [
  { name: 'Refund ID', visible: true },
  { name: 'Transaction ID', visible: true },
  { name: 'Order ID', visible: true },
  { name: 'Buyer', visible: true },
  { name: 'Date', visible: true },
  { name: 'Transactions', visible: true },
  { name: 'Refund Request', visible: true },
  { name: 'Refund Type', visible: true },
  { name: 'Status', visible: true },
];

// Table seach
export const REFUND_TABLE_SEARCH = [
  {
    id: 0,
    title: 'ID',
    value: 'id',
  },
  {
    id: 0,
    title: 'Transaction',
    value: 'transaction',
  },
  {
    id: 1,
    title: 'Date',
    value: 'date',
  },
  {
    id: 2,
    title: 'Buyer',
    value: 'buyer',
  },
  {
    id: 3,
    title: 'Order',
    value: 'order',
  },
];

// Order details
interface PaymentMode {
  mode: string;
}
interface Product {
  id: string;
  title: string;
}
export interface RefundDetails {
  id: number;
  refund_amount: number;
  reason: string;
  payment_mode: PaymentMode;
  currency_code: string;
  currency_symbol: string;
  order_id: number;
  transaction_id: number;
  product: Product;
  user: User;
  instructor: User; // Assuming instructor has the same structure as user
  date: string;
  course_progress: number;
  remaining_days: number;
  eligiblity: boolean;
  status: string;
}

export interface RefundRequestStatus {
  status: string;
}
export interface RejectionPayload {
  reason: string;
  description: string;
}

export interface RefundRequestResponse {
  reason: string;
  id: number;
  description: string;
  order_line_item: number;
  payment_mode: {
    mode: string;
  };
  user: number;
  currency_code: string;
  currency_symbol: string;
}
