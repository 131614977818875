import { createAction, props } from '@ngrx/store';
import { PricingGeoCreateUpdatePayload, PricingGeoLoadRemovePayload, PricingGeoPayload } from './geo-state.model';

export const PricingGeoActions = {
  loadGeoLocation: createAction('[GeoLocation] Get Geo Location list', props<PricingGeoPayload>()),
  loadGeoLocationSuccess: createAction('[GeoLocation] Geo Location list success', props<any>()),
  loadGeoLocationFail: createAction('[GeoLocation] Geo Location list fail', props<{ error: unknown }>()),

  addGeoLoc: createAction('[GeoLocation] Add Geo Location', props<PricingGeoCreateUpdatePayload>()),
  addGeoLocSuccess: createAction('[GeoLocation] Add Geo Location Success', props<any>()),
  addGeoLocFail: createAction('[GeoLocation] Add Geo Location fail', props<any>()),

  getGeoLoc: createAction('[GeoLocation] Get Geo Location data', props<PricingGeoLoadRemovePayload>()),
  getGeoLocSuccess: createAction('[GeoLocation] Load Geo Location success', props<any>()),
  getGeoLocFail: createAction('[GeoLocation] Load Geo Location fail', props<{ error: unknown }>()),

  loadGeoPricingTiers: createAction('[GeoPricingTier] Get all geo pricing tiers', props<any>()),
  loadGeoPricingTiersSuccess: createAction('[GeoPricingTier] Get all geo pricing tiers success', props<any>()),
  loadGeoPricingTiersFail: createAction('[GeoPricingTier] Get all geo pricing tiers fail', props<{ error: unknown }>()),
};
