import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit {
  @Input() closable = false;

  constructor() { }

  ngOnInit(): void { }

  // Column action
  isShowDrawer: boolean[] = [];
  colAction(type: string) {
    this.isShowDrawer[type] = !this.isShowDrawer[type];
  }

  @Input() isOpened: boolean = false;
  @Output() closeDrawer = new EventEmitter();
  @Input() customClass: string = 'md:max-w-[1044px] sm:w-full sm:max-w-lg';
  @Input() alignment: 'left' | 'right' = 'right';

  open() {
    this.isOpened = true;
  }

  close() {
    if (this.isOpened) {
      this.isOpened = false;
      this.closeDrawer.emit();
    }
  }
}
