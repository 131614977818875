import { Injectable } from '@angular/core';
import { Category, SubCategory, TopicsLists, TopicsResponse } from '@shared';
import { Observable } from 'rxjs';
import {
  API_GET_POST_ADMIN_CATEGORY,
  API_GET_POST_ADMIN_SUBCATEGORY,
  API_GET_POST_ADMIN_TOPICS,
  API_PUT_PATCH_DELETE_ADMIN_TOPICS,
  API_UPDATE_ADMIN_CATEGORY,
  API_UPDATE_DELETE_ADMIN_SUBCATEGORY,
  encodeURL,
  HttpService,
  UtilsService,
} from 'thkee-common';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly http: HttpService, private utilsService: UtilsService) {}

  getCategoryLists(): Observable<Category[]> {
    return this.http.get<Category[]>(API_GET_POST_ADMIN_CATEGORY);
  }

  createCategory(payload: Category): Observable<Category> {
    return this.http.post<Category>(API_GET_POST_ADMIN_CATEGORY, payload);
  }

  updateCategory(payload: Category, categoryId: string): Observable<Category> {
    return this.http.put<Category>(API_UPDATE_ADMIN_CATEGORY.replace('<CATEGORY_ID>', categoryId), payload);
  }

  deleteCategory(categoryId: string): Observable<Category> {
    return this.http.delete<Category>(API_UPDATE_ADMIN_CATEGORY.replace('<CATEGORY_ID>', categoryId));
  }

  createSubCategory(payload: SubCategory): Observable<SubCategory> {
    return this.http.post<SubCategory>(API_GET_POST_ADMIN_SUBCATEGORY, payload);
  }

  getAllSubCategory(): Observable<SubCategory[]> {
    return this.http.get<SubCategory[]>(API_GET_POST_ADMIN_SUBCATEGORY);
  }

  updateSubCategory(payload: Category, subCategoryId: string): Observable<Category> {
    return this.http.put<Category>(
      API_UPDATE_DELETE_ADMIN_SUBCATEGORY.replace('<SUBCATEGORY_ID>', subCategoryId),
      payload
    );
  }

  deleteSUbCategory(subCategoryId: string): Observable<Category> {
    return this.http.delete<Category>(API_UPDATE_DELETE_ADMIN_SUBCATEGORY.replace('<SUBCATEGORY_ID>', subCategoryId));
  }

  // Course topics
  getTopicLists(params = {} as object): Observable<TopicsResponse> {
    return this.http.get<TopicsResponse>(encodeURL(API_GET_POST_ADMIN_TOPICS, params));
  }

  createTopic(payload: TopicsLists): Observable<TopicsLists> {
    return this.http.post<TopicsLists>(API_GET_POST_ADMIN_TOPICS, payload);
  }

  updateTopic(payload = {} as object, topicId: string): Observable<TopicsLists> {
    return this.http.put<TopicsLists>(API_PUT_PATCH_DELETE_ADMIN_TOPICS.replace('<TOPIC_ID>', topicId), payload);
  }

  updateTopicStatus(payload = {} as object, topicId: string): Observable<TopicsLists> {
    return this.http.patch<TopicsLists>(API_PUT_PATCH_DELETE_ADMIN_TOPICS.replace('<TOPIC_ID>', topicId), payload);
  }

  deleteTopicStatus(topicId: string): Observable<TopicsLists> {
    return this.http.delete<TopicsLists>(API_PUT_PATCH_DELETE_ADMIN_TOPICS.replace('<TOPIC_ID>', topicId));
  }
}
