import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'common-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() title?: string | boolean = '';
  @Input() isOpened: boolean = false;
  @Input() customClass: string = 'sm:w-full sm:max-w-lg';
  @Input() color: 'default' | 'red' | 'yellow' | 'green' = 'default';
  @Input() alignment: 'center' | 'left' | 'right' = 'center';

  @Output() closeEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  open() {
    this.isOpened = true;
  }

  close() {
    if (this.isOpened) {
      this.isOpened = false;
      this.closeEvent.emit();
    }
  }
}
