import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AUTO_SAVE_DEBOUNCE_TIME_MS, UntilDestroy, untilDestroyed } from '@shared';
import { combineLatest, debounceTime, filter, firstValueFrom, map, Observable, skip, tap } from 'rxjs';
import {
  CategoryV2,
  CourseActions,
  CourseV2,
  InstructorService,
  Logger,
  ProjectService,
  RouterStoreService,
  selectCategories,
  selectCourse,
} from 'thkee-common';
import { HeaderActions } from '../components/course-header/course-header.component';
const log = new Logger('CourseDetailsComponent');

@UntilDestroy()
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class CoursePricingComponent implements OnInit {
  @Input() isDefaultView: boolean = true;
  courseState: string = '0';
  readOnly: boolean = true;
  modelDetails!: CourseV2;
  course$ = this.store.select(selectCourse);
  categories$!: Observable<CategoryV2[]>;
  courseId$ = this.routerStore.getParam('courseId').pipe(tap((v) => log.debug('courseId$: ', v)));

  formTier = new FormGroup({});
  formTierModel: any = {};
  formTierFields$: Observable<FormlyFieldConfig[]> = new Observable<FormlyFieldConfig[]>();
  isLoadingPrice: boolean = true;

  constructor(
    private store: Store,
    private projectService: ProjectService,
    private readonly instructorService: InstructorService,
    private routerStore: RouterStoreService
  ) {
    this.formTierFields$ = combineLatest([
      this.course$,
      this.instructorService.getCurrencies(),
      this.instructorService.getCoursePrice(),
    ]).pipe(
      untilDestroyed(this),
      map(([course, currencies, coursePrices]) => {
        this.isLoadingPrice = false;
        const form = [
          {
            fieldGroupClassName: 'grid grid-cols-2 gap-3',
            fieldGroup: [
              {
                key: 'currency',
                type: 'select',
                defaultValue: course.currency,
                props: {
                  wrapAppendClass: ['!mb-0', 'block'],
                  required: true,
                  stylish: true,
                  options: currencies.map((currency) => {
                    return {
                      label: currency.name,
                      value: currency.id,
                    };
                  }),
                  wrapClass: '',
                },
              },
              {
                key: 'tier',
                type: 'select',
                defaultValue: course.pricing,
                props: {
                  wrapAppendClass: ['!mb-0'],
                  required: true,
                  stylish: true,
                  options: coursePrices.results.map((price) => {
                    return {
                      label: price.name + ' (' + price.currency_symbol + ' ' + price.price + ')',
                      value: price.id,
                    };
                  }),
                  expressionProperties: {
                    'props.options': (model: { currency: any }, formState: any, field: any) => {
                      const selectedCurrency = model.currency;
                      return coursePrices.results
                        .filter((price) => price.currency === selectedCurrency)
                        .map((price) => ({
                          label: price.name,
                          value: price.id,
                        }));
                    },
                  },
                  wrapClass: '',
                },
              },
            ],
          },
        ];
        return form;
      })
    );
  }

  ngOnInit(): void {
    this.categories$ = this.store.select(selectCategories);
    combineLatest([this.course$, this.categories$])
      .pipe(
        filter(([course, categories]) => !!course && categories.length > 0),
        untilDestroyed(this)
      )
      .subscribe(([course]) => {
        this.modelDetails = {
          ...this.modelDetails,
          ...course,
        };
      });

    combineLatest([this.courseId$]);
    this.formTier.valueChanges
      .pipe(debounceTime(AUTO_SAVE_DEBOUNCE_TIME_MS), skip(1), untilDestroyed(this))
      .subscribe((value) => {
        firstValueFrom(this.courseId$).then((courseId) => {
          this.instructorService
            .patchCourse(courseId, {
              pricing: this.formTierModel.tier,
              currency: this.formTierModel.currency,
            })
            .subscribe();
        });
        if (value) {
        }
      });
  }

  actionEvent(event: HeaderActions) {
    this.readOnly = true;
    this.courseState = '0';
    if (event === 'update') {
      this.store.dispatch(CourseActions.upsertCourse({ course: this.modelDetails }));
    } else if (event === 'accept') {
      this.projectService.toStatePublished(this.modelDetails.project);
    } else if (event === 'reject') {
      this.projectService.toStateRejected(this.modelDetails.project);
    }
  }
}
