<div *ngIf="paginationData && paginationData.totalRecords" class="pagination flex items-center justify-between">
  <div class="counts text-sm font-medium">
    {{
      currentPage === paginationData.startPage
        ? currentPage
        : (currentPage * paginationData.pageSize) + 1 - (paginationData.pageSize)
    }}
    to
    {{
      currentPage === paginationData.endPage
        ? paginationData.totalRecords
        : currentPage * paginationData.pageSize
    }}
    of {{paginationData.totalRecords}} records
  </div>
  <nav class="flex items-center gap-2">
    <div *ngIf="sizeChangable && paginationFormFields" class="form">
      <formly-form [form]="paginationForm" [fields]="paginationFormFields" class="page-size">
      </formly-form>
   </div>
    <ul class="inline-flex gap-2">
      <li (click)="previous()">
        <a [ngClass]="currentPage === 1 ? 'pointer-events-none': ''"
          class="btn border-0 bg-slate-50 px-2.5">
          <svg-icon src="@assets/images/icons/chevron-left.svg" class="btn-icon rtl:hidden h-5 w-5"></svg-icon>
          <svg-icon src="@assets/images/icons/chevron-right.svg" class="btn-icon ltr:hidden h-5 w-5"></svg-icon>
        </a>
      </li>
      <li (click)="gotoPage(page)" *ngFor="let page of paginationData.pages; trackBy: trackByPage">
        <a [ngClass]="page === currentPage ? ' bg-primary-600 active' : 'bg-slate-50'"
          class="btn border-0 text-sm">{{page}}</a>
      </li>
      <li (click)="next()">
        <a
          [ngClass]="currentPage === paginationData.endPage ? 'pointer-events-none' : ''"
          class="btn border-0 bg-slate-50 px-2.5 text-sm">
          <svg-icon src="@assets/images/icons/chevron-right.svg" class="btn-icon rtl:hidden h-5 w-5"></svg-icon>
          <svg-icon src="@assets/images/icons/chevron-left.svg" class="btn-icon ltr:hidden h-5 w-5"></svg-icon>
        </a>
      </li>
    </ul>
  </nav>
</div>
