<div class="price-currencies-new p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>{{ title }} Member</h3>
    </div>
  </div>

  <!-- Shared Record -->
  <div class="user-groups mb-4">
    <app-data-table [title]="title" badge="{{ memberData.length }} Instructor">
      <ng-container table>
        <table class="w-full text-left">
          <thead class="heading">
            <tr>
              <th scope="col">
                <div class="col-name">ID</div>
              </th>
              <th scope="col">
                <div class="col-name">First Name</div>
              </th>
              <th scope="col">
                <div class="col-name">Last Name</div>
              </th>
              <th scope="col">
                <div class="col-name">Email Address</div>
              </th>
              <th scope="col" class="w-32">
                <div class="col-name">
                  Profile<br />
                  Completion
                </div>
              </th>
              <th scope="col">
                <div class="col-name">
                  Draft<br />
                  Course
                </div>
              </th>
              <th scope="col">
                <div class="col-name">
                  Course<br />
                  Published
                </div>
              </th>
              <th scope="col">
                <div class="col-name">Join Date</div>
              </th>
              <th scope="col">
                <div class="col-name">Status</div>
              </th>
              <th scope="col" class="w-20">
                <div class="col-name">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="" class="text-left" *ngFor="let member of memberData">
              <td class="!py-5">
                <a class="data-id text-blue-600 underline" href="#">#{{ member.id }}</a>
              </td>
              <td>
                <a class="data-firstname text-blue-600 underline" href="#">{{ member.first_name }}</a>
              </td>
              <td>
                <span class="data-lastname">{{ member.last_name }}</span>
              </td>
              <td>
                <span class="data-email">{{ member.email }}</span>
              </td>
              <td>
                <span class="data-completion">{{ member.profile_completion }}</span>
              </td>
              <td>
                <span class="data-draft">{{
                  computeDraft(member.stats?.courses, member.stats?.published_courses)
                  }}</span>
              </td>
              <td>
                <span class="data-published">{{ member.stats?.published_courses }}</span>
              </td>
              <td>
                <div class="data-data">{{ member.date_created | formatDate : 'll' }}</div>
                <div class="data-time text-neutral-500">{{ member.date_created | formatDate : 'h:ss A' }}</div>
              </td>
              <td>
                <div class="tag-green tag-sm">Active</div>
                <!-- <div class="tag-red tag-sm">Disabled</div> -->
              </td>
              <td>
                <div class="flex justify-center gap-2">
                  <a routerLink="./123/edit">
                    <button class="btn-amber-soft btn-sm px-2">
                      <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
                    </button>
                  </a>
                  <button class="btn-outline-red btn-sm px-2">
                    <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon h-4 w-4"></svg-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </app-data-table>
  </div>
</div>