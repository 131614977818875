<ng-container *ngIf="{
    hasData: hasData,
    priceCurrencyData: (priceCurrency$ | async) ?? priceCurrencyDataDefault,
    status: (status$ | async) ?? '',
    pricingCountryList: (pricingCountryList$ | async) ?? []
  } as vm">
  <div class="price-currencies-new p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>Edit {{ vm.priceCurrencyData.name ? vm.priceCurrencyData.name : 'Currencies' }}</h3>
      </div>
      <div>
        <!-- <button [disabled]="!enableLiveRate" type="button" class="btn-blue" (click)="getLivesRates()">
          <svg-icon class="btn-icon" src="@assets/images/icons/arrow-trending-up.svg"></svg-icon> Get Lives Rates
        </button> -->
        <button type="button" class="btn-blue" (click)="liveRates.open()">
          <svg-icon class="btn-icon" src="@assets/images/icons/arrow-trending-up.svg"></svg-icon> Get Lives Rates
        </button>
        <common-modal #liveRates title="Live Rates" customClass="sm:w-full sm:max-w-3xl">
          <ng-container content *ngIf="liveRates.isOpened">
            <div class="p-4">
              <app-live-rates-table [currencyCode]="formData.currency_code" (applyEvent)="handleApply($event)"></app-live-rates-table>
            </div>
          </ng-container>
        </common-modal>
      </div>
    </div>

    <div class="tier-form">
      <div *ngIf="vm.priceCurrencyData.name" class="card rounded-lg border border-neutral-100 px-6 py-9">
        <!-- <app-form-price-currency (onSubmit)="onSubmit($event)" [model]="vm.priceCurrencyData"></app-form-price-currency> -->
        <app-form-price-currency #currencyForm *ngIf="vm.priceCurrencyData && vm.hasData" (submitEvent)="onSubmit($event)"
          [model]="vm.priceCurrencyData" [countries]="vm.pricingCountryList" (changeEvent)="onChange($event)">
        </app-form-price-currency>
      </div>
    </div>
  </div>
</ng-container>