<router-outlet></router-outlet>

<!-- global common modal -->
<ng-container *ngIf="{ modal: modal$ | async } as vm">
  <app-common-modal-confirm #modalConfirm [title]="vm.modal?.title" [message]="vm.modal?.message ?? ''"
    [confirmText]="vm.modal?.confirmText" [hasCancel]="vm.modal?.hasCancel" (confirm)="vm.modal?.onConfirm?.()">
  </app-common-modal-confirm>
</ng-container>

<ng-container *ngIf="{ toast: toast$ | async } as vm">
  <app-common-toast #toastComponent [type]="vm.toast?.type" [message]="vm.toast?.message ?? ''"></app-common-toast>
</ng-container>
