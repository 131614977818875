import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-paid-payout-ondemand',
  templateUrl: './paid-payout-ondemand.component.html',
  styleUrls: ['./paid-payout-ondemand.component.scss'],
})
export class PaidPayoutOndemandComponent implements OnInit {
  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    },
    {
      key: 'on_demand',
      type: 'checkbox',
      className: '',
      props: {
        label: 'On-demand (16)',
        required: true,
      },
    },
    {
      key: 'monthly',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Monthly (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'paid',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paid (16)',
        required: true,
      },
    },
    {
      key: 'inactive',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Inactive (16)',
        required: true,
      },
    },
    {
      key: 'failed',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Failed (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  resetFilter() {
    this.filterForm.reset();
  }

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Payouts',
        url: '/ecommerce/payout/paid-payout/all',
      },
      {
        label: 'Paid Payouts',
        url: '',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
  }
  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }
  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Failed') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 1,
      title: 'Total Amount Paid-out',
      value: 10000,
    },
    {
      id: 2,
      title: 'Number of Payess',
      value: 78,
    },
    {
      id: 3,
      title: 'Total Payouts',
      value: 228,
    },
    {
      id: 4,
      title: 'Total Transactions',
      value: 20,
    },
  ];
  public paymentCardHistory: any = [
    {
      id: 5,
      title: 'Paypal Paid Amount',
      value: 320,
    },
    {
      id: 6,
      title: 'Bank Transfer Amount',
      value: 320,
    },
    {
      id: 7,
      title: 'Payoneer Paid Amount',
      value: 320,
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      payout_type: 'On-Demand',
      period: 'N/A',
      transactions: 23,
      payment_methode: 'Paypal',
      payout_amount: 344,
      status: 'Paid',
    },
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      payout_type: 'On-Demand',
      period: 'N/A',
      transactions: 23,
      payment_methode: 'Paypal',
      payout_amount: 344,
      status: 'Inactive',
    },
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      payout_type: 'On-Demand',
      period: 'N/A',
      transactions: 23,
      payment_methode: 'Paypal',
      payout_amount: 344,
      status: 'Failed',
    },
  ];
}
