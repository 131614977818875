<ng-template #fieldTypeTemplate></ng-template>
<ng-container *ngIf="props.uploadType === 'default'">
  <ng-container *ngTemplateOutlet="default"></ng-container>
</ng-container>
<ng-container *ngIf="props.uploadType === 'dashboard'">
  <ng-container *ngTemplateOutlet="uppyDashboard"></ng-container>
</ng-container>
<ng-container *ngIf="props.uploadType === 'drag-drop'">
  <ng-container *ngTemplateOutlet="dragandDrop"></ng-container>
</ng-container>

<ng-template #uppyDashboard>
  <div class="field-file">
    <div class="inline-flex justify-start items-start gap-6">
      <!-- preview -->
      <div *ngIf="props.preview" class="preview overflow-hidden rounded-md"
        [ngClass]="props.previewResource !== 'avatar' ? 'h-40 w-72' : 'h-[84px] w-[84px] min-w-[84px]'">
          <!-- Image Preview -->
          <ng-container *ngIf="props.previewType === 'image'">
            <ng-container *ngIf="!props.previewUrl || !field.formControl.value; else showPreviewImg">
              <ng-container *ngTemplateOutlet="noPreviewUI"></ng-container>
            </ng-container>
            <ng-template #showPreviewImg>
              <div class="overflow-hidden" [ngClass]="props.previewResource === 'avatar' ? 'rounded-full w-full h-full' : 'rounded'">
                <img [src]="sanitizeUrl(props.previewUrl)" class="w-full h-full"/>
              </div>
            </ng-template>
          </ng-container>
          <!-- File Preview -->
          <ng-container *ngIf="props.previewType === 'file'">
            <ng-container *ngIf="!props.previewUrl || !field.formControl.value; else showPreviewFile">
              <ng-container *ngTemplateOutlet="noPreviewUI"></ng-container>
            </ng-container>
            <ng-template #showPreviewFile>
              <div class="overflow-hidden rounded">
                <img src="assets/images/image-placeholder.svg" class="m-auto h-11 w-11" alt="" />
              </div>
            </ng-template>
          </ng-container>
          <!-- Video Preview -->
          <ng-container *ngIf="props.previewType === 'video'">
            <ng-container *ngIf="!props.processingStatus?.status; else showPreviewVideo">
              <ng-container *ngTemplateOutlet="noPreviewUI"></ng-container>
            </ng-container>
            <ng-template #showPreviewVideo>
              <div class="overflow-hidden rounded h-full relative">
                <ng-container *ngIf="processingStatus === 'upload-progress'">
                  <div class="h-full w-full flex mx-auto items-center justify-center rounded-xl bg-slate-300 animate-pulse">
                    <h3 class="text-lg" i18n>Uploading...</h3>
                  </div>
                </ng-container>
                <ng-container *ngIf="processingStatus === 'upload-success'">
                  <div class="h-full w-full flex mx-auto items-center justify-center rounded-xl bg-slate-300 animate-pulse">
                    <h3 class="text-lg text-green-500" i18n>File Uploaded</h3>
                  </div>
                </ng-container>
                <ng-container *ngIf="processingStatus === 'processing'">
                  <div class="h-full w-full flex mx-auto items-center justify-center rounded-xl bg-slate-300 animate-pulse">
                    <h3 class="text-lg" i18n>Processing...</h3>
                  </div>
                </ng-container>
                <ng-container *ngIf="processingStatus === 'failed'">
                  <div class="h-full w-full flex mx-auto items-center justify-center rounded-xl bg-red-50">
                    <h3 class="text-lg text-red-500" [tooltip]="props.processingStatus?.error" i18n>Processing failed</h3>
                  </div>
                </ng-container>
                <ng-container *ngIf="processingStatus === 'completed' || processingStatus ===  'complete'">
                  <app-formly-videoplayer [sources$]="sources$"></app-formly-videoplayer>
                </ng-container>
                <div *ngIf="processingStatus === 'loading'" class="flex h-[300px] w-full items-center justify-center">
                  <svg
                    class="text-amber-500 m-auto h-12 w-12 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              </div>
            </ng-template>
          </ng-container>
      </div>
      <!-- end preview -->
      <div class="flex flex-col gap-4 mb-2">
        <formly-fileDashboardInfo [field]="field" (clickEvent)="handleFileInfo($event)"></formly-fileDashboardInfo>
      </div>
    </div>
  </div>
  <uppy-drag-drop #dashboardUploader [uppy]="uppy" class="hidden h-[0] w-[0]"></uppy-drag-drop>
  <uppy-status-bar [uppy]="uppy" [props]="uppyStatusBarOptions" class="relative z-[999]"></uppy-status-bar>
  <common-modal #uploadModal customClass="sm:w-full sm:max-w-2xl" [title]="props.label">
    <ng-container content>
      <div class="h-[380px] p-4">
        <uppy-dashboard
          #uppyDashboard
          [uppy]="uppy"
          [props]="uppyDashboardOptions"
          class="uppy-dashboard"
        ></uppy-dashboard>
      </div>
    </ng-container>
  </common-modal>
</ng-template>

<ng-template #dragandDrop>
  <div class="uppyUpload relative">
    <div class="bg-white bg-opacity-50 z-10 absolute w-full h-full cursor-not-allowed" *ngIf="props.disabled"></div>
    <uppy-drag-drop [uppy]="uppy" class="uppyUpload-dragdrop" *ngIf="!(state$ | async)?.viewState && (this.isLoading$ | async) === null"></uppy-drag-drop>
    <div class="uppyUpload-dragdrop" *ngIf="(state$ | async)?.viewState || (this.isLoading$ | async) !== null">
      <formly-DragDropInfo
        [uploadState]="(state$ | async)!"
        (clickEvent)="handleFileInfo($event)"
        [fileStatus]="props.processingStatus"
        [sources$]="sources$"
      ></formly-DragDropInfo>
    </div>
  </div>
</ng-template>

<ng-template #default>
  <div class="uppyUpload">
    <div
      class="uppyUpload-default-container input-field items-center"
      type="file"
      *ngIf="!(state$ | async)?.viewState"
    >
      <uppy-drag-drop [uppy]="uppy" class="uppyUpload-default btn btn-sm p-0"></uppy-drag-drop>
    </div>
    <div class="uppyUpload-default-container input-field" type="file" *ngIf="(state$ | async)?.viewState">
      <formly-FileDefaultInfo
        [uploadState]="(state$ | async)!"
        (clickEvent)="handleFileInfo($event)"
        class="block w-full grow"
      ></formly-FileDefaultInfo>
    </div>
  </div>
</ng-template>

<!-- No Preview URL -->
<ng-template #noPreviewUI>
  <div *ngIf="props.previewResource === 'avatar'" class="bg-white flex h-full mx-auto rounded-full shadow h-[82px] w-[82px]">
    <img src="assets/images/image-placeholder.svg" class="m-auto h-11 w-11" alt="" defaultImage/>
  </div>
  <formly-imageplaceholder *ngIf="props.previewResource !== 'avatar'" text="Not Available"></formly-imageplaceholder>
</ng-template>