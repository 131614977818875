import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
})
export class InstructorGroupNewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onSubmit(event: any) {
    if (event.valid) {
      console.log('API CALL HERE--', event.values);
    }
  }
}
