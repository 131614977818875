import { createAction, props } from '@ngrx/store';
import { PricingTierCreateUpdatePayload, PricingTierLoadRemovePayload, PricingTierPayload } from './pricing-state.model';

export const PricingTierActions = {
  loadPricingTierList: createAction('[PricingTier] Process list', props<PricingTierPayload>()),
  loadPricingTierListSuccess: createAction('[PricingTier]Load pricing-tier list success', props<any>()),
  loadPricingTierListFail: createAction('[PricingTier] Load pricing-tier list fail', props<{ error: unknown }>()),
  getPricingTier: createAction('[PricingTier] Process data', props<PricingTierLoadRemovePayload>()),
  getPricingTierSuccess: createAction('[PricingTier]Load pricing-tier data success', props<any>()),
  getPricingTierFail: createAction('[PricingTier] Load pricing-tier data fail', props<{ error: unknown }>()),
  addPricingTier: createAction('[PricingTier] Add Process Tier', props<PricingTierCreateUpdatePayload>()),
  addPricingTierSuccess: createAction('[PricingTier] Add Process Tier Success', props<any>()),
  addPricingTierFail: createAction('[PricingTier] Add pricing-tier list fail', props<{ error: unknown }>()),
  updatePricingTier: createAction('[PricingTier] Update Process Tier', props<PricingTierCreateUpdatePayload>()),
  updatePricingTierSuccess: createAction('[PricingTier] Update Process Tier Success', props<any>()),
  updatePricingTierFail: createAction('[PricingTier] Update pricing-tier list fail', props<{ error: unknown }>()),
  clearPricingTierStatus: createAction('[PricingTier] Clear pricing tier status'),
  removePricingTier: createAction('[PricingTier] Remove Process Tier', props<PricingTierLoadRemovePayload>()),
  removePricingTierSuccess: createAction('[PricingTier] Remove PricingTier Process Tier Success', props<PricingTierLoadRemovePayload>()),
  removePricingTierFail: createAction('[PricingTier] Remove pricing-tier list fail', props<{ error: unknown }>()),
};

