import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  CurrencyCRUDStatus,
  PricingCountryActions,
  PricingCountryPayload,
  PricingCurrencyActions,
  PricingCurrencyCreateUpdatePayload,
  selectCurrencyCRUDStatus,
  selectLoadedPricingCurrencyData,
  selectPricingCountryList,
} from '@shared';
import { Observable } from 'rxjs';
import { PricingCountryData, PricingCurrency } from 'thkee-common';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class PriceCurrenciesViewComponent implements OnInit {
  id = '';
  priceCurrency$: Observable<PricingCurrency> = this.store.select(selectLoadedPricingCurrencyData);
  status$: Observable<CurrencyCRUDStatus> = this.store.select(selectCurrencyCRUDStatus);
  priceCurrencyDataDefault: PricingCurrency = {
    id: '',
    name: '',
    currency_code: '',
    rate: '',
    rounding_type: '',
    is_primary_exchange_rate_currency: false,
    is_primary_store_currency: false,
    pricing_tier_status: false,
    force: false,
    published: false,
    country: [],
    symbol: '',
    primary_currency: '',
  };

  formData: any = {};
  hasData = false;
  pricingCurrencyPayload!: PricingCurrencyCreateUpdatePayload;

  pricingCountryList$: Observable<PricingCountryData[]> = this.store.select(selectPricingCountryList);
  pricingCountryList: any = [];
  pricingCountryPayload!: PricingCountryPayload;

  constructor(private store: Store, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.pricingCountryPayload = {
      page: 1,
      page_size: 100,
    };
    this.store.dispatch(PricingCountryActions.loadPricingCountryList(this.pricingCountryPayload));

    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(PricingCurrencyActions.getPricingCurrency({ id: this.id }));

    this.priceCurrency$.subscribe((currency) => {
      // console.log("HERE", currency)
      this.formData = currency;
      this.hasData = true;
    });
  }

  onInit(fields: FormlyFieldConfig[]) {
    this.disableFiels(fields);
  }

  disableFiels(objects: FormlyFieldConfig[]): FormlyFieldConfig[] {
    return objects.map((obj) => {
      if (obj.key && obj.type) {
        obj.props = {
          ...obj.props,
          disabled: true,
        };
      }
      if (obj.fieldGroup && obj.fieldGroup.length > 0) {
        obj.fieldGroup = this.disableFiels(obj.fieldGroup);
      }
      return obj;
    });
  }
}
