import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged, filter, map, skip, Subscription } from 'rxjs';

interface PageItem {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  startPage: number;
  endPage: number;
  pages: number[];
  totalRecords: number;
}

export interface PaginationChange {
  page_size: number;
  page: number;
}
@Component({
  selector: 'thk-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() set metadata(metadata: { size: number; total: number; }) {
    this.size = metadata.size;
    this.total = metadata.total;
  }
  @Output() pageChange = new EventEmitter<number>();
  @Output() paginationChange = new EventEmitter<PaginationChange>();
  @Input() set page(p: number | undefined) {
    if (!p) {
      return;
    }
    this._currentPage = Number(p);
  };
  @Input() size = 5;
  @Input() total = 0;
  @Input() sizeChangable = false;
  @Input() customPageSizes = [5, 10, 15, 20, 40, 50];

  get currentPage() {
    return this._currentPage;
  }

  set currentPage(page: number) {
    if (!page || this.currentPage === page) {
      return;
    }
    this._currentPage = page;
    this.emitChange();
  }

  paginationData?: PageItem;

  // Pageination items
  paginationForm!: FormGroup<{ pageSize: FormControl<number | null> }>;
  paginationFormFields?: FormlyFieldConfig[];

  private subscription = new Subscription();
  private _currentPage = 1;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['total']) {
      setTimeout(() => {
        this.paginationData = this.getPager();
      });
    }
    if (changes['size']) {
      if (this.size && this.paginationForm) {
        this.paginationForm.patchValue(
          { pageSize: Number(this.size) }, { emitEvent: false }
        );
      }
    }
  }

  ngOnInit(): void {
    this.paginationForm = this.formBuilder.group({
      pageSize: this.size,
    })
    this.setupPageSizeChanger();
  }

  ngAfterViewInit(): void {
    this.handlePageSizeChange()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getPager(): PageItem | undefined {
    if (!this.total) {
      return undefined;
    }
    // Calculate total pages
    const totalPages = Math.ceil(this.total / this.pageSize);

    // Ensure the current page is within the valid range
    if (this.currentPage < 1) {
      this._currentPage = 1;
    } else if (this.currentPage > totalPages) {
      this._currentPage = totalPages;
    }

    // Calculate start and end page numbers for display in the pagination control
    const startPage = Math.max(1, this.currentPage - 2);
    const endPage = Math.min(totalPages, this.currentPage + 2);

    // Generate an array of page numbers
    const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => i + startPage);

    // Return object with all pager properties required by the view
    return {
      currentPage: this.currentPage,
      totalPages: totalPages,
      pageSize: this.pageSize,
      startPage: startPage,
      endPage: endPage,
      pages: pages,
      totalRecords: this.total,
    };
  }

  gotoPage(page: number) {
    this.currentPage = page;
    this.paginationData = this.getPager();
  }

  next() {
    this.gotoPage(this.currentPage + 1);
  }

  previous() {
    this.gotoPage(this.currentPage - 1);
  }

  trackByPage(page: number) {
    return page;
  }

  private setupPageSizeChanger() {
    this.paginationFormFields = [
      {
        key: 'pageSize',
        type: 'select',
        ...this.size ? { defaultValue: Number(this.size) } : {},
        props: {
          wrapAppendClass: ['!mb-3'],
          label: '',
          placeholder: '',
          multiple: false,
          stayPlaceholder: true,
          disabled: false,
          stylish: true,
          options: this.customPageSizes.map(value => ({ label: `${value} / page`, value })),
        },
        expressions: {},
      },
    ];
  }

  private get pageSize() {
    return Number(this.paginationForm.value.pageSize || 0);
  }

  private handlePageSizeChange() {
    this.subscription.add(
      this.paginationForm.valueChanges
        .pipe(
          map(value => Number(value.pageSize)),
          filter(Boolean),
          distinctUntilChanged(),
          skip(1) // hack because of formly trigger the first
        )
        .subscribe(() => {
          if (this.currentPage !== 1) {
            this.currentPage = 1;
          } else {
            this.emitChange();
          }
          this.paginationData = this.getPager();
        })
      );
  }

  private emitChange() {
    this.pageChange.next(this.currentPage);
    this.paginationChange.next({ page: this.currentPage, page_size: this.pageSize });
  }
}
