import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { PricingCurrencyService } from './currency-store.service';
import { PricingCurrencyActions } from './currency.actions';
@Injectable()
export class PricingCurrencyEffects {
  constructor(private actions$: Actions, private pricingService: PricingCurrencyService, private router: Router) {}

  loadPricingCurrencyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCurrencyActions.loadPricingCurrencyList),
      switchMap((params) =>
        this.pricingService.getPricingCurrencyList(params).pipe(
          map((data) => PricingCurrencyActions.loadPricingCurrencyListSuccess({ data })),
          catchError((error) => of(PricingCurrencyActions.loadPricingCurrencyListFail({ error })))
        )
      )
    )
  );

  addPricingCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCurrencyActions.addPricingCurrency),
      switchMap((params) =>
        this.pricingService.addPricingCurrency(params).pipe(
          map((data) => PricingCurrencyActions.addPricingCurrencySuccess({ data })),
          catchError((error) => of(PricingCurrencyActions.addPricingCurrencyFailed()))
        )
      )
    )
  );

  getPricingCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCurrencyActions.getPricingCurrency),
      switchMap((params) =>
        this.pricingService.getPricingCurrency(params).pipe(
          map((data) => PricingCurrencyActions.getPricingCurrencySuccess({ data })),
          catchError((error) => of(PricingCurrencyActions.getPricingCurrencyFail({ error })))
        )
      )
    )
  );

  updatePricingCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCurrencyActions.updatePricingCurrency),
      switchMap((params) =>
        this.pricingService.updatePricingCurrency(params).pipe(
          map((data) => PricingCurrencyActions.updatePricingCurrencySuccess({ data })),
          catchError((error) => of(PricingCurrencyActions.updatePricingCurrencyFailed()))
        )
      )
    )
  );

  removePricingCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCurrencyActions.removePricingCurrency),
      switchMap((params) =>
        this.pricingService.removePricingCurrency(params).pipe(
          map((data) => PricingCurrencyActions.removePricingCurrencySuccess(params)),
          catchError((error) => of(PricingCurrencyActions.removePricingCurrencyFail({ error })))
        )
      )
    )
  );
}
