import { Component, Input, OnInit } from '@angular/core';

interface MetricCardItem {
  title: string;
  value: any;
  description?: string;
}

type VariantType = 'default' | 'primary';

@Component({
  selector: 'app-metric-card',
  templateUrl: './metric-card.component.html',
  styleUrls: ['./metric-card.component.scss']
})
export class MetricCardComponent implements OnInit {
  @Input() data!: MetricCardItem;
  @Input() variant: VariantType = 'default';
  styles: Record<VariantType, { color: string; bg: string; border: string }> = {
    default: {
      color: 'text-slate-500',
      bg: 'bg-slate-600',
      border: 'border-slate-500'
    },
    primary: {
      color: 'text-primary-500',
      bg: 'bg-primary-600',
      border: 'border-primary-500'
    },
  }

  constructor() { }

  ngOnInit(): void {
  }

}
