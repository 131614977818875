import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntilDestroy, untilDestroyed } from '@shared';
import { CombinationSingleInputItem, CombinationSingleInputValue } from '@shared/components/combination-single-input/combination-single-input.component';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { combineLatest, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { AdminPayoutRequestDetailSummary, AdminPayoutRequestTransactionQuery, AdminPayoutTransaction, Pagination, QueryFormConverter, QueryStringFilterService, RouterStoreService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-paid-payout-transaction',
  templateUrl: './paid-payout-transaction.component.html',
  styleUrls: ['./paid-payout-transaction.component.scss'],
  providers: [QueryStringFilterService.forComponent()]
})
export class PaidPayoutTransactionComponent implements OnInit, AfterViewInit {
  query?: AdminPayoutRequestTransactionQuery;
  transactionsPagination?: Pagination<AdminPayoutTransaction>;
  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    },
    {
      key: 'on_demand',
      type: 'checkbox',
      className: '',
      props: {
        label: 'On-demand (16)',
        required: true,
      },
    },
    {
      key: 'monthly',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Monthly (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'paid',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paid (16)',
        required: true,
      },
    },
    {
      key: 'inactive',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Inactive (16)',
        required: true,
      },
    },
    {
      key: 'failed',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Failed (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];

  requestId$ = this.routerStore.getParam('payoutId').pipe(filter(Boolean), distinctUntilChanged());
  /**
   * HACK:: For showing request information, because of no api for getting request information for now
   */
  request?: AdminPayoutTransaction;
  searchOptions: CombinationSingleInputItem[] = [
    {
      id: 'id',
      title: 'ID',
      placeholder: 'Enter Transaction ID'
    }
  ];
  searchValue?: CombinationSingleInputValue;
  private formQueryConverter = new QueryFormConverter<AdminPayoutRequestTransactionQuery>(['payout_status']);
  summary?: AdminPayoutRequestDetailSummary;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routerStore: RouterStoreService,
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
    private queryFilterService: QueryStringFilterService<AdminPayoutRequestTransactionQuery>,
  ) {}

  ngOnInit(): void {
    this.getTransactions()
    this.initialUiData();
    this.getRequest();
    this.getSummary();
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  back() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  search(data: CombinationSingleInputValue) {
    const { key, value } = data;
    if (key && value) {
      this.queryFilterService.patch({ search: [key, value].join(':') });
      return;
    }

    this.queryFilterService.remove(['search']);
  }

  resetFilter() {
    this.filterForm.reset();
  }

  paginate($event: PaginationChange) {
    this.queryFilterService.patch($event);
  }

  // Dropdown methode
  showingDropdown = '';
  toggleDropdown(type: string) {
    if (type !== this.showingDropdown) {
      this.showingDropdown = type;
      return;
    }
    this.showingDropdown = '';
  }
  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Failed') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  // Transaction details drawer action
  @ViewChild('transactionDrawer') transactionDrawer: any;
  transactionData: any;
  openTransactionDrawer(data: any) {
    this.transactionData = data;
    this.transactionDrawer.open();
  }

  // Order details drawer action
  @ViewChild('orderDrawer') orderDrawer: any;
  orderData: any;
  openOrderDrawer(data: any) {
    this.orderData = data;
    this.orderDrawer.open();
  }

  private initialUiData() {
    this.routerStore.getParam('payoutId').pipe(untilDestroyed(this)).subscribe(payoutId => {
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Paid Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: `Payout #${payoutId}`,
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  private setupFilter() {
    this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.queryFilterService.patch(this.formQueryConverter.toQuery(value as any));
    });

    this.queryFilterService.initialValue.subscribe(query => {
      this.query = query;
      if (this.query.search) {
        const [key, value] = this.query.search.split(':');
        if (value && key) {
          this.searchValue = { key, value };
        }
      }
      this.filterForm.patchValue(this.formQueryConverter.toForm(query))
    });
  }

  private getTransactions() {
    combineLatest([
      this.requestId$,
      this.queryFilterService.valueChanges
    ])
    .pipe(
      debounceTime(400),
      untilDestroyed(this)
    ).subscribe(([id, query]) => {
      this.payoutService.getPayoutRequestTransactions(Number(id), query)
        .subscribe(data => this.transactionsPagination = data);
    })
  }

  private getRequest() {
    this.requestId$.pipe(untilDestroyed(this)).subscribe(id => {
      this.payoutService.getPayoutRequest(Number(id))
        .subscribe(data => {
          this.request = data;
        })
    })
  }

  private getSummary() {
    this.requestId$.pipe(untilDestroyed(this)).subscribe(id => {
      this.payoutService.getPayoutRequestDetailSummary(Number(id))
        .subscribe(data => this.summary = data);
    })
  }
}
