import { createFeature, createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';

export type AuthState = {
  userId: number;
  username: string;
  token: string;
  email: string;
  firstName: string;
  lastName: string;
  isInstructor: boolean;
};

export const initialAuthState: AuthState = {
  userId: 0,
  username: '',
  token: '',
  email: '',
  firstName: '',
  lastName: '',
  isInstructor: false,
};

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.loginSuccess, (state, { refresh, access }) => ({
    ...state,
    token: access,
  })),
  on(AuthActions.userLoaded, (state, { user }) => ({
    ...state,
    userId: user.id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    isInstructor: user.is_instructor,
  })),
  on(AuthActions.logout, () => ({ ...initialAuthState })),
  on(AuthActions.signupInstructorSuccess, (state) => ({
    ...state,
    isInstructor: true,
  }))
);

export const authFeature = createFeature({
  name: 'auth',
  reducer: authReducer,
});
