import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { RouterStoreService } from 'thkee-common';

@Component({
  selector: 'app-upcoming-payout-transaction',
  templateUrl: './upcoming-payout-transaction.component.html',
  styleUrls: ['./upcoming-payout-transaction.component.scss'],
})
export class UpcomingPayoutTransactionComponent implements OnInit {
  params: any = {};
  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Method</div>`,
    },
    {
      key: 'paypal',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paypal (16)',
        required: true,
      },
    },
    {
      key: 'payoneer',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Payoneer (16)',
        required: true,
      },
    },
    {
      key: 'bank',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Bank to Transfer (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Status</div>`,
    },
    {
      key: 'paid',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paid (16)',
        required: true,
      },
    },
    {
      key: 'inactive',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Inactive (16)',
        required: true,
      },
    },
    {
      key: 'failed',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Failed (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Sale Price</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Net Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Instructor Earning</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Thkee Earning</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  resetFilter() {
    this.filterForm.reset();
  }

  // Hold Reason form
  holdForm = new FormGroup({});
  holdFormModel: any = {};
  holdFormFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'radio',
      className: 'rounded-lg radio-group',
      props: {
        label: 'Reason',
        labelClass: 'font-bold text-black text-lg pb-2.5',
        required: true,
        tips: 'This is a tip message for course title',
        options: [
          { value: '1', label: 'Reason 1' },
          { value: '2', label: 'Reason 2' },
          { value: '3', label: 'Reason 3' },
        ],
      },
      defaultValue: '1',
    },
    {
      key: 'feedback',
      type: 'textarea',
      props: {
        label: 'Description',
        labelClass: 'font-bold text-black text-lg pb-2.5',
        tips: 'This is a tip message for course title',
        placeholder: 'Enter a description...',
        minHeight: '110px',
        maxHeight: '100%',
      },
    },
  ];

  constructor(private routerStore: RouterStoreService, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Upcoming Payouts',
          url: '/ecommerce/payout/upcoming-payouts/monthly',
        },
        {
          label: `Ahmed Hany #${params['instructorId']}`,
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Status methode
  getStatus(status: string = 'Available') {
    let style = { bg: '', text: '' };
    if (status === 'Available') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Pending') {
      style.bg = 'bg-amber-50';
      style.text = 'text-amber-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  // Transaction details drawer action
  @ViewChild('transactionDrawer') transactionDrawer: any;
  transactionData: any;
  openTransactionDrawer(data: any) {
    this.transactionData = data;
    this.transactionDrawer.open();
  }

  // Order details drawer action
  @ViewChild('orderDrawer') orderDrawer: any;
  orderData: any;
  openOrderDrawer(data: any) {
    this.orderData = data;
    this.orderDrawer.open();
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 1,
      title: 'Upcoming Payout Period',
      value: '2024-02-20T10:30:31.398Z',
      type: 'date',
    },
    {
      id: 3,
      title: 'Total Earnings',
      value: 233,
      type: 'currency',
    },
    {
      id: 3,
      title: 'Total Orders',
      value: 233,
      type: 'number',
    },
    {
      id: 4,
      title: 'Total Transactions',
      value: 28,
      type: 'number',
    },
    {
      id: 3,
      title: 'Total Refund',
      value: 233,
      type: 'currency',
    },
    {
      id: 3,
      title: 'Forced Refund',
      value: 233,
      type: 'currency',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      order_id: '323',
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      status: 'Available',
    },
    {
      id: '123',
      order_id: '323',
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      status: 'Available',
    },
    {
      id: '123',
      order_id: '323',
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      status: 'Pending',
    },
  ];
}
