
<div class="card-subheader flex items-center justify-between px-6 py-5">
  <div class="flex items-center gap-1">
     <h6>Requests</h6>
     <div *ngIf="payoutsPagination" class="tag tag-sm">{{payoutsPagination.count}} Requests</div>
  </div>
  <div class="actions flex flex-row gap-4">
     <!-- Filter -->
     <div class="relative">
        <button (click)="toggleDropdown('filter')" class="btn-neutral">
           <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
           <span>Filter</span>
        </button>
        <div [ngClass]="showingDropdown === 'filter' ? '' : 'hidden'"
           class="bg-white shadow-lg rounded px-2.5 pt-5 pb-[250px] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
           <div class="form">
              <formly-form [form]="filterForm" [fields]="filterFormFields">
              </formly-form>
           </div>
           <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
              <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                 <span>Reset</span>
              </button>
           </div>
        </div>
     </div>
     <button class="btn-neutral px-3">
        <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
     </button>
  </div>
</div>
<div class="card-body">
  <div class="courses-list"><!-- Table -->
    <div class="datagrid-table relative overflow-x-auto">
       <table class="w-full text-left">
          <thead class="heading">
             <tr>
                <th scope="col" class="">
                   <div class="col-name">Instructor</div>
                </th>
                <th scope="col" class="w-28">
                   <div class="col-name">Transactions</div>
                </th>
                <th scope="col" class="">
                   <div class="col-name">Payout Method</div>
                </th>
                <th scope="col" class="">
                   <div class="col-name">Payout Type</div>
                </th>
                <th scope="col" class="">
                   <div class="col-name">Created on</div>
                </th>
                <th scope="col" class="">
                   <div class="col-name">Total Amount</div>
                </th>
                <th scope="col" class="">
                   <div class="col-name">Status</div>
                </th>
             </tr>
          </thead>
          <tbody>
             <tr *ngFor="let data of payoutsPagination?.results; let i = index" class="hover:cursor-pointer">
                <td>
                   <span [routerLink]="'/ecommerce/payout/payouts-requests/' + data.id"
                      class="course-id">
                      <span class="text-blue-600 cursor-pointer">
                         {{ data.instructor.fullname }}
                      </span>
                   </span>
                </td>
                <td>
                   {{data.no_of_transactions}}
                </td>
                <td>
                   {{data.payment_method}}
                </td>
                <td class="max-w-[170px]">
                   <span class="">
                      {{ data.payout_type }}
                   </span>
                </td>
                <td>
                   <div class="course-id">
                      {{ data.date_created| date:'d MMMM y' }}
                      <div class="text-slate-400">{{ data.date_created| date:'h:mm:ss a' }}</div>
                   </div>
                </td>
                <td>
                   {{data.requested_amount | currency}}
                </td>
                <td>
                   {{data.status}}
                </td>
             </tr>
          </tbody>
       </table>
    </div>

     <!-- Pagination -->
     <div class="pagination flex items-center justify-between p-5">
        <thk-pagination class="w-full" (paginationChange)="paginate($event)" [total]="payoutsPagination?.count || 0" [page]="query.page || 1" [size]="query.page_size || 10"></thk-pagination>
     </div>
  </div>
</div>
