import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { ContentTypeApps, ContentTypeModels } from '../../models';
import { isDefined } from '../../utils';
import { selectContentTypesState } from './course.selectors';

export const selectContentType = (app_label?: ContentTypeApps, model?: ContentTypeModels) =>
  createSelector(selectContentTypesState, (state) => {
    return _.find(_.values(state.entities).filter(isDefined), { app_label, model });
  });
