import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { COURSE_TAB, TabItem } from '@shared';

export type HeaderActions = 'cancel' | 'update' | 'accept' | 'reject' | '';

@Component({
  selector: 'app-course-header',
  templateUrl: './course-header.component.html',
  styleUrls: ['./course-header.component.scss'],
})
export class CourseHeaderComponent implements OnInit, AfterViewInit {
  @Input() courseState: string = '0';
  @ViewChildren('buttonState', { read: TemplateRef }) buttonStateTemplates!: QueryList<TemplateRef<any>>;
  @Output() clickEvent = new EventEmitter<HeaderActions>();
  tabItems: TabItem[] = COURSE_TAB;
  buttonsArray: any[] = [];
  loaded: boolean = false;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.buttonsArray = this.buttonStateTemplates.toArray();
    this.loaded = true;
    this.cdr.detectChanges();
  }

  emitButton(action: HeaderActions = '') {
    this.clickEvent.emit(action);
  }

  get currentTemplate(): any {
    return this.buttonsArray[this.courseState];
  }
}
