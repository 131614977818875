import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { CourseActions, CourseV2, PartialEntity, selectCourse, selectCourseHistory } from 'thkee-common';

@Component({
  selector: 'app-common-history-course',
  templateUrl: './history-course.component.html',
  styleUrls: ['./history-course.component.scss'],
})
export class HistoryCourseComponent implements OnInit {
  @Input() maxHeight: string = '310px';

  course$!: Observable<PartialEntity<CourseV2>>;
  courseHistory$!: Observable<PartialEntity<CourseV2>[]>;

  constructor(private store: Store) {}

  async ngOnInit() {
    this.course$ = this.store.select(selectCourse);
    this.courseHistory$ = this.store.select(selectCourseHistory);
    await firstValueFrom(this.course$).then((course) => {
      this.store.dispatch(CourseActions.loadHistory({ projectId: course.project ?? '' }));
    });
  }
}
