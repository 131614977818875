import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { INSTRUCTOR_DRAWER_TAB, INSTRUCTOR_SETTINGS_DRAWER_TAB, TabItem } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { RouterStoreService } from 'thkee-common';

@Component({
  selector: 'app-upcoming-payout-transaction-hold',
  templateUrl: './upcoming-payout-transaction-hold.component.html',
  styleUrls: ['./upcoming-payout-transaction-hold.component.scss'],
})
export class UpcomingPayoutTransactionHoldComponent implements OnInit {
  params: any = {};

  //Student TAB
  instructorTabLists: TabItem[] = INSTRUCTOR_DRAWER_TAB;

  //Student Settings TAB
  instructorSettingsTabLists: TabItem[] = INSTRUCTOR_SETTINGS_DRAWER_TAB;
  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Method</div>`,
    },
    {
      key: 'paypal',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paypal (16)',
        required: true,
      },
    },
    {
      key: 'payoneer',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Payoneer (16)',
        required: true,
      },
    },
    {
      key: 'direct-to-Bank',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Direct to Bank (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Hold by</div>`,
    },
    {
      key: 'Ibrahim',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Ibrahim (16)',
        required: true,
      },
    },
    {
      key: 'Faris',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Faris (16)',
        required: true,
      },
    },
    {
      key: 'Abdullah',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Abdullah (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'paid',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paid (16)',
        required: true,
      },
    },
    {
      key: 'inactive',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Inactive (16)',
        required: true,
      },
    },
    {
      key: 'failed',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Failed (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Sale Price</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Net Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Instructor Share</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
  ];
  resetFilter() {
    this.filterForm.reset();
  }

  constructor(private routerStore: RouterStoreService, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Upcoming Payouts',
          url: '/ecommerce/payout/upcoming-payouts/monthly',
        },
        {
          label: `Ahmed Hany #${params['instructorId']}`,
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Transaction details drawer action
  @ViewChild('transactionDrawer') transactionDrawer: any;
  transactionData: any;
  openTransactionDrawer(data: any) {
    this.transactionData = data;
    this.transactionDrawer.open();
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 3,
      title: 'Statement Period',
      value: '2024-02-20T10:30:31.398Z',
      type: 'date',
    },
    {
      id: 2,
      title: 'Total Earnings',
      value: 19,
      type: 'currency',
    },
    {
      id: 4,
      title: 'Total Orders',
      value: 28,
      type: 'number',
    },
    {
      id: 4,
      title: 'Total Transactions',
      value: 28,
      type: 'number',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      boldby: 'Frank',
    },
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      boldby: 'Ibrahim',
    },
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      status: 'Forced Refund',
      boldby: 'Emran',
    },
  ];
}
