import { createFeature, createReducer, on } from '@ngrx/store';
import { CountryCRUDStatus, initialPricingCountryState } from './country-state.model';
import { PricingCountryActions } from './country.actions';

export const pricingCountryReducer = createReducer(
  initialPricingCountryState,
  on(PricingCountryActions.loadPricingCountryListSuccess, (state, { data }) => ({
    ...state,
    pricingCountryData: {
      id: '',
      name: '',
      two_letter_iso_code: '',
      three_letter_iso_code: '',
      numeric_iso_code: '',
      subject_to_vat: false,
      allow_billing: false,
      currency_code: '',
      sort_number: 0,
    },
    pricingCountryList: data,
  })),

  on(PricingCountryActions.addPricingCountrySuccess, (state, { data }) => {
    return {
      ...state,
      pricingCountryData: data,
      // pricingCountryList: { ...state?.pricingCountryList },
      status: CountryCRUDStatus.Success,
    };
  }),

  on(PricingCountryActions.addPricingCountryFail, (state, {}) => ({
    ...state,
    status: CountryCRUDStatus.Failed,
  })),

  on(PricingCountryActions.addPricingCountryFailed, (state, {}) => ({
    ...state,
    status: CountryCRUDStatus.Failed,
  })),

  on(PricingCountryActions.updatePricingCountrySuccess, (state, {}) => ({
    ...state,
    status: CountryCRUDStatus.Success,
  })),

  on(PricingCountryActions.updatePricingCountryFail, (state, {}) => ({
    ...state,
    status: CountryCRUDStatus.Failed,
  })),

  on(PricingCountryActions.updatePricingCountryFailed, (state, {}) => ({
    ...state,
    status: CountryCRUDStatus.Failed,
  })),

  on(PricingCountryActions.clearCountryCRUDStatus, (state, {}) => ({
    ...state,
    status: CountryCRUDStatus.Empty,
  })),

  on(PricingCountryActions.getPricingCountrySuccess, (state, { data }) => ({
    ...state,
    pricingCountryData: data,
  })),

  on(PricingCountryActions.updatePricingCountrySuccess, (state, { data }) => ({
    ...state,

    status: CountryCRUDStatus.Success,
  })),

  on(PricingCountryActions.removePricingCountrySuccess, (state, { id }) => {
    const updatedItems = Object.values(state.pricingCountryList);
    const filteredData = updatedItems.filter((item: any) => item.id !== id);

    return {
      ...state,
      pricingCountryList: filteredData,
      status: CountryCRUDStatus.Success,
    };
  })
);

export const pricingCountryFeature = createFeature({
  name: 'pricing-country',
  reducer: pricingCountryReducer,
});
