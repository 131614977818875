const prefix = 'thk';

type LocalStorageField =
  | 'geolocation'
  | 'referrals'
  | 'geo_exp'
  | 'lang'
  | 'cart'
  | 'cart_guest'
  | 'guest_cart_token'
  | 'credentials'
  | 'precessing_info';

type SessoonStorageField = 'withdraw_requested_created';

const getFieldName = <N>(name: N) => [prefix, name].join('_');

class CustomStorage<K> {
  constructor(private localStorage: Storage) {}
  getText<T = string>(name: K) {
    return this.localStorage.getItem(getFieldName(name)) as T | null;
  }

  /**
   * The value will be converted to string by JSON.stringify, should use when you want to store the object
   * @param name
   * @param value
   * @returns
   */
  setValue<T = unknown>(name: K, value: T) {
    return this.localStorage.setItem(getFieldName(name), JSON.stringify(value));
  }

  removeStorageItem(name: K) {
    this.localStorage.removeItem(getFieldName(name));
  }

  setText(name: K, value: string) {
    return this.localStorage.setItem(getFieldName(name), value);
  }

  getObject<T>(name: K): T | undefined {
    try {
      const value = this.getText(name);
      if (value) {
        return JSON.parse(value);
      }
    } catch (_) {}

    return undefined;
  }
}

export const LocalStorage = new CustomStorage<LocalStorageField>(localStorage);
export const SessionStorage = new CustomStorage<SessoonStorageField>(sessionStorage);
