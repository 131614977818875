import { ExchangRate } from 'thkee-common';

// Define the enum for status
export enum ExchangRateCRUDStatus {
  Empty = '',
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export type ExchangRateState = {
  error: object;
  exchangeRateList: ExchangRate[];
  exchangeRateData: ExchangRate;
  status: ExchangRateCRUDStatus;
};

export const initialExchangeRateState: ExchangRateState = {
  error: {},
  exchangeRateList: [],
  exchangeRateData: {
    id: '',
    from_currency: '',
    to_currency: '',
    rate: '',
    last_updated: '',
    active: false,
  },
  status: ExchangRateCRUDStatus.Empty,
};

export interface FetchExchangeRatePayload {
  page: number;
  page_size: number;
}
