
<common-modal #modal title="Update information">
  <div content class="flex flex-col py-4 px-6 gap-4">
    <div class="flex flex-col gap-2 bg-slate-100 p-4 rounded">
      <table *ngIf="payout">
        <tbody>
          <tr>
            <td class="font-semibold py-2 text-sm">Instructor</td>
            <td class="text-slate-500 py-2 text-sm">{{payout.instructor.fullname}}</td>
          </tr>
          <tr>
            <td class="font-semibold py-2 text-sm">Total Amount</td>
            <td class="text-slate-500 py-2 text-sm">{{payout.requested_amount}}</td>
          </tr>
          <tr>
            <td class="font-semibold py-2 text-sm">Payment method</td>
            <td class="text-slate-500 py-2 text-sm">{{payout.payment_method}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form">
      <form [formGroup]="markAsPaidForm" (ngSubmit)="markAsPaid()">
        <formly-form [form]="markAsPaidForm" [fields]="markAsPaidFormFields">
        </formly-form>
        <button class="hidden" type="submit" #markAsPaidRequestBtn></button>
      </form>
    </div>
  </div>
  <div footer class="flex justify-end gap-2 p-4">
    <button class="btn btn-secondary"
      (click)="hide()">Cancel</button>
    <button class="btn btn-teal" [thkLibProcessing]="requestingChange"
      (click)="markAsPaidRequestBtn.click()">Yes, Confirm</button>
  </div>
</common-modal>
