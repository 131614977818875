<div *ngIf="dummyData.length > 0; else notFound" class="course-overview p-4 pt-0">
   <div class="flex flex-col gap-4 mb-4">
      <div class="flex flex-row gap-4">
         <div *ngFor="let payment of paymentHistory"
            class="relative flex flex-col gap-1 border border-neutral-100 rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-neutral-600">{{payment.title}}</h5>
            <h5 *ngIf="payment.type==='currency'" class="text-lg font-bold text-blue-600">{{payment.value | currency}}
            </h5>
            <h5 *ngIf="payment.type==='number'" class="text-lg font-bold text-blue-600">{{payment.value}}</h5>
            <svg-icon src="@assets/images/icons/question-mark-circle.svg" class="btn-icon absolute right-4"></svg-icon>
         </div>
      </div>
      <div class="flex flex-row gap-4">
         <div *ngFor="let payment of paymentCardHistory"
            class="relative flex flex-col gap-1 border border-neutral-100 rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-neutral-600">{{payment.title}}</h5>
            <h5 *ngIf="payment.type==='currency'" class="text-lg font-bold text-blue-600">{{payment.value | currency}}
            </h5>
            <h5 *ngIf="payment.type==='number'" class="text-lg font-bold text-blue-600">{{payment.value}}</h5>
            <svg-icon src="@assets/images/icons/question-mark-circle.svg" class="btn-icon absolute right-4"></svg-icon>
         </div>
      </div>
   </div>
   <!-- Warnings -->
   <div class="px-6 py-4 mb-4 bg-rose-50 flex gap-2.5 rounded-md">
      <svg-icon src="@assets/images/icons/exclamation-circle.svg" class="w-[26px] h-[26px] text-rose-500">
      </svg-icon>
      <div class="flex flex-col gap-2.5 flex-1">
         <h3 class="text-rose-500 text-xl font-semibold">Attention</h3>
         <p class="text-rose-500 text-base font-normal mb-0">All Monthly Type Payouts Request have been created on 1st
            June 2023 For April 2023 Period Please Approve it and make the payout for instructors as soon as possible to
            prevent any delay. </p>
      </div>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'hidden':''"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <form [formGroup]="filterForm">
                     <formly-form [form]="filterForm" [fields]="filterFormFields" [model]="filterFormModel">
                     </formly-form>
                  </form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
                  <button class="btn btn-dark bg-neutral-600 text-white">
                     <span>Filter</span>
                  </button>
               </div>
            </div>
         </div>
         <!-- Search With Dropdown -->
         <div class="input-combine flex">
            <div class="select2">
               <div class="relative">
                  <a (click)="dropdown('course')" type="button" aria-haspopup="listbox" aria-expanded="true"
                     aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
                     <div class="selected-item truncate">Statement ID</div>
                     <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
                  </a>
                  <ul [ngClass]="isDropdown['course']?'':'hidden'" tabindex="-1" role="listbox"
                     aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3" class="select-items "
                     ng-reflect-ng-class="hidden">
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 1</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 2</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 3</li>
                  </ul>
               </div>
            </div>
            <div class="input-group">
               <div class="group !rounded-l-none">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input placeholder="Enter your full name" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex items-center gap-1">
               <h6> Payouts Requests</h6>
               <div class="tag tag-sm">100 Payee</div>
            </div>
            <div class="actions flex flex-row gap-4">
               <button (click)="rejectionConfirmed.open()" class="btn btn-primary bg-teal-600 text-white">
                  <svg-icon src="@assets/images/icons/refund-icon.svg" class="text-white"></svg-icon>
                  <span>Pay All</span>
               </button>
               <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
                  <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
                  <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
               </button>
               <button class="btn-neutral px-3">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list">
               <!-- Table -->
               <div class="datagrid-table relative overflow-x-auto">
                  <table class="w-full text-left">
                     <thead class="heading">
                        <tr>
                           <th scope="col" class="w-28 pl-4">
                              <div class="col-name flex items-center gap-2">
                                 <input type="checkbox" [checked]="isAllCheckBoxChecked()"
                                    (change)="checkAllCheckBox($event)">
                                 {{checkedItemsCount()}} Selected
                              </div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Instructor</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Transactions</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Payout Method</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Period</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Payout Type</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Created on</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Total Amount</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Actions</div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="hover:cursor-pointer" *ngFor="let data of dummyData; let i = index"
                           [routerLink]="'/ecommerce/payout/paid-payout/'+data.id+'/all'">
                           <td>
                              <input (click)="checked($event)" [(ngModel)]="dummyData[i].checked" type="checkbox"
                                 name="" id="">
                           </td>
                           <td>
                              <span [routerLink]="'/ecommerce/payout/payouts-requests/transactions/'+data.id"
                                 class="course-id">
                                 <span class="text-blue-600 cursor-pointer">
                                    {{ data.instructor.title }}
                                 </span>
                              </span>
                           </td>
                           <td>
                              {{data.transactions}}
                           </td>
                           <td>
                              {{data.payment_methode}}
                           </td>
                           <td>
                              {{data.period | date:'MMMM y'}}
                           </td>
                           <td class="max-w-[170px]">
                              <span class="">
                                 {{ data.payout_type }}
                              </span>
                           </td>
                           <td>
                              <span class="course-id">
                                 <span class="">
                                    {{ data.request_date | date:'d MMMM y' }}<br>
                                    {{ data.request_date | date:'h:mm:ss a' }}
                                 </span>
                              </span>
                           </td>
                           <td>
                              {{data.total_amount | currency}}
                           </td>
                           <td>
                              <button *ngIf="data.payout_type !== 'Monthly'"
                                 class="btn p-3 bg-teal-600 rounded-[4px] border border-teal-500">
                                 <svg-icon src="@assets/images/icons/check.svg" class="text-white btn-icon">
                                 </svg-icon>
                              </button>
                              <button class="btn p-3 bg-transparent border border-rose-600 rounded-[4px]">
                                 <svg-icon src="@assets/images/icons/no-symbol.svg" class="text-rose-600 btn-icon">
                                 </svg-icon>
                              </button>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <!-- Pagination -->
               <div class="pagination flex items-center justify-between px-5">
                  <div class="flex flex-row gap-4 items-center">
                     <span class="text text-neutral-700 font-normal text-sm">Show Items:</span>
                     <div class="form">
                        <form [formGroup]="paginationForm">
                           <formly-form [form]="paginationForm" [fields]="paginationFormFields"
                              [model]="paginationFormModel">
                           </formly-form>
                        </form>
                     </div>
                     <span class="text text-neutral-700 font-normal text-sm">entries</span>
                  </div>
                  <div class="navigation">
                     <nav aria-label="Page navigation example">
                        <ul class="inline-flex">
                           <li>
                              <a href="#" class="btn btn-outline">
                                 <svg-icon src="@assets/images/icons/arrow-left.svg" class="btn-icon h-5 w-5">
                                 </svg-icon>
                                 <span>Previous</span>
                              </a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">1</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">2</a>
                           </li>
                           <li>
                              <a href="#" aria-current="page" class="btn btn-outline active">3</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">...</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">4</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">5</a>
                           </li>
                           <li>
                              <a href="#" class="btn btn-outline">
                                 <span>Next</span>
                                 <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5">
                                 </svg-icon>
                              </a>
                           </li>
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<ng-template #notFound>
   <div style="height: calc(100% - 108px);" class="flex flex-col items-center justify-center">
      <div class="max-w-md text-center">
         <h2 class="text-5xl text-[#9094FF] font-black">Oops!</h2>
         <h5 class="text-base text-neutral-600 font-normal">There is no payout request currently, please check later
         </h5>
      </div>
   </div>
</ng-template>

<!-- Rejection confirm modal -->
<common-modal #rejectionConfirmed customClass="sm:w-full sm:max-w-[618px]">
   <ng-container header>
      <div class="p-5 flex flex-row gap-4 justify-between items-center bg-[#FEF3C7]">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl">Pay All</h1>
         </div>
         <button (click)="rejectionConfirmed.close()" class="bg-[#E5E5E5] text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div class="card-subheader text-center px-6 py-5 border-b border-b-neutral-100">
         <h6 class="text-base font-normal text-neutral-900">
            Are you sure you want to Pay All Payout Request?
         </h6>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="rejectionConfirmed.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
            </button>
            <button (click)="rejectionConfirmed.close()" class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold">Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>