import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { selectUserId } from '@shared';
import * as _ from 'lodash';
import { combineLatest, distinctUntilChanged, filter, map, Observable, switchMap, take } from 'rxjs';
import {
  CourseActions,
  CourseV2,
  FileProps,
  isDefined,
  LectureDetailV2,
  LectureResourceV2,
  LectureType,
  LectureV2,
  Logger,
  PartialEntity,
  selectCourse,
  selectLecture,
  selectLectureResoucesByLectureId,
  selectSelectedActivityId,
} from 'thkee-common';

const log = new Logger('LectureComponent');

@UntilDestroy()
@Component({
  selector: 'app-activity-lecture',
  templateUrl: './lecture.component.html',
  styleUrls: ['./lecture.component.scss'],
})
export class LectureComponent implements OnInit {
  @ViewChild('ngForm') ngForm!: NgForm;
  @Output() nextEvent = new EventEmitter<Partial<string>>();
  @Output() createEvent = new EventEmitter<Partial<any>>();
  @Output() saveEvent = new EventEmitter<Partial<any>>();
  @Output() cancelEvent = new EventEmitter<Partial<any>>();

  lectureType?: LectureType;
  onSelected: boolean = false;
  lectureId: string = '';

  lectureId$!: Observable<string>;
  lecture$!: Observable<PartialEntity<LectureV2> | undefined>;
  lectureResources$!: Observable<PartialEntity<LectureResourceV2>[]>;
  course$!: Observable<PartialEntity<CourseV2>>;
  userId$!: Observable<number>;
  isEdit$!: Observable<boolean>;

  readonly form: FormGroup = new FormGroup({});
  readonly options: FormlyFormOptions = {};
  model: Partial<LectureDetailV2> = {
    title: '',
  };
  fields: FormlyFieldConfig[] = [];

  constructor(private store: Store, private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    this.course$ = this.store.select(selectCourse);
    this.lectureId$ = this.store.select(selectSelectedActivityId);
    this.lectureId$.pipe(filter(isDefined), take(1)).subscribe((lectureId) => {
      this.lectureId = lectureId;
    });
    this.lecture$ = this.lectureId$.pipe(switchMap((lectureId) => this.store.select(selectLecture(lectureId))));
    this.lectureResources$ = this.lectureId$.pipe(
      switchMap((lectureId) => this.store.select(selectLectureResoucesByLectureId(lectureId)))
    );
    this.userId$ = this.store.select(selectUserId);
    this.isEdit$ = this.lectureId$.pipe(map((lectureId) => !!lectureId));
    combineLatest([this.lecture$, this.lectureResources$])
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(([lecture, lectureResources]) => {
        this.lectureType = lecture?.type;
        this.onSelected = !!lecture?.id;
        this.model = {
          ...this.model,
          ..._.cloneDeep(lecture),
          resources: _.cloneDeep(lectureResources),
        };
        this.initFields();
      });
  }

  private async initFields() {
    this.fields = [
      {
        key: 'title',
        type: 'input',
        props: {
          label: 'Title',
          placeholder: 'e.g. The basics of the Course',
          required: true,
          minLength: 6,
          maxLength: 60,
        },
        validation: {
          messages: {
            minLength: "Title can't be lower than 6 characters.",
          },
        },
      },
      {
        key: 'article',
        type: 'textarea',
        props: {
          label: 'Article',
          minLength: 100,
          maxLength: 1000,
          placeholder: 'Article content here...',
          minHeight: '170px',
          maxHeight: '300px',
        },
        validation: {
          messages: {
            minLength: "Article can't be lower than 100 characters.",
          },
        },
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit || this.lectureType !== 'article')),
        },
      },
      {
        key: 'video',
        type: 'file',
        props: {
          label: 'Video',
          placeholder: '.mkv or .mp4 up to 25MB',
          preview: true,
          previewType: 'video',
          previewUrl: '',
          allowedTypes: ['video/mp4', 'video/mkv'],
          uploadType: 'drag-drop',
          metadata: {},
          onUpload: (upload, field) => {
            log.debug('upload: ', upload);
            this.store.dispatch(
              CourseActions.uploadStart({
                referenceId: `${this.lectureId}`,
                fileName: upload.name,
                fileType: upload.type,
              })
            );
          },
          onProgress: (progress, field) => {
            log.debug('progress: ', progress);
            this.store.dispatch(
              CourseActions.uploadProgress({
                referenceId: `${this.lectureId}`,
                bytesTotal: progress.bytesTotal,
                bytesUploaded: progress.bytesUploaded,
                progressPercent: progress.progressPercent,
              })
            );
          },
          onComplete: (field) => {
            log.debug('complete');
            this.store.dispatch(
              CourseActions.uploadComplete({
                referenceId: `${this.lectureId}`,
              })
            );
          },
          onAbort: (field) => {
            log.debug('abort');
            this.store.dispatch(
              CourseActions.uploadAbort({
                referenceId: `${this.lectureId}`,
              })
            );
          },
        } as FileProps,
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit || this.lectureType !== 'video')),
          'props.metadata': combineLatest([this.course$, this.lecture$, this.userId$]).pipe(
            map(([course, lecture, userId]) => ({ user: userId, course: course?.id, lecture: lecture?.id }))
          ),
          'props.previewUrl': this.lecture$.pipe(
            map((lecture) => {
              return lecture?.video_url;
            }),
            distinctUntilChanged()
          ),
        },
        hooks: {
          afterViewInit: (field: any) => {
            setTimeout(() => {
              const element = this.el.nativeElement.querySelector('.replace_video');
              if (element) {
                this.renderer.listen(element, 'click', () => {
                  field.props.componentRef.handleFileInfo('reset');
                });
              }
            }, 1000);
          },
        },
      },
      {
        fieldGroupClassName: 'grid grid-cols-2 gap-6',
        fieldGroup: [
          {
            type: 'checkbox',
            key: 'preview',
            props: {
              label: 'Available for Preview',
            },
          },
          {
            template: `<div target="video" class="replace_video text-right text-sm cursor-pointer">Replace</div>`,
          },
        ],
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit || this.lectureType !== 'video')),
        },
      },
      {
        template: `<div class="text-lg font-medium mt-3 mb-2">Downloadable Materials</div>`,
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit)),
        },
      },
      {
        key: 'resources',
        type: 'repeat',
        props: {
          addText: 'Add Resources',
          deleteConfirmation: true,
          onDelete: (field: FormlyFieldConfig, model: Partial<LectureResourceV2>) => {
            if (model?.id) {
              this.store.dispatch(CourseActions.deleteLectureResource({ lectureResourceId: model.id }));
            }
          },
        },
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit)),
        },
        fieldArray: {
          fieldGroup: [
            {
              type: 'file',
              key: 'file',
              defaultValue: '',
              props: {
                allowedTypes: [
                  'video/mp4',
                  'video/mkv',
                  'image/png',
                  'image/jpg',
                  'image/jpeg',
                  'application/pdf',
                  'application/x-pdf',
                  'application/vnd.ms-excel',
                ],
                preview: false,
                uploadType: 'default',
                metadata: {},
              } as FileProps,
              expressions: {
                'props.metadata': combineLatest([this.course$, this.lecture$, this.userId$]).pipe(
                  map(([course, lecture, userId]) => ({
                    user: userId,
                    course: course?.id,
                    lecture: lecture?.id,
                    resource: '',
                  }))
                ),
                'props.previewUrl': 'model.file',
              },
            },
          ],
        },
      },
    ];
  }

  select(select: LectureType) {
    this.lectureType = select;
  }

  next() {
    this.nextEvent.emit(this.lectureType?.toLocaleLowerCase());
    this.onSelected = true;
  }

  submit(): void {
    this.form.markAsTouched();
    (this.ngForm as any).submitted = true;
    if (this.form.valid) {
      this.lectureId$.pipe(take(1)).subscribe((lectureId) => {
        const model: Partial<LectureV2> = { ...this.model, type: this.lectureType };
        if (lectureId) {
          this.saveEvent.emit(model);
        } else {
          this.createEvent.emit(model);
        }
        this.form.markAsUntouched();
      });
    }
  }

  cancel(): void {
    this.cancelEvent.emit(this.model);
  }
}
