import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-published',
  templateUrl: './published.component.html',
  styleUrls: ['./published.component.scss']
})
export class PublishedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
