import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface CombinationSingleInputItem {
  id: string;
  title: string;
  placeholder?: string;
}

export interface CombinationSingleInputValue {
  key: CombinationSingleInputItem['id'];
  value?: string;
}

@Component({
  selector: 'app-combination-single-input',
  templateUrl: './combination-single-input.component.html',
  styleUrls: ['./combination-single-input.component.scss']
})
export class CombinationSingleInputComponent implements OnInit {
  @Output() valueChanges = new EventEmitter<CombinationSingleInputValue>();
  @Input() set model(value: CombinationSingleInputValue | undefined) {
    if (!value) {
      return;
    }
    this.selectedSearchItem = value.key;
    this.textSearch = value.value || '';
  };
  @Input() set keys(items: CombinationSingleInputItem[]) {
    this._keys = items;
    this.searchOptionMetadata = items.reduce((result, key) => Object.assign(result, { [key.id]: key }), {});
    if (!this.selectedSearchItem) {
      this.selectedSearchItem = items[0].id;
    }
  };
  private _keys!: CombinationSingleInputItem[];

  get keys() {
    return this._keys;
  }

  searchOptionMetadata: Record<string, CombinationSingleInputItem> = {};
  selectedSearchItem: CombinationSingleInputItem['id'] = '';
  textSearch = '';

  constructor() { }

  ngOnInit(): void {
  }

  isDropdownShown = false;
  toggleDropdown() {
    this.isDropdownShown = !this.isDropdownShown;
  }

  selectItem(value: string) {
    this.toggleDropdown();
    if (this.selectedSearchItem !== value) {
      this.selectedSearchItem = value;
      if (this.textSearch) {
        this.textSearch = '';
        this.valueChanges.next({ key: this.selectedSearchItem!, value: '' })
      }
    }
  }

  searchRequest() {
    this.valueChanges.next({ key: this.selectedSearchItem!, value: this.textSearch });
  }
}
