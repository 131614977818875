<ng-container
  *ngIf="{
    liveRatesData: pricingCurrencyList ?? []
  } as vm"
>
  <div class="mb-4 flex items-center justify-between">
    <div class="text-lg font-semibold">Get Live Rates</div>
    <!-- <div class="option text-right">
      <button type="button" class="btn-blue btn-sm" (click)="clickApply(vm.liveRatesData)">Apply All</button>
    </div> -->
  </div>

  <div class="datagrid-table no_pagination relative">
    <ng-container *ngIf="isLoadingCurrencyRate; else loadedRate">
        <ng-container *ngTemplateOutlet="loader"></ng-container>
    </ng-container>
    <ng-template #loadedRate>
      <ng-container *ngIf="vm.liveRatesData.length > 0; else noLiveRate">
        <table class="w-full text-left">
          <thead class="heading">
            <tr>
              <th scope="col">
                <div class="col-name" i18n>Currency Name</div>
              </th>
              <th scope="col">
                <div class="col-name" i18n>Curency Code</div>
              </th>
              <th scope="col">
                <div class="col-name" i18n>Current Rate</div>
              </th>
              <th scope="col">
                <div class="col-name" i18n>Live Rate</div>
              </th>
              <th scope="col">
                <div class="col-name text-right" i18n>Apply Rate</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="" class="text-left" *ngFor="let rate of vm.liveRatesData">
              <td class="">
                <span class="course-id py-0">{{ rate.name }}</span>
              </td>
              <td class="">
                <span class="course-id py-0">{{ rate.currency_code }}</span>
              </td>
              <td class="">
                <span class="course-id py-0">{{ rate.rate }}</span>
              </td>
              <td class="">
                <span class="course-id py-0">{{ rate?.live_rate }}</span>
              </td>
              <td class="!py-2 text-right">
                <button
                  type="button"
                  class="btn-blue btn-sm"
                  *ngIf="rate.currency_code !== 'SAR' && rate.rate"
                  (click)="clickApply(rate)"
                  i18n>
                  Apply
                </button>
    
                <button type="button" class="btn-blue btn-sm" *ngIf="rate.currency_code === 'SAR' || !rate.rate" disabled i18n>
                  Apply
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-template #noLiveRate>
        <h3 class=" text-center font-medium text-slate-500 my-12" i18n>
          No Live Rates Founded
        </h3>
      </ng-template>
    </ng-template>
  </div>
</ng-container>

<!-- Price loader -->
<ng-template #loader>
  <div class="flex items-center justify-center w-full min-h-[200px]">
     <div class="loader">
        <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
           <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
           <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
           </path>
        </svg>
     </div>
  </div>
</ng-template>
