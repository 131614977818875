export interface SharePricingUpdatePayload {
  share_types?: 'ORGANICS' | 'ADS' | 'INSTRUCTOR_REFERRAL' | 'AFFILIATE' | 'STUDENT_REFERRAL';
  instructor_share?: number;
  platform_share?: number;
  affiliate_share?: number;
  thkee_credit?: number;
  group_name?: string;
  group?: number;
}

export interface SharePricingPayload {
  share_types: 'ORGANICS' | 'ADS' | 'INSTRUCTOR_REFERRAL' | 'AFFILIATE' | 'STUDENT_REFERRAL';
  instructor_share: number;
  platform_share: number;
  affiliate_share: number;
  thkee_credit: number;
  group_name: string;
  group: number;
}

export type SharePricing = SharePricingPayload & {
  id: number;
  date_created: string;
  date_updated: string;
  code: string;
};

export enum ShareTypes {
  ORGANICS = 'Organics',
  ADS = 'Ads',
  INSTRUCTOR_REFERRAL = 'Instructor Referral',
  AFFILIATE = 'Affiliate',
  STUDENT_REFERRAL = 'Student Referral',
}
