import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-formly-videoplayer',
  template: `
    <div class="preview h-[300px] overflow-hidden rounded-lg">
      <ng-container *ngIf="sources$">
        <app-thk-video-player
          [sources$]="sources$"
          [settingsButton]="false"
          [relatedMenu]="false"
          [qualityMenu]="false"
          [buttonRewind]="false"
          [autoplay]="true"
          [singlePlayer]="false"
          [theaterButton]="false"
          [poster]="''"
        >
        </app-thk-video-player>
      </ng-container>
    </div>
  `,
})
export class FormlyFileVideoPlayerComponent implements OnInit {
  @Input() sources$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor() {}

  ngOnInit(): void {}
}
