import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import { dragdropState, FileProcessingState } from 'thkee-common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-DragDropInfo',
  template: `
    <!-- Upload in progress -->
    <div class="flex flex-col gap-3" *ngIf="uploadState.viewState === 'upload-progress'">
      <div class="file-icon">
        <svg-icon src="@assets/images/icons/file-up.svg"></svg-icon>
      </div>
      <div class="status">
        <div class="uploading">Uploading...</div>
        <div class="estimate">Estimated time remaining: <span>20 min</span></div>
      </div>
      <div class="progress-status flex items-center gap-3">
        <div>
          <svg-icon src="@assets/images/icons/file.svg" class="block"></svg-icon>
        </div>
        <div class="grow">
          <div class="flex text-xs font-medium">
            <div class="grow truncate">{{ uploadState.fileName }}</div>
            <div class="text-slate-400">{{ uploadState.currentProgress }}%</div>
          </div>
          <div class="progress-container">
            <div class="progress" [style.width.%]="uploadState.currentProgress"></div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-sm btn-outline-red" (click)="emitClickEvent('abort')">
          <svg-icon src="@assets/images/icons/x-circle.svg" class="btn-icon"></svg-icon>
          <span>Abort</span>
        </button>
      </div>
    </div>
    <!-- Upload Success -->
    <div *ngIf="uploadState.viewState === 'upload-success'" class="flex flex-col gap-3">
      <div class="file-icon">
        <svg-icon src="@assets/images/icons/check-circle-solid.svg" class="text-green"></svg-icon>
      </div>
      <div class="status">
        <div class="uploading">File Uploaded.</div>
        <div class="estimate">Video is processing....</div>
      </div>
    </div>
    <!-- Uploaded Preview -->
    <div *ngIf="uploadState.viewState === 'complete'" class="-m-6">
      <div class="preview h-[300px] overflow-hidden rounded-lg">
        <video class="h-full w-full bg-black" [src]="uploadState.fileURL" controls></video>
      </div>
    </div>
  `,
})
export class FormlyFileDragDropInfoComponent implements OnInit {
  @Input() field!: FormlyFieldConfig;
  @Input() fileStatus: FileProcessingState | undefined;
  @Input() sources$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Input() uploadState: dragdropState = {
    fileName: 'Uploading...',
    currentProgress: 0,
    viewState: '',
    fileURL: '',
  };
  @Output() clickEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  emitClickEvent(action: string) {
    this.clickEvent.emit(action);
  }
}
