<div class="card rounded-lg border border-neutral-100">
  <div class="card-header border-b border-neutral-100 px-6 py-5" *ngIf="navigation.length">
    <div class="filter-buttons flex gap-2">
      <a *ngFor="let nav of navigation" [routerLink]="nav.url">
        <button class="" [ngClass]="nav.active ? 'btn-soft' : 'btn-transparent'">
          <span>{{ nav.title }}</span>
        </button>
      </a>
    </div>
  </div>
  <div class="card-subheader flex items-center justify-between px-6 py-5">
    <div class="flex items-center gap-1">
      <h6>{{ title }}</h6>
      <div class="tag tag-sm">{{ badge }}</div>
    </div>
    <div class="actions">
      <button class="btn-neutral px-2">
        <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="courses-list">
      <div class="datagrid-table relative overflow-x-auto" [class.no_pagination]="!pagination.total">
        <ng-content select="[table]"></ng-content>
      </div>

      <div class="pagination flex items-center justify-between px-5 py-[14px]" *ngIf="pagination.total">
        <div class="counts">
          {{ pagination.currentPage }} to {{ pagination.totalPage }} of {{ pagination.total }} records
        </div>
        <div class="navigation">
          <nav aria-label="Page navigation example">
            <ul class="inline-flex">
              <li>
                <a href="#" class="btn btn-outline">
                  <svg-icon src="@assets/images/icons/arrow-left.svg" class="btn-icon h-5 w-5"></svg-icon>
                  <span>Previous</span>
                </a>
              </li>
              <li>
                <a href="#" class="btn btn-outline">1</a>
              </li>
              <li>
                <a href="#" class="btn btn-outline">2</a>
              </li>
              <li>
                <a href="#" aria-current="page" class="btn btn-outline active">3</a>
              </li>
              <li>
                <a href="#" class="btn btn-outline">...</a>
              </li>
              <li>
                <a href="#" class="btn btn-outline">4</a>
              </li>
              <li>
                <a href="#" class="btn btn-outline">5</a>
              </li>
              <li>
                <a href="#" class="btn btn-outline">
                  <span>Next</span>
                  <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5"></svg-icon>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
