import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrderDetails } from '@shared';
import { TransactionsService } from '@shared/services';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { Subscription } from 'rxjs';
import { RouterStoreService } from 'thkee-common';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  params: any = {};
  private subscriptions = new Subscription();
  orderDetails!: OrderDetails;
  isLoading: boolean = false;

  constructor(
    private routerStore: RouterStoreService,
    private breadcrumbService: BreadcrumbService,
    private transactionsService: TransactionsService
  ) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Orders',
          url: '/ecommerce/orders',
        },
        {
          label: `Order #${params['orderId']}`,
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });

    this.getOrderDetails();
  }

  // Get order details
  private getOrderDetails() {
    this.isLoading = true;
    this.subscriptions.add(
      this.transactionsService.getOrderDetails(this.params['orderId']).subscribe({
        next: (res: OrderDetails) => {
          this.orderDetails = res;
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
