<ng-container *ngIf="type==='static'; else dynamicPage">
   <div class="pagination flex items-center justify-between px-5 py-[14px]">
      <div class="counts">1 to 5 of 65 records</div>
      <div class="navigation">
         <nav aria-label="Page navigation example">
            <ul class="inline-flex">
               <li>
                  <a href="#" class="btn btn-outline">
                     <svg-icon src="@assets/images/icons/arrow-left.svg" class="btn-icon h-5 w-5"></svg-icon>
                     <span>Previous</span>
                  </a>
               </li>
               <li>
                  <a href="#" class="btn btn-outline">1</a>
               </li>
               <li>
                  <a href="#" class="btn btn-outline">2</a>
               </li>
               <li>
                  <a href="#" aria-current="page" class="btn btn-outline active">3</a>
               </li>
               <li>
                  <a href="#" class="btn btn-outline">...</a>
               </li>
               <li>
                  <a href="#" class="btn btn-outline">4</a>
               </li>
               <li>
                  <a href="#" class="btn btn-outline">5</a>
               </li>
               <li>
                  <a href="#" class="btn btn-outline">
                     <span>Next</span>
                     <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5"></svg-icon>
                  </a>
               </li>
            </ul>
         </nav>
      </div>
   </div>
</ng-container>
<ng-template #dynamicPage>
   <ng-container *ngIf="paginationData.totalPages > 0">
      <div class="pagination flex items-center justify-between px-5 py-[14px]">
         <div class="counts text-sm font-medium">
            <ng-container *ngIf="paginationData.currentPage===paginationData.startPage; else pageStart">
               {{paginationData.currentPage}}
            </ng-container>
            <ng-template #pageStart>
               {{(paginationData.currentPage * paginationData.pageSize)-(paginationData.pageSize)}}
            </ng-template>
            to
            <ng-container *ngIf="paginationData.currentPage===paginationData.endPage; else pageEnd">
               {{paginationData.totalRecords}}
            </ng-container>
            <ng-template #pageEnd>
               {{paginationData.currentPage * paginationData.pageSize}}
            </ng-template>
            of
            {{paginationData.totalRecords}} records
         </div>
         <div class="navigation">
            <nav aria-label="Page navigation example">
               <ul class="inline-flex">
                  <li>
                     <a (click)="paginateAction('prev')"
                        [ngClass]="paginationData.currentPage===1?'pointer-events-none':''" class="btn btn-outline">
                        <svg-icon src="@assets/images/icons/arrow-left.svg" class="btn-icon h-5 w-5"></svg-icon>
                        <span>Previous</span>
                     </a>
                  </li>
                  <li (click)="switchPage(page)" *ngFor="let page of paginationData.pages">
                     <a [ngClass]="page===paginationData.currentPage?'bg-neutral-300 text-neutral-600':'bg-slate-50'"
                        class="btn btn-outline">{{page}}</a>
                  </li>
                  <li>
                     <a (click)="paginateAction('next')"
                        [ngClass]="paginationData.currentPage===paginationData.endPage?'pointer-events-none':''"
                        class="btn btn-outline">
                        <span>Next</span>
                        <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5"></svg-icon>
                     </a>
                  </li>
               </ul>
            </nav>
         </div>
      </div>
   </ng-container>
</ng-template>