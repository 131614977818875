import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  PricingGeoActions,
  PricingGeoService,
  PricingTierActions,
  PricingTierState,
  selectGeoPricingTiers,
  selectLoadedPricingGeoData,
  selectPricingTier,
} from '@shared';
import * as _ from 'lodash';
import { Observable, combineLatest, map, switchMap, take, tap } from 'rxjs';
import {
  GeoLocation,
  GeoLocationPayload,
  GeoPricing,
  GeoPricingTier,
  GeoPricingTierPayload,
  PricingTier,
  RouterStoreService,
  ToastService,
} from 'thkee-common';

interface geoPricingTiersTable {
  id: string | number;
  name: string;
  percentage: string | number;
  original_percentage: string | number;
  tierAmount: string | number;
  geoAmount: string | number;
  status: boolean | undefined;
  currency_code: string;
}

@UntilDestroy()
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class PriceGeolocationEditComponent implements OnInit {
  private geoLocId$ = this.routerStore.getParam('id');
  geoLocId!: string;
  geoLocationData!: GeoLocation; // TODO: Update or replace this with real data
  priceGeo$: Observable<GeoLocation> = this.store.select(selectLoadedPricingGeoData);

  // geoTiersData!: GeoPricingTier[];
  geoPricingModel!: GeoPricing;

  // Pricing Tiers
  priceTiers$: Observable<PricingTierState> = this.store.select(selectPricingTier);
  priceTiers!: PricingTier[];

  // Geo Pricing Tiers
  geoPricingTiers$: Observable<GeoPricingTier[]> = this.store.select(selectGeoPricingTiers);
  geoPricingTiers!: GeoPricingTier[];

  // Geo Tiers Data
  geoPricingTiersTable!: geoPricingTiersTable[];

  constructor(
    private routerStore: RouterStoreService,
    private pricingGeoService: PricingGeoService,
    private readonly toastService: ToastService,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    // this.geoLocId$.subscribe((id) => {
    //   this.geoLocId = id;
    //   if (id) {
    //     this.store.dispatch(PricingGeoActions.getGeoLoc({ id: id }));
    //     this.priceGeo$.pipe(untilDestroyed(this)).subscribe((data) => {
    //       if (data && data.id) {
    //         this.geoLocationData = data;
    //         console.log('this.geoLocationData--', this.geoLocationData);
    //       }
    //     });
    //   }
    // });

    this.geoLocId$
      .pipe(
        untilDestroyed(this),
        switchMap((id) => {
          if (id) return this.pricingGeoService.getGeoLoc({ id }).pipe(untilDestroyed(this));
          return [];
        })
      )
      .subscribe((res: GeoLocation) => {
        if (res) {
          this.geoLocId = String(res.id);
          this.geoLocationData = res;
          if (res) {
            // this.geoPricingModel = res.geo_pricing;
            // if (this.geoPricingModel?.id) {
            // Dispatch the action only if geo_pricing.id is available
            this.store.dispatch(PricingTierActions.loadPricingTierList({ page: 1, page_size: 100 }));
            this.store.dispatch(PricingGeoActions.loadGeoPricingTiers({ geo_location: res.id }));
            // }
          }
        }
      });

    combineLatest([
      this.priceTiers$.pipe(
        untilDestroyed(this),
        tap((priceTiers) => {
          this.priceTiers = priceTiers?.pricingTierList?.results || [];
          return this.priceTiers;
        }),
        map(() => this.priceTiers)
      ),
      this.geoPricingTiers$.pipe(untilDestroyed(this)),
    ]).subscribe(([priceTiers, geoPricingTier]) => {
      this.geoPricingTiersTable = [];
      if (geoPricingTier) {
        geoPricingTier.forEach((gTier) => {
          const sTier = _.find(priceTiers, { id: gTier.pricing_tier });
          // console.log('🚀 ~ PriceGeolocationEditComponent ~ geoPricingTier.forEach ~ sTier:', sTier);
          if (sTier) {
            // Compute
            let originalPrice = Number(sTier?.price);
            let tierPrice: number = 0;
            tierPrice = originalPrice + (originalPrice * Number(gTier.percentage)) / 100;
            const data: geoPricingTiersTable = {
              id: gTier.id,
              name: sTier?.name || 'Not Found',
              percentage: Math.round(Number(gTier.percentage)),
              original_percentage: Math.round(Number(gTier.percentage)),
              tierAmount: sTier ? Number(sTier?.price).toFixed(2) : '',
              geoAmount: tierPrice.toFixed(2),
              status: sTier?.price_tier_status || false,
              currency_code: sTier['currency_code'] ?? 'USD',
            };
            // console.log('🚀 ~ PriceGeolocationEditComponent ~ geoPricingTier.forEach ~ data:', data);
            this.geoPricingTiersTable.push(data);
          }
        });
      }
    });
  }

  onSubmit(event: any) {
    let values = event?.values;
    if (event.valid) {
      console.log('API CALL HERE--');
      console.log('Geo Form - ', values); // Pick properly for payload
      console.log('Tier Form - ', this.geoPricingModel);

      // Update geo pricing if exist
      const geoPricingId = this.geoLocationData?.id;
      if (geoPricingId) {
        const geoPricingPayload = _.pick(this.geoLocationData, ['currency_convert', 'status']);
        this.pricingGeoService.updateGeoPricing(geoPricingId, geoPricingPayload).pipe(take(1)).subscribe();
      }

      // Update geo location
      const geoLocPayload: GeoLocationPayload = _.pick(values, ['status', 'country', 'currency']);
      this.pricingGeoService
        .updateGeoLoc(this.geoLocId, geoLocPayload)
        .pipe(take(1))
        .subscribe((resp) => {
          if (resp && resp.id) {
            this.toastService.message({
              message: `Geolocation successfully saved.`,
            });
            this.router.navigate([`/price-management/geolocation/${this.geoLocId}`]);
          }
        });
    }
  }

  private timeoutId: any = {};
  newChanges: any = {};
  percentageChange($event: any, id: string) {
    if (id) {
      let currentTier = _.find(this.geoPricingTiersTable, { id });
      if (currentTier) {
        let percentage = this.cleanUpPercentage(currentTier['percentage']); // Clean up percentage with min and max

        let originalPrice: number = 0;
        let geoAmount: number = 0;
        if (currentTier['tierAmount']) {
          originalPrice = Number(currentTier['tierAmount']);
          geoAmount = originalPrice + (originalPrice * Number(percentage)) / 100;
        }
        currentTier.geoAmount = geoAmount;
        const newChange: GeoPricingTierPayload = { percentage: String(percentage) };
        this.newChanges[id] = newChange;
        console.log('New Changes--', newChange);

        // Update after 2 secs
        // clearTimeout(this.timeoutId[id]);
        // this.timeoutId[id] = setTimeout(() => {
        //   this.pricingGeoService.updateGeoPricingTiers(id, newChanges).subscribe((res) => {
        //     this.toastService.message({
        //       message: ` ${currentTier?.name} Tier for successfully saved.`,
        //     });
        //   });
        // }, 2000);

        // this.pricingGeoService.updateGeoPricingTiers(id, newChanges).subscribe((res) => {
        //   console.log('res--', res);
        // });

        // const existingChange = _.findIndex(this.geoPricingModel.geopricing_tiers, { id: Number(id) });
        // if (existingChange !== -1) {
        //   this.geoPricingModel.geopricing_tiers[existingChange] = newChanges;
        // } else {
        //   this.geoPricingModel.geopricing_tiers.push(newChanges);
        // }
      }
    }
  }

  percentageSave(id: string) {
    if (this.newChanges[id]) {
      this.pricingGeoService.updateGeoPricingTiers(id, this.newChanges[id]).subscribe((res) => {
        this.toastService.message({
          message: `Tier successfully saved.`,
        });
      });
    }
    this.newChanges[id] = null;
  }

  cancel(tier_id: any, b: any) {
    let fTier = _.find(this.geoPricingTiersTable, { id: tier_id });
    if (fTier) {
      fTier.percentage = fTier.original_percentage;
      this.percentageChange({}, tier_id);
      this.newChanges[tier_id] = null;
    }
  }

  cleanUpPercentage(percentage: any) {
    const min = -100;
    const max = 100;
    percentage = Number(String(percentage).replace(/[^0-9-]/g, '')); // convert to number and remove alphabeth
    if (isNaN(percentage)) {
      percentage = 0;
    }
    if (percentage > max) {
      percentage = max; // set to 100 if greather than max
    }
    if (percentage < min) {
      percentage = max; // set to 100 if greather than max
    }
    return percentage;
  }
}
