import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { INSTRUCTOR_DRAWER_TAB, INSTRUCTOR_SETTINGS_DRAWER_TAB, PRODUCT_DRAWER_TAB, TabItem, UntilDestroy, untilDestroyed } from '@shared';
import { CombinationSingleInputItem, CombinationSingleInputValue } from '@shared/components/combination-single-input/combination-single-input.component';
import { PAYOUT_TRANSACTION_STATUS_NAMES } from '@shared/constants/payout';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import * as _ from 'lodash';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { combineLatest, distinctUntilChanged, filter } from 'rxjs';
import { AdminInactivePayoutTransactionsQuery, AdminPayoutRequest, AdminPayoutTransaction, FileProps, LANGUAGES, Pagination, PayoutTransactionStatus, QueryFormConverter, QueryStringFilterService, RouterStoreService } from 'thkee-common';

const supportedPayoutStatus: PayoutTransactionStatus[] = ['available', 'failed', 'ready'];
@UntilDestroy()
@Component({
  selector: 'app-inactive-payout-instructor',
  templateUrl: './inactive-payout-instructor.component.html',
  styleUrls: ['./inactive-payout-instructor.component.scss'],
  providers: [
    QueryStringFilterService.forComponent(),
  ]
})
export class InactivePayoutInstructorComponent implements OnInit, AfterViewInit {
  params: any = {};
  //Product TAB
  productTabLists: TabItem[] = PRODUCT_DRAWER_TAB;

  //Student TAB
  instructorTabLists: TabItem[] = INSTRUCTOR_DRAWER_TAB;

  //Student Settings TAB
  instructorSettingsTabLists: TabItem[] = INSTRUCTOR_SETTINGS_DRAWER_TAB;

  // Filter form
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Status</div>`,
    },
    {
      key: 'payout_status',
      fieldGroup: supportedPayoutStatus.map(status => {
        return {
          key: status,
          type: 'checkbox',
          className: '',
          props: {
            label: PAYOUT_TRANSACTION_STATUS_NAMES[status],
            required: true,
          },
        }
      })
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Sale Price</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'sale_price_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'sale_price_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Net Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'net_amount_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'net_amount_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Instructor Earning</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'instructor_earning_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'instructor_earning_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Thkee Earning</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'platform_earning_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'platform_earning_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
  ];
  query: AdminInactivePayoutTransactionsQuery = {};
  transactionsPagination?: Pagination<AdminPayoutTransaction>;

  // Create product form
  productForm = new FormGroup({});
  productFormModel: any = {};
  productFormFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'card block py-9 px-6 mb-4',
      fieldGroup: [
        {
          className: 'section-label block border-b-[2px] border-neutral-200 pb-3 mb-3',
          template: '<h5>General</h5>',
        },
        {
          key: 'title',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: '#SAP MM Material Management',
          props: {
            inputAppendClass: ['border-0', 'bg-transparent', 'bg-none'],
            label: 'Title',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Learn Blender in 60 minutes',
            disabled: true,
            tips: 'This is a tip message for course title',
          },
          validation: {
            messages: {
              minLength: "Title can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'subtitle',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: 'Introduction to SAP MM Material Management',
          props: {
            label: 'Subtitle',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Introduction to SAP MM Material Management!',
            disabled: true,
            tips: 'This is a tip message for course sub title',
          },
          validation: {
            messages: {
              minLength: "This field can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'topics',
          type: 'select',
          defaultValue: ['uiux', 'frontend'],
          wrappers: ['feedback-field'],
          props: {
            label: 'Topics',
            placeholder: 'Choose topic',
            multiple: true,
            stayPlaceholder: true,
            disabled: true,
            tips: 'This is a tip message for course title',
            stylish: true,
            options: [
              { label: 'UI/UX', value: 'uiux' },
              { label: 'Frontend', value: 'frontend' },
              { label: 'Backend', value: 'backend' },
            ],
          },
          expressions: {},
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
          fieldGroup: [
            {
              key: 'skill_level',
              type: 'select',
              defaultValue: 'Beginner',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Level',
                placeholder: 'Choose a level',
                stylish: true,
                options: [
                  { label: 'All', value: 'All' },
                  { label: 'Beginner', value: 'Beginner' },
                  { label: 'Intermediate', value: 'Intermediate' },
                  { label: 'Expert', value: 'Expert' },
                ],
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
            {
              key: 'language',
              type: 'select',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Language',
                placeholder: 'Select language',
                stylish: true,
                options: _.sortBy(
                  _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                  (lang) => lang.label
                ),
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6',
          fieldGroup: [
            {
              key: 'category',
              type: 'select',
              defaultValue: 'business',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Category',
                placeholder: 'Choose a category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Business', value: 'business' },
                  { label: 'Design', value: 'design' },
                  { label: 'Language', value: 'language' },
                ],
              },
              expressions: {},
            },
            {
              key: 'subcategory',
              type: 'select',
              defaultValue: 'graphic-design',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Sub category',
                placeholder: 'Choose sub-category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Graphic Design', value: 'graphic-design' },
                  { label: 'Web Design', value: 'web-design' },
                  { label: 'UI/UX Design', value: 'uiux-design' },
                ],
              },
              expressions: {},
            },
          ],
        },
      ],
    },

    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'image',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Image Cover',
        placeholder: 'Upload your course image here. Supported files are .jpg, .jpeg, or .png.',
        preview: true,
        previewType: 'image',
        allowedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
      } as FileProps,
      expressions: {},
    },
    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'promo_video',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Promotional Video',
        placeholder: "Upload your course's promotional video here. Maximum of 200MB.",
        preview: true,
        previewType: 'video',
        hideLabel: false,
        allowedTypes: ['video/mp4', 'video/mkv', 'video/mov'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
        onUpload: (upload, field) => {
          // log.debug('upload: ', upload);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadStart({
          //       referenceId: `${courseId}-${field.key}`,
          //       fileName: upload.name,
          //       fileType: upload.type,
          //     })
          //   );
          // });
        },
        onProgress: (progress, field) => {
          // log.debug('progress: ', progress);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadProgress({
          //       referenceId: `${courseId}-${field.key}`,
          //       bytesTotal: progress.bytesTotal,
          //       bytesUploaded: progress.bytesUploaded,
          //       progressPercent: progress.progressPercent,
          //     })
          //   );
          // });
        },
        onComplete: (field) => {
          // log.debug('complete');
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadComplete({
          //       referenceId: `${courseId}-${field.key}`,
          //     })
          //   );
          // });
        },
      } as FileProps,
      expressions: {
        // 'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
        //   map(([course, userId]) => ({
        //     user: userId,
        //     course: course.id,
        //   })),
        //   distinctUntilChanged()
        // ),
        // 'props.previewUrl': this.course$.pipe(
        //   map((course) => {
        //     return course.promo_video_url;
        //   }),
        //   distinctUntilChanged()
        // ),
        // 'props.feedback': this.getFeedbackCount('promo_video'),
      },
    },
  ];

  searchValue: CombinationSingleInputValue = { key: 'id' };
  searchOptions: CombinationSingleInputItem[] = [
    { id: 'id', placeholder: 'Enter Transaction ID', title: 'Transaction ID' },
    { id: 'order_id', placeholder: 'Enter Order ID', title: 'Order ID' },
    { id: 'student', placeholder: 'Enter Student Name', title: 'Student Name' },
  ];

  private queryFormConverter = new QueryFormConverter<AdminInactivePayoutTransactionsQuery>([
    'payout_status',
  ]);
  private requestId$ = this.routerStore.getParam('payoutId').pipe(filter(Boolean), distinctUntilChanged());
  payout?: AdminPayoutRequest;

  constructor(
    private queryStringFilterService: QueryStringFilterService<AdminInactivePayoutTransactionsQuery>,
    private routerStore: RouterStoreService,
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
  ) {}

  ngOnInit(): void {
    this.getPayout();
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Inactive Payout',
          url: '/ecommerce/payout/inactive-payouts',
        },
        {
          label: 'Fatima Henny #' + params['instructorId'],
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  search($event: CombinationSingleInputValue) {
    const { key, value } = $event;
    if (value) {
      this.queryStringFilterService.patch({ search: [key, value].join(':') });
    }
  }

  resetFilter() {
    this.filterForm.reset();
  }

  paginate($event: PaginationChange) {
    this.queryStringFilterService.patch($event);
  }

  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Transaction details drawer action
  @ViewChild('transactionDrawer') transactionDrawer: any;
  transactionData: any;
  openTransactionDrawer(data: any) {
    this.transactionData = data;
    this.transactionDrawer.open();
  }

  // Order details drawer action
  @ViewChild('orderDrawer') orderDrawer: any;
  orderData: any;
  openOrderDrawer(data: any) {
    this.orderData = data;
    this.orderDrawer.open();
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 2,
      title: 'Total Sale',
      value: 219,
      type: 'currency',
      tooltip: 'Total Sale',
    },
    {
      id: 4,
      title: 'Net Amount',
      value: 28,
      type: 'currency',
      tooltip: 'Net Amount',
    },
    {
      id: 1,
      title: 'Thkee Earning',
      value: 28,
      type: 'currency',
      tooltip: 'Thkee Earning',
    },
    {
      id: 3,
      title: 'Instructor Earning',
      value: 28,
      type: 'currency',
      tooltip: 'Instructor Earning',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      order_id: '3341',
      date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      thkee_earning: 344,
      payment_method: 'Paypal',
      payout_status: 'Not Refunded',
    },
    {
      id: '124',
      order_id: '3342',
      date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      thkee_earning: 344,
      payment_method: 'Paypal',
      payout_status: 'Not Refunded',
    },
    {
      id: '125',
      order_id: '3343',
      date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      thkee_earning: 344,
      payment_method: 'Paypal',
      payout_status: 'Not Refunded',
    },
  ];

  private setupFilter() {
    this.filterForm.valueChanges.pipe(untilDestroyed(this))
      .subscribe(value => this.queryStringFilterService.patch(this.queryFormConverter.toQuery(value as any)));

    this.queryStringFilterService.initialValue.subscribe(query => {
      this.query = query;
      const [key, value] = (query.search || '').split(':');
      if (value && key) {
        this.searchValue = { key, value }
      }
      this.filterForm.patchValue(this.queryFormConverter.toForm(query));
    });

    combineLatest([
      this.queryStringFilterService.valueChanges,
      this.requestId$,
    ]).pipe(untilDestroyed(this)).subscribe(([query, id]) => {
      this.payoutService.getInactivePayoutRequestTransactions(Number(id), query).subscribe(
        pagination => this.transactionsPagination = pagination
      );
    })
  }

  private getPayout() {
    this.requestId$.pipe(untilDestroyed(this)).subscribe(id => {
      this.payoutService.getInactivePayout(Number(id)).subscribe(payout => this.payout = payout);
    })
  }
}
