<div class="main-shell min-h-screen">
  <div class="flex min-h-screen">
    <div class="w-full max-w-[245px] border-r border-neutral-100">
      <app-sidebar #sideBar [NavItems]="sidebarItems" [activeItem]="activeItem" [activeId]="activeId"></app-sidebar>
    </div>
    <div class="grow overflow-y-auto">
      <app-header *ngIf="shellComponents?.header !== false"></app-header>
      <div class="vertical-scroll">

        <div *ngIf="tabTitle" class="flex justify-between p-4">
          <h3 class="font-semibold text-2xl">{{tabTitle}}</h3>
          <app-common-breadcrumbs *ngIf="shellComponents?.breadcrumbs !== false" [enableBackBtn]="false" class="p-0"></app-common-breadcrumbs>
        </div>
        <app-common-tab-menu *ngIf="shellComponents?.tab !== false && tabItems.length" [items]="tabItems"></app-common-tab-menu>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
