import { Inject, Injectable, InjectionToken, Provider, Self } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, ReplaySubject, take } from 'rxjs';

type FilterData = Record<string, any>;

interface QueryStringFilterMetadata {
  default?: Record<string, any>;
  /**
   * For isolating the filter search value with other router query params
   */
  key?: string;
}

const QUERY_STRING_FILTER_METADATA = new InjectionToken<QueryStringFilterMetadata>(
  'router-filter-query-key'
);

@Injectable()
export class QueryStringFilterService<FD = FilterData> {
  private behaviour = new ReplaySubject<FD>();
  private trigger = new BehaviorSubject(true);
  private initial = new ReplaySubject<FD>();
  private initialNotified = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Self() @Inject(QUERY_STRING_FILTER_METADATA) private metadata: QueryStringFilterMetadata,
  ) {
    this.build();
  }

  private build(): void {
    this.route.queryParams
      .pipe(
        map(value => this.decodeFilter(this.getFitlerData(value))),
      )
      .subscribe(value => {
        const queryData = { ...this.metadata.default, ...value };
        if (!this.initialNotified) {
          this.initialNotified = true;
          this.initial.next(queryData);
        }
        this.behaviour.next(queryData);
      });
  }

  get initialValue() {
    return this.initial.pipe(take(1));
  }

  get valueChanges() {
    return combineLatest([
      this.behaviour.pipe(distinctUntilChanged(isEqual)),
      this.trigger
    ]).pipe(map(([query]) => query as FD));
  }

  patch(data: Partial<FD>) {
    this.behaviour.pipe(take(1)).subscribe(value => {
      const query = this.generateFilterData(this.encodeFilter({ ...value, ...data } as any));
      this.router.navigate([], { queryParams: query, queryParamsHandling: 'merge' })
    })
  }

  remove(fields: (keyof FD)[]) {
    this.patch(
      fields.reduce((pre, field) => Object.assign(pre, { [field]: undefined }), {} as any)
    )
  }

  refresh() {
    this.trigger.next(true);
  }

  reset() {
    this.router.navigate([], { queryParams: this.generateFilterData({} as FD) });
  }

  private getFitlerData(data: any) {
    if (!this.metadata.key) {
      return data;
    }

    return data[this.metadata.key];
  }

  private generateFilterData(data: FD): any {
    if (!this.metadata.key) {
      return data as FD;
    }

    return { [this.metadata.key]: data }
  }

  private encodeFilter(data: FD): any {
    if (!this.metadata.key) {
      return data;
    }

    return btoa(JSON.stringify(data));
  }

  private decodeFilter(data: string | FD): FD {
    if (typeof data !== 'string' || !this.metadata.key) {
      return data as FD;
    }

    try {
      const dataString = atob(data as string);
      return JSON.parse(dataString);
    } catch (error) {
      return {} as FD;
    }
  }

  static forComponent<FD = FilterData>(metadata: QueryStringFilterMetadata = {}): Provider[] {
    return [
      QueryStringFilterService<FD>,
      {
        provide: QUERY_STRING_FILTER_METADATA,
        useValue: metadata || {}
      }
    ];
  }
}
