import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-tax-config',
  templateUrl: './price-tax-config.component.html',
  styleUrls: ['./price-tax-config.component.scss']
})
export class PriceTaxConfigComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
