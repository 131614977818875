import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CourseV2, selectCourse, UtilsService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-course-heading',
  templateUrl: './course-heading.component.html',
  styleUrls: ['./course-heading.component.scss'],
})
export class CourseHeadingComponent implements OnInit {
  course!: CourseV2;
  constructor(private store: Store, private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.store
      .select(selectCourse)
      .pipe(untilDestroyed(this))
      .subscribe((course) => {
        this.course = course;
      });
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      return this.utilsService.getStatusColor(status);
    }
    return 'green';
  }
}
