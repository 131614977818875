<div class="flex h-10 items-center gap-6 bg-neutral-50 px-4">
  <div *ngIf="title" class="tab-title font-bold">
    {{ title }}
  </div>
  <div *ngIf="items && items.length" class="tab-menu">
    <ul class="items flex gap-[10px]">
      <li *ngFor="let item of items; let i = index" [id]="'item-' + item.id" class="item"
        [class.active]="activeItem === item.route" [ngClass]="'item-' + item.id">
        <a [routerLink]="parentRoute + item.route" [ngClass]="'item-link-' + item.id" class="flex items-center gap-2">
          <svg-icon *ngIf="item.hasChanges" src="@assets/images/icons/exclamation-circle.svg"
            class="block h-5 w-5 text-amber-600"></svg-icon>
          {{ item.displayName }}
        </a>
      </li>
    </ul>
  </div>
</div>
