import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  PricingCurrencyService,
  PricingTierActions,
  PricingTierStatus,
  selectLoadedPricingTierData,
  selectPricingTierCRUDStatus,
} from '@shared';
import { Observable, Subscription } from 'rxjs';
import { Currency, PricingTier, TireConversationRate } from 'thkee-common';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class PriceTiersViewComponent implements OnInit, OnDestroy {
  id = '';
  priceTier$: Observable<PricingTier> = this.store.select(selectLoadedPricingTierData);
  status$: Observable<PricingTierStatus> = this.store.select(selectPricingTierCRUDStatus);
  currencyList$: Observable<Currency[]> = new Observable();

  priceTierDataDefault: PricingTier = {
    id: '',
    price: 0,
    name: '',
    price_tier_status: false,
    currency: '',
    is_active: false,
  };
  hasData = false;
  formData: any = {};

  tireRate: TireConversationRate[] = [];
  isLoadingCurrencyRate: boolean = false;
  private currencyRateSubscription: Subscription = new Subscription();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private currencyStore: PricingCurrencyService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') || '';
    console.log('🚀 ~ PriceTiersViewComponent ~ ngOnInit ~ this.id:', this.id);
    this.store.dispatch(PricingTierActions.getPricingTier({ id: this.id }));
    this.currencyList$ = this.currencyStore.getCurrencyList();
    // combineLatest([this.priceTier$])
    //   .pipe()
    //   .subscribe(([price]) => {
    //     this.priceTierData = { ...price?.pricingTierData } || {};
    //     if (this.priceTierData?.id !== '') {
    //       this.hasData = true;
    //     }
    //   });

    this.priceTier$.subscribe((item) => {
      this.formData = item;
      this.hasData = true;
    });

    this.status$.subscribe((status) => {
      console.log('Status', status);
      if (status === PricingTierStatus.Success) {
        // do your routing thing
        console.log('Country saved successfully', this.formData);
        // this.router.navigate(['/price-management/tiers']);
      }
    });

    this.isLoadingCurrencyRate = true;
    this.currencyStore.getTireExchangeRate(this.id).subscribe({
      next: (res) => {
        this.tireRate = res;
        this.isLoadingCurrencyRate = false;
      },
      error: (err) => {
        this.isLoadingCurrencyRate = false;
      },
    });
  }

  onInit(fields: FormlyFieldConfig[]) {
    this.disableFiels(fields);
  }

  disableFiels(objects: FormlyFieldConfig[]): FormlyFieldConfig[] {
    return objects.map((obj) => {
      if (obj.key && obj.type) {
        obj.props = {
          ...obj.props,
          disabled: true,
        };
      }
      if (obj.fieldGroup && obj.fieldGroup.length > 0) {
        obj.fieldGroup = this.disableFiels(obj.fieldGroup);
      }
      return obj;
    });
  }

  ngOnDestroy(): void {
    this.currencyRateSubscription.unsubscribe();
  }
}
