import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdminActivities, UntilDestroy, untilDestroyed } from '@shared';
import { CourseService } from '@shared/services';
import { combineLatest, Observable } from 'rxjs';
import { CategoryV2, CourseV2, Logger, RouterStoreService } from 'thkee-common';
const log = new Logger('CourseDetailsComponent');

@UntilDestroy()
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements OnInit {
  courseState: string = '3';
  readOnly: boolean = true;
  modelDetails!: CourseV2;
  course$!: Observable<CourseV2>;
  categories$!: Observable<CategoryV2[]>;

  sectionEdit: boolean[] = [];
  sectionCollapse: boolean[] = [];
  courseId: string = '';
  activity_lists!: Partial<AdminActivities>;

  constructor(private store: Store, private courseService: CourseService, private routerStore: RouterStoreService) {}

  ngOnInit(): void {
    combineLatest([this.routerStore.getParam('courseId')])
      .pipe(untilDestroyed(this))
      .subscribe(([courseId]) => {
        this.courseId = courseId;
        if (courseId) {
          this.getData(courseId);
        }
      });
  }

  getData(courseId: string = '') {
    this.courseService
      .getAdminCoursesActivities({}, courseId, false)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.activity_lists = data;
      });
  }
}
