<div class="price-currencies-new p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>Edit Instructor Group</h3>
    </div>
  </div>

  <!-- Form -->
  <div class="group-form mb-4">
    <div class="card rounded-lg border border-neutral-100 px-6 py-9">
      <div class="mb-4 flex items-center justify-between">
        <div>
          <h4>Group Settings</h4>
        </div>
        <div>
          <a routerLink="/price-management/shares/organics">
            <button class="btn-amber-soft btn-sm px-2">
              <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
              <span>Edit Shares</span>
            </button>
          </a>
        </div>
      </div>
      <div class="stats flex flex-col gap-4">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-8 flex flex-col gap-1.5">
            <label for="" class="flex items-center gap-2 text-lg font-semibold">Instructor Group Name
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
                tooltip="This is a tooltip"></svg-icon>
            </label>
            <input disabled value="Sample Value Here" class="input-field w-full" />
          </div>
          <div class="col-span-4 flex flex-col gap-1.5">
            <label for="" class="flex items-center gap-2 text-lg font-semibold">Status
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
                tooltip="This is a tooltip"></svg-icon>
            </label>
            <div class="py-2">Active</div>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4">
          <div>
            <div class="stats-box rounded-[5px] border border-neutral-100 px-4 py-6 text-center">
              <div class="stats-label mb-2 font-bold text-neutral-500">Organic Share</div>
              <div class="stats-data border-b border-neutral-100 pb-2 text-3xl">100%</div>
              <div class="flex py-2 text-center">
                <div class="grow basis-full border-r border-neutral-100">
                  <div class="label text-sm text-neutral-600">Instructor</div>
                  <div class="stats font-bold">70%</div>
                </div>
                <div class="grow basis-full">
                  <div class="label text-sm text-neutral-600">Platform</div>
                  <div class="stats font-bold">30%</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="stats-box rounded-[5px] border border-neutral-100 px-4 py-6 text-center">
              <div class="stats-label mb-2 font-bold text-neutral-500">Ads Share</div>
              <div class="stats-data border-b border-neutral-100 pb-2 text-3xl">100%</div>
              <div class="flex py-2 text-center">
                <div class="grow basis-full border-r border-neutral-100">
                  <div class="label text-sm text-neutral-600">Instructor</div>
                  <div class="stats font-bold">70%</div>
                </div>
                <div class="grow basis-full">
                  <div class="label text-sm text-neutral-600">Platform</div>
                  <div class="stats font-bold">30%</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="stats-box rounded-[5px] border border-neutral-100 px-4 py-6 text-center">
              <div class="stats-label mb-2 font-bold text-neutral-500">Instructor Referral</div>
              <div class="stats-data border-b border-neutral-100 pb-2 text-3xl">100%</div>
              <div class="flex py-2 text-center">
                <div class="grow basis-full border-r border-neutral-100">
                  <div class="label text-sm text-neutral-600">Instructor</div>
                  <div class="stats font-bold">70%</div>
                </div>
                <div class="grow basis-full">
                  <div class="label text-sm text-neutral-600">Platform</div>
                  <div class="stats font-bold">30%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div class="stats-box rounded-[5px] border border-neutral-100 px-4 py-6 text-center">
              <div class="stats-label mb-2 font-bold text-neutral-500">Affiliates Share</div>
              <div class="stats-data border-b border-neutral-100 pb-2 text-3xl">100%</div>
              <div class="flex py-2 text-center">
                <div class="grow basis-full border-r border-neutral-100">
                  <div class="label text-sm text-neutral-600">Instructor</div>
                  <div class="stats font-bold">50%</div>
                </div>
                <div class="grow basis-full border-r border-neutral-100">
                  <div class="label text-sm text-neutral-600">Affiliate</div>
                  <div class="stats font-bold">10%</div>
                </div>
                <div class="grow basis-full">
                  <div class="label text-sm text-neutral-600">Platform</div>
                  <div class="stats font-bold">40%</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="stats-box rounded-[5px] border border-neutral-100 px-4 py-6 text-center">
              <div class="stats-label mb-2 font-bold text-neutral-500">Student Referral</div>
              <div class="stats-data border-b border-neutral-100 pb-2 text-3xl">100%</div>
              <div class="flex py-2 text-center">
                <div class="grow basis-full border-r border-neutral-100">
                  <div class="label text-sm text-neutral-600">Instructor</div>
                  <div class="stats font-bold">70%</div>
                </div>
                <div class="grow basis-full">
                  <div class="label text-sm text-neutral-600">Platform</div>
                  <div class="stats font-bold">30%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Shared Record -->
  <div class="user-groups mb-4">
    <app-data-table title="Instructor Groups" badge="{{ sharePricingData.length }} Instructor Group">
      <ng-container table>
        <app-form-price-shares [data]="sharePricingData" [enableEdit]="false"></app-form-price-shares>
      </ng-container>
    </app-data-table>
  </div>
</div>