<div class="price-shares p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>{{ title }}</h3>
    </div>
  </div>

  <!-- Data record -->
  <div class="user-groups mb-4">
    <app-data-table title="User Groups" badge="{{ sharePricingData.length }} Instructor Group">
      <ng-container table>
        <app-form-price-shares [data]="sharePricingData"></app-form-price-shares>
      </ng-container>
    </app-data-table>
  </div>
  <!-- Notes -->
  <div class="notes">
    <div class="card flex flex-col gap-3 rounded-lg border border-neutral-100 px-6 py-8">
      <h4>Note</h4>
      <div class="border-b-2 border-b-neutral-100">
        <h6 class="mb-2">Instructor Shares %</h6>
        <div class="text-neutral-600">
          <p>
            The percentage share for instructor once transaction fees and platform share have been deducted from the net
            revenue of the sale.
          </p>
        </div>
      </div>
      <div>
        <h6 class="mb-2">Platform Shares %</h6>
        <div class="text-neutral-600">
          <p>
            The percentage share earned by the Platform once transaction fees and instructor share have been deducted
            from the net revenue of the sale.
          </p>
          <p><small>Note: Platform Share % + Instructor Share % = %100 of Net Amuont.</small></p>
        </div>
      </div>
    </div>
  </div>
</div>