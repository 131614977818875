<ng-container>
  <div class="price-geolocation-new p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>Add Geolocation</h3>
      </div>
    </div>

    <div class="geolocation-form">
      <div class="card rounded-lg border border-neutral-100 px-6 py-9">
        <app-form-price-geolocation (submitEvent)="onSubmit($event)"></app-form-price-geolocation>
      </div>
    </div>
  </div>
</ng-container>