import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexToAlphabet',
})
export class IndexToAlphabetPipe implements PipeTransform {
  transform(index: number): string {
    if (index >= 0 && index < 26) {
      // Convert the index to the corresponding alphabet letter (A=0, B=1, ..., Z=25)
      return String.fromCharCode(65 + index); // 65 is the ASCII code for 'A'
    } else {
      return '';
    }
  }
}
