import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, share } from 'rxjs';

export type ActionStatus =
  | 'loading'
  | 'success'
  | 'failed'
  | 'upload-progress'
  | 'upload-success'
  | 'processing'
  | 'completed'
  | 'complete';
export interface LoadingAction {
  actionId: string;
  status: ActionStatus;
}

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  action$ = new BehaviorSubject<LoadingAction>({ actionId: '', status: 'loading' });

  constructor() {}

  loaderAction(actionId: string, response: ActionStatus = 'loading') {
    this.action$.next({ actionId, status: response });
  }

  response(actionId: string) {
    return this.get(actionId).pipe(map((record) => record.status));
  }

  private get(actionId: string) {
    return this.action$.pipe(
      filter((record) => record.actionId === actionId),
      share()
    );
  }
}
