import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FetchExchangeRatePayload,
  PricingCurrencyPayload,
  PricingCurrencyService,
  selectExchangeRateList,
  selectPricingCurrencyList,
} from '@shared/store';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { ExchangRateData, LiveRatesData, PricingCurrencyData } from 'thkee-common';

@Component({
  selector: 'app-live-rates-table',
  templateUrl: './live-rates-table.component.html',
  styleUrls: ['./live-rates-table.component.scss'],
})
export class LiveRatesTableComponent implements OnInit, OnDestroy {
  @Output() applyEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() currencyCode: string = '';

  pricingCurrencyList$: Observable<PricingCurrencyData[]> = this.store.select(selectPricingCurrencyList);
  pricingCurrencyList: PricingCurrencyData[] = [];
  pricingCurrencyPayload!: PricingCurrencyPayload;

  exchangeRateList$: Observable<ExchangRateData[]> = this.store.select(selectExchangeRateList);
  exchangeRateList: ExchangRateData[] = [];
  fetchExchangeRatePayload!: FetchExchangeRatePayload;

  liveRatesData: LiveRatesData[] = [];

  isLoadingCurrencyRate: boolean = false;
  private currencyRateSubscription: Subscription = new Subscription();
  queryParams: { currency_code?: string } = {};

  constructor(private store: Store, private pricingCurrencyService: PricingCurrencyService) {}

  ngOnInit(): void {
    // this.pricingCurrencyPayload = {
    //   detailed: true,
    // };

    // this.fetchExchangeRatePayload = {
    //   page: 1,
    //   page_size: 10,
    // };

    // this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList(this.pricingCurrencyPayload));
    // this.store.dispatch(ExchangRateActions.clearExchangeRateCRUDStatus());
    // this.store.dispatch(ExchangRateActions.loadExchangeRatesList(this.fetchExchangeRatePayload));

    // this.exchangeRateList$.subscribe((rates) => {
    //   this.exchangeRateList = rates;
    // });
    // this.pricingCurrencyList$.subscribe((currencies) => {
    //   this.exchangeRateList$.subscribe((rates) => {
    //     this.exchangeRateList = rates;
    //     const updatedCurrencies = currencies.map((currency) => ({
    //       ...currency,
    //       rate: this.filterCurrencyRate(this.exchangeRateList, currency.currency_code),
    //     }));

    //     // this.pricingCurrencyList = updatedCurrencies;
    //   });
    // });
    this.queryParams.currency_code = this.currencyCode;
    this.getCurrenciesLiveRate();
  }

  filterCurrencyRate(exchange_rates: ExchangRateData[], currency_code: string) {
    const rate = _.find(exchange_rates, { to_currency: currency_code });
    return rate?.rate || '';
  }

  clickApply(data: PricingCurrencyData): void {
    // Emitting the event when the button is clicked
    this.applyEvent.emit(data);
  }

  private getCurrenciesLiveRate() {
    this.isLoadingCurrencyRate = true;
    this.currencyRateSubscription.add(
      this.pricingCurrencyService.getCurrenciesLiveRate(this.queryParams).subscribe({
        next: (res) => {
          this.isLoadingCurrencyRate = false;
          this.pricingCurrencyList = res;
        },
        error: (error) => {
          this.isLoadingCurrencyRate = false;
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.currencyRateSubscription.unsubscribe();
  }
}
