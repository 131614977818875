import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { InstructoGroupService, SharePricing, SharePricingService } from 'thkee-common';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss'],
})
export class SharesAdsComponent implements OnInit {
  title: string = 'Shares';
  sharePricingData: SharePricing[] = [];

  constructor(
    private sharePricingService: SharePricingService,
    private instructorGroupService: InstructoGroupService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.sharePricingService.getAllSharePricing(),
      this.instructorGroupService.getAllInstructorGroup(),
    ]).subscribe(([sharePricing, group]) => {
      // Update group name of share pricing based on it's instructor group
      let shareData = sharePricing.map((item: any) => {
        if (group['results']) {
          const selectedGroup = _.find(group['results'], { id: item.group });
          if (selectedGroup) {
            return {
              ...item,
              group_name: selectedGroup.group_name,
            };
          }
        }
        return {
          ...item,
        };
      });
      shareData = _.filter(shareData, { share_types: 'ADS' }); // Filter organics
      this.sharePricingData = _.sortBy(shareData, 'id'); // Sort by id
    });
  }
}
