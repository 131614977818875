import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PriceTiersEditComponent } from './edit/edit.component';
import { PriceTiersNewComponent } from './new/new.component';
import { PriceTiersComponent } from './price-tiers.component';
import { PriceTiersViewComponent } from './view/view.component';

export * from './edit/edit.component';
export * from './new/new.component';
export * from './view/view.component';

export const Components = [
  PriceTiersViewComponent,
  PriceTiersNewComponent,
  PriceTiersEditComponent,
  PriceTiersComponent,
];

export const Routing = [
  {
    path: 'tiers',
    component: PriceTiersComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Price Tiers' },
  },
  {
    path: 'tiers/new',
    component: PriceTiersNewComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Add Pricing Tiers' },
  },
  {
    path: 'tiers/:id',
    component: PriceTiersViewComponent,
    data: { title: marker('Price Management'), breadcrumb: 'View Pricing Tiers' },
  },
  {
    path: 'tiers/:id/edit',
    component: PriceTiersEditComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Edit Pricing Tiers' },
  },
];
