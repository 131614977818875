import { createAction, props } from '@ngrx/store';
import {
  PricingCountryCreateUpdatePayload,
  PricingCountryLoadRemovePayload,
  PricingCountryPayload,
} from './country-state.model';

export const PricingCountryActions = {
  loadPricingCountryList: createAction('[PricingCountry] Process list', props<PricingCountryPayload>()),
  loadPricingCountryListSuccess: createAction('[PricingCountry]Load pricing-country list success', props<any>()),
  loadPricingCountryListFail: createAction(
    '[PricingCountry] Load pricing-country list fail',
    props<{ error: unknown }>()
  ),

  addPricingCountry: createAction('[PricingCountry] Add Process Country', props<PricingCountryCreateUpdatePayload>()),
  addPricingCountrySuccess: createAction('[PricingCountry] Add Process Country Success', props<any>()),
  addPricingCountryFail: createAction('[PricingCountry] Fail pricing-country list fail', props<{ error: unknown }>()),
  addPricingCountryFailed: createAction('[PricingCountry] Failed pricing-country list fail'),
  clearCountryCRUDStatus: createAction('[PricingCountry] Clear the Country CRUD status'),

  getPricingCountry: createAction('[PricingCountry] Process data', props<PricingCountryLoadRemovePayload>()),
  getPricingCountrySuccess: createAction('[PricingCountry]Load pricing-country data success', props<any>()),
  getPricingCountryFail: createAction('[PricingCountry] Load pricing-country data fail', props<{ error: unknown }>()),

  updatePricingCountry: createAction(
    '[PricingCountry] Update Process Country',
    props<PricingCountryCreateUpdatePayload>()
  ),
  updatePricingCountrySuccess: createAction('[PricingCountry] Update Process Country Success', props<any>()),
  updatePricingCountryFail: createAction(
    '[PricingCountry] Update pricing-country list fail',
    props<{ error: unknown }>()
  ),
  updatePricingCountryFailed: createAction('[PricingCountry] Update Failed pricing-country list fail'),

  removePricingCountry: createAction(
    '[PricingCountry] Remove Process Country',
    props<PricingCountryLoadRemovePayload>()
  ),
  removePricingCountrySuccess: createAction(
    '[PricingCountry] Remove PricingTier Process Country Success',
    props<PricingCountryLoadRemovePayload>()
  ),
  removePricingCountryFail: createAction(
    '[PricingCountry] Remove pricing-country list fail',
    props<{ error: unknown }>()
  ),
};
