import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { InstructoGroupService, SharePricing, SharePricingService } from 'thkee-common';

// let dummyData: SharePricing[] = [
//   {
//     id: 1,
//     date_created: '2024-01-04T03:53:31.630Z',
//     date_updated: '2024-01-04T03:53:31.630Z',
//     code: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
//     share_types: 'ORGANICS',
//     instructor_share: 60,
//     platform_share: 40,
//     affiliate_share: 100,
//     thkee_credit: 100,
//     group_name: 'Instructor (Default)',
//     group: 0,
//   },
//   {
//     id: 2,
//     date_created: '2024-01-04T03:53:31.630Z',
//     date_updated: '2024-01-04T03:53:31.630Z',
//     code: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
//     share_types: 'ORGANICS',
//     instructor_share: 90,
//     platform_share: 10,
//     affiliate_share: 100,
//     thkee_credit: 100,
//     group_name: 'Premium Instructor',
//     group: 0,
//   },
//   {
//     id: 3,
//     date_created: '2024-01-04T03:53:31.630Z',
//     date_updated: '2024-01-04T03:53:31.630Z',
//     code: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
//     share_types: 'ORGANICS',
//     instructor_share: 70,
//     platform_share: 30,
//     affiliate_share: 100,
//     thkee_credit: 100,
//     group_name: 'Partner Instructor',
//     group: 0,
//   },
// ];

@Component({
  selector: 'app-organics',
  templateUrl: './organics.component.html',
  styleUrls: ['./organics.component.scss'],
})
export class SharesOrganicsComponent implements OnInit {
  title: string = 'Shares';
  sharePricingData: SharePricing[] = [];

  constructor(
    private sharePricingService: SharePricingService,
    private instructorGroupService: InstructoGroupService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.sharePricingService.getAllSharePricing(),
      this.instructorGroupService.getAllInstructorGroup(),
    ]).subscribe(([sharePricing, group]) => {
      // Update group name of share pricing based on it's instructor group
      let shareData = sharePricing.map((item: any) => {
        if (group['results']) {
          const selectedGroup = _.find(group['results'], { id: item.group });
          if (selectedGroup) {
            return {
              ...item,
              group_name: selectedGroup.group_name,
            };
          }
        }
        return {
          ...item,
        };
      });
      shareData = _.filter(shareData, { share_types: 'ORGANICS' }); // Filter organics
      this.sharePricingData = _.sortBy(shareData, 'id'); // Sort by id
    });
  }
}
