<div class="price-geolocation-new p-4">
  <ng-container *ngIf="geoLocationData">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>Edit Geo Pricing for {{ geoLocationData.country_str }}</h3>
      </div>
    </div>

    <div class="geolocation-form mb-4">
      <div class="card rounded-lg border border-neutral-100 px-6 py-9">
        <app-form-price-geolocation (submitEvent)="onSubmit($event)" [model]="geoLocationData" submitLabel="Save">
        </app-form-price-geolocation>
      </div>
    </div>
  </ng-container>

  <div class="geolocation-tiers" *ngIf="geoLocationData">
    <div class="card rounded-lg border border-neutral-100 px-6 py-4">
      <div class="mb-4 flex items-center justify-between">
        <div class="text-xl font-bold">Tier List</div>
        <div class="flex gap-8">
          <div class="tier-currency-convert">
            <label class="mb-2 block font-bold">Currency Convert</label>
            <label class="relative inline-flex cursor-pointer items-center">
              <input type="checkbox" [(ngModel)]="geoLocationData.currency_convert" class="peer sr-only" />
              <div
                class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800">
              </div>
              <span class="ms-3 text-sm">{{ geoLocationData.currency_convert ? 'Active' : 'Disabled' }}</span>
            </label>
          </div>
          <div class="tier-status">
            <label class="mb-2 block font-bold">Status</label>
            <label class="relative inline-flex cursor-pointer items-center">
              <input type="checkbox" [(ngModel)]="geoLocationData.status" class="peer sr-only" />
              <div
                class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800">
              </div>
              <span class="ms-3 text-sm">{{ geoLocationData.status ? 'Active' : 'Disabled' }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="tier-items">
        <ng-container *ngFor="let tier of geoPricingTiersTable">
          <ng-container *ngTemplateOutlet="tierItem; context: { tier }"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #tierItem let-tier="tier">
  <div class="tierItem border-t border-neutral-200 py-4">
    <div class="col-span-12 mb-4 font-bold">{{ tier.name }}</div>
    <div class="grid grid-cols-3 gap-7">
      <div>
        <label class="mb-2 block">Tiers</label>
        <select id="countries" placeholder="Choose value" class="w-full" disabled>
          <option selected [value]="tier.name">{{ tier.name }} - {{ tier.currency_code }} {{ tier.tierAmount }}</option>
        </select>
      </div>
      <div>
        <label class="mb-2 block">Geo Price</label>
        <input type="text" class="input-field w-full" value="{{ tier.currency_code }} {{ tier.geoAmount }}" disabled />
      </div>
      <div>
        <label class="mb-2 block">Percentage</label>
        <div class="flex items-center gap-2">
          <div class="input-group">
            <div class="group">
              <input placeholder="0%" [(ngModel)]="tier.percentage"
                (ngModelChange)="percentageChange($event, tier.id)" />
              <svg-icon class="input-icon" src="@assets/images/icons/arrow-path.svg"></svg-icon>
            </div>
          </div>
          <div class="flex items-center gap-2" *ngIf="newChanges[tier.id]">
            <button class="btn-neutral btn-sm px-2" (click)="cancel(tier.id, tier.percentage)">
              <svg-icon src="@assets/images/icons/x-mark.svg" class="block w-5"></svg-icon>
            </button>
            <button class="btn-blue btn-sm" (click)="percentageSave(tier.id)">
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>