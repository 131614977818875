import { createFeature, createReducer, on } from '@ngrx/store';
import { ExchangRateCRUDStatus, initialExchangeRateState } from './exchange-rate-state.model';
import { ExchangRateActions } from './exchange-rate.actions';

export const exchangeRateReducer = createReducer(
  initialExchangeRateState,
  on(ExchangRateActions.loadExchangeRatesListSuccess, (state, { data }) => {
    return {
      ...state,
      exchangeRateData: {
        id: '',
        from_currency: '',
        to_currency: '',
        rate: '',
        last_updated: '',
        active: false,
      },
      exchangeRateList: data,
      status: ExchangRateCRUDStatus.Success,
    };
  }),
  on(ExchangRateActions.clearExchangeRateCRUDStatus, (state, {}) => ({
    ...state,
    status: ExchangRateCRUDStatus.Empty,
  })),
  on(ExchangRateActions.updateCurrencyRateSuccess, (state, { data }) => ({
    ...state,
    status: ExchangRateCRUDStatus.Success,
  }))
);

export const exchangeRateFeature = createFeature({
  name: 'exchange-rate',
  reducer: exchangeRateReducer,
});
