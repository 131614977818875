import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CurrencyCRUDStatus,
  ExchangRateActions,
  ExchangRateCRUDStatus,
  FormPriceCurrencyComponent,
  ModalComponent,
  PricingCountryActions,
  PricingCountryPayload,
  PricingCurrencyActions,
  PricingCurrencyCreateUpdatePayload,
  PricingCurrencyPayload,
  selectCurrencyCRUDStatus,
  selectExchangeRateCRUDStatus,
  selectLoadedPricingCurrencyData,
  selectPricingCountryList,
} from '@shared';
import { Observable } from 'rxjs';
import { ModalService, PricingCountryData, PricingCurrency, PricingCurrencyData, ToastService } from 'thkee-common';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class PriceCurrenciesEditComponent implements OnInit {
  @ViewChild('liveRates') liveRates!: ModalComponent;
  @ViewChild('currencyForm') currencyForm!: FormPriceCurrencyComponent;

  id = '';
  priceCurrency$: Observable<PricingCurrency> = this.store.select(selectLoadedPricingCurrencyData);
  status$: Observable<CurrencyCRUDStatus> = this.store.select(selectCurrencyCRUDStatus);
  priceCurrencyDataDefault: PricingCurrency = {
    id: '',
    name: '',
    currency_code: '',
    rate: '',
    rounding_type: '',
    is_primary_exchange_rate_currency: false,
    is_primary_store_currency: false,
    pricing_tier_status: false,
    force: false,
    published: false,
    country: [],
    symbol: '',
    primary_currency: '',
  };

  formData: any = {};
  hasData = false;
  pricingCurrencyPayload!: PricingCurrencyCreateUpdatePayload;

  pricingCountryList$: Observable<PricingCountryData[]> = this.store.select(selectPricingCountryList);
  pricingCountryList: any = [];
  pricingCountryPayload!: PricingCountryPayload;

  enableLiveRate!: boolean;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private readonly toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.pricingCountryPayload = {
      page: 1,
      page_size: 100,
    };
    this.store.dispatch(PricingCountryActions.loadPricingCountryList(this.pricingCountryPayload));

    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(PricingCurrencyActions.getPricingCurrency({ id: this.id }));

    this.priceCurrency$.subscribe((currency) => {
      this.formData = currency;
      this.hasData = true;
    });
  }

  getLivesRates() {
    this.modalService.confirm({
      title: $localize`Live Rates`,
      message: $localize`Are you sure, You want to update rate?`,
      onConfirm: () => {
        // this.deleteCategory(data);
        if (this.formData['currency_code']) {
          this.currencyForm.getLivesRates(this.formData['currency_code']);
        } else {
          alert($localize`Please select currency`);
        }
      },
    });
  }

  onSubmit(event: any) {
    if (event.valid) {
      let values = event?.values;
      this.pricingCurrencyPayload = { ...values };
      // this.pricingCurrencyPayload.rate = Number(this.pricingCurrencyPayload.rate).toFixed(6);
      // console.log(event.selectedCountries);

      // const idArray = event.selectedCountries.map((obj: PricingCountryData) => obj.id);
      // this.pricingCurrencyPayload.country = idArray;
      // console.log('this.pricingCurrencyPayload', this.pricingCurrencyPayload);

      this.store.dispatch(PricingCurrencyActions.updatePricingCurrency(this.pricingCurrencyPayload));
      this.status$.subscribe((status) => {
        if (status === CurrencyCRUDStatus.Success) {
          this.toastService.message({
            message: $localize`Currency updated successfully`,
          });
          // this.store.dispatch(
          //   PricingCurrencyActions.loadPricingCurrencyList({
          //     detailed: false,
          //   })
          // );
          setTimeout(() => {
            this.router.navigate(['/price-management/currencies']); // Navigate to the countries list
          }, 1000);
        }

        if (status === CurrencyCRUDStatus.Failed) {
          this.toastService.error({
            message: $localize`Something went wrong. Please try again.`,
          });
        }
      });
    }
  }

  // Depricated
  // handleApply(rateData: CurrencyRate[] | CurrencyRate): void {
  //   console.log('Apply clicked!', rateData);
  //   // Your logic when the button is clicked
  // }

  rateStatus$: Observable<ExchangRateCRUDStatus> = this.store.select(selectExchangeRateCRUDStatus);
  pricingCurrencyPayload2: PricingCurrencyPayload = { detailed: false };
  handleApply(currencyData: PricingCurrencyData): void {
    if (currencyData && currencyData.currency_code) {
      const currencyUpdatePayload = {
        id: currencyData.id,
        rate: Number(currencyData.rate).toFixed(6),
      };
      this.store.dispatch(ExchangRateActions.updateCurrencyRate(currencyUpdatePayload));
      this.rateStatus$.subscribe((status) => {
        if (status === ExchangRateCRUDStatus.Success) {
          this.toastService.message({
            message: currencyData.currency_code + $localize`currency rate updated successfully`,
          });
          this.liveRates.close();
          // setTimeout(() => {
          //   this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList(this.pricingCurrencyPayload2));
          // }, 1000);
        } else {
          this.toastService.error({
            message: $localize`Something went wrong. Please try again.`,
          });
        }
        this.store.dispatch(PricingCurrencyActions.clearCurrencyCRUDStatus());
      });
    }
  }

  onChange(values: any) {
    this.formData = values;
    this.enableLiveRate = false;

    if (values && values['currency_code']) {
      this.enableLiveRate = true;
    }

    // Manually trigger change detection
    this.cdr.detectChanges();
  }
}
