import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DrawerComponent, UntilDestroy, untilDestroyed } from '@shared';
import { PayoutService } from '@shared/services/payout/payout.service';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import { AdminInstructorBalance, AdminPayoutInstructorBalanceTransactionsQuery, AdminPayoutTransaction, Pagination, PayoutType, QueryFormConverter, QueryStringFilterService, RouterStoreService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-instructor-balance-transactions',
  templateUrl: './instructor-balance-transactions.component.html',
  styleUrls: ['./instructor-balance-transactions.component.scss'],
  providers: [QueryStringFilterService.forComponent({ key: 'instructor-trans' })]
})
export class InstructorBalanceTransactionsComponent implements OnInit, AfterViewInit {
  instructorBalance?: AdminInstructorBalance;
  query: AdminPayoutInstructorBalanceTransactionsQuery = {};

  // Transaction Filter
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Type</div>`,
    },
    {
      key: 'payout_type',
      fieldGroup: [

        {
          key: PayoutType.onDemand,
          type: 'checkbox',
          className: '',
          props: {
            label: 'On-demand',
            required: true,
          },
        },
        {
          key: PayoutType.monthly,
          type: 'checkbox',
          className: '',
          props: {
            label: 'Monthly',
            required: true,
          },
        },
      ]
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Status</div>`,
    },
    {
      key: 'payout_status',
      fieldGroup: [
        {
          key: 'paid',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Paid',
            required: true,
          },
        },
        {
          key: 'avalable',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Avalable',
            required: true,
          },
        },
        {
          key: 'pending',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Pending',
            required: true,
          },
        },
        {
          key: 'forced_refunded',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Forced Refunded',
            required: true,
          },
        },
      ]
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Sale Price</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'sale_price_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'sale_price_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Net Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'net_amount_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'eg: 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'net_amount_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'eg: 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Instructor Share</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'instructor_share_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'eg: 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'instructor_share_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'eg: 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Date</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'created_date_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'start',
          },
        },
        {
          template: '-',
        },
        {
          key: 'created_date_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'end',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Release Date</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'release_date_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'start',
          },
        },
        {
          template: '-',
        },
        {
          key: 'release_date_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: 'end',
          },
        },
      ],
    },
  ];
  transactionsPagination?: Pagination<AdminPayoutTransaction>;
  transactionData?: AdminPayoutTransaction;

  private formQueryConverter = new QueryFormConverter<AdminPayoutInstructorBalanceTransactionsQuery>(
    ['payout_status', 'payout_type']
  );

  constructor(
    private routerStore: RouterStoreService,
    private payoutService: PayoutService,
    private queryStringFilterService: QueryStringFilterService<AdminPayoutInstructorBalanceTransactionsQuery>,
  ) {}

  ngOnInit(): void {
    this.getTransactions();
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  paginate($event: PaginationChange) {
    this.queryStringFilterService.patch($event);
  }

  resetFilter() {
    this.filterForm.reset();
  }

  // Dropdown methode
  showingDropdown: string = '';
  dropdown(type: string) {
    if (type === this.showingDropdown) {
      this.showingDropdown = '';
      return;
    }

    this.showingDropdown = type;
  }
  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Available') {
      style.bg = 'bg-blue-50';
      style.text = 'text-blue-600';
    } else if (status === 'Forced Refund') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-amber-50';
      style.text = 'text-amber-600';
    }
    return style;
  }

  openTransactionDrawer(data: AdminPayoutTransaction, drawer: DrawerComponent) {
    this.transactionData = data;
    drawer.open();
  }

  private setupFilter() {
    this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.queryStringFilterService.patch(this.formQueryConverter.toQuery(value as any));
    });

    this.queryStringFilterService.initialValue.subscribe(query => {
      this.query = query;
      this.filterForm.patchValue(this.formQueryConverter.toForm(query));
    });
  }

  private getTransactions() {
    combineLatest([
      this.routerStore.getParam('instructorId').pipe(distinctUntilChanged()),
      this.queryStringFilterService.valueChanges
    ])
    .pipe(
      debounceTime(400),
      untilDestroyed(this)
    ).subscribe(([instructorId, query]) => {
      this.payoutService.getInstructorTransactions(Number(instructorId), query).subscribe(data => {
        this.transactionsPagination = data;
      });
    });
  }
}
