import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  PricingCurrencyService,
  PricingTierActions,
  PricingTierCreateUpdatePayload,
  PricingTierStatus,
  selectPricingTierCRUDStatus,
} from '@shared';
import { Observable } from 'rxjs';
import { Currency, ToastService } from 'thkee-common';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
})
export class PriceTiersNewComponent implements OnInit {
  pricingTierPayload!: PricingTierCreateUpdatePayload;
  currencyList$: Observable<Currency[]> = new Observable();
  status$: Observable<PricingTierStatus> = this.store.select(selectPricingTierCRUDStatus);

  constructor(
    private store: Store,
    private currencyStore: PricingCurrencyService,
    private router: Router,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.currencyList$ = this.currencyStore.getCurrencyList();
  }

  onSubmit(event: any) {
    let values = event?.values;
    values.currency_id = values.currency;
    values['price'] = parseFloat(values?.price) || 0;
    this.pricingTierPayload = values;
    this.store.dispatch(PricingTierActions.addPricingTier(this.pricingTierPayload));

    this.status$.subscribe((status) => {
      if (status === PricingTierStatus.Success) {
        this.toastService.message({
          message: `New Pricing Tier added successfully`,
        });
        this.router.navigate(['/price-management/tiers']);
      }
    });
  }
}
