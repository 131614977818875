import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntilDestroy, untilDestroyed } from '@shared';
import { PayoutService } from '@shared/services/payout/payout.service';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import { AdminInstructorBalance, AdminInstructorPayoutsQuery, AdminPayoutRequest, Pagination, PayoutRequestStatus, QueryFormConverter, QueryStringFilterService, RouterStoreService } from 'thkee-common';

const FILTER_PAYOUT_STATUS: { value: PayoutRequestStatus, label: string }[] = [
  { value: 'paid', label: 'Paid' },
  { value: 'requested', label: 'Requested' },
  { value: 'ready', label: 'Ready' },
  { value: 'deactivated', label: 'Deactivated' },
  { value: 'failed', label: 'Failed' },
];

@UntilDestroy()
@Component({
  selector: 'app-instructor-balance-requests',
  templateUrl: './instructor-balance-requests.component.html',
  styleUrls: ['./instructor-balance-requests.component.scss'],
  providers: [QueryStringFilterService.forComponent({ key: 'instructor-req' })]
})
export class InstructorBalanceRequestsComponent implements OnInit, AfterViewInit {
  instructorBalance?: AdminInstructorBalance;
  query: AdminInstructorPayoutsQuery = {};

  // Transaction Filter
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    },
    {
      key: 'payout_type',
      fieldGroup: [
        {
          key: 'on_demand',
          type: 'checkbox',
          className: '',
          props: {
            label: 'On-demand',
            required: true,
          },
        },
        {
          key: 'monthly',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Monthly',
            required: true,
          },
        },
      ]
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'status',
      fieldGroup: FILTER_PAYOUT_STATUS.map(data => {
        return {
          key: data.value,
          type: 'checkbox',
          className: '',
          props: {
            label: data.label,
            required: true
          },
        };
      })
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'requested_amount_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'requested_amount_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'no_of_transactions_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            type: 'number',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'no_of_transactions_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            type: 'number',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  payoutsPagination?: Pagination<AdminPayoutRequest>;

  private formQueryConverter = new QueryFormConverter<AdminInstructorPayoutsQuery>(
    ['status', 'payout_type']
  );

  constructor(
    private routerStore: RouterStoreService,
    private payoutService: PayoutService,
    private queryStringFilterService: QueryStringFilterService<AdminInstructorPayoutsQuery>,
  ) {}

  ngOnInit(): void {
    this.getPayouts();
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  resetFilter() {
    this.filterForm.reset();
  }

  paginate($event: PaginationChange) {
    this.queryStringFilterService.patch($event);
  }

  // Dropdown methode
  showingDropdown: string = '';
  toggleDropdown(type: string) {
    if (type === this.showingDropdown) {
      this.showingDropdown = '';
      return;
    }

    this.showingDropdown = type;
  }
  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Available') {
      style.bg = 'bg-blue-50';
      style.text = 'text-blue-600';
    } else if (status === 'Forced Refund') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-amber-50';
      style.text = 'text-amber-600';
    }
    return style;
  }

  private setupFilter() {
    this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.queryStringFilterService.patch(this.formQueryConverter.toQuery(value as any));
    });

    this.queryStringFilterService.initialValue.subscribe(query => {
      this.query = query;
      this.filterForm.patchValue(this.formQueryConverter.toForm(query));
    });
  }

  private getPayouts() {
    combineLatest([
      this.routerStore.getParam('instructorId').pipe(distinctUntilChanged()),
      this.queryStringFilterService.valueChanges
    ])
    .pipe(
      debounceTime(400),
      untilDestroyed(this)
    ).subscribe(([instructorId, query]) => {
      this.payoutService.getInstructorPayouts(Number(instructorId), query).subscribe(data => {
        this.payoutsPagination = data;
      });
    });
  }
}
