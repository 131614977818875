import { PricingCurrencyData } from 'thkee-common';

// Define the enum for status
export enum CurrencyCRUDStatus {
  Empty = '',
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export type PricingCurrencyState = {
  error: object;
  pricingCurrencyList: PricingCurrencyData[];
  pricingCurrencyData: PricingCurrencyData;
  status: CurrencyCRUDStatus;
};

export const initialPricingCurrencyState: PricingCurrencyState = {
  error: {},
  pricingCurrencyList: [],
  pricingCurrencyData: {
    id: '',
    name: '',
    currency_code: '',
    rate: '',
    rounding_type: '',
    is_primary_exchange_rate_currency: false,
    is_primary_store_currency: false,
    pricing_tier_status: false,
    force: false,
    published: false,
    country: [],
    symbol: '',
    primary_currency: '',
  },
  status: CurrencyCRUDStatus.Empty,
};

export interface PricingCurrencyPayload {
  detailed: boolean;
  search?: string;
  is_primary_exchange_rate_currency?: boolean;
  is_primary_store_currency?: boolean;
  pricing_tier_status?: boolean;
}

export interface PricingCurrencyCreateUpdatePayload {
  id?: string;
  name?: string;
  currency_code?: string;
  rate?: string;
  rounding_type?: string;
  is_primary_exchange_rate_currency?: boolean;
  is_primary_store_currency?: boolean;
  pricing_tier_status?: boolean;
  force?: boolean;
  published?: boolean;
  country?: number[];
}

export interface PricingCurrencyLoadRemovePayload {
  id: string;
}

export interface CurrencyData {
  id?: string;
  name: string;
  currency_code: string;
  rate: string;
  rounding_type: boolean;
}
