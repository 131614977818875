import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-upcoming-payout-monthly',
  templateUrl: './upcoming-payout-monthly.component.html',
  styleUrls: ['./upcoming-payout-monthly.component.scss'],
})
export class UpcomingPayoutMonthlyComponent implements OnInit {
  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Method</div>`,
    },
    {
      key: 'paypal',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paypal (16)',
        required: true,
      },
    },
    {
      key: 'payoneer',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Payoneer (16)',
        required: true,
      },
    },
    {
      key: 'bank',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Bank to Transfer (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  resetFilter() {
    this.filterForm.reset();
  }

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Payouts',
        url: '/ecommerce/payout/paid-payout/all',
      },
      {
        label: 'Upcoming Payouts',
        url: '/ecommerce/payout/upcoming-payouts/monthly',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
  }
  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Checked function
  checkAllCheckBox(ev: any) {
    this.dummyData.forEach((x: any) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked() {
    this.checkedItemsCount();
    return this.dummyData.every((p: any) => p.checked);
  }
  checkedItemsCount() {
    let count = 0;
    this.dummyData.forEach((x: any) => {
      if (x.checked) count++;
    });
    return count;
  }
  checked(ev: any) {
    ev.stopPropagation();
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 1,
      title: 'Upcoming Payout Period',
      value: '2024-02-20T10:30:31.398Z',
      type: 'date',
      tooltip: 'The Upcoming payout period in July 2022 will be processed on September 3, 2022.',
    },
    {
      id: 1,
      title: 'Due Date',
      value: '2024-02-20T10:30:31.398Z',
      type: 'date',
      tooltip: '',
    },
    {
      id: 1,
      title: 'Paypal Balances',
      value: 10000,
      type: 'currency',
      tooltip: 'The total PayPal balance needed to cover the payment amount that will be disbursed to instructors',
    },
    {
      id: 2,
      title: 'Bank Balances',
      value: 78,
      type: 'currency',
      tooltip: 'The total bank balance needed to cover the payment amount that will be disbursed to instructors',
    },
    {
      id: 3,
      title: 'Payoneer Balances',
      value: 228,
      type: 'currency',
      tooltip: '',
    },
  ];
  public paymentCardHistory: any = [
    {
      id: 5,
      title: 'Total To Pay',
      value: 24,
      type: 'currency',
      tooltip: 'The total balance needed to fulfill the payment amount that will be disbursed to the instructors',
    },
    {
      id: 5,
      title: 'Number of Payees',
      value: 24,
      type: 'number',
      tooltip: 'The total number of payees registered for this payout period',
    },
    {
      id: 5,
      title: 'Total Transactions',
      value: 24,
      type: 'number',
      tooltip: 'The total number of transactions registered for this payout period',
    },
    {
      id: 7,
      title: 'Total Refund',
      value: 1024,
      type: 'currency',
      tooltip: 'The total amount of refund registered in this payout period',
    },
  ];
  public paymentMethodHistory: any = [
    {
      id: 8,
      title: 'Bank Payment',
      value: 2498,
      type: 'currency',
      tooltip: 'Total payout amount using Bank Transfer method for this payout period',
    },
    {
      id: 8,
      title: 'Paypal Payment',
      value: 2498,
      type: 'currency',
      tooltip: 'Total payout amount using PayPal method for this payout period',
    },
    {
      id: 8,
      title: 'Payoneer Payment',
      value: 2498,
      type: 'currency',
      tooltip: '',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      transactions: 23,
      payment_methode: 'Paypal',
      pending_amount: 44,
      available_amount: 34,
      total_amount: 344,
      request_date: '2024-02-20T10:30:31.398Z',
    },
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      transactions: 23,
      payment_methode: 'Paypal',
      pending_amount: 44,
      available_amount: 34,
      total_amount: 344,
      request_date: '2024-02-20T10:30:31.398Z',
    },
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      transactions: 23,
      payment_methode: 'Paypal',
      pending_amount: 44,
      available_amount: 34,
      total_amount: 344,
      request_date: '2024-02-20T10:30:31.398Z',
    },
    {
      id: '122',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      transactions: 23,
      payment_methode: 'Paypal',
      pending_amount: 44,
      available_amount: 34,
      total_amount: 344,
      request_date: '2024-02-20T10:30:31.398Z',
    },
    {
      id: '121',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      transactions: 23,
      payment_methode: 'Paypal',
      pending_amount: 44,
      available_amount: 34,
      total_amount: 344,
      request_date: '2024-02-20T10:30:31.398Z',
    },
  ];
}
