import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { SharePricing, SharePricingService, SharePricingUpdatePayload, ShareTypes } from 'thkee-common';

const initialEditModel: SharePricingUpdatePayload = {
  instructor_share: 0,
  platform_share: 0,
};

@Component({
  selector: 'app-form-price-shares',
  templateUrl: './price-shares.component.html',
  styleUrls: ['./price-shares.component.scss'],
})
export class FormPriceSharesComponent implements OnInit {
  @Input() data: SharePricing[] = [];
  @Input() enableEdit: boolean = true;
  ShareTypes = ShareTypes;
  editId: number | null = null;
  editModel: SharePricingUpdatePayload = {
    instructor_share: 0,
    platform_share: 0,
  };
  // sharePricingData: SharePricing[] = this.data;

  constructor(private sharePricingService: SharePricingService) {}

  ngOnInit(): void {}

  editSharePricing(data: SharePricing) {
    console.log();
    if (data) {
      this.editId = data.id;
      this.editModel.instructor_share = data.instructor_share;
      this.editModel.platform_share = data.platform_share;
    }
  }

  saveSharePricing() {
    console.log('Save API CALL HERE:', this.editId, this.editModel);
    if (this.editId) {
      // Save and replace current index data
      this.sharePricingService.updateSharePricing(this.editId, this.editModel).subscribe((response) => {
        if (response.id) {
          const listIndex = _.findIndex(this.data, { id: response.id });
          this.data[listIndex] = { ...this.data[listIndex], ...this.editModel };
        }
      });
    }

    // Reset
    this.editId = null;
    // this.editModel = initialEditModel;
  }

  onShareChange(newInstructorShare: string | number, type: string) {
    const max = 100;
    newInstructorShare = Number(String(newInstructorShare).replace(/[^0-9]/g, '')); // convert to number and remove alphabeth
    if (newInstructorShare > max) {
      newInstructorShare = max; // set to 100 if greather than max
    }
    if (type == 'instructor_share') {
      this.editModel.platform_share = max - Number(newInstructorShare);
      this.editModel.instructor_share = Number(newInstructorShare);
    }
    if (type == 'platform_share') {
      this.editModel.instructor_share = max - Number(newInstructorShare);
      this.editModel.platform_share = Number(newInstructorShare);
    }
  }
}
