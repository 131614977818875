import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PriceGeolocationEditComponent } from './edit/edit.component';
import { PriceGeolocationNewComponent } from './new/new.component';
import { PriceGeolocationComponent } from './price-geolocation.component';
import { PriceGeolocationViewComponent } from './view/view.component';

export const Components = [
  PriceGeolocationComponent,
  PriceGeolocationNewComponent,
  PriceGeolocationEditComponent,
  PriceGeolocationViewComponent,
];
export const Routing = [
  {
    path: 'geolocation',
    component: PriceGeolocationComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Geolocation' },
  },
  {
    path: 'geolocation/new',
    component: PriceGeolocationNewComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Add Geolocation' },
  },
  {
    path: 'geolocation/:id',
    component: PriceGeolocationViewComponent,
    data: { title: marker('Price Management'), breadcrumb: 'View Geolocation' },
  },
  {
    path: 'geolocation/:id/edit',
    component: PriceGeolocationEditComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Edit Geolocation' },
  },
];
