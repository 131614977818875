<ng-container *ngIf="{
    pricingCountryList: (pricingCountryList$ | async) ?? []
  } as vm">
  <div class="price-tiers p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>{{ title }}</h3>
        <a routerLink="./new">
          <button class="btn-blue">
            <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
            <span>Add Country</span>
          </button>
        </a>
      </div>
      <div class="actions flex gap-4">
        <!-- TODO: Convert to formly -->
        <div>
          <button class="btn-neutral">
            <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
            <span>Filter</span>
          </button>
        </div>
        <div class="input-combine flex">
          <div class="select2">
            <div class="relative">
              <a type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
                class="btn-neutral rounded-r-none">
                <div class="selected-item truncate">Countries ID</div>
                <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
              </a>
              <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"
                class="select-items hidden" ng-reflect-ng-class="hidden">
                <li id="listbox-option-0" role="option" class="item">Item 1</li>
                <li id="listbox-option-0" role="option" class="item">Item 2</li>
                <li id="listbox-option-0" role="option" class="item">Item 3</li>
              </ul>
            </div>
          </div>
          <div class="input-group">
            <div class="group !rounded-l-none">
              <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
              <input placeholder="Enter your full name" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{vm.pricingCountryList | json}} -->
    <div class="tier-list">
      <app-data-table title="Country" badge="195 Countries">
        <ng-container table>
          <table class="w-full text-left">
            <thead class="heading">
              <tr>
                <th scope="col">
                  <div class="col-name">ID</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Name</div>
                </th>
                <th scope="col" class="">
                  <div class="sortable">
                    <div class="col-name">Allows Billing</div>
                  </div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Two letter ISO code</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Three letter ISO code</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Numeric ISO code</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Currency code</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Subject to VAT</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Sort Number</div>
                </th>
                <!-- <th scope="col" class="">
                  <div class="col-name">Pricing Tier Status</div>
                </th> -->
                <th scope="col" class="flex justify-center gap-2">
                  <div class="col-name">Option</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="" class="text-left" *ngFor="let country of vm.pricingCountryList">
                <td class="">
                  <span class="course-id"> {{ country.id }} </span>
                </td>
                <td>
                  <div class="">
                    <a routerLink="./{{ country.id }}" class="course-name text-blue-600 underline">{{
                      country.name
                      }}</a>
                  </div>
                </td>
                <td>
                  <span class="course-price">
                    <svg-icon *ngIf="country.allow_billing" src="@assets/images/icons/green-check.svg"
                      class="btn-icon h-4 w-4"></svg-icon>
                    <svg-icon *ngIf="!country.allow_billing" src="@assets/images/icons/x-mark-red.svg"
                      class="btn-icon h-4 w-4"></svg-icon>
                  </span>
                </td>
                <td>
                  <span class="course-sold"> {{ country.two_letter_iso_code }} </span>
                </td>
                <td>
                  <span class="course-sold"> {{ country.three_letter_iso_code }} </span>
                </td>
                <td>
                  <span class="course-sold"> {{ country.numeric_iso_code }} </span>
                </td>
                <td>
                  <span class="course-sold"> {{ country.currency_code }} </span>
                </td>
                <td>
                  <span class="course-sold">
                    <svg-icon *ngIf="country.subject_to_vat" src="@assets/images/icons/green-check.svg"
                      class="btn-icon h-4 w-4"></svg-icon>
                    <svg-icon *ngIf="!country.subject_to_vat" src="@assets/images/icons/x-mark-red.svg"
                      class="btn-icon h-4 w-4"></svg-icon>
                  </span>
                </td>
                <td>
                  <span class="course-sold"> {{ country.sort_number }} </span>
                </td>
                <!-- <td>
                  <span class="course-sold">Active</span>
                </td> -->
                <td>
                  <div class="flex justify-center gap-2">
                    <a routerLink="./{{ country.id }}/edit">
                      <button class="btn-amber-soft btn-sm px-2">
                        <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
                      </button>
                    </a>
                    <button class="btn-outline-red btn-sm px-2" (click)="deleteConfirmation(country)">
                      <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon h-4 w-4"></svg-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </app-data-table>
    </div>
  </div>
</ng-container>