export * from './course-categories/course-categories.component';
export * from './course-topics/course-topics.component';
export * from './settings.component';

import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CourseCategoriesComponent } from './course-categories/course-categories.component';
import { CourseTopicsComponent } from './course-topics/course-topics.component';

export const Components = [CourseCategoriesComponent, CourseTopicsComponent];

export const Routing = [
  // {
  //   path: 'settings',
  //   component: SettingsComponent,
  //   data: { title: marker('Settings'), breadcrumb: 'Settings' },
  // },
  {
    path: 'course-categories',
    component: CourseCategoriesComponent,
    data: { title: marker('Category Management'), breadcrumb: 'Category' },
  },
  {
    path: 'course-topic',
    component: CourseTopicsComponent,
    data: { title: marker('Topics Management'), breadcrumb: 'Topics' },
  },
];
